import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Error } from '@a0/support-center-components';

const ERROR_MESSAGE = 'We cannot log you in from this browser because of web storage issues. Please try again a different browser.';

/**
 * Displays an alert to the user if there was a problem
 * with their local or session storage
 * @param {object} props
 * @param {object} props.error
 * @returns {object} StorageError
 */
export const StorageError = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div className="bg-dots">
      <Container>
        <Error message={ERROR_MESSAGE} />
      </Container>
    </div>
  );
};

StorageError.propTypes = {
  error: PropTypes.object
};

function mapStateToProps(state) {
  return {
    error: state.storageService.error
  };
}

export default connect(mapStateToProps, {})(StorageError);
