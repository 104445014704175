import React from "react";
import Alert from "react-bootstrap/Alert";
import { useCoveoController } from "./utils";
import { buildQueryError } from "@coveo/headless";

export const QueryError = () => {
  const { state } = useCoveoController(
    (headlessEngine) => buildQueryError(headlessEngine),
    "QueryError"
  );

  if (!state.hasError) {
    return null;
  }

  return (
    <div>
      <Alert variant="danger">
        <h4>Oops! There was an error performing the search</h4>
        <p>{state.error && state.error.message}</p>
      </Alert>
    </div>
  );
};
