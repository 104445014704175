import initialState from '../initialState';

import {
  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN,
  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS,
  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR
} from '../actionTypes';

export default (state = initialState.document, action) => {
  switch (action.type) {
    case LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN:
      return { loading: true, error: null };

    case LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR:
      return { loading: false, error: action.errorMessage };

    case LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS:
      return { loading: false, error: null };
    
    default:
      return state;
  }
};