import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

class AdditionalFieldsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { showAddFields: props.showAddFields };
    this.toggleSwitch = this.toggleSwitch.bind(this);
  }

  toggleSwitch(event) {
    const showAddFieldsValue = !this.state.showAddFields;

    // Since onChanges is requiring and event we need to send a synthetic one
    const showAddFieldsEvent = {
      target: { name: 'showAddFields', value: showAddFieldsValue }
    };

    this.props.onChange(showAddFieldsEvent);
    this.setState({ showAddFields: showAddFieldsValue });
  }

  render() {
    return (
      <div className="form-group additional-fields">
        <div className="switch-row">
          <label className="col-md-10">
            <strong>
              We have some common questions for this ticket type, answering them
              will help us solve your issue quicker
            </strong>
          </label>
          <div className="ui-switch ui-switch-labeled ui-switch-sm pull-right">
            <input
              name="showAddFields"
              type="checkbox"
              checked={this.state.showAddFields}
              onChange={this.toggleSwitch}
            />
            <label
              className="status"
              data-label-true="Sure!"
              data-label-false="Skip"
            />
          </div>
        </div>
        {this.state.showAddFields && (
          <div className="type-box">{this.props.children}</div>
        )}
      </div>
    );
  }
}

AdditionalFieldsWrapper.propTypes = {
  children: PropTypes.node,
  showAddFields: PropTypes.bool,
  onChange: PropTypes.func
};

AdditionalFieldsWrapper.defaultProps = {
  showAddFields: true,
  onChange: noop
};

export default AdditionalFieldsWrapper;
