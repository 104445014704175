import { request } from 'api';

class TenantApi {

  static getAll(userId, allTenants, options) {
    // Need to strip off "mr|" prefix to fetch all tenants for user
    const formattedUserId = userId.replace(/^mr\|/, '');
    let url = `tenants?user_id=${encodeURIComponent(formattedUserId)}&allTenants=${allTenants}`;
    return request(url, options);
  }

  static getTenantsSummary(userId, options) {
    return request('tenants/summary?user_id=' + userId, options);
  }

  static getTenantsAgreement(userId, options, allTenants = false) {
    return request(`tenants/custom_agreements?user_id=${encodeURIComponent(userId)}&allTenants=${allTenants}`, options);
  }

  static extendTrial(tenantId, data) {
    return request(`tenants/${tenantId}/trial/extension`, { method: 'post', data });
  }

  static updateEnvironment(id, data) {
    return request(`tenants/${id}/environment`, { method: 'patch', data });
  }

  static getClients(tenantId) {
    return request(`tenants/${tenantId}/clients`, { method: 'get' });
  }
}

export default TenantApi;
