import {
  buildSearchEngine as buildCoveoSearchEngine,
  getOrganizationEndpoints
} from "@coveo/headless";
import { createRenewInvalidAccessTokenMiddleware } from "./renewInvalidTokenMiddleware";
import { getSearchTokenResponse, renewAccessToken, TokenType } from "./coveoToken";

export async function buildSearchEngine() {
  let headlessEngine;
  const tokenResponse = await getSearchTokenResponse(TokenType.SEARCH);
  const getEngine = () => headlessEngine;
  const renewSearchToken = () => renewAccessToken(TokenType.SEARCH);

  headlessEngine = buildCoveoSearchEngine({
    configuration: {
      accessToken: tokenResponse.token,
      renewAccessToken: renewSearchToken,
      organizationId: tokenResponse.coveo_org_id,
      organizationEndpoints: getOrganizationEndpoints(tokenResponse.coveo_org_id)
    },
    middlewares: [createRenewInvalidAccessTokenMiddleware(getEngine, renewSearchToken)]
  });

  return headlessEngine;
}
