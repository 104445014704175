import React from 'react';
import PropTypes from 'prop-types';

import { Auth0Notification } from '@a0/support-center-components';

const SwitchSubscriptionAlert = ({subscription}) => (
  <Auth0Notification type="primary">
    To open a support ticket in your <strong className="capitalize">{subscription}</strong> subscription please select the subscription from the menu above.
  </Auth0Notification>
);

SwitchSubscriptionAlert.propTypes = {
  subscription: PropTypes.string.isRequired
};

export default SwitchSubscriptionAlert;
