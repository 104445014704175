import React from "react";
import { buildQuerySummary } from "@coveo/headless";
import { useCoveoController } from "./utils";

export const NoResultsQuerySummary = () => {
  const { state } = useCoveoController(
    (headlessEngine) => buildQuerySummary(headlessEngine),
    "QuerySummary-NoResults"
  );

  const { hasResults, firstSearchExecuted, query } = state;

  if (firstSearchExecuted && !hasResults) {
    return (
      <div>
        No results found for <code>{query}</code>. Would you like to try another search term?
      </div>
    );
  }

  return null;
};
