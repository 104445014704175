export function setUserAttributes(userInfo, subscriptionInfo) {
  if (window.drift && typeof window.drift.identify === 'function') {
    const res = window.drift.identify(userInfo.user_id, {
      email: userInfo.email,
      name: userInfo.name,
      first_name: userInfo.given_name,
      last_name: userInfo.family_name,
      paying: subscriptionInfo.paying,
      trial: subscriptionInfo.trial
    });
  }
}

export function logout() {
  if (window.drift && typeof window.drift.reset === 'function') {
    window.drift.reset();
  }
}
