import { default as tickets } from '../features/tickets/ticketApi';
import { default as tenants } from '../features/tenants/tenantApi';
import { default as notifications } from '../features/notifications/notificationApi';
import { default as reports } from '../features/reports/reportsApi';
import { api as appliances } from '../features/appliances';
import { default as users } from '../features/login/userApi';
import { default as checks } from '../features/login/checkApi';
import { default as emailVerification } from '../features/emailVerification/emailVerificationApi';
import { default as compliance } from '../features/compliance/api';
import { default as localities } from '../features/common/localitiesApi';
import { default as search } from '../features/search/searchApi';

export { default as request, requestUnauthenticated } from './request';

export default {
  tickets,
  users,
  tenants,
  notifications,
  checks,
  reports,
  appliances,
  emailVerification,
  compliance,
  search,
  localities
};
