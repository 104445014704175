import versions from './versions';
import appliances from './appliances';

const respond = data => new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve(Object.assign([], data));
  }, 0);
});

export default {
  getAll() {
    return respond(appliances);
  },

  getVersions() {
    return respond(versions);
  },

  appliances,
  versions
};

export * from './appliances';
export * from './versions';
