import React from "react";
import { buildQuerySummary } from "@coveo/headless";
import { useCoveoController } from "./utils";

export const QuerySummary = () => {
  const { state } = useCoveoController(
    (headlessEngine) => buildQuerySummary(headlessEngine),
    "QuerySummary"
  );

  const { hasResults, hasQuery, firstSearchExecuted, firstResult, lastResult, total, query } =
    state;
  if (!firstSearchExecuted || !hasResults) {
    return null;
  }

  return (
    <p>
      {`Results ${firstResult}-${lastResult} of ${total}`}
      {hasQuery && (
        <React.Fragment>
          {" "}
          for <code>{query}</code>
        </React.Fragment>
      )}
    </p>
  );
};
