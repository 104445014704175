/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './history';
import store from './redux/store';

import { bindStore } from './services/storage';
bindStore(store);

import { getSelectedTenant } from '@a0/support-center-common/selectors/common';
import { handleRedirectCallback } from './features/login/actions/authActions';
import { loadOrgTickets, loadMyTickets } from './features/tickets/actions/ticketActions';
import * as types from './redux/actionTypes';
import addPlanData from './utils/metrics/addPlanData';
import Metrics from './utils/metrics';
import logger from './utils/logger';
import routes from './routes';

// TODO: Remove temporary fix for https://github.com/JedWatson/react-input-autosize/issues/84
import 'create-react-class';

import '../node_modules/animate.css/animate.min.css';
import '../node_modules/react-select/dist/react-select.css';
import './styles/styles.styl';
import "./override.css";

const OMIT_FROM_METRICS = ['/tenants'];

const {
  AUTH0_DOMAIN,
  SUPPORT_CENTER_CLIENT_ID,
  SUPPORT_CENTER_CALLBACK_URL,
  SUPPORT_CENTER_SCOPES,
  SUPPORT_CENTER_API_AUDIENCE
} = window.scConfig || {};

// clean the query string if passed through from email verification
if (/^\?supportSignUp=true(.*)success=true$/.test(window.location.search)) {
  window.location = '/login';
}

// add current subscription data to all events
Metrics.setAugmentPayload(addPlanData(store));

// track page views
history.listen(location => {
  if (OMIT_FROM_METRICS.indexOf(location.pathname) === -1) {
    Metrics.page();
  }
});

store.subscribe(() => {
  switch (store.getState().lastAction.type) {
    case types.CREATE_TICKET_SUCCESS:
    case types.COMMENT_TICKET_SUCCESS:
      store.dispatch(loadMyTickets());
      store.dispatch(
        loadOrgTickets(getSelectedTenant(store.getState().tenants))
      );
      break;
    default:
      break;
  }
});

function renderIt(routes) {
  render(
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={SUPPORT_CENTER_CLIENT_ID}
      onRedirectCallback={handleRedirectCallback}
      useRefreshTokensFallback
      useRefreshTokens
      authorizationParams={{
        redirect_uri: SUPPORT_CENTER_CALLBACK_URL || window.location.origin,
        audience: SUPPORT_CENTER_API_AUDIENCE,
        scope: SUPPORT_CENTER_SCOPES.join(" ")
      }}>
      <Provider store={store}>
        <Router history={history} routes={routes} />
      </Provider>
    </Auth0Provider>,
    document.getElementById('app')
  );
}

renderIt(routes(store));

if (module.hot) {
  module.hot.accept('./routes', () => {
    const hotRoutes = require('./routes').default;
    renderIt(hotRoutes);
  });
}
