import { request } from 'api';

import axios from 'axios';
import urls from '@a0/support-center-common/constants/appliances';

export default {
  getAll(masterTenantId) {
    return request('private-saas?master_tenant_id=' + masterTenantId);
  },

  getVersions() {
    return axios({ url: urls.CHANGELOG_JSON_URL })
    .then(result => result.data);
  }
};
