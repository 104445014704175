import { uniqBy, sumBy, filter } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { SummaryBar, SummaryBarItem, Loading, Error } from '@a0/support-center-components';
import { getMessage } from '@a0/support-center-common/lib/getMessage';

import TenantSummaryCards from '../../tenants/components/TenantSummaryCards';

import Metrics from '../../../utils/metrics';

import { tenantRoles } from '@a0/support-center-common/constants/tenants';


class PublicSaas extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      search: "",
      selectedFilterOption: "all-tenants"
    };

    this.searchChanged = this.searchChanged.bind(this);
  }

  searchChanged(event) {
    this.setState({ search: event.target.value });
  }

  searchTenants(tenants) {
    const term = this.state.search.toUpperCase();
    return this.state.search
      ? filter(
          tenants,
          t =>
            t.id.toUpperCase(term).indexOf(term) > -1 ||
            t.name.toUpperCase(term).indexOf(term) > -1
        )
      : tenants;
  }

  trackChildTenants(isMasterTenantAdmin) {
    const { loading, error, selectedSubscription, tenants } = this.props;

    if (loading || error || !selectedSubscription || !isMasterTenantAdmin) {
      return;
    }

    Metrics.track("success:sc:tenants:loadChildTenants", {
      trackData: `${selectedSubscription.name}, ${selectedSubscription.tenant}, ${tenants.length}`
    });
  }

  getNotification(isMasterTenantAdmin) {
    const { selectedSubscription, loading, error, tenants } = this.props;

    if (!selectedSubscription || loading || error || !selectedSubscription.tenant) {
      return null;
    }

    if (!isMasterTenantAdmin) {
      if (!selectedSubscription.childTenants || selectedSubscription.childTenants <= tenants.length) {
        return null;
      }
      return `The ${selectedSubscription.name} subscription has a total of ${selectedSubscription.child_tenants} tenants. You are seeing the ${tenants.length} tenants to which you have administration access.`;
    }

    return `The ${selectedSubscription.name} subscription has a total of ${tenants.length} tenants. As an administrator of the master tenant ${selectedSubscription.tenant} you can see the complete list here.`;
  }

  render() {
    const {
      selectedSubscription,
      tenants,
      loading,
      error,
      updating,
      locationRef
    } = this.props;

    const showingTenants = this.searchTenants(tenants);
    const isMasterTenantAdmin = tenants.some(t => t.role === tenantRoles.MASTER_ADMIN_TENANT_ROLE);

    this.trackChildTenants(isMasterTenantAdmin);
    const notification = this.getNotification(isMasterTenantAdmin);

    if (tenants.length === 0)
      return (
        <Loading show={loading}>
          <Error message={error}>
            <div>No tenants Found</div>
          </Error>
        </Loading>
      );

    return (
      <Loading show={loading}>
        <Error message={error}>
          <div>
            <div className="row pad-top">
              <div className="col-xs-12">
                <SummaryBar notification={notification}>
                  <SummaryBarItem
                    iconClassName="icon-budicon-661"
                    title="Tenants"
                    data={{
                      Total: tenants.length,
                      Regions: uniqBy(tenants, "region").length || 0
                    }}
                  />
                  <SummaryBarItem
                    iconClassName="icon-budicon-300"
                    title="Users"
                    data={{
                      Total: sumBy(tenants, "total_users") || 0,
                      link: <Link to="/reports/quota">See Usage</Link>
                      // 'Active': sumBy(tenants, 'active_users_last_30d') || 0
                    }}
                  />
                  <SummaryBarItem
                    iconClassName="icon-budicon-375"
                    title={getMessage("CLIENTS")}
                    data={{
                      Apps: sumBy(tenants, "total_clients") || 0,
                      Connections: sumBy(tenants, "total_connections") || 0
                    }}
                  />
                  <SummaryBarItem
                    iconClassName="icon-budicon-173"
                    title="Rules"
                    data={{
                      Total: sumBy(tenants, "total_rules") || 0,
                      Active: sumBy(tenants, "active_rules") || 0
                    }}
                  />
                </SummaryBar>
              </div>
            </div>
            <div className="row search-filter-container pad-top">
              <div className="col-xs-12">
                <i className="icon-budicon-489" />
                <input
                  type="search"
                  name="search"
                  id="search-input"
                  placeholder="Search for Tenants"
                  onChange={this.searchChanged}
                  value={this.state.search}
                />
              </div>
            </div>
            <TenantSummaryCards
              tenants={showingTenants}
              updatingTenants={updating}
              handleTenantEnvironmentUpdate={
                this.props.handleTenantEnvironmentUpdate
              }
              locationRef={locationRef}
              selectedSubscription={selectedSubscription}
            />
          </div>
        </Error>
      </Loading>
    );
  }
}

PublicSaas.propTypes = {
  handleTenantEnvironmentUpdate: PropTypes.func.isRequired,
  selectedSubscription: PropTypes.object,
  tenants: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  updating: PropTypes.array.isRequired,
  error: PropTypes.string,
  locationRef: PropTypes.string
};

export default PublicSaas;
