import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const TrialAccountContact = ({ handleContactSales, handleAskForum, handleOpenTicket, floating, allowedToOpenTicket }) => {
  return (
    <div className={`${floating ? 'free-account-contact' : ''} trio-button-group`}>
        <Link id="ask-forum" onClick={handleAskForum} href="https://community.auth0.com" target="_blank" className="btn btn-md btn-primary">Ask our Community</Link>
      {allowedToOpenTicket ? <Link id="open-ticket" onClick={handleOpenTicket} to="/tickets/new" className="btn btn-success btn-md">Open Ticket</Link> : ""}
        <Link id="talk-to-sales" onClick={handleContactSales} href="#" className="talk-to-sales-link btn btn-transparent btn-md">Talk to Sales</Link>
    </div>
  );
};

TrialAccountContact.propTypes = {
  handleContactSales: PropTypes.func.isRequired,
  handleAskForum: PropTypes.func.isRequired,
  handleOpenTicket: PropTypes.func.isRequired,
  floating: PropTypes.bool,
  allowedToOpenTicket: PropTypes.bool.isRequired
};

TrialAccountContact.defaultProps = {
  floating: true
};

export default TrialAccountContact;
