import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import types from '@a0/support-center-common/constants/tickets/types';
import severities from '@a0/support-center-common/constants/tickets/customerSelectedSeverities';
import customerReasons from '@a0/support-center-common/constants/tickets/customerReasons';
import followUpQuestions from '@a0/support-center-common/constants/tickets/followUpQuestions';
import { isTrialTenant } from '@a0/support-center-common/lib/tenants';
/**
 * Transformation function for deriving a ticket
 * payload from the ticket state in react
 * @param   {Object} ticketState
 * @param   {Object} additionalQuestionState
 * @param   {Object} selectedTenant
 * @returns {Object} ticket
 */
export function ticketPayloadFromState(ticketState, additionalQuestionState, selectedTenant) {
  return {
    ...ticketState,
    comment: resolveComment(ticketState, additionalQuestionState),
    customerReason: resolveCustomerReason(ticketState),
    customerSelectedSeverity: resolveSeverity(ticketState, selectedTenant),
    timezone: moment.tz.guess(),
    type: types.TYPES[ticketState.type] || ''
  };
}

/**
 * Appends additional questions to the ticket comment as a string
 * @param   {Object} ticketState
 * @param   {Object} additionalQuestionState
 * @returns {String} comment
 */
function resolveComment(ticketState, additionalQuestionState) {
  if (isEmpty(additionalQuestionState)) {
    return ticketState.comment;
  }

  // Append additionalQuestions to the comment
  const additionalQuestionsString = Object.keys(additionalQuestionState).map(key => {
    const question = followUpQuestions.FOLLOW_UP_QUESTIONS[key];
    const answer = additionalQuestionState[key];
    return `${question}\n${answer || 'Not provided'}`;
  });

  return `${ticketState.comment}\n\n__Additional Data__:\n ${additionalQuestionsString.join('\n\n')}`;
}

/**
 * Sets Severity if appropriate according to pre-determined rules
 * @param   {Object} ticketState
 * @param   {Object} selectedTenant
 * @returns {String} customerSelectedSeverity
 */
function resolveSeverity(ticketState, tenant) {
  if (isTrialTenant(tenant)) {
    return severities.CUSTOMER_SELECTED_SEVERITIES[severities.NORMAL_SEVERITY];
  }

  if (ticketState.customerSelectedSeverity) {
    return ticketState.customerSelectedSeverity;
  }

  // If there is only one specified in the constants, use it
  const availableSeverities = severities.CUSTOMER_SELECTED_SEVERITIES_BY_TYPE[ticketState.type] || {};
  const severityKeys = Object.keys(availableSeverities);

  if (severityKeys.length !== 1) {
    return;
  }

  return availableSeverities[severityKeys[0]];
}

/**
 * Sets CustomerReason if appropriate according to pre-determined rules
 * @param   {Object} ticketState
 * @returns {String} customerReason
 */
function resolveCustomerReason(ticketState) {
  // If there is only one specified in the constants, use it
  const availableCustomerReasons = customerReasons.CUSTOMER_REASONS_BY_TYPE[ticketState.type] || {};
  const customerReasonKeys = Object.keys(availableCustomerReasons);

  if (customerReasonKeys.length === 1) {
    return availableCustomerReasons[customerReasonKeys[0]];
  }

  // Otherwise use the set reason
  if (ticketState.customerReason) {
    return customerReasons.CUSTOMER_REASONS[ticketState.customerReason];
  }
}
