import React from 'react';
import PropTypes from 'prop-types';
import { IndexLink, Link } from 'react-router';
import { HotjarMasked } from '@a0/support-center-components';

const STEP_CREATE = 'create';
const STEP_ADD_COMMENT = 'update';

const OfflineTicketResultPage = ({
  ticket, ticketSubject, step, handleUpdateLink
}) => {
  if (step !== STEP_CREATE && step !== STEP_ADD_COMMENT) {
    throw new Error(`step attribute should be "${STEP_CREATE}" or "${STEP_ADD_COMMENT}" but received: ${step}`);
  }

  const printSuccessMsg = (step, ticket) => {
    if (step === STEP_CREATE && ticket.id) {
      return `Your request #${ticket.id} has been received and is being reviewed by our support staff.`;
    } else if (step === STEP_CREATE) {
      return 'Your request has been received and is being reviewed by our support staff. It can take a few minutes until it appears in the ticket list.';
    } else {
      return 'There was a problem creating your suppor ticket, please try again.';
    }
  };

  return (
    <div className="ticket-result">
      <div className="bg-dots page-header-dots hide-for-mobiles"></div>
      <div className="row">
        <div className="bg-dots page-header-dots">
          <div className="container page-canvas col-md-6 col-md-offset-3">

            <h2>Ticket created!</h2>
            <p>{printSuccessMsg(step, ticket)}</p>

          </div>
        </div>
      </div>
    </div>
  );
};

OfflineTicketResultPage.propTypes = {
  ticket: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  handleUpdateLink: PropTypes.func,
  ticketSubject: PropTypes.string
};

export default OfflineTicketResultPage;
