import React, { useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { buildRecommendationList, buildContext } from "@coveo/headless/recommendation";

import { RecommendationList } from "./RecommendationsList";
import { useCoveoController } from "./utils";
import { throttle } from "lodash";
import { CollapsibleRecommendationsList } from "./CollapsibleRecommendationsList";
import logger from "../../../utils/logger";

const MIN_SUBJECT_LENGTH = 4;
const THROTTLED_SEARCH_MS = 2000;

// this component will fire a new search every time the context is changed
export const RecommendationsSection = ({ context }) => {
  const { state, headlessEngine, controller } = useCoveoController(
    (headlessEngine) =>
      buildRecommendationList(headlessEngine, {
        options: {
          numberOfRecommendations: 10
        }
      }),
    "ResultsList"
  );

  const { controller: contextController } = useCoveoController(
    (headlessEngine) => buildContext(headlessEngine),
    "Context"
  );

  // the refresh function depends on controller and contextController,
  // so we memoize it with useCallback
  const refresh = useCallback((context) => {
    // context carries subject and description typed by the user
    // which are used to tailor the recommendations
    if (context && context.subject && context.subject.trim().length >= MIN_SUBJECT_LENGTH) {
      contextController.set(context);
      controller.refresh();
    }
  }, [contextController, controller]);

  // a throttled refresh protects again accidental excesive use of the search
  const throttledRefresh = useMemo( () => throttle(refresh, THROTTLED_SEARCH_MS),
    []
  );

  useEffect(() => {
    throttledRefresh(context);
  }, [context]);

  if (state.error) {
    logger.warn(state.error, { subject: context && context.subject }, "Couldn't fetch recommended articles.");

    return null;
  }

  if (!state.recommendations.length) {
    return null;
  }

  return (
    <div className="recommendations">
      <CollapsibleRecommendationsList>
        <RecommendationList
          headlessEngine={headlessEngine}
          recommendations={state.recommendations}
        />
      </CollapsibleRecommendationsList>
    </div>
  );
};

RecommendationsSection.propTypes = {
  context: PropTypes.shape( {
    subject: PropTypes.string.isRequired,
    description: PropTypes.string
  })
};
