import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import * as contactActions from '../../sales/actions/contactActions';

import Metrics from '../../../utils/metrics';
import resources from '../../../utils/resources';

const FreeAccountContact = ({ actions, trackData }) => {
  const placement = 'bottom';

  function onAskForum(e) {
    Metrics.track(`click:sc:ask-forum`, { trackData });
  }

  function onContactSales(e) {
    e.preventDefault();
    actions.contactSales(trackData, { place: 'overlay', type: 'button', text: 'talk to sales' });
  }

  return (
    <div className={trackData.indexOf('having-trouble') === 0 ? 'homepage-free-contact' : 'free-account-contact'}>
      <OverlayTrigger placement={placement} overlay={<Tooltip className={placement} id="tooltip">{resources.CONTACT_SALES_TOOLTIP}</Tooltip>}>
        <a id="talk-to-sales" href="#" onClick={onContactSales} className="btn btn-transparent btn-white btn-lg">Talk To Sales</a>
      </OverlayTrigger>
      <a id="ask-forum" href="https://community.auth0.com" target="_blank" onClick={onAskForum} className="btn btn-success btn-lg">Ask our Community</a>
    </div>
  );
};

FreeAccountContact.propTypes = {
  actions: PropTypes.object.isRequired,
  trackData: PropTypes.string.isRequired
};

FreeAccountContact.defaultProps = {
  trackData: 'free-account-contact'
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, contactActions), dispatch)
  };
}

const FreeAccountContactButtons = connect(null, mapDispatchToProps)(FreeAccountContact);
export { FreeAccountContactButtons as FreeAccountContact };
