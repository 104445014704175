import { initialState as storageService } from '../services/storage/reducer';
import compliance from '../features/compliance/initialState';

export default {
  auth: {
    isAuthenticated: false,
    isLoading: true,
    status: 'up',
    error: null,
    user: {},
    data: {},
    tenantIds: [],
    tenantAgreementIds:[],
    currentTenant: null,
    initialCheckError: null
  },
  appliances: {
    loading: false,
    error: null,
    records: [],
    versions: []
  },
  tenants: {
    loaded: false, // flags first load for metrics
    loading: false,
    updating: [],
    error: null,
    selected: null,
    records: {}
  },
  tenantClients: {
    loading: false,
    error: null,
    records: []
  },
  tenantChecklist: {
    loading: false,
    error: null,
    records: [],
    job: {},
    result: []
  },
  tenantAgreements: {
    loading: false,
    error: null,
    selected: null,
    records: {}
  },
  tenantSummaries: {
    loading:false,
    error: null,
    records: {}
  },
  reportsTenantActiveUsers: {
    loading: false,
    error: null,
    records: [],
    filtering: {},
    page: 1
  },
  reportsTenantTotalUsers: {
    loading: false,
    error: null,
    records: [],
    filtering: {},
    page: 1
  },
  reportsTenantQuota: {
    loading: false,
    error: null,
    record: {},
    filtering: {},
    page: 1
  },
  reportsTenantFeatures: {
    loading: false,
    error: null,
    record: {},
    filtering: {},
    page: 1
  },
  reportsTenantM2MDaily: {
    maxLimitReached: false,
    loading: false,
    error: null,
    record: {},
    filtering: {},
    page: 1
  },
  reportsTenantEnterpriseConnections: {
    loading: false,
    error: null,
    record: {},
    filtering: {},
    page: 1
  },
  reportsTenantEnabled: {
    loading: false,
    error: null,
    enabled: null
  },
  reportsApplianceLastUpdatedUsage: {
    loading:false,
    error: null,
    records: []
  },
  orgTickets: {
    loading: false,
    error: null,
    records: []
  },
  myTickets: {
    loading: false,
    error: null,
    records: []
  },
  ticket: {
    loading: true,
    error: null,
    unauthorizedObjectId: null,
    record: {
      comments: []
    }
  },
  ticketCategories: {
    loading: true,
    error: null,
    records: []
  },
  notifications: {
    loading: false,
    error: null,
    filterOption: null,
    records: []
  },
  notification: {
    loading: false,
    error: null,
    record: {}
  },
  search:{
    loading: false,
    text: null,
    error:null,
    results:[],
    page: 1,
    count: null,
    filter: null
  },
  uploads:{
    loading: false,
    error: null,
    records: [],
    uploading: []
  },
  systemStatus: {
    page: {},
    components: {},
    status: {}
  },
  extendTrial: {
    loading: false,
    success: false,
    error: null,
    tenantId: null
  },
  recentNotifications: {
    loading: false,
    error: null,
    records: [],
    states: []
  },
  notificationState: {
    loading: false,
    error: null
  },
  emailVerification:{
    loading: false,
    error: null,
    emailSent: false
  },
  compliance,
  storageService
};
