export const MAX_SUBJECT_LENGTH = 150;
export const MIN_SUBJECT_LENGTH = 1;

export const MAX_DESCRIPTION_LENGTH = 64193;
export const MIN_DESCRIPTION_LENGTH = 1;

export const APPLIANCE_TENANT = {
  LABEL: 'Affected Tenant',
  DEFAULT_OPTION: 'Please choose...'
};

export const COLLABORATORS = {
  LABEL: 'CC',
  PLACEHOLDER: 'Include other comma separated emails to notify of this ticket'
};

export const ROOT_TENANT_AUTHORITY = {
  LABEL: 'Affected Root Tenant Authority',
  DEFAULT_OPTION: 'Please choose..'
};

export const SEVERITY = {
  LABEL: 'Severity (*)'
};

export const SUBJECT = {
  LABEL: 'Request Summary (*)'
};

export const ENVIRONMENT = {
  LABEL: 'Environment'
};

export const TARGET_VERSION = {
  LABEL: 'Target Version'
};

export const PROPOSED_DATE = {
  LABEL: 'Proposed Date (DD/MM/YYYY), Time, and Timezone'
};

export const TENANT = {
  LABEL: 'Affected Tenant',
  APPLIANCE_LABEL: 'Associated Support Tenant',
  DEFAULT_OPTION: [
    'Please choose a tenant from your',
    'subscription'
  ],
  DEFAULT_OPTION_DEV_SUFFIX: '(s)'
};

export const DEFERRED_UPLOAD_FAILURE_ALERT = [
  'There was a problem uploading the file attachments.',
  'Please try again in a few minutes by attaching them to a new comment.',
  'Note: Attachments cannot exceed 10 MB.'
];
