import React from 'react';
import PropTypes from 'prop-types';

const ERROR_TITLE = 'Oh snap!';
const ERROR_MESSAGE = 'Something went wrong downloading this file. We\'ve been notified and will look into the issue. Feel free to try again.';

/**
 * Renders an horizontal banner displaying
 * a customer-friendly download error
 */
const DownloadFailureAlert = ({ handleDismissal }) => {
  return (
    <div className="alert alert-danger alert-dismissible">
      <button className="close" onClick={handleDismissal}>
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>{ERROR_TITLE}</strong> {ERROR_MESSAGE}
    </div>
  );
};

DownloadFailureAlert.propTypes = {
  handleDismissal: PropTypes.func.isRequired
};

export default DownloadFailureAlert;