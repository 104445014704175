/**
 * Log types that trigger PD alerts.
 */
export const SUPPORT_TICKET_ERROR = 'support_ticket_error';
export const SUBSCRIPTION_ERROR = 'subscription_error';
export const USER_DATA_ERROR = 'user_data_error';
export const NETWORK_ERROR = 'network_error';
export const ABORTED = 'aborted';
export const AUTHORIZATION_ERROR = 'authorization_error';
export const AUTHORIZATION_ERROR_EDGE_CASE = 'authorization_error_edge_case';
export const UNVERIFIED_EMAIL = 'sc_unverified_email';
export const INVALID_TICKET_ID_ERROR = 'invalid_ticket_id_error';
