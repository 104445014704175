import axios from 'axios';
import queryString from 'query-string';
import { API_CALL_RESPONSE_TIMEOUT_MS } from '@a0/support-center-common/constants/timeouts';
import { getAccessTokenSilently } from '../features/login/actions/authActions';

const { SUPPORT_API_URL } = window.scConfig;
const UNAVAILABLE_TOKEN = "unavailable_token";
export const isUnavailableTokenError = (error) => error && error.code === UNAVAILABLE_TOKEN;

export const defaultOptions = {
  method: 'get',
  cache: false,
  timeout: API_CALL_RESPONSE_TIMEOUT_MS,
  validateStatus(status) {
    return status >= 200 && status < 500;
  }
};

function appendQueryParam(url, key, value) {
  const parts = url.split('?');

  if (parts.length === 1) {
    return `${parts[0]}?${key}=${value}`;
  }

  if (parts.length === 2) {
    const query = queryString.parse(parts[1]);
    query[key] = value;
    return `${parts[0]}?${queryString.stringify(query)}`;
  }

  return url;
}

/**
 * Checks request result for success
 * @param {Object} result
 * @returns {Boolean}
 * @private
 */
function isSuccess(result) {
  return result.status < 400;
}

/**
 * Creates success request result payload
 * @param {Object} result - request result
 * @param {Boolean} includeHeaders
 * @returns {Object}
 * @private
 */
function handleSuccessResponse(result, includeHeaders) {
  return includeHeaders ? { headers: result.headers, data: result.data } : result.data;
}

/**
 * Creates request options object
 * @param {String} url
 * @param {String} token
 * @param {Object} config
 * @returns {Object}
 * @private
 */
function createRequestOptions(url, bearerToken, config) {
  const options = {
    ...defaultOptions,
    url,
    headers: {
    },
    ...config
  };
  if (bearerToken) {
    options.headers.Authorization = `Bearer ${bearerToken}`;
  }
  return options;
}

/**
 * Catch network errors and decorate them before they hit our app middleware
 * This is a bit fragile but we have to be careful not to accidentally include
 * exceptions from the axios module
 * There is some complexity to catching these specific errors, see
 * https://github.com/axios/axios/issues/383
 *
 * @param {Error}
 * @throws {Error}
 */
export function decorateNetworkErrors(error) {
  if (!!error.request && error.response === undefined) {
    error.networkError = true;
  }

  throw error;
}

/**
 * Closure that stores resource and config request data,
 * the returned function will perform the configured request
 * with current authentication token from AuthService
 *
 * @param {String} resource - path to the API resource
 * @param {Object} config - request config object
 * @returns {Function} preparedAxiosRequest
 */
function createPreparedAxiosRequest(resource, config, authenticatedRequest = true) {
  return async function preparedAxiosRequest() {
    let token = null;
    if (authenticatedRequest) {
      try {
        token = await getAccessTokenSilently();
      } catch (err) {
        const error = new Error('Invalid authentication state', { cause: err });
        error.code = UNAVAILABLE_TOKEN;
        throw error;
      }
    }
    const requestUrl = SUPPORT_API_URL + resource;
    const requestOptions = createRequestOptions(requestUrl, token, config);

    return axios.request(requestOptions).catch(decorateNetworkErrors);
  };
}

/**
 * Closure that stores response options (extras) and a prepared axios request
 *
 * @param {Object} extras - response options
 * @param {Function} performOriginalAxiosRequest - optional fn to retry the original request
 * @returns {Function} responseHandler
 */
function createResponseHandler(extras, performOriginalAxiosRequest) {
  return function responseHandler(axiosResponse) {
    if (isSuccess(axiosResponse)) {
      return handleSuccessResponse(axiosResponse, extras.includeHeaders);
    }

    throw axiosResponse;
  };
}


/**
 * API request wrapper with renew token / request retry functionality for 401 responses
 * @param {String} resource - path to the API resource
 * @param {Object} config - request options
 * @param {Object} extras - additional options
 * @returns {Promise<AxiosResponse>}
 */
export default function request(resource, config, extras = {}) {
  if (config && config.expire) {
    // Append query param _ with the current timestamp to expire the cache
    resource = appendQueryParam(resource, '_', new Date().getTime());
    delete config.expire;
  }

  const performAxiosRequest = createPreparedAxiosRequest(resource, config);
  const handleResponse = createResponseHandler(extras, performAxiosRequest);

  return performAxiosRequest()
    .then(handleResponse);
}

/**
 * API request wrapper with NO token logic in it
 * @param {String} resource - path to the API resource
 * @param {Object} config - request options
 * @param {Object} extras - additional options
 * @returns {Promise<AxiosResponse>}
 */
 function requestUnauthenticated(resource, config, extras = {}) {
  const performAxiosRequest = createPreparedAxiosRequest(resource, config, false);
  const handleResponse = createResponseHandler(extras, performAxiosRequest);
  return performAxiosRequest().then(handleResponse);
}

export { appendQueryParam, requestUnauthenticated };
