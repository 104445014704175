import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/components/SelectInput';
import api from 'api';
import { Error } from '@a0/support-center-components';
import Loading from "../../../common/components/Loading";

/**
 * New Ticket Region field component
 * Specifically for offline ticket submission
 */
const Region = ({ name, onChange, value, error }) => {
  const [localities, setLocalities] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadLocalities = () => {
    return api.localities.get()
      .then((localities) => {
        setLocalities(localities.filter(locality => locality.id !== 'pus2'));
      }).catch(setApiError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadLocalities();
  }, []);

  const regionsAsOptions = localities.map((l) => {
    return {
      value: l.id,
      text: l.name
    };
  });

  return (
    <Loading show={loading}>
      <Error message={apiError}>
        <SelectInput
          name={name}
          label="Tenant Region"
          defaultOption="Please choose..."
          options={regionsAsOptions}
          onChange={onChange}
          classes="animated fadeIn"
          value={value}
          error={error}
        />
      </Error>
    </Loading>
  );
};

Region.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** The possible error of the field */
  error: PropTypes.string
};

export default Region;
