import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../../common/components/PageHeader';
import PageWrap from '../../common/components/PageWrap';
import Breadcrumb from '../../common/components/Breadcrumb';

/**
 * Component responsible for wrapping the
 * new ticket form in page context
 */
const NewTicketPage = ({ children }) => (
  <Fragment>
    <PageHeader extended>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/tickets">Tickets</Breadcrumb.Item>
        <Breadcrumb.Item>New</Breadcrumb.Item>
      </Breadcrumb>
    </PageHeader>
    <PageWrap canvas>
      {children}
    </PageWrap>
  </Fragment>
);

NewTicketPage.propTypes = {
  children: PropTypes.node
};

export default NewTicketPage;
