import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import constants from '@a0/support-center-common/constants/tickets/types';

import SelectInput from '../../../common/components/SelectInput';

const typesAsOptions = isAppliance => {
  let options = Object.keys(constants.TYPES).map(value => ({
    value,
    text: constants.TYPES[value]
  }));

  if (!isAppliance) {
    return options.filter(
      option => option.value !== constants.APPLIANCE_SUPPORT_INCIDENT_TYPE
    );
  }

  return options;
};

/**
 * New Ticket Type field component
 */
const Type = ({ name, value, selectedSubscription, onChange }) => {
  const isAppliance = !!get(selectedSubscription, 'is_appliance');

  return (
    <SelectInput
      name={name}
      label="Issue Type (*)"
      defaultOption="Please choose..."
      options={typesAsOptions(isAppliance)}
      onChange={onChange}
      classes="animated fadeIn"
      value={value}
    />
  );
};

Type.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** The selected subscription object */
  selectedSubscription: PropTypes.object.isRequired
};

export default Type;
