import React from 'react';
import PropTypes from 'prop-types';

import Metrics from '../../../utils/metrics';
import SecureLink from './SecureLink';
import Alert from 'react-bootstrap/Alert';


export const LeastPrivilegedAccessAlert = () => {
  const trackEvent = typeOfEvent => () =>
    Metrics.track(`click:sc:${typeOfEvent}`, {trackData: 'least-privileged-access-alert'});

  return (
    <Alert variant="warning">
        <span>Access to this feature is changing. Click  <SecureLink href="https://auth0.com/docs/get-started/manage-dashboard-access/feature-access-by-role" onClick={trackEvent('support-access')}>here</SecureLink> to learn more.</span>{' '}
    </Alert>
  );
};
