import schemas from '@a0/support-center-common/schemas/tickets';

const validate = (values, schema) => {
  const errors = {};

  const formErrors = schema.validate(values, { abortEarly: false }).error;
  if (formErrors && formErrors.details) {
    formErrors.details.forEach(error => {
      const errorPath = error.path && error.path.length ? error.path.join("."):"";
      errors[errorPath] = error.message;
    });
  }
  return errors;
};

export default values => validate(values, schemas.offlineSchema);
