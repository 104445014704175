import initialState from '../../../redux/initialState';

import {
  SET_EXTEND_TRIAL_TENANT_ID,
  EXTEND_TRIAL_BEGIN,
  EXTEND_TRIAL_SUCCESS,
  EXTEND_TRIAL_ERROR,
  EXTEND_TRIAL_RESET
} from '../../../redux/actionTypes';

export default function extendTrial(state = initialState.extendTrial, action) {
  switch (action.type) {

    case SET_EXTEND_TRIAL_TENANT_ID:
      return {
        ...state,
        tenantId: action.tenantId
      };

    case EXTEND_TRIAL_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case EXTEND_TRIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true
      };

    case EXTEND_TRIAL_ERROR:
      return {
        ...state,
        loading: false,
        error: `An error occurred while extending your trial: ${action.errorMessage}`
      };

    case EXTEND_TRIAL_RESET:
      return {...initialState.extendTrial};

    default:
      return state;
  }
}
