import React from "react";
import PropTypes from "prop-types";

import { SearchBox } from "./coveo/SearchBox";
import { CoveoEngineWrapper } from "./coveo/CoveoEngineWrapper";
import { Auth0DocsSearchBox } from "./Auth0DocsSearchBox";

export const StandaloneSearchBox = () => {
  return (
    <CoveoEngineWrapper fallback={<Auth0DocsSearchBox />}>
      <SearchBox redirectionUrl="/search" />
    </CoveoEngineWrapper>
  );
};

StandaloneSearchBox.propTypes = {
  redirectionUrl: PropTypes.string
};
