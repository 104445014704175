import { STORAGE_ERROR } from './actions.js';

export const initialState = {
  error: null
};

/**
 * Reducer with sole purpose of handling storage errors
 * @param {object} state 
 * @param {object} action
 * @returns {object} state 
 */
export default function storageServiceReducer(state = initialState, action) {
  switch (action.type) {
    case STORAGE_ERROR:
      return {
        error: action.error
      };
    
    default:
      return state;
  }
}