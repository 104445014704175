import {
  filterTenantsBySubscription,
  filterNotificationByTenant
} from '@a0/support-center-common/selectors/common';

export const filterNotificationStateByTenant = ({ states }, tenants) => {
  const tenantList = tenants.map(t => t.id);
  return states.filter(notificationState => {
    return tenantList.indexOf(notificationState._id.tenant) !== -1;
  });
};

export const getRecentSubscriptionNotifications = state => {
  const tenants = filterTenantsBySubscription(state);
  const notifications = filterNotificationByTenant(state.recentNotifications, tenants) || [];
  const notificationStates = filterNotificationStateByTenant(state.recentNotifications, tenants) || [];

  // attach highest state for current subscription, filter out dismissed notifications
  return notifications.map(n => {
    const notification = {...n};

    let highest = 'unread';
    const hierarchy = ['read', 'clicked', 'dismissed'];

    notificationStates.forEach(notificationState => {
      if (notificationState._id.notification_id === notification._id) {
        if (hierarchy.indexOf(notificationState.state) > hierarchy.indexOf(highest)) {
          highest = notificationState.state;
        }
      }
    });

    notification.state = highest;
    return notification;
  })
  .filter(n => n.state !== 'dismissed');
};
