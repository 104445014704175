import React, { useState } from "react";
import PropTypes from "prop-types";

import { SearchInput } from "./SearchInput";

const baseAuth0DocsSearchUrl = "https://auth0.com/docs/search";

// The fallback searchbox that redirects to Auth0 Docs search
// that will be used if the Coveo engine can't be initialized
export const Auth0DocsSearchBox = ({ initialValue = "" }) => {
  const [value, setValue] = useState(initialValue);
  const handleClear = () => {
    setValue("");
  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div className="search-box-container">
      <SearchInput
        value={value}
        onClear={handleClear}
        onChange={handleChange}
        action={baseAuth0DocsSearchUrl}
        target="_blank"
        placeholder="Search Auth0 Docs"
      />
    </div>
  );
};

Auth0DocsSearchBox.propTypes = {
  initialValue: PropTypes.string
};
