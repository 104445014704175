import React from 'react';
import PropTypes from 'prop-types';

import { IndexLink, Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AccountPicker from '../../common/components/AccountPicker';
import Metrics from '../../../utils/metrics';
import QuotaReportIntroduction from '../components/QuotaReportIntroduction';

import {
  filterTenantsBySubscription,
  getSelectedTenant,
  hasAdminMasterAccess
} from '@a0/support-center-common/selectors/common';

import { getSelectedSubscription, getSimplifiedSubscriptionPlanName } from '@a0/support-center-common/selectors/subscriptions';
import { setSelectedTenant } from '../../tenants/actions/tenantActions';

import itIsFreeOrTrialOrDeveloper from '../../../utils/itIsFreeOrTrialOrDeveloper';
import onContactSales from '../../../utils/onContactSales';
import itShouldRenderReports from '../../../utils/itShouldRenderReports';

import {
  // Quota Reports
  loadReportsTenantQuota,
  loadReportsTenantQuotaReset,

  // Features Reports
  loadReportsTenantFeatures,
  loadReportsTenantFeaturesReset,

  // Enterprise Connections Reports
  loadReportsTenantEnterpriseConnections,
  loadReportsTenantEnterpriseConnectionsReset,

  // Reports Enabled
  loadReportsEnabled,
  loadReportsEnabledReset,

  // Appliance Last Updated Usage
  loadApplianceLastUpdateUsage,
  loadApplianceLastUpdateUsageReset
} from '../actions/reportActions';

import {
  Loading,
  Error,
  QuotaSummary,
  PlanTypeBox
} from '@a0/support-center-components';

import {
  getQuotaReportLoadingStatus,
  getQuotaReportError,
  getQuotaReportData,
  isQuotaOverquota
} from '../selectors/quota';

import {
  getFeaturesReportData,
  getFeaturesReportLoadingStatus,
  getFeaturesReportError,
  isFeaturesOverquota
} from '../selectors/features';

import {
  getEnterpriseConnectionsReportData,
  getEnterpriseConnectionsReportLoadingStatus,
  getEnterpriseConnectionsReportError,
  isEnterpriseConnectionsOverquota
} from '../selectors/enterpriseConnections';

import { contactSales } from '../../sales/actions/contactActions';
import { humanizeTenantId, isApplianceTenant } from '@a0/support-center-common/lib/tenants';

const getTenantId = tenant => tenant && (tenant.master_tenant || tenant.id);

const TRACKING_QUOTA_REPORTS = 'click:sc:reports:quota:';

export class QuotaPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.selectTenant = this.selectTenant.bind(this);
    this.showReportDetails = this.showReportDetails.bind(this);
    this.itIsOverquota = this.itIsOverquota.bind(this);
  }

  componentDidMount() {
    const { currentTenant, hasAdminMasterAccess } = this.props;
    const tenantId = getTenantId(currentTenant);

    if (tenantId && hasAdminMasterAccess) {
      this.props.loadReportsTenantQuota(tenantId);
      this.props.loadReportsTenantFeatures(tenantId);
      this.props.loadReportsTenantEnterpriseConnections(tenantId);
      this.props.loadReportsEnabled(tenantId);

      if (isApplianceTenant(tenantId)) {
        this.props.loadApplianceLastUpdateUsage(tenantId);
      }
    }
  }

  componentWillReceiveProps({ currentTenant, hasAdminMasterAccess }) {
    if (currentTenant != this.props.currentTenant && hasAdminMasterAccess) {
      const tenantId = getTenantId(currentTenant);

      this.props.loadReportsTenantQuota(tenantId);
      this.props.loadReportsTenantFeatures(tenantId);
      this.props.loadReportsTenantEnterpriseConnections(tenantId);
      this.props.loadReportsEnabled(tenantId);

      if (isApplianceTenant(tenantId)) {
        this.props.loadApplianceLastUpdateUsage(tenantId);
      }
    }
  }

  componentWillUnmount() {
    this.props.loadReportsTenantQuotaReset();
    this.props.loadReportsTenantFeaturesReset();
    this.props.loadReportsTenantEnterpriseConnectionsReset();
    this.props.loadReportsEnabledReset();
    this.props.loadApplianceLastUpdateUsageReset();
  }

  selectTenant(event) {
    this.props.setSelectedTenant(event.target.value);
  }

  showReportDetails(reportType) {
    const { currentTenant } = this.props;
    const tenantId = getTenantId(currentTenant);

    return function(event) {
      if (event) {
        event.preventDefault();

        Metrics.track(`${TRACKING_QUOTA_REPORTS}view`, {
          trackData: {
            reportType
          }
        });

        browserHistory.push(
          `/reports/quota/${reportType}?tenantId=${tenantId}`
        );
      }
    };
  }

  itIsOverquota() {
    const {
      isQuotaOverquota,
      isFeaturesOverquota,
      isEnterpriseConnectionsOverquota
    } = this.props;

    return (
      isQuotaOverquota ||
      isFeaturesOverquota ||
      isEnterpriseConnectionsOverquota
    );
  }

  render() {
    const {
      reportsTenantEnabled,
      reportsTenantEnabledLoding,
      reportsTenantEnabledError,

      hasAdminMasterAccess,
      tenants,
      currentTenant,
      selectedSubscription,

      // Features
      featuresReportData,
      featuresReportLoading,
      featuresReportError,

      // Quota
      quotaReportData,
      quotaReportLoading,
      quotaReportError,

      // Enterprise Connections
      enterpriseConnectionsReportData,

      reportsApplianceLastUpdatedUsageLoading,
      reportsApplianceLastUpdatedUsageError,
      reportsApplianceLastUpdatedUsageRecords,

      contactSales
    } = this.props;

    const tenantId = getTenantId(currentTenant);

    return (
      <div className="reports">
        <div className="bg-dots page-header-dots">
          <div className="container">
            <ol className="breadcrumb">
              <li>
                <IndexLink to="/">Home</IndexLink>
              </li>
              <li>Quota Utilization</li>
            </ol>
            <h1>Quota Utilization</h1>
          </div>
        </div>
        <div id="quota-report" className="container">
          {currentTenant &&
            !currentTenant.subscription.isCustomAgreement &&
            !(selectedSubscription.id === 'v8') &&
            (
              <AccountPicker
                tenants={tenants}
                currentTenant={currentTenant}
                onChange={this.selectTenant}
                selectedSubscriptionName={
                  selectedSubscription && selectedSubscription.name
                }
              />
            )}
          <Loading show={!tenantId}>
            {hasAdminMasterAccess ? (
              itShouldRenderReports(
                selectedSubscription,
                reportsTenantEnabled
              ) ? (
                <div>
                  {itIsFreeOrTrialOrDeveloper(selectedSubscription) && (
                    <PlanTypeBox
                      subscriptionType={
                        currentTenant &&
                        currentTenant.subscription &&
                        currentTenant.subscription.name
                      }
                      contactSales={
                        this.itIsOverquota() &&
                        onContactSales(contactSales, selectedSubscription,
                          {place: 'subscription column', type: 'button', text: 'upgrade your plan'})
                      }
                    />
                  )}
                  <QuotaSummary
                    loading={
                      quotaReportLoading ||
                      featuresReportLoading ||
                      reportsTenantEnabledLoding ||
                      reportsApplianceLastUpdatedUsageLoading
                    }
                    error={
                      quotaReportError ||
                      featuresReportError ||
                      reportsTenantEnabledError ||
                      reportsApplianceLastUpdatedUsageError
                    }
                    shouldRenderSummary={hasAdminMasterAccess}
                    reportData={{
                      quotaReportData,
                      enterpriseConnectionsReportData,
                      featuresReportData
                    }}
                    showReportDetails={this.showReportDetails}
                    contactSalesLink={
                      itIsFreeOrTrialOrDeveloper(selectedSubscription)
                        ? 'Upgrade'
                        : null
                    }
                    contactSales={onContactSales(contactSales, selectedSubscription,
                      {
                        place: 'quota summary',
                        type: 'link',
                        text: itIsFreeOrTrialOrDeveloper(selectedSubscription) ? 'upgrade' : 'talk to sales'}
                    )}
                    isAppliance={isApplianceTenant(tenantId)}
                    applianceUsageRecords={
                      reportsApplianceLastUpdatedUsageRecords
                    }
                  />
                </div>
              ) : (
                <Loading
                  show={
                    quotaReportLoading ||
                    featuresReportLoading ||
                    reportsTenantEnabledLoding
                  }
                >
                  <Error
                    message={
                      quotaReportError ||
                      featuresReportError ||
                      reportsTenantEnabledError
                    }
                  >
                    <div className="auth0-notification atention alert alert-warning">
                      <div
                        style={{ marginBottom: '0' }}
                        className="alert alert-global alert-info"
                      >
                        We are busy improving our quota reports for you. They'll
                        be available again soon.
                      </div>
                    </div>
                  </Error>
                </Loading>
              )
            ) : (
              <div className="auth0-notification atention alert alert-warning">
                <div className="alert alert-global alert-warning">
                  <i className="icon-budicon-354" /> Sorry! Only dashboard
                  admins of the main production tenant{' '}
                  <b>{humanizeTenantId(getTenantId(currentTenant))}</b> have
                  access to this report. However, you may view the{' '}
                  <Link to="/reports/usage">usage reports</Link>.
                </div>
              </div>
            )}
          </Loading>
        </div>
      </div>
    );
  }
}

QuotaPage.propTypes = {
  hasAdminMasterAccess: PropTypes.bool.isRequired,
  setSelectedTenant: PropTypes.func.isRequired,
  contactSales: PropTypes.func.isRequired,
  tenants: PropTypes.array.isRequired,
  currentTenant: PropTypes.object,
  selectedSubscription: PropTypes.object,

  // Quota
  quotaReportData: PropTypes.object,
  quotaReportType: PropTypes.string,
  quotaReportLoading: PropTypes.bool,
  quotaReportError: PropTypes.string,
  loadReportsTenantQuota: PropTypes.func.isRequired,
  loadReportsTenantQuotaReset: PropTypes.func.isRequired,
  isQuotaOverquota: PropTypes.bool,

  // Features
  featuresReportData: PropTypes.object,
  featuresReportLoading: PropTypes.bool,
  featuresReportError: PropTypes.string,
  loadReportsTenantFeatures: PropTypes.func.isRequired,
  loadReportsTenantFeaturesReset: PropTypes.func.isRequired,
  isFeaturesOverquota: PropTypes.bool,

  // Enterprise Connections
  enterpriseConnectionsReportData: PropTypes.object,
  enterpriseConnectionsReportLoading: PropTypes.bool,
  enterpriseConnectionsReportError: PropTypes.string,
  loadReportsTenantEnterpriseConnections: PropTypes.func.isRequired,
  loadReportsTenantEnterpriseConnectionsReset: PropTypes.func.isRequired,
  isEnterpriseConnectionsOverquota: PropTypes.bool,

  loadReportsEnabled: PropTypes.func.isRequired,
  loadReportsEnabledReset: PropTypes.func.isRequired,

  reportsTenantEnabled: PropTypes.bool,
  reportsTenantEnabledLoding: PropTypes.bool,
  reportsTenantEnabledError: PropTypes.string,

  loadApplianceLastUpdateUsage: PropTypes.func.isRequired,
  loadApplianceLastUpdateUsageReset: PropTypes.func.isRequired,

  reportsApplianceLastUpdatedUsageLoading: PropTypes.bool,
  reportsApplianceLastUpdatedUsageError: PropTypes.string,
  reportsApplianceLastUpdatedUsageRecords: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  const {
    reportsTenantEnabled: {
      loading: reportsTenantEnabledLoding,
      error: reportsTenantEnabledError,
      enabled: reportsTenantEnabled
    },
    reportsApplianceLastUpdatedUsage: {
      loading: reportsApplianceLastUpdatedUsageLoading,
      error: reportsApplianceLastUpdatedUsageError,
      records: reportsApplianceLastUpdatedUsageRecords
    }
  } = state;

  return {
    reportsTenantEnabledLoding,
    reportsTenantEnabledError,
    reportsTenantEnabled,

    // Appliance Last Updated Usage
    reportsApplianceLastUpdatedUsageLoading,
    reportsApplianceLastUpdatedUsageError,
    reportsApplianceLastUpdatedUsageRecords,

    tenants: filterTenantsBySubscription(state),
    currentTenant: getSelectedTenant(state.tenants),
    selectedSubscription: getSelectedSubscription(state),

    // Quota
    quotaReportData: getQuotaReportData(state),
    quotaReportLoading: getQuotaReportLoadingStatus(state),
    quotaReportError: getQuotaReportError(state),
    isQuotaOverquota: isQuotaOverquota(state),

    // Features
    featuresReportData: getFeaturesReportData(state),
    featuresReportLoading: getFeaturesReportLoadingStatus(state),
    featuresReportError: getFeaturesReportError(state),
    isFeaturesOverquota: isFeaturesOverquota(state),

    // Enterprise Connections
    enterpriseConnectionsReportData: getEnterpriseConnectionsReportData(state),
    enterpriseConnectionsReportLoading: getEnterpriseConnectionsReportLoadingStatus(
      state
    ),
    enterpriseConnectionsReportError: getEnterpriseConnectionsReportError(
      state
    ),
    isEnterpriseConnectionsOverquota: isEnterpriseConnectionsOverquota(state),

    hasAdminMasterAccess: hasAdminMasterAccess(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadReportsEnabled: bindActionCreators(loadReportsEnabled, dispatch),
    loadReportsEnabledReset: bindActionCreators(
      loadReportsEnabledReset,
      dispatch
    ),

    // Quota Reports
    loadReportsTenantQuota: bindActionCreators(
      loadReportsTenantQuota,
      dispatch
    ),
    loadReportsTenantQuotaReset: bindActionCreators(
      loadReportsTenantQuotaReset,
      dispatch
    ),

    // Features Reports
    loadReportsTenantFeatures: bindActionCreators(
      loadReportsTenantFeatures,
      dispatch
    ),
    loadReportsTenantFeaturesReset: bindActionCreators(
      loadReportsTenantFeaturesReset,
      dispatch
    ),

    // Enterprise Connections Reports
    loadReportsTenantEnterpriseConnections: bindActionCreators(
      loadReportsTenantEnterpriseConnections,
      dispatch
    ),

    loadReportsTenantEnterpriseConnectionsReset: bindActionCreators(
      loadReportsTenantEnterpriseConnectionsReset,
      dispatch
    ),

    // Appliance Last Updated Usage
    loadApplianceLastUpdateUsage: bindActionCreators(
      loadApplianceLastUpdateUsage,
      dispatch
    ),

    loadApplianceLastUpdateUsageReset: bindActionCreators(
      loadApplianceLastUpdateUsageReset,
      dispatch
    ),

    setSelectedTenant: bindActionCreators(setSelectedTenant, dispatch),
    contactSales: bindActionCreators(contactSales, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotaPage);
