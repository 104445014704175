import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BSDropdown from 'react-bootstrap/Dropdown';
import NavbarItem from './NavbarItem';
import './Dropdown.module.css';

/**
 * Wraps react-bootstrap's Dropdown class to make it
 * suitable for Support Center Navbar use
 */
class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: props.show || false
    };

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  /**
   * Toggle or set dropdown state
   * @param {boolean} setting - the state if provided, otherwise toggles
   */
  toggleDropdown(setting) {
    const showDropdown = typeof(setting) === 'boolean' ? setting : !this.state.showDropdown;
    this.setState({ showDropdown });
  }

  openDropdown() {
    this.toggleDropdown(true);
  }

  closeDropdown() {
    this.toggleDropdown(false);
  }

  render() {
    const { title, children, active } = this.props;

    return (
      <BSDropdown
        id={title}
        className="btn-group"
        onMouseEnter={this.openDropdown}
        onMouseLeave={this.closeDropdown}
        onToggle={this.toggleDropdown}
        show={this.state.showDropdown}
      >
        <NavbarItem bsRole="toggle" dropdown active={active}>
          {title}
        </NavbarItem>
        <BSDropdown.Menu>{children}</BSDropdown.Menu>
      </BSDropdown>
    );
  }
}

Dropdown.propTypes = {
  title:    PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  onToggle: PropTypes.func,
  show:     PropTypes.bool,
  active:   PropTypes.bool
};

export default Dropdown;
