import React, { useContext, useEffect } from "react";

import { loadQueryActions } from "@coveo/headless";
import { SearchBox } from "./SearchBox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { bindUrlManager } from "./urlManager";
import { CoveoEngineContext } from "./CoveoEngineContext";
import { QueryError } from "./QueryError";
import { FacetSection } from "./FacetSection";
import { ResultsSection } from "./ResultsSection";
import { retrieveStandaloneSearchBoxData, clearStandaloneSearchBoxData } from "./standaloneSearchBoxRedirectStorage";
import { NoResultsQuerySummary } from "./NoResultsQuerySummary";

export const SearchSection = () => {
  const headlessEngine = useContext(CoveoEngineContext);
  useEffect(() => {
    // binding the URL Manager should be done only after all the controllers are registered.
    const unbindUrlManager = bindUrlManager(headlessEngine);

    executeInitialSearch();

    return unbindUrlManager;
  }, [headlessEngine]);

  function executeInitialSearch() {
    const data = retrieveStandaloneSearchBoxData();
    clearStandaloneSearchBoxData();
    if (data) {
      executeInitialSearchAfterStandaloneSearchBoxRedirect(data);
      return;
    }
    const currentQuery =
      headlessEngine.state && headlessEngine.state.query && headlessEngine.state.query.q;
    if (currentQuery) {
      headlessEngine.executeFirstSearch();
    }
  }

  function executeInitialSearchAfterStandaloneSearchBoxRedirect(data) {
    const { value, analytics } = data;
    const { updateQuery } = loadQueryActions(headlessEngine);
    headlessEngine.dispatch(updateQuery({ q: value }));
    headlessEngine.executeFirstSearchAfterStandaloneSearchBoxRedirect(analytics);
  }

  return (
    <div className="search-layout">
      <div className="search-layout-section search">
        <SearchBox />
      </div>
      <QueryError />
      <div className="main-section">
        <NoResultsQuerySummary />
        <Row>
          <Col sm={3}>
            <FacetSection />
          </Col>
          <Col sm={9}>
            <ResultsSection />
          </Col>
        </Row>
      </div>
    </div>
  );
};
