import React from 'react';
import PropTypes from 'prop-types';

const supportSystemName = 'Salesforce';
const noticeMsg = `We've noticed you are a ${supportSystemName} agent. Do you want to open this ticket in ${supportSystemName} instead?`;
const buttonLabel = `Open in ${supportSystemName}`;

function createTicketUrl(id) {
  return `https://${window.scConfig.SALESFORCE_TENANT}.lightning.force.com/lightning/r/Case/${id}/view`;
}

const AgentNotice = ({id}) => {
  return (
    <div className="alert alert-info zendesk-agent">
      <strong>Heads up!</strong> {noticeMsg}
      <div className="actions pull-right">
        <a className="btn btn-sm btn-info" href={createTicketUrl(id)}>
          {buttonLabel}
        </a>
      </div>
    </div>
  );
};

AgentNotice.propTypes = {
  id: PropTypes.string.isRequired
};

export default AgentNotice;