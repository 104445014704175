/**
 *
 * Based on the Google HAR redaction tool published at https://github.com/google/har-sanitizer/blob/master/harsanitizer/static/js/harsanitizer.js
 * This logic is shared between WIC and CIC.
 * TO-DO: Move the code to a shared repo (https://github.com/auth0/har-sanitizer), add unit tests, make it an internal shareable package.
 * Current maintainers: Anil Kamath and Nicolas Sabena
 *
 */
const sensitiveWordsList = [
  "Authorization",
  "DT",
  "MDMOktaMobileId",
  "OKTA_IWA_SSO_SESSION_COOKIE",
  "SAMLRequest",
  "SAMLResponse",
  "api_key",
  "appID",
  "assertion",
  "auth",
  "auth0-extension-secret",
  "auth_token",
  "authenticity_token",
  "aws_secret_access_key",
  "cae",
  "challenge",
  "client_assertion",
  "client_secret",
  "code_challenge",
  "code_verifier",
  "datadogApiKey",
  "encryption_key",
  "encryption_key.cert",
  "facetID",
  "f.req", // Google
  "fcParams",
  "hdf",
  "httpAuthorization",
  "idx",
  "ikey",
  "invitation",
  "invitation_url",
  "log_token",
  "login_password", // PayPal
  "lu",
  "masterkey",
  "mixpanelServiceAccountPassword",
  "okta-oauth-nonce",
  "okta-oauth-redirect-params",
  "okta-oauth-state",
  "okta_su",
  "okta_su_se",
  "otp",
  "p12",
  "passwd", // Microsoft Account / Azure Ad
  "password",
  "private_key",
  "refresh_token",
  "secret",
  "secretAccessKey",
  "secret_access_key",
  "secrets",
  "segmentWriteKey",
  "serverData",
  "server_key",
  "servicePassword",
  "session_password", // LinkedIn
  "session_token",
  "sessionToken",
  "sha256_cert_fingerprints",
  "shdf",
  "sid",
  "signature", // for SAML HTTP-Redirect binding
  "signing_keys",
  "signing_secret",
  "skey",
  "smtp_pass",
  "splunkToken",
  "subject_token",
  "sumoSourceAddress",
  "token",
  "totp_secret",
  "user_code",
  "usg",
  "verification_uri",
  "verification_uri_complete",
  "webtask-token",
  "webtask_token",
  "x-client-data",
  "x-client-data"
];

const redactedMimeTypes = [
  "application/javascript",
  "application/json",
  "application/octet-stream",
  "application/x-javascript",
  "binary/octet-stream",
  "font/otf",
  "font/woff",
  "font/woff2",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/webp",
  "text/css",
  "text/html",
  "text/javascript",
  "text/xml"
];

const standardHeaders = [
  ":authority",
  ":method",
  ":scheme",
  "accept",
  "accept-ch",
  "accept-ch-lifetime",
  "accept-encoding",
  "accept-language",
  "accept-push-policy",
  "accept-ranges",
  "accept-signature",
  "access-control",
  "access-control-allow-credentials",
  "access-control-allow-headers",
  "access-control-allow-methods",
  "access-control-allow-origin",
  "access-control-allow-private-network",
  "access-control-expose-headers",
  "access-control-max-age",
  "access-control-request-headers",
  "access-control-request-method",
  "access-control-request-private-network",
  "age",
  "allow",
  "allow-control*",
  "alt-svc",
  "alt-used",
  "cache-control",
  "clear-side-data",
  "connection",
  "content-disposition",
  "content-encoding",
  "content-language",
  "content-length",
  "content-md5",
  "content-range",
  "content-security-policy",
  "content-security-policy*",
  "content-security-policy-report-only",
  "content-type",
  "critical-ch",
  "cross-origin-opener-policy",
  "cross-origin-opener-policy-report-only",
  "cross-origin-resource-policy",
  "date",
  "early-data",
  "etag",
  "expect",
  "expect-ct",
  "expires",
  "forwarded",
  "from",
  "host",
  "if-match",
  "if-modified-since",
  "if-none-match",
  "if-range",
  "if-unmodified-since",
  "keep-alive",
  "large-allocation",
  "last-event-id",
  "last-modified",
  "link",
  "locale",
  "location",
  "max-forwards",
  "nel",
  "okta_user_lang",
  "origin",
  "origin-isolation",
  "permissions-policy",
  "pragma",
  "proxy-authenticate",
  "push-policy",
  "range",
  "referer",
  "referrer-policy",
  "refresh",
  "report-to",
  "retry-after",
  "sec-ch-ua",
  "sec-ch-ua-arch",
  "sec-ch-ua-bitness",
  "sec-ch-ua-full-version",
  "sec-ch-ua-full-version-list",
  "sec-ch-ua-mobile",
  "sec-ch-ua-model",
  "sec-ch-ua-platform",
  "sec-ch-ua-platform-version",
  "sec-ch-ua-wow64",
  "sec-fetch-dest",
  "sec-fetch-mode",
  "sec-fetch-site",
  "sec-fetch-user",
  "sec-gpc",
  "sec-purpose",
  "server",
  "server-timing",
  "service-worker-navigation-preload",
  "strict-transport-security",
  "te",
  "timing-allow-origin",
  "trailer",
  "transfer-encoding",
  "upgrade-insecure-requests",
  "user-agent",
  "vary",
  "via",
  "warning",
  "www-authenticate",
  "x-content-type-options",
  "x-forwarded-for",
  "x-forwarded-host",
  "x-forwarded-proto",
  "x-frame-options",
  "x-permitted-cross-domain-policies",
  "x-powered-by",
  "x-xss-protection"
];

const internalUseHeaders = [
  "cf-cache-status",
  "cf-ray",
  "traceparent",
  "tracestate",
  "ot-baggage-auth0-request-id",
  "ot-tracer-sampled",
  "ot-tracer-spanid",
  "ot-tracer-traceid",
  "trace",
  "x-auth0-dl",
  "x-auth0-requestid",
  "x-azuqua-span-id",
  "x-azuqua-trace-id",
  "x-oag-host",
  "x-okta-edge-log",
  "x-okta-request-id",
  "x-okta-user-agent-extended",
  "x-rate-limit-limit",
  "x-rate-limit-remaining",
  "x-rate-limit-reset",
  "x-ratelimit-limit",
  "x-ratelimit-remaining",
  "x-ratelimit-reset",
  "x-robots-tag"
];

// these headers will be unredacted.
const allowedHeaders = standardHeaders.concat(internalUseHeaders);

// values from query string, hash params and post data that
// require special redaction
// make sure all the keys are lower case
const specialRedaction = {
  // the following values will be hashed
  "mfa_token": redactAsHash,
  "oob_code": redactAsHash,
  "binding_code": redactAsHash,
  "recovery_code": redactAsHash,
  "ticket_id": redactAsHash,
  "ticket": redactAsHash,
  // the following values will be redacted to keep the first and last 5 characters
  "state": redactKeepingBeginningAndEnd,
  "nonce": redactKeepingBeginningAndEnd,
  // redact JWT if recognized, otherwise fully redacted
  "access_token": redactAsJwt,
  "id_token": redactAsJwt,
  "id_token_hint": redactAsJwt,
  // leave intact if URL, otherwise redact as hash
  "relaystate": redactRelayState,
  // leave the last 3 characters, redacting the rest
  "code": redactKeepingEnd
};

const escapeRegExp = function (text) {
  return text.replace(/[-[\]{}();:=*+?.,\\^$|#\s ]/g, "\\$&");
};

const toLower = function (x) {
  return x.toLowerCase();
};

function redactSaml(value) {
  const samlNodesToRedact = ["SignatureValue"];
  try {
    if (value.slice(-2) === "%3D") {
      return null;
    }
    const decodedString = window.atob(decodeURIComponent(value));
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(decodedString, "application/xml");

    // Access elements without knowing the namespace URL
    for (const samlNode of samlNodesToRedact) {
      const elements = xmlDoc.getElementsByTagNameNS(
        "*",
        samlNode
      );

      for (let i = 0; i < elements.length; i++) {
        elements[i].textContent = "redacted";
      }
    }
    const xmlString = new XMLSerializer().serializeToString(xmlDoc);
    return encodeURIComponent(window.btoa(xmlString));
  } catch (e) {
    return null;
  }
}

// leave it unchanged if it is a valid URL, otherwise hash the value
async function redactRelayState(input) {
  try {
    new URL(decodeURIComponent(input));
    return input;
  } catch(error) {
    return await redactAsHash(input);
  }
}

async function redactKeepingBeginningAndEnd(input) {
  const charsToKeep = 5;
  if (input.length < charsToKeep*3) {
    // fall back to hashed value for shorter values
    return await redactAsHash(input);
  }
  return (
    input.substring(0, charsToKeep) +
    "[...redacted...]" +
    input.substring(input.length - charsToKeep)
  );
}

async function redactKeepingEnd(input) {
  const charsToKeep = 3;
  if (input.length < charsToKeep*3) {
    // fall back to hashed value for shorter values
    return await redactAsHash(input);
  }
  return (
    "[...redacted...]" +
    input.substring(input.length - charsToKeep)
  );
}


const jwtRegex = /\b(ey[A-Za-z0-9-_=]+)\.(ey[A-Za-z0-9-_=]+)\.[A-Za-z0-9-_.+/=]+\b/g;

async function redactAsJwt(value) {
  if (value.match(jwtRegex)) {
    return value.replace(jwtRegex, `$1.$2.[signature redacted]`);
  }
  // an unrecognized token format is fully redacted
  return null;
}

const auth0DidCookieRedaction = async (value) => {
  const SIGNED_PREFIX = 's%3A'; // "s:" URL encoded
  if (value.startsWith(SIGNED_PREFIX)) {
    const [signedValue, signature] = value.split(".");
    // remove signature
    return `${signedValue}.[signature redacted]`;
  }
  return await redactCookieValueAsHash(value);
};

const auth0CookieRedaction = async (value) => {
  const COOKIE_VALUE_LOG_LENGTH = 5;
  const SIGNED_PREFIX = 's%3A'; // "s:" URL encoded
  const ANONYMOUS_SESSION_PREFIX = 'v1.';
  /**
   * The fixed static prefix of an anonymous session is because of the msgpack5 encoding and
   * the fixed object structure `session: { handle:`. This value doens't add anything for
   * troubleshooting purposes, and is therefor trimmed.
   */
  const FIXED_STATIC_PREFIX_VALUE = `${ANONYMOUS_SESSION_PREFIX}gadzZXNzaW9ugqZoYW5kbGXEQ`;
  const PREFIX_LENGTH_TO_TRIM = FIXED_STATIC_PREFIX_VALUE.length;
  function isAnonymousSessionId(cookieValue) {
    return cookieValue.startsWith(ANONYMOUS_SESSION_PREFIX);
  }
  function getAnonymousCookieValueForLogging(cookieValue, length) {
    if (
      cookieValue.startsWith(FIXED_STATIC_PREFIX_VALUE) &&
      cookieValue.length > PREFIX_LENGTH_TO_TRIM + length
    ) {
      return cookieValue.substring(PREFIX_LENGTH_TO_TRIM, PREFIX_LENGTH_TO_TRIM + length);
    }

    return 'unknown-cookie-format';
  }

  if (value.startsWith(SIGNED_PREFIX)) {
    const valueAfterSignedPrefix = value.substring(SIGNED_PREFIX.length);
    const valueForLogging = isAnonymousSessionId(valueAfterSignedPrefix)
      ? getAnonymousCookieValueForLogging(valueAfterSignedPrefix, COOKIE_VALUE_LOG_LENGTH)
      : valueAfterSignedPrefix.substring(0, COOKIE_VALUE_LOG_LENGTH);
    return `[...redacted...]${valueForLogging}[...redacted...]`;
  }
  return await redactCookieValueAsHash(value);

};

async function hashValue(value) {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hash = await crypto.subtle.digest("SHA-256", data);
  const sha1 = Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return sha1.substring(0, 6);
}

async function redactAsHash(value) {
  return `[redacted-${await hashValue(value)}]`;
}

async function redactCookieValueAsHash(value) {
  // cookie values sometimes appear as URI encoded
  // and sometimes unencoded. To make sure hashes for the same value match,
  // we decode before redacting.
  try {
    const decodedValue = decodeURIComponent(value);
    return await redactAsHash(decodedValue);
  }
  catch(err) {
    return await redactAsHash(value);
  }
}

// add "export" if using ES modules
export const HarSanitizer = function (harJson) {
  this.harJson = harJson;
  this.harElems = {
    headers: {},
    cookies: {},
    queryString: {},
    params: {},
    hashParams: {},
    mimeTypes: []
  };
  this.options = {
    sensitiveWordsList,
    allowedHeaders,
    specialRedaction,
    redactedMimeTypes
  };

  this.sensitiveWordsList = [];
  this.allowedHeaders = [];
  this.transformationForCookies = {
    auth0: auth0CookieRedaction,
    auth0_compat: auth0CookieRedaction,
    did: auth0DidCookieRedaction,
    did_compat: auth0DidCookieRedaction,
    "x-okta-xsrftoken": redactCookieValueAsHash,
    "okta-oauth-state": redactKeepingBeginningAndEnd,
    "okta-oauth-redirect-params": redactKeepingBeginningAndEnd,
    "okta-oauth-nonce": redactKeepingBeginningAndEnd
  };
  this.transformationForHeaders = {
    'x-csrftoken': redactAsHash,
    "x-okta-xsrftoken": redactAsHash
  };
  this.genericParamsTransform = async function(type, key, value) {
    const keyLower = key.toLowerCase();
    if (specialRedaction[keyLower]) {
      return await specialRedaction[keyLower](value);
    } else if (
      ["samlrequest", "samlresponse"].includes(keyLower)
      && type === "params"
    ) {
      return redactSaml(value);
    } else if (
      // HTTP-Redirect binding puts the signature in a separate field
      // (that will be redacted) so we can leave the saml request intact
      keyLower === "samlrequest" && ["queryString", "hashParams"].includes(type)
    ) {
      return true;
    }
    return null;
  };

  this.transformersByType = {
    cookies: async (key, value) => {
      if (value === "") {
        return true; // leave unchanged
      }
      if (this.transformationForCookies.hasOwnProperty(key)) {
        return await this.transformationForCookies[key](value);
      }
      return await redactCookieValueAsHash(value);
    },
    headers: async (key, value) => {
      const keyLower = key.toLowerCase();
      if (value === "") {
        return true; // leave unchanged
      }
      if (this.transformationForHeaders.hasOwnProperty(keyLower)) {
        return await this.transformationForHeaders[keyLower](value);
      }
      if (keyLower === "authorization") {
        const [scheme, parameters] = value.split(/\s(.*)/);
        if (scheme.toLowerCase() === "bearer") {
          const redactedToken = await redactAsJwt(parameters);
          if (redactedToken) {
            return `${scheme} ${redactedToken}`;
          }
        }
        return `${scheme} [redacted]`;
      } else if (
        // cookie values are redacted as part of the cookies transformation
        // so no need to handle the cookie headers separately
        ["cookie", "set-cookie"].includes(keyLower) ||
        this.allowedHeaders.includes(keyLower)
      ) {
        // true means keep the same value
        return true;
      }
      return null;
    },
    queryString: (key, value) => this.genericParamsTransform("queryString", key, value),
    params: (key, value) => this.genericParamsTransform("params", key, value),
    hashParams: (key, value) => this.genericParamsTransform("hashParams", key, value),
    mimeTypes: (key, mimeType, mimeData) => {
      if (
        mimeType === "application/json" &&
        mimeData.hasOwnProperty("text")
      ) {
        // TODO: check if the content is a token JSON response and,
        // if it is, allow it, but redact all the content as we do with other fields
        return null;
      }

      return null;
    }
  };
};

HarSanitizer.prototype = {
  get harStr() {
    return JSON.stringify(this.harJson);
  }
};

HarSanitizer.prototype.scrubUrlPass = function () {
  const re = /((ftp|http|https):\/\/)([^":@/]+:)[^@/]+@/g;
  const redacted = "$1$3[password redacted]@";
  const harStrRedacted = this.harStr.replace(re, redacted);
  const harJsonRedacted = JSON.parse(harStrRedacted);
  return harJsonRedacted;
};

HarSanitizer.prototype.trimWordlist = function (wordlist) {
  const trimmedlist = [];
  const wordListLower = wordlist.map(toLower);
  const harStrLower = this.harStr.toLowerCase();

  for (const word of wordListLower) {
    if (harStrLower.includes(word)) {
      trimmedlist.push(word);
    }
  }
  return trimmedlist;
};

HarSanitizer.prototype.iterEvalExec = function (myIter, condTable) {
  const iter = myIter;
  const table = condTable;

  // because Array is a type of Object, check to see if it is an Array first
  if (Array.isArray(iter)) {
    for (const value of iter) {
      this.iterEvalExec(value, table);
    }
  } else if (typeof iter === "object") {
    for (const key in iter) {
      const value = iter[key];
      const cond = table[0](iter, key, value);
      if (cond === true) {
        const callback = table[1];
        callback(iter, key, value);
      }
      // Have to catch and ignore errors because 'value' can often be null
      try {
        if (Array.isArray(value) || typeof value === "object") {
          this.iterEvalExec(value, table);
        }
      } catch (err) {
        // ignored
      }
    }
  }
};

HarSanitizer.prototype.addHarElement = function (elementType, name, value) {
  // rawValue is created here to preserve backslashes
  // that will be present in the final JSON string due to
  // escaped double-quotes, so that the regexes for
  // these values will correctly match.
  const rawValue = value.replace(/"/g, '\\"');
  if (name in this.harElems[elementType]) {
    if (!this.harElems[elementType][name].includes(rawValue)) {
      this.harElems[elementType][name].push(rawValue);
    }
  } else {
    this.harElems[elementType][name] = [rawValue];
  }
};

// look for parameters after the "#" in URLs in:
// - the "location" header
// - the "RedirectUrl" property of response
//
// the # does not appear in regular requests URLs, as it stays in the browser
HarSanitizer.prototype.getHashParams = function() {
  const processUrl = (urlString) => {
    if (!urlString || !urlString.includes("#")) {
      return;
    }
    const url = new URL(urlString);
    if (url.hash) {
      const parsedParams = new URLSearchParams(url.hash.substring(1)); // strip the '#' character
      for(const [name, value] of parsedParams.entries()) {
        this.addHarElement("hashParams", name, value);
      }
    }
  };

  const outerCond = (iter, key, value) => {
    return ["redirecturl", "headers"].includes(key.toLowerCase());
  };
  const callback = (iter, key, value) => {
    const keyLower = key.toLowerCase();
    if (keyLower === "redirecturl") {
      if (value) {
        processUrl(value);
      }
    } else if (keyLower === "headers") {
      const locationHeader = value.find(header => header.name.toLowerCase() === "location");
      if (locationHeader) {
        processUrl(locationHeader.value);
      }
    }
  };
  const condTable = [outerCond, callback];
  this.iterEvalExec(this.harJson, condTable);
};

HarSanitizer.prototype.getTypeNames = function (harType) {
  // harType must be one of the following:
  // "cookies",
  // "headers",
  // "queryString"
  // "params" (found under postData of mimeType "application/x-www-form-urlencoded")
  // "hashParams" (found after the "#" character in URLs in the Location header and redirectURL response property)
  const field = harType;

  if (field === "hashParams") {
    this.getHashParams(harType);
    return this.harElems[field];
  }

  const outerCond = (iter, key, value) => {
    return key == field;
  };

  const callback = (iter, key, value) => {
    const innerCond = (iter, key, value) => {
      return key == "name";
    };
    const inner_callback = (iter, key, value) => {
      this.addHarElement(field, value, iter["value"]);
    };
    const innerCondTable = [innerCond, inner_callback.bind(this)];
    this.iterEvalExec(value, innerCondTable);
  };

  const condTable = [outerCond, callback.bind(this)];
  this.iterEvalExec(this.harJson, condTable);

  if (field === 'cookies') {
    // also scrub the cookie headers
    this.scrubCookieHeaders();
  }
  if (field === 'params') {
    // also scrub the "text" raw data for postData with form-urlencoded
    this.scrubParamsText();
  }

  return this.harElems[field];
};

HarSanitizer.prototype.scrubCookieHeaders = function() {
  // In addition to appearing as a separate entity under the "cookies" key,
  // cookie values also appear in the 'Cookie` and 'Set-Cookie` headers, sometimes with different encoding.
  // This second pass adds the cookie values as found in the cookie headers, so that the search/replace
  // takes all encodings into account.
  const headersCond = (iter, key, value) => key === 'headers';
  const headersCallback = (iter, key, value) => {
    const innerCond = (iter, key, value) => key === "name" && ['cookie', 'set-cookie'].includes(value.toLowerCase());
    const innerCallback = (iter, key, value) => {
      const headerNameLower = value.toLowerCase();
      const headerValue = iter["value"];
      if (headerNameLower === "cookie") {
        const pairs = headerValue.split("; ");
        for(const pair of pairs) {
          const [cookieName, cookieValue] = pair.split(/=(.*)/);
          if (cookieName && cookieValue) {
            this.addHarElement("cookies", cookieName, cookieValue);
          }
        }
      } else if (headerNameLower === "set-cookie") {
        const nameValuePair = headerValue.split(";")[0];
        if (nameValuePair) {
          const [cookieName, cookieValue] = nameValuePair.split(/=(.*)/);
          if (cookieName && cookieValue) {
            this.addHarElement("cookies", cookieName, cookieValue);
          }
        }
      }
    };

    const innerHeadersCondTable = [innerCond, innerCallback.bind(this)];
    this.iterEvalExec(value, innerHeadersCondTable);
  };

  const headersCondTable = [headersCond, headersCallback.bind(this)];
  this.iterEvalExec(this.harJson, headersCondTable);
};

HarSanitizer.prototype.scrubParamsText = function() {
    // params values can also appear in the 'text' property, sometimes (Firefox) URL-encoded, which
    // looks different than the "value" property of the params.
    // "postData": {
    //   "mimeType": "application/x-www-form-urlencoded",
    //   "params": [
    //     {
    //       "name": "username",
    //       "value": "nico@nico.com"
    //     },
    //     {
    //       "name": "password",
    //       "value": "75Pq[r-w9P"
    //     }
    //   ],
    //   "text": "username=nico%40nico.com&password=75Pq%5Br-w9P"
    // }
    //
    // This second pass parses the "text" value for application/x-www-form-urlencoded postData
    // without URL-decoding the values, and add those as params data to evaluate.

    function splitFormUrlEncoded(text) {
      const tuples = text.split("&");
      const result = [];

      for(const tuple of tuples) {
        result.push(tuple.split(/=(.*)/,2),);
      }
      return result;

    }
    const outerCond = (iter, key, value) => key === "postData";
    const callback = (iter, key, value) => {
      const innerCond = (iter, key, value) => iter["mimeType"] === "application/x-www-form-urlencoded" && key === "text";
      const innerCallback = (iter, key, value) => {
        if (value) {
          const parsedValues = splitFormUrlEncoded(value);
          for(const [formKey, formValue] of parsedValues) {
            if (formValue) {
              this.addHarElement("params", formKey, formValue);
            }
          }
        }
      };
      const innerCondTable = [innerCond, innerCallback.bind(this)];
      this.iterEvalExec(value, innerCondTable);
    };

    const condTable = [outerCond, callback.bind(this)];
    this.iterEvalExec(this.harJson, condTable);
};

HarSanitizer.prototype.getMimeTypes = function () {
  const callback = (iter, key, value) => {
    if (!this.harElems["mimeTypes"].includes(value)) {
      this.harElems["mimeTypes"].push(value);
    }
  };
  const cond = (iter, key, value) => {
    return key == "mimeType";
  };
  const condTable = [cond, callback.bind(this)];
  this.iterEvalExec(this.harJson, condTable);
  return this.harElems["mimeTypes"];
};

HarSanitizer.prototype.scrubMimeTypes = function (mimeTypes) {
  mimeTypes = mimeTypes || this.options.redactedMimeTypes;
  for (const mimeType of mimeTypes) {
    const cond = (iter, key, value) => {
      const isKeyMimetype = key == "mimeType";
      const isValueMimetype = isKeyMimetype && value.startsWith(mimeType);
      const isText = Object.keys(iter).includes("text");
      const isAll = isKeyMimetype && isValueMimetype && isText;
      return isAll;
    };

    const callback = (iter, key, value) => {
      const redactedText = this.transformersByType["mimeTypes"](
        key,
        value,
        iter
      );
      iter["text"] = redactedText
        ? redactedText
        : iter.encoding === "base64" || mimeType.startsWith("image/") || mimeType.startsWith("font/") || mimeType.includes("octet-stream")
        // base64 encoded content can't say "[xxx redacted]" because it breaks
        // HAR analyzer tools like https://toolbox.googleapps.com/apps/har_analyzer/
        ? ""
        : `[${mimeType} redacted]`;
    };

    const condTable = [cond, callback.bind(this)];
    this.iterEvalExec(this.harJson, condTable);
  }
  return this.harJson;
};

HarSanitizer.prototype.scrubStackTraces = function () {
  for(const entry of this.harJson.log.entries) {
    if(entry._initiator && entry._initiator.stack) {
      delete entry._initiator.stack;
    }
  }
  return this.harJson;
};

HarSanitizer.prototype.redactHar = async function () {
  let harStrRedacted = this.harStr;
  // join sensitive words list with the other lists and dedup
  const sensitiveWordsList = [...new Set(
    this.options.sensitiveWordsList
    .concat(Object.keys(specialRedaction))
  )];
  const scrubList = this.trimWordlist(sensitiveWordsList);
  const scrubListLower = scrubList.map(toLower);
  this.sensitiveWordsList = scrubListLower;
  this.allowedHeaders = this.options.allowedHeaders.map(toLower);

  for (const type of Object.keys(this.harElems).filter(type => type !== "mimeTypes")) {
    for (const key of Object.keys(this.harElems[type])) {
      const keyLower = key.toLowerCase();
      const keyInList = scrubListLower.includes(keyLower);
      if (
        keyInList ||
        type == "cookies" ||
        type == "headers"
      ) {
        for (const value of this.harElems[type][key]) {
          if (value === "") {
            // leave empty values unredacted
            continue;
          }
          const transformedValue = await this.transformersByType[type](key, value);
          if (transformedValue === true) {
            // true means don't redact the value
            continue;
          }

          // for bigger values like SAML responses, we do a simple search and replace
          if (
            value.length > 256 &&
            transformedValue &&
            transformedValue.length > 256
          ) {
            harStrRedacted = harStrRedacted.replaceAll(value, transformedValue);
          } else {
            const valueFormatted = escapeRegExp(value.toString());

            const redactedText = (transformedValue || `[${key} redacted]`)
            // escape any double quotes in the redacted text to avoid breaking the JSON string
            .replace(/"/g, '\\"');

            const regex =
              "" +
              '(["?#&;, ]{1}' +
              key +
              "){1}(?![{}[]])" +
              '(","value":"|=){1}' +
              "(" +
              valueFormatted +
              "){1}" +
              '(",|"}|"]|;|&){1}';
            const redacted = `$1$2${redactedText}$4`;
            const re = new RegExp(regex, "g");
            harStrRedacted = harStrRedacted.replace(re, redacted);
            // key/value order flipped regex
            const regexBackwards =
              "" +
              '("value":"){1}' +
              "(" +
              valueFormatted +
              "){1}(?![{}[]])" +
              '(","name":"' +
              key +
              '"){1}';
            const redactedBackwards = `$1${redactedText}$3`;
            const reBackwards = new RegExp(regexBackwards, "g");
            harStrRedacted = harStrRedacted.replace(
              reBackwards,
              redactedBackwards
            );
          }
        }
      }
    }
  }
  const harJsonRedacted = JSON.parse(harStrRedacted);
  return harJsonRedacted;
};
