import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from 'react-bootstrap/Button';

export const SearchInput = ({
  value,
  onClear,
  onSubmit,
  onChange,
  action,
  target,
  disabled,
  placeholder = "Search Auth0 Docs, Community and Blog"
}) => {
  function clearResults() {
    if (onClear) {
      onClear();
    }
  }

  function handleSubmit(event) {
    if (onSubmit) {
      onSubmit(event);
    }
  }

  function handleChange(event) {
    if (onChange) {
      onChange(event.target.value);
    }
  }
  return (
    <form action={action} target={target} method="get" onSubmit={handleSubmit}>
      <div className="search-input-container input-group">
        <div className="input-text-field">
          <i key="search-icon-loupe" className="loupe icon-budicon-247" />
          <input
            type="search"
            name="q"
            value={value}
            className="form-control search-input"
            placeholder={placeholder}
            onChange={handleChange}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            autoFocus
          />
          {value && (
            <i
              key="search-icon-clear"
              className="icon icon-budicon-471 clear-result"
              onClick={clearResults}
            />
          )}
        </div>
        <div className="input-group-btn">
          <Button type="submit" variant="primary" disabled={disabled}>
            Search
          </Button>
        </div>
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  action: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func
};
