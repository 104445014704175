import { postSalesforceAttachment } from '../api/deferredUploadApi';
import { SUPPORT_TICKET_ERROR } from '../../../utils/logger/logTypes';
import logger from '../../../utils/logger';
import { validateAndCleanup } from './validate';

// All of the actions relating to deferred uploads
export const actionTypes = {
  DEFERRED_UPLOAD_BEGIN: 'DEFERRED_UPLOAD_BEGIN',
  DEFERRED_UPLOAD_SUCCESS: 'DEFERRED_UPLOAD_SUCCESS',
  DEFERRED_UPLOAD_ERROR: 'DEFERRED_UPLOAD_ERROR',
  DEFERRED_UPLOAD_QUEUE_ADDING: 'DEFERRED_UPLOAD_QUEUE_ADDING',
  DEFERRED_UPLOAD_QUEUE_ADDED: 'DEFERRED_UPLOAD_QUEUE_ADDED',
  DEFERRED_UPLOAD_QUEUE_FAILED: 'DEFERRED_UPLOAD_QUEUE_FAILED',
  DEFERRED_UPLOAD_QUEUE_REMOVE: 'DEFERRED_UPLOAD_QUEUE_REMOVE',
  DEFERRED_UPLOAD_QUEUE_CLEAR: 'DEFERRED_UPLOAD_QUEUE_CLEAR',
  DEFERRED_UPLOAD_REQUEST_BEGIN: 'DEFERRED_UPLOAD_REQUEST_BEGIN',
  DEFERRED_UPLOAD_REQUEST_SUCCESS: 'DEFERRED_UPLOAD_REQUEST_SUCCESS',
  DEFERRED_UPLOAD_REQUEST_ERROR: 'DEFERRED_UPLOAD_REQUEST_ERROR'
};

/**
 * A set of id: File pairs
 * Updated through actions
 * Used to store File objects outside of the Redux store
 */
export const fileMap = {};

/**
 * Similar to fileMap, but contains the file that couldn't be added to the queue
 * after cleanup because it exceeded maximum file size. We keep it so that users can
 * still download it.
 *
 */
export const erroredFile = {};

function setErroredFile(file, id) {
  erroredFile.file = file;
  erroredFile.id = id;
}

function clearErroredFile() {
  delete erroredFile.file;
  delete erroredFile.id;
}

/**
 * Clears the upload queue
 *
 * @returns {object} action
 */
export function clearQueue() {
  // Remove all fileMap items
  for (const id of Object.keys(fileMap)) {
    delete fileMap[id];
  }
  clearErroredFile();

  return {
    type: actionTypes.DEFERRED_UPLOAD_QUEUE_CLEAR
  };
}

/**
 * Adds a file to the upload queue
 *
 * @param {string} id
 * @param {File} file
 * @returns {object} action
 */
export function addToQueue(id, file) {
  return async function dispatchStartUploading(dispatch, getState) {
    clearErroredFile();
    dispatch(addingToQueue(id, file));
    const results = (await validateAndCleanup(file)) || {};
    if (results.error) {
      setErroredFile(results.cleanedFile, id);
      dispatch(addToQueueFailed(id, file, results.error, results.cleanedUp));
    } else {
      fileMap[id] = results.cleanedFile || file;
      dispatch(addedToQueue(id, fileMap[id], results.cleanedUp));
    }
  };
}


function addingToQueue(id, file) {
  return {
    type: actionTypes.DEFERRED_UPLOAD_QUEUE_ADDING,
    filename: file.name,
    id
  };
}

function addedToQueue(id, file, cleanedUp) {
  return {
    type: actionTypes.DEFERRED_UPLOAD_QUEUE_ADDED,
    filename: file.name,
    id,
    cleanedUp: cleanedUp || false
  };
}

function addToQueueFailed(id, file, error, cleanedUp) {
  return {
    type: actionTypes.DEFERRED_UPLOAD_QUEUE_FAILED,
    filename: file.name,
    id,
    error: error.toString(),
    cleanedUp: cleanedUp
  };
}

/**
 * Removes a file from the upload queue
 * @param {string} id
 * @returns {object} action
 */
export function removeFromQueue(id) {
  delete fileMap[id];
  clearErroredFile();
  return {
    type: actionTypes.DEFERRED_UPLOAD_QUEUE_REMOVE,
    id
  };
}

/**
 * Start uploading all the files in the queue
 * @param {string} commentId
 * @returns {object} action
 */
export function upload(commentId) {
  return function dispatchStartUploading(dispatch, getState) {
    clearErroredFile();
    dispatch(uploadBegin());

    const promisedUploads = getState().deferredUploads.queue.map(queueItem => {
      const file = fileMap[queueItem.id];
      dispatch(uploadRequestBegin(queueItem.id));

      return postSalesforceAttachment(commentId, file)
        .then(() => dispatch(uploadRequestSuccess(queueItem.id)))
        .catch(err => {
          dispatch(uploadRequestError(queueItem.id, err));
          throw(err);
        });
    });

    return Promise.all(promisedUploads)
      .then((  ) => dispatch(uploadSuccess()))
      .catch(err => dispatch(uploadError(err)));
  };
}

/**
 * Marks the start of uploading all the files
 * @returns {object} action
 */
export function uploadBegin() {
  return {
    type: actionTypes.DEFERRED_UPLOAD_BEGIN
  };
}

/**
 * Marks the end of uploading all the files
 * @returns {object} action
 */
export function uploadSuccess() {
  return {
    type: actionTypes.DEFERRED_UPLOAD_SUCCESS
  };
}

/**
 * Marks an error during file upload
 * @param {Error} error
 * @returns {object} action
 */
export function uploadError(error) {
  return {
    type: actionTypes.DEFERRED_UPLOAD_ERROR,
    error,
    log_type: SUPPORT_TICKET_ERROR
  };
}

/**
 * Marks the start of uploading a single file
 * @param {string} id
 * @returns {object} action
 */
export function uploadRequestBegin(id) {
  return {
    type: actionTypes.DEFERRED_UPLOAD_REQUEST_BEGIN,
    id
  };
}

/**
 * Marks the successful completion of uploading a single file
 * @param {string} id
 * @returns {object} action
 */
export function uploadRequestSuccess(id) {
  delete fileMap[id];

  return {
    type: actionTypes.DEFERRED_UPLOAD_REQUEST_SUCCESS,
    id
  };
}

/**
 * Marks an error occurred during upload of a single file
 * @param {string} id
 * @param {Error} error
 * @returns {object} action
 */
export function uploadRequestError(id, error) {
  delete fileMap[id];

  if (error.config) {
    delete error.config;
  }

  logger.error('Error uploading file', {
    log_type: SUPPORT_TICKET_ERROR,
    err: error
  });

  return {
    type: actionTypes.DEFERRED_UPLOAD_REQUEST_ERROR,
    id,
    error,
    log_type: SUPPORT_TICKET_ERROR
  };
}

export function getQueuedFile(localId) {
  return fileMap[localId];
}

export function getErroredFile(localId) {
  return erroredFile.id === localId ? erroredFile.file : undefined;
}
