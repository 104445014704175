import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapPagination from 'react-bootstrap/Pagination';

const Pagination = ({activePage, pageCount, pageSelect, children}) => {
  activePage = Number.parseInt(activePage, 10);
  const items = [];
  for (let i = 0; i < pageCount; i++) {
    const pageNumber = i + 1;
    items.push(<BootstrapPagination.Item onClick={pageSelect(pageNumber)} active={activePage === pageNumber}>{pageNumber}</BootstrapPagination.Item>);
  }
  return (
    <div>
      {children}
      {pageCount >= 1  &&
        <BootstrapPagination bsPrefix="auth0-pagination">
          {activePage > 1 && <BootstrapPagination.First onClick={pageSelect(1)}>« First</BootstrapPagination.First>}
          {activePage > 1 && <BootstrapPagination.Prev onClick={pageSelect(activePage - 1)}>‹ Previous</BootstrapPagination.Prev>}
          {items}
          {activePage < pageCount &&<BootstrapPagination.Next onClick={pageSelect(activePage + 1)}>Next ›</BootstrapPagination.Next>}
          {activePage < pageCount && <BootstrapPagination.Last onClick={pageSelect(pageCount)}>Last »</BootstrapPagination.Last>}
        </BootstrapPagination>
      }
    </div>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageSelect: PropTypes.func.isRequired,
  children: PropTypes.node
};

Pagination.defaultProps = {
  activePage: 1,
  pageCount: 1,
  pageSelect: 1
};

export default Pagination;
