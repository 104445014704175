import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import { more as constants } from '../../constants';

import './Dropdown.module.css';

/**
 * 'More' item in the Navbar
 * Used to display a dropdown of items that don't fit horizontally
 */
const DropdownMore = ({ show, children, className }) => (
  <div styleName="more" className={className}>
    <Dropdown title={constants.title} show={show}>
      {
        children.map((item, i) => (
          <li key={i} styleName="dropdown-item">
            {item}
          </li>
        ))
      }
    </Dropdown>
  </div>
);

DropdownMore.propTypes = {
  children: PropTypes.array.isRequired,
  show: PropTypes.bool,
  className: PropTypes.string
};

export default DropdownMore;