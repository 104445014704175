import React from 'react';
import PropTypes from 'prop-types';
import NeutralAlert from '../../common/components/NeutralAlert';

const EmailVerificationSentAlert = ({ email, autoClose }) => {
  return (<NeutralAlert outside dismiss autoClose={autoClose}>
    <i className="icon-neutral icon-neutral--outside icon-budicon-500 green" />
    <span className="alert-neutral-text-body">
      Verification email sent to <strong>{email}</strong>
    </span>
  </NeutralAlert>);
};

EmailVerificationSentAlert.propTypes = {
  email: PropTypes.string,
  autoClose:PropTypes.bool
};

export default EmailVerificationSentAlert;