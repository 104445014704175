import api from 'api';
import * as types from '../../../redux/actionTypes';

export function sendEmailVerificationBegin() {
  return { type: types.SEND_EMAIL_VERIFICATION_BEGIN };
}

export function sendEmailVerificationSuccess() {
  return { type: types.SEND_EMAIL_VERIFICATION_SUCCESS };
}


export function sendEmailVerification() {
  return function (dispatch) {
    dispatch(sendEmailVerificationBegin());
    return api.emailVerification.verifyEmail()
      .then(() => dispatch(sendEmailVerificationSuccess()))
      .catch(error => dispatch(sendEmailVerificationError(error)));
  };
}

export function sendEmailVerificationError(error) {
  return { type: types.SEND_EMAIL_VERIFICATION_ERROR, error: error };
}
