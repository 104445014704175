import { request } from 'api';
import axios from 'axios';

class checkApi {
  static getAuthentication() {
    return axios({
      url: `${window.scConfig.SUPPORT_API_URL}healthchecks/authentication`,
      method: 'get',
      responseType: 'json',
      cache: false,
      timeout: 10 * 1000 // 10 seconds in ms
    }).then(result => {
      return result.data;
    });
  }
}

export default checkApi;
