import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { FILE_EXTENSIONS } from '@a0/support-center-common/constants/tickets/attachments';

import * as deferredUploadActions from '../../../uploads/actions/deferredUploadActions';
import { propTypes as deferredUploadPropTypes } from '../../../uploads/reducers/deferredUploadReducer';
import { QueuedFile } from './QueuedFile';
import { DownloadFileLink } from './DownloadFileLink';

const fileSizeToText = (size) => {
  const sizeInMBs = size/1024/1024;
  const rounded = Math.ceil(sizeInMBs*10) / 10;
  return `${rounded} MB`;
};

export const FileUploads = ({ deferredActions, deferredUploads }) => {
  const fileInputRef = useRef(null);

  useEffect(function clearQueue() {
    deferredActions.clearQueue();
  }, []);

  function onFileUpload(event) {
    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];
      const localId = `file_${Date.now()}`;
      // Deferred upload to Salesforce
      deferredActions.addToQueue(localId, file);
    }
  }

  function handleUploadFileRemove(localFileId) {
    // Remove the file from the Salesforce upload queue
    deferredActions.removeFromQueue(localFileId);
    if (fileInputRef.current) {
      // if the field is not cleared, the onChange event won't fire
      // when the user tries to upload a file right after removing it.
      fileInputRef.current.value = "";
    }
  }

  function handleAttachClick() {
    fileInputRef.current.click();
  }

  const { lastQueueOperation, queue } = deferredUploads;
  const erroredFile = lastQueueOperation && lastQueueOperation.error && lastQueueOperation.cleanedUp
    && deferredUploadActions.getErroredFile(lastQueueOperation.id);
  return (
    <div className="attachments-footer">
      <input
        type="file"
        id="file"
        name="file"
        onChange={onFileUpload}
        accept={FILE_EXTENSIONS.map((ext) => `.${ext}`).join(",")}
        ref={fileInputRef}
        className="hidden"
      />
      <Button onClick={handleAttachClick} size="sm" variant="default">
        <i className="icon-budicon-696 icon" /> <span>Attach file</span>
      </Button>
      {queue.length > 0 && (
        <ul>
          {queue.map((queuedItem) => (
            <li key={queuedItem.id}>
              <QueuedFile onRemove={handleUploadFileRemove} queuedItem={queuedItem} />
            </li>
          ))}
        </ul>
      )}
      {lastQueueOperation && lastQueueOperation.error && (
        <Alert variant="danger">
          Error adding <code>{lastQueueOperation.filename}</code>:{" "}
          {lastQueueOperation.error.toString()}
          {erroredFile && (
            <div>
              <DownloadFileLink file={erroredFile}>
                Download sanitized file ({fileSizeToText(erroredFile.size)})
              </DownloadFileLink>
            </div>
          )}
        </Alert>
      )}
    </div>
  );
};

FileUploads.propTypes = {
  deferredActions: PropTypes.object.isRequired,
  deferredUploads: deferredUploadPropTypes.isRequired
};

function mapStateToProps(state) {
  return {
    deferredUploads: state.deferredUploads
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deferredActions: bindActionCreators(deferredUploadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploads);
