import {browserHistory} from 'react-router';

import api from 'api';
import * as types from '../../../redux/actionTypes';

import { getUser } from '../../login/actions/authActions';
import { SUBSCRIPTION_ERROR } from '../../../utils/logger/logTypes';

function loadTenantAgreementsBegin() {
  return { type: types.LOAD_TENANT_AGREEMENTS_BEGIN};
}

function loadTenantAgreementsSuccess(records) {
  return { type: types.LOAD_TENANT_AGREEMENTS_SUCCESS, records};
}

export function loadTenantAgreementsError(error) {
  return { type: types.LOAD_TENANT_AGREEMENTS_ERROR, error, log_type: SUBSCRIPTION_ERROR };
}
function loadTenantAgreementsIds(custom_agreements) {
  return { type: types.LOAD_TENANT_AGREEMENTS_IDS_FOR_USER, custom_agreements};
}

export function setSelectedAgreement(tenant) {
  return { type: types.SET_SELECTED_AGREEMENT, tenant};
}

export function loadTenantAgreementsForUser(userId, options) {
  if (!userId) {
    const user = getUser();
    userId = user.sub;
  }

  return function(dispatch, getState) {
    dispatch(loadTenantAgreementsBegin());

    return api.tenants.getTenantsAgreement(userId, options, true)
    .then(agreements => {
      const normalizedData = {};
      const agreementIds = [];

      agreements.forEach(agreement => {
        normalizedData[agreement.tenant] = agreement;
        agreementIds.push(agreement.tenant);
      });

      dispatch(loadTenantAgreementsIds(agreementIds));
      dispatch(loadTenantAgreementsSuccess(normalizedData));
    }).catch(error => {
      dispatch(loadTenantAgreementsError(error));
    });
  };
}
