import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ClassName from 'classnames';
import { HotjarMasked } from '@a0/support-center-components';

import Markdown from '../../common/components/Markdown';

import Metrics from '../../../utils/metrics';
import { ArrowRight } from 'react-feather';

const Notification = ({notification, truncateHeight = false }) => {
  const categoryClass = ClassName({
    'notification-item': true,
    'critical-notification': notification.priority != 1,
    'truncate-height': truncateHeight
  });

  const trackOpen = (title, category) => () => {
    /*Metrics.track('open:sc:notification', {trackData: title, value: category});*/
  };

  return (
    <li data-notification-category={notification.category}
        className={categoryClass}>
      <div className="notification-item-inner">
        <span className="notification-icon">
          <Link
            to={"/notifications/" + notification._id}
            /*onClick={trackOpen(notification.title, notification.category)}*/
            className="notification-title">
              <i className="icon" />
          </Link>
        </span>
        <Link
          to={"/notifications/" + notification._id}
          /*onClick={trackOpen(notification.title, notification.category)}*/
          className="notification-title">
            <HotjarMasked isInline>{notification.title}</HotjarMasked>
        </Link>
        <span className="notification-date">{notification.updatedOn}</span>
        <div className="notification-text">
          <HotjarMasked><Markdown text={notification.body}/></HotjarMasked>
        </div>

      </div>
      {notification.hasLongBody &&
        <Link to={"/notifications/" + notification._id}
          /*onClick={trackOpen(notification.title, notification.category)}*/
          className="read-more pull-right">
            Read More
        </Link>}
    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  truncateHeight: PropTypes.bool
};

export default Notification;
