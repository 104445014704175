import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import { humanizeTenantId } from '@a0/support-center-common/lib/tenants';

const SelfServiceMasterAlert = ({tenants}) => {

  return (
    <Alert variant="info">
      Selected Service has consolidated reports for associated Services: {
        tenants.map((tenant, i) => `${humanizeTenantId(tenant.id)}${i+1 < tenants.length ? ', ' :''}`)
      }
    </Alert>
  );
};


SelfServiceMasterAlert.propTypes = {
  tenants: PropTypes.array.isRequired
};

export default SelfServiceMasterAlert;
