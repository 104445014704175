import { getCompanyName } from '@a0/support-center-common/selectors/subscriptions';
import { getSelectedTenant } from '@a0/support-center-common/selectors/common';

import queryString from 'query-string';

import Metrics from '../../../utils/metrics';

export const contactSales = (eventLocation, metadata = {}) => (dispatch, getState) => {
  const state = getState();
  const user = state.auth.user;
  const { name, email } = user;
  const selectedTenant = getSelectedTenant(state.tenants);
  const tenant = selectedTenant ? selectedTenant.id : '';

  const query = {
    ...metadata,
    company: getCompanyName(state),
    name,
    email,
    tenant
  };
  window.open(`https://auth0.com/contact-us-dashboard?${queryString.stringify(query)}`,
    '_blank');
  Metrics.track(`click:sc:contact-sales`, { trackData: eventLocation });
  Metrics.track('click:contact-sales', { trackData: query, tenant });
};
