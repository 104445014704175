import * as authActions from '../../features/login/actions/authActions';
import { confirm } from '../../utils/confirm';
import { removeUnloadHook } from '../../utils/unloadHooks';
import { clearTicketStateFromStorage } from '../../features/tickets/utils/session';
import getErrorStatus from '../../utils/getErrorStatus';
import { isUnavailableTokenError } from '../../api/request';
import * as types from '../actionTypes';

const ONE_SECOND_IN_MS = 1000;
const { SUPPORT_API_URL } = window.scConfig;

/**
 * Clear the redirection timeout before call the redirect callback
 * @param {number} timeoutId the timeout id
 * @param {function} redirect the redirect callback
 */
function clearTimeoutAndRedirect(timeoutId, redirect) {
  return function() {
    clearTimeout(timeoutId);
    redirect();
  };
}

const is401ErrorIsFromAuthenticatedApiRequest = (error) => (
  error
    && getErrorStatus(error) === 401
    && error.config && error.config.url.startsWith(SUPPORT_API_URL)
);

/**
 * Middleware for catch 401 responses
 * @param store
 */
const redirectToLoginIfUnauthorized = ({ dispatch }) => next => async action => {
  if (
    action.type.endsWith("_ERROR") &&
    action.type !== types.LOAD_AUTHENTICATION_STATUS_ERROR &&
    (isUnavailableTokenError(action.error) || is401ErrorIsFromAuthenticatedApiRequest(action.error))
  ) {
    const { pathname, search } = window.location;
    const redirect = () => authActions.login(`${pathname}${search}`)(dispatch);
    const redirectTimeoutId = setTimeout(redirect, ONE_SECOND_IN_MS);

    // Remove the beforeUnload hook
    removeUnloadHook(clearTicketStateFromStorage);

    await confirm("Your session has expired. Redirecting to login page...", {
      title: "Session Expired",
      okLabel: "Continue to log in",
      cancelLabel: "Return to the home page",
      enableEscape: false
    }).then(clearTimeoutAndRedirect(redirectTimeoutId, redirect),() => {
      // confirm throws an error when the user cancels
      // so we can ignore the error safely.
      clearTimeout(redirectTimeoutId);
      return authActions.logout()(dispatch);
    });
  }
  return next(action);
};

export default redirectToLoginIfUnauthorized;
