import classNames from 'classnames';

const ZENDESK_STATUSES = {
  open: ["new", "open"],
  pending: ["pending"],
  solved: ["solved"],
  closed: ["closed"],
  hold: ["hold"]
};

const SALESFORCE_STATUSES = {
  open: ["in progress", "with sustainment"],
  pending: ["pending"],
  solved: ["resolved", "closed - no response"],
  closed: ["closed"],
  hold: ["customer hold"]
};


/**
 * Checks is current status string is supported by given status
 * @param {String} statusType
 * @param {String} currentStatusString
 * @returns {Boolean}
 */
export function checkTicketStatusClass(statusType, currentStatusString) {
  return [
    ...new Set([...ZENDESK_STATUSES[statusType], ...SALESFORCE_STATUSES[statusType]])
  ].includes(currentStatusString);
}

/**
 * Get the status classes for tickets
 * 
 * @param {String} status Current status
 */
export function getTicketStatusClasses(status) {
  return classNames("label", {
    "label-primary": checkTicketStatusClass("open", status),
    "label-warning": checkTicketStatusClass("pending", status),
    "label-success": checkTicketStatusClass("solved", status),
    "label-default": checkTicketStatusClass("closed", status),
    "label-dark": checkTicketStatusClass("hold", status)
  });
}
