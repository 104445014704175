import React from "react";

import { IndexLink } from "react-router";
import { CoveoEngineWrapper } from "../components/coveo/CoveoEngineWrapper";
import { SearchSection } from "../components/coveo/SearchSection";
import { Auth0DocsSearchBox } from "../components/Auth0DocsSearchBox";

export const SearchPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const initialSearchQuery = urlParams.get("q") || "";

  return (
    <div className="search">
      <div className="bg-dots page-header-dots">
        <div className="container">
          <ol className="breadcrumb">
            <li>
              <IndexLink to="/">Home</IndexLink>
            </li>
            <li>Search</li>
          </ol>
          <div className="search-page-contents">
            <CoveoEngineWrapper fallback={<Auth0DocsSearchBox initialValue={initialSearchQuery} />}>
              <SearchSection />
            </CoveoEngineWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
