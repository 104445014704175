import * as api from 'api';

const UPLOAD_TIMEOUT = 4 * 60 * 1000; // 4' in ms

/**
 * Makes a POST request to SC API to create an attachment in Salesforce
 * @param {string} commentId 
 * @param {File} file
 * @returns {promise<object>} response 
 */
export function postSalesforceAttachment(commentId, file) {  
  return api.request(
    `tickets/attachment?filename=${encodeURIComponent(file.name)}&emailMessageId=${commentId}`, 
    {
      method: 'post',
      data: file,
      timeout: UPLOAD_TIMEOUT
    }
  );
}