/**
 * TODO fixme
 * refactor this error messaging into react components using composition
 * instead of passing html strings and using dangerouslySetInnerHtml 
 */
function getTicketErrorMessage({ type = "comment" }) {
  return `
    There was an error submitting your ${ type }. We have been notified of the issue.<br />
    Please try again in a few minutes or use our <a href="https://community.auth0.com">Auth0 Community</a>
    to receive support from our engineers. 
  `;
}

export default getTicketErrorMessage;