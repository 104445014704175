import React from 'react';
import PropTypes from 'prop-types';
import CommentEditor from '../CommentEditor';

/**
 * New Ticket Comment (description) field component
 */
const Comment = ({ name, onChange, value, error, uploadedRecords }) => (
  <CommentEditor
    name={name}
    onChange={onChange}
    classes="animated fadeIn"
    value={value}
    error={error}
    uploadedRecordsFromStorage={uploadedRecords}
  />
);

Comment.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** The possible error of the field */
  error: PropTypes.string,
  /* The uploaded records from storage */
  uploadedRecords: PropTypes.array
};

export default Comment;
