import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';
import moment from 'moment';
import { getTargetTenantsLegend } from '@a0/support-center-common/lib/tenants';

export default function notificationsReducer(state = initialState.notifications, action) {
  switch (action.type) {
    case types.LOAD_NOTIFICATIONS_BEGIN:
      return Object.assign({}, state,
          {loading: true, error: null, records: []});

    case types.LOAD_NOTIFICATIONS_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        error: null,
        records: action.notifications.map(notification => {
          return Object.assign({}, notification, {
            updatedOn: moment(notification.updatedOn).fromNow(),
            body: notification.body,
            targetTenantsStr: getTargetTenantsLegend(notification.targetTenants, 'tenant')
          });
        })
      });
    }

    case types.LOAD_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading your notifications: ${action.errorMessage}`,
        records: []
      });

    case types.SET_NOTIFICATION_FILTER_OPTION:
      return Object.assign({}, state, {filterOption: action.filterOption});

    default:
      return state;
  }
}
