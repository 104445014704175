/*
 * Drop in replacement for react-bootstrap's Breadcrumb.Item
 * Uses react-router to prevent page reload
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classNames from 'classnames';

const propTypes = {
  active: PropTypes.bool,
  href: PropTypes.string,
  title: PropTypes.node,
  target: PropTypes.string,
  className: PropTypes.string
};

const defaultProps = {
  active: false
};

const BreadcrumbItem = props => {
  const linkProps = {
    to:     props.href,
    title:  props.title,
    target: props.target
  };
  
  return (
    <li className={classNames(props.className, { active: props.active })}>
      {props.active ?
        <span {...props} /> :
        <Link {...props} {...linkProps} />
      }
    </li>
  );
};

BreadcrumbItem.propTypes = propTypes;
BreadcrumbItem.defaultProps = defaultProps;

export default BreadcrumbItem;