import { flow } from 'lodash';
import { requireAuthentication } from './features/common/containers/AuthenticatedComponent';
import { catchErrors } from './features/common/containers/ErrorBoundary';

/**
 * Compose two HOCs into a helper fn
 */

export const requireAuthenticationAndCatchErrors = (Component, logTags) => {
  return catchErrors(requireAuthentication(Component), logTags);
};

export { requireAuthentication, catchErrors };