import React from 'react';
import PropTypes from 'prop-types';
import { startCase, get } from 'lodash';
import SelectInput from '../../../common/components/SelectInput';
import { TENANT } from '../../constants';
import { filterTenantsToShow } from '../../../../utils/ticketFilters';

import {
  tenantsFormattedForDropdown
} from '@a0/support-center-common/selectors/common';

/**
 * New Ticket Tenant name selector component
 */
const Tenant = ({ name, value, onChange, selectedSubscription, tenants }) => {
  const isAppliance = !!get(selectedSubscription, 'is_appliance');
  const onlyOneTenant = get(tenants, 'length') === 1;
  const formattedTenants = tenantsFormattedForDropdown(tenants.filter(filterTenantsToShow));

  const subscriptionName = selectedSubscription && selectedSubscription.name;
  const defaultOptionText = `${TENANT.DEFAULT_OPTION[0]} ${subscriptionName} ${TENANT.DEFAULT_OPTION[1]}`;

  const defaultOption = selectedSubscription && selectedSubscription.name === 'Developer' ?
  `${defaultOptionText}${TENANT.DEFAULT_OPTION_DEV_SUFFIX}` :
    defaultOptionText;

  const label = isAppliance ?
    TENANT.APPLIANCE_LABEL :
    TENANT.LABEL;

  return (
    <SelectInput
      name={name}
      label={`${label} (*)`}
      defaultOption={defaultOption}
      options={formattedTenants}
      onChange={onChange}
      classes="animated fadeIn"
      value={value}
      disabled={onlyOneTenant}
      hotjarMasked
    />
  );
};

Tenant.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** Subscription context from redux state */
  selectedSubscription: PropTypes.object,
  /** Tenant list for select */
  tenants: PropTypes.array
};

export default Tenant;
