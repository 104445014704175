import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IndexLink, Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Error, HotjarMasked } from '@a0/support-center-components';

import Loading from '../../common/components/Loading';
import Markdown from '../../common/components/Markdown';

import * as notificationsActions from '../actions/notificationActions';
import { filterTenantIdsBySubscription } from '@a0/support-center-common/selectors/common';

import Metrics from '../../../utils/metrics';

export class NotificationPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actions.loadNotification(this.props.params.id);
  }

  componentWillReceiveProps({ params, actions, notification, currentTenantIds }) {
    if (params.id != this.props.params.id) {
      actions.loadNotification(params.id);
    }
  }

  render() {
    const {loading, notification, error} = this.props;
    return (
      <div>
        <div className="bg-dots page-header-dots hide-for-mobiles"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 page-canvas">
              <div className="add-dots-mobiles">
                <ol className="breadcrumb text-truncate">
                  <li><IndexLink to="/">Home</IndexLink></li>
                  <li className="active"><Link to="/notifications">Notifications</Link></li>
                  <li className="active">{notification.title}</li>
                </ol>
              </div>
              <Loading show={loading}>
                <div className="support-main-container notification notification-mobile">
                  <Error message={error}>
                    <div>
                      <h1>{notification.title}</h1>
                      <h5>{notification.createdOn}</h5>
                      <div><HotjarMasked><Markdown text={notification.longBody}/></HotjarMasked></div>
                    </div>
                  </Error>
                </div>
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotificationPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  notification: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  currentTenantIds: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    loading: state.notification.loading,
    error: state.notification.error,
    notification: state.notification.record,
    currentTenantIds: filterTenantIdsBySubscription(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(notificationsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
