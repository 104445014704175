import logger from '../../../utils/logger';

import {
  FILE_MAX_SIZE,
  FILE_EXTENSIONS,
  FILE_NAME_REGEX
} from '@a0/support-center-common/constants/tickets/attachments';

import { cleanupHar } from "./harCleanup";

/**
 * Validates and cleans files to be attached
 * @param {*} file
 * @returns - undefined if the file is accepted as-is
 * - { error: xxx } if the file can't be added
 * - { cleanedUp: true, file: newVersion } if the file was cleaned up prior to being added
 */
export async function validateAndCleanup(file) {
  let error;
  let cleanedFile;
  error = validateFileName(file);
  if (error) {
    return {
      error
    };
  }
  const hasHarExtension = file.name && file.name.endsWith(".har");

  if (hasHarExtension) {
    try {
      cleanedFile = await cleanupHar(file);
    } catch (error) {
      // parse errors indicate a problem on the user-provided file
      if (!error.jsonParseError) {
        logger.warn("Error sanitizing .HAR file", error);
      }
      return {
        error: "Couldn't sanitize .HAR file. " + error.message
      };
    }
  }

  // validate the file size. If we have a cleaned up version, we'll use it
  error = validateFileSize(cleanedFile || file);
  if (error) {
    return {
      // include the redacted file if available
      // so that the user can download it, even if it's too big to attach
      cleanedUp: !!cleanedFile,
      cleanedFile,
      error
    };
  }

  if (cleanedFile) {
    return { cleanedUp: true, cleanedFile };
  }
  return null;
}

function validateFileName(file) {
  if (!FILE_NAME_REGEX.test(file.name)) {
    const parts = file.name.split('.');

    if (parts.length < 2) {
      return new Error('Missing file extension.');
    }

    const ext = parts.pop();

    if (!FILE_EXTENSIONS.includes(ext.toLowerCase())) {
      const err = new Error(`File extension ${ext} is not supported. Supported extensions: ${FILE_EXTENSIONS.join(', ')}`);

      err.extension = ext;

      logger.warn(err, 'Attempted upload of attachment with unsupported file extension');
      return err;
    }

    return new Error('Invalid filename. Please rename the file using alphanumeric characters and try again.');
  }
}

/**
 * Local validation of file uploads
 * @param {File} file
 * @return {Error|null}
 */
function validateFileSize(file) {
  if (file.size > FILE_MAX_SIZE) {
    return new Error(`The file is too large, the maximum accepted size is 10 MB.`);
  }
}
