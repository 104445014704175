import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "../../../common/components/TextInput";
import { isString, uniq, isEqual, isEmpty } from "lodash";
import { COLLABORATORS } from "../../constants";

/**
 * New Ticket CC field component
 */
class Collaborators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: isString(props.value) ? props.value : props.value.join(", ")
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value, name } = this.props;

    if (isEqual(value, prevProps.value)) {
      return;
    }

    this.updateValue({ name, value });
  }

  getSeparatorAtEnd(value) {
    const matches = value.replace(/\s+/g, " ").match(/\,\s?$/g);
    return matches? matches[0] : '';
  }

  handleChange(event) {
    const {
      currentTarget: { value }
    } = event;
    const { name } = this.props;

    this.updateValue({ name, value });
  }

  /**
   * This method returns the value as a array to the onChange callback but storage the value as
   * a string into the local state to show it in the UI.
   */
  updateValue({ name, value }) {
    if (value && isString(value)) {
      const list = uniq(value.replace(/\s/g, "").split(",")).filter(v => !isEmpty(v));
      const txtValue = `${list.join(", ")}${this.getSeparatorAtEnd(value)}`;
      this.setState({ value: txtValue });
      this.props.onChange({ target: { name, value: list } });
    } else if (value && Array.isArray(value)) {
      this.setState({ value: value.join(", ") });
      this.props.onChange({ target: { name, value } });
    } else if (!isEqual(this.state.value, value) && isEmpty(value)) {
      this.setState({ value: "" });
      this.props.onChange({ target: { name, value: [] } });
    }
  }

  render() {
    const { name, label, error } = this.props;
    return (
      <TextInput
        name={name}
        label={label || COLLABORATORS.LABEL}
        placeholder={COLLABORATORS.PLACEHOLDER}
        onChange={this.handleChange}
        value={this.state.value}
        error={error}
        hotjarMasked
      />
    );
  }
}

Collaborators.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.oneOf([PropTypes.string, PropTypes.array]).isRequired,
  /** The label of the field */
  label: PropTypes.string,
  /** The possible error of the field */
  error: PropTypes.string
};

export default Collaborators;
