import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IndexLink, Link, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AccountPicker from '../../common/components/AccountPicker';
import Loading from '../../common/components/Loading';
import { Error } from '@a0/support-center-components';
import Pagination from '../../common/components/Pagination';
import Notification from '../components/Notification';

import {
  filterTenantsBySubscription,
  getNotificationFilterTenant,
  getNotifications,
  filterRecordsByPage
} from '@a0/support-center-common/selectors/common';

import { getSelectedSubscriptionName } from '@a0/support-center-common/selectors/subscriptions';

import * as notificationsActions from '../actions/notificationActions';
import * as tenantActions from '../../tenants/actions/tenantActions';

export class NotificationsPage extends Component {
  constructor() {
    super();
    this.selectTenant = this.selectTenant.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadNotifications();
  }

  componentWillReceiveProps({ filterOption, selectedSubscriptionName }) {
    if (this.props.filterOption && this.props.filterOption != filterOption) {
      browserHistory.push(location.pathname);
    } else if (
      this.props.selectedSubscriptionName &&
      this.props.selectedSubscriptionName != selectedSubscriptionName
    ) {
      this.props.actions.setNotificationFilterOption(null);
    }
  }

  handlePageSelect(pageNumber) {
    return () => {
      browserHistory.push({ ...location, search: `?notifications=${pageNumber}` });
      document
        .getElementById('notifications')
        .scrollIntoView({ behavior: 'smooth' });
    };
  }

  selectTenant(event) {
    this.props.actions.setNotificationFilterOption(event.target.value);
  }

  render() {
    const {
      loading,
      notifications,
      tenants,
      activePage,
      pageCount,
      selectedSubscriptionName,
      error,
      filterOption,
      filterByTenant
    } = this.props;

    return (
      <div id="notifications" className="notifications">
        <div className="bg-dots page-header-dots">
          <div className="container">
            <ol className="breadcrumb">
              <li>
                <IndexLink to="/">Home</IndexLink>
              </li>
              <li className="active">Notifications</li>
            </ol>
            <div className="notif-banner">
            <h1>Your Notifications</h1>
            <div className="form-inline notif-account-picker pull-right">
              {tenants.length != 1 &&
                filterByTenant && (
                  <AccountPicker
                    tenants={tenants}
                    currentTenant={filterByTenant}
                    selectedSubscriptionName={selectedSubscriptionName}
                    onChange={this.selectTenant}
                    defaultOption="all"
                  />
                )}
            </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Loading show={loading}>
            <Error message={error}>
              <ul className="notifications-general">
                {notifications.length === 0 && (
                  <li className="empty-notifications">
                    <p>You have no notifications.</p>
                  </li>
                )}
                <Pagination
                  activePage={activePage}
                  pageCount={pageCount}
                  pageSelect={this.handlePageSelect}>
                  {notifications.map(notification => (
                    <Notification
                      key={notification._id}
                      notification={notification}
                    />
                  ))}
                </Pagination>
              </ul>
            </Error>
          </Loading>
        </div>
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  activePage: PropTypes.string,
  pageCount: PropTypes.number,
  tenants: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  filterByTenant: PropTypes.object.isRequired,
  filterOption: PropTypes.string,
  selectedSubscriptionName: PropTypes.string
};

function mapStateToProps(state, { location }) {
  const { activePage, recordsByPage, pageCount } = filterRecordsByPage(
    getNotifications(state),
    location.query.notifications || '1'
  );

  return {
    loading: state.notifications.loading || state.tenants.loading,
    error: state.notifications.error || state.tenants.error,
    activePage,
    pageCount,
    notifications: recordsByPage,
    selectedSubscriptionName: getSelectedSubscriptionName(state),
    tenants: filterTenantsBySubscription(state),
    filterByTenant: getNotificationFilterTenant(state),
    filterOption: state.notifications.filterOption
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, notificationsActions, tenantActions),
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
