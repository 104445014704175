import React, { Component } from "react";
import PropTypes from "prop-types";
import { SalesforceAttachment, DownloadFailureAlert } from "./Attachments";
import logger from "../../../utils/logger";
import { SUPPORT_TICKET_ERROR } from '../../../utils/logger/logTypes';

class TicketCommentAttachments extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hasError: false
    };

    this.handleError = this.handleError.bind(this);
    this.resetErrors = this.resetErrors.bind(this);
  }

  /**
   * Clear error state
   */
  resetErrors() {
    this.setState({
      hasError: false
    });
  }

  /**
   * Set and log error state
   * @param {Error} error
   */
  handleError(error) {
    this.setState({
      hasError: true
    });

    logger.error(error, 
      { log_type: SUPPORT_TICKET_ERROR }, 
      'DOWNLOAD_ATTACHMENT_ERROR: Error downloading attachment from SF.');
  }

  render() {
    return (
      <div>
        <hr />
        {this.state.hasError && <DownloadFailureAlert handleDismissal={this.resetErrors} />}
        <ul className="attachments">
          {this.props.attachments.map(attachment => (
            <li key={attachment.id}>
              <SalesforceAttachment {...attachment} handleError={this.handleError} resetErrors={this.resetErrors} />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

TicketCommentAttachments.propTypes = {
  attachments: PropTypes.array.isRequired
};

export default TicketCommentAttachments;
