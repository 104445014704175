import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantM2MDailyReducer(
  state = initialState.reportsTenantM2MDaily,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORTS_TENANT_M2M_DAILY_BEGIN:
      return {
        ...state,
        loading: true,
        maxLimitReached: false,
        error: null
      };

    case types.LOAD_REPORTS_TENANT_M2M_DAILY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        record: action.record,
        maxLimitReached: action.maxLimitReached
      };

    case types.LOAD_REPORTS_TENANT_M2M_DAILY_ERROR:
      return {
        ...state,
        loading: false,
        error: `An error occurred while loading the report: ${
          action.errorMessage
        }`,
        record: [],
        maxLimitReached: false
      };

    case types.PAGINATE_REPORTS_TENANT_M2M_DAILY:
      return {
        ...state,
        page: +action.page
      };

    case types.FILTER_REPORTS_TENANT_M2M_DAILY:
      return {
        ...state,
        filtering: {
          ...state.filtering,
          [action.key]: action.set
        }
      };

    case types.RESET_FILTER_REPORTS_TENANT_M2M_DAILY:
      return {
        ...state,
        filtering: {}
      };

    case types.LOAD_REPORTS_TENANT_M2M_DAILY_RESET:
      return {
        ...initialState.reportsTenantM2MDaily
      };

    default:
      return state;
  }
}
