import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { buildRecommendationEngine } from "../../../utils/coveo/recommendationEngine";
import { CoveoRecommendationEngineContext } from "./CoveoRecommendationEngineContext";
import logger from "../../../utils/logger";

// Ensures the Coveo engine is initialized before showing its children
export const CoveoRecommendationEngineWrapper = ({ children, fallback = null}) => {

  const [state, setState] = useState({});
  useEffect(() => {
    async function load() {
      if (!window.scConfig.ENABLE_RECOMMENDED_ARTICLES) {
        setState({ disabled: true });
        return;
      }
      setState({ loading: true });
      try {
        const headlessEngine = await buildRecommendationEngine();
        setState({ headlessEngine });
      }
      catch (e) {
        // console.error(e);
        logger.error(e, {}, `Error setting up the recommendation engine. Falling back to default experience.`);
        setState({ error: true });
      }
    }
    load();
  }, []);

  if (state.error || state.disabled) {
    return fallback;
  }

  if (!state.headlessEngine) {
    return null;
  }

  return (
    <CoveoRecommendationEngineContext.Provider value={state.headlessEngine}>
      {children}
    </CoveoRecommendationEngineContext.Provider>
  );
};

CoveoRecommendationEngineWrapper.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node
};
