import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import Metrics from '../../../utils/metrics';
import NeutralAlert from './NeutralAlert';
import { safeLocalStorage } from '../../../services/storage';
const manageUrl = window.scConfig.MANAGE_URL;

class TrialGeneralAlert extends Component {
  constructor(props) {
    super(props);

    this.state = { isExpiredAlertHidden: safeLocalStorage.getItem('isExpiredAlertHidden') };

    this.onFreePlan = this.onFreePlan.bind(this);
    this.onBilling = this.onBilling.bind(this);
    this.onServices = this.onServices.bind(this);
    this.hideExpiredAlert = this.hideExpiredAlert.bind(this);
  }

  onFreePlan(e) {
    const { trackData } = this.props;
    Metrics.track(`click:sc:free-plan`, { trackData });
  }

  onBilling(e) {
    const { trackData } = this.props;
    Metrics.track(`click:sc:billing`, { trackData });
  }

  onServices(e) {
    const { trackData } = this.props;
    Metrics.track(`click:sc:tenants`, { trackData });
    this.props.setAccountContext('Trial');
  }

  hideExpiredAlert() {
    this.setState({ isExpiredAlertHidden: true });
    localStorage.setItem('isExpiredAlertHidden', true);
  }

  checkIfTenantIsAboutToExpire(trialTenant) {
    const { subscription: { trialDates: { trialEnds } } } = trialTenant;
    const today = moment().utc();
    const trialExpiration = moment(trialEnds).utc();
    const before7DaysTrialEnds = moment(trialEnds).utc().add(-7, 'day');
    const after7DaysTrialEnds  = moment(trialEnds).utc().add(14, 'day');

    return { today, trialExpiration, before7DaysTrialEnds, after7DaysTrialEnds};
  }

  renderExpiredAlert(name, subscription) {
    return (
      <NeutralAlert outside dismiss handleDismiss={this.hideExpiredAlert}>
          <i className="icon-neutral icon-neutral--outside icon-budicon-115" />
          <span className="alert-neutral-text-body">
            Your tenant <strong>{name}</strong> recently concluded its trial period. You can now find this tenant under your {subscription.name} Subscription.
          </span>
      </NeutralAlert>
    );
  }

  renderTrialTenant() {
    const { trialTenants } = this.props;
    const trialTenant = trialTenants[0];
    const { subscription: { trialDates: { trialEnds } } } = trialTenant;

    const {
      today,
      trialExpiration,
      before7DaysTrialEnds,
      after7DaysTrialEnds
    } = this.checkIfTenantIsAboutToExpire(trialTenant);

    if (today >= before7DaysTrialEnds && today < trialExpiration) {
      return (
        <NeutralAlert outside>
            <i className="icon-neutral icon-neutral--outside icon-budicon-115" />
            <span className="alert-neutral-text-body">
              Thank you for choosing to trial Auth0.&nbsp;
              You have <strong>{moment(trialEnds).fromNow(true)}</strong> period remaining on trial tenant <strong>{trialTenant.name}</strong> to experiment with features that are not available on the <a onClick={this.onFreePlan} href="https://auth0.com/pricing#full-features" target="_blank">Free Plan</a>.&nbsp;
              If you wish to continue to use these features then please provide your <a onClick={this.onBilling} href={`${manageUrl}/#/account/billing/subscription`} target="_blank">billing information</a>.
            </span>
        </NeutralAlert>
      );
    } else if (today >= before7DaysTrialEnds && today < after7DaysTrialEnds && !this.state.isExpiredAlertHidden) {
      return this.renderExpiredAlert(trialTenant.name, trialTenant.subscription);
    } else {
      return null;
    }
  }

  renderTrialTenants() {
    const { trialTenants } = this.props;
    
    const isAboutToExpire = trialTenants.reduce((acc, cur) => {
      const { today, trialExpiration, before7DaysTrialEnds } = this.checkIfTenantIsAboutToExpire(cur);
      return (today >= before7DaysTrialEnds && today < trialExpiration) || acc;
    }, false);

    const expiredTenant = trialTenants.reduce((acc, cur) => {
      const { today, before7DaysTrialEnds, after7DaysTrialEnds } = this.checkIfTenantIsAboutToExpire(cur);
      if (today >= before7DaysTrialEnds && today < after7DaysTrialEnds && !this.state.isExpiredAlertHidden) {
        return { name: cur.name, subscription: cur.subscription };
      } else {
        return acc;
      }
    }, false);

    if (isAboutToExpire) {
      return (
        <NeutralAlert outside>
            <div className="col-md-10">
              <i className="icon-neutral icon-neutral--outside icon-budicon-115" />
              <span className="alert-neutral-text-body">
                Thank you for choosing to trial Auth0 and experiment with the full range of Auth0 features. One of your trial tenants is about to expire.&nbsp;
                If you wish to continue to use features not available in the <a onClick={this.onFreePlan} href="https://auth0.com/pricing#full-features" target="_blank">Free Plan</a> please provide your <a onClick={this.onBilling} href={`${manageUrl}/#/account/billing/subscription`} target="_blank">billing information</a>.
              </span>
            </div>
            <div className="col-md-2">
              <Link to="/tenants" onClick={this.onServices} className="btn btn-md btn-success">Tenants</Link>
            </div>
        </NeutralAlert>
      );
    } else if (expiredTenant) {
      return this.renderExpiredAlert(expiredTenant.name, expiredTenant.subscription);
    } else {
      return null;
    }
  }

  render() {
    const { trialTenants, emailVerified } = this.props;

    if (!emailVerified || (trialTenants && trialTenants.length <= 0)) {
      return null;
    } else if (trialTenants && trialTenants.length > 1) {
      return this.renderTrialTenants();
    } else {
      return this.renderTrialTenant();
    }
  }
}

TrialGeneralAlert.propTypes = {
  trialTenants: PropTypes.array.isRequired,
  trackData: PropTypes.string.isRequired,
  setAccountContext: PropTypes.func,
  emailVerified: PropTypes.bool
};

TrialGeneralAlert.defaultProps = {
  trialTenants: [],
  trackData: 'homepage'
};

export default TrialGeneralAlert;
