import { debounce } from "lodash";
import { loadConfigurationActions } from "@coveo/headless";
import logger from "../logger";

/**
 * Creates a (redux) middleware that detects an invalid token reported by Coveo,
 * retrieves a new token from SC-API and dispatches the failed action again.
 *
 * @param {*} getEngine - A function to get a reference to the headless engine instance
 * @param {*} renewToken - A function that returns a promise to obtain a new search token.
 * @returns - An instance of the middleware.
 */
export function createRenewInvalidAccessTokenMiddleware(getEngine, renewToken) {
  let accessTokenRenewalsAttempts = 0;
  let configurationActions;
  const resetRenewalTriesAfterDelay = debounce(() => (accessTokenRenewalsAttempts = 0), 500);

  return (store) => (next) => async (action) => {
    const isThunk = typeof action === "function";

    if (!isThunk) {
      return next(action);
    }

    const payload = await next(action);

    if (!isInvalidTokenError(payload)) {
      return payload;
    }

    if (!configurationActions) {
      configurationActions = loadConfigurationActions(getEngine());
    }

    if (accessTokenRenewalsAttempts >= 5) {
      logger.warn({},
        "Attempted to renew the coveo token but was not successful. Please check the #renewAccessToken function."
      );
      return payload;
    }

    accessTokenRenewalsAttempts++;
    resetRenewalTriesAfterDelay();

    logger.warn({},
      "An invalid coveo token was detected. Retrieving a new token."
    );

    const accessToken = await attempt(renewToken);
    store.dispatch(configurationActions.updateBasicConfiguration({ accessToken }));
    store.dispatch(action);
    return {};
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isInvalidTokenError(action) {
  return action.error && action.payload && action.payload.type === "InvalidTokenException";
}

async function attempt(fn) {
  try {
    return await fn();
  } catch (e) {
    logger.warn(e,
      `Error retrieving a new coveo token`
    );
    return "";
  }
}
