import React, { Component } from 'react';
import PropTypes from 'prop-types';
const TEN_SECONDS_IN_MILLISECONDS = 10000 * 1;

class NeutralAlert extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
    this.onDismiss = this.onDismiss.bind(this);
    this.executeAutoClose = this.executeAutoClose.bind(this);
    this.hideComponent = this.hideComponent.bind(this);
  }

  onDismiss(e) {
    const { handleDismiss } = this.props;
    this.hideComponent();
    handleDismiss();
  }

  executeAutoClose() {
    setTimeout(this.hideComponent, TEN_SECONDS_IN_MILLISECONDS);
  }

  hideComponent() {
    this.setState({isVisible: false});
  }

  render() {
    const { children, outside, dismiss, autoClose = false } = this.props;
    if (!this.state.isVisible) {
      return null;
    }
    return (
      <div className={`alert alert-neutral alert-neutral--${outside ? 'outside': 'inside'}`}>
        <div className={outside ? 'container' : 'container-fluid'}>
          <div className={outside ? 'row' : ''}>
            {children}
            {dismiss && <button type="button" data-dismiss="alert" aria-label="Close" className="close" onClick={this.onDismiss}><span aria-hidden="true">×</span></button>}
            {dismiss && autoClose && this.executeAutoClose()}
          </div>
        </div>
      </div>
    );
  }
}

NeutralAlert.propTypes = {
  children: PropTypes.node.isRequired,
  outside: PropTypes.bool,
  handleDismiss: PropTypes.func,
  dismiss: PropTypes.bool,
  autoClose: PropTypes.bool
};

NeutralAlert.defaultProps = {
  dismiss: false,
  handleDismiss: () => {}
};

export default NeutralAlert;
