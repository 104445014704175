import itIsFreeOrTrialOrDeveloper from './itIsFreeOrTrialOrDeveloper';
const manageUrl = window.scConfig.MANAGE_URL;

const onContactSales = (contactSales, selectedSubscription, metadata = {} ) => event => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (itIsFreeOrTrialOrDeveloper(selectedSubscription)) {
    const manageUrlBase = `${manageUrl}/#/tenant/billing/subscription`;
    const pricingTab = window.open(
      manageUrlBase,
      '_blank'
    );
    pricingTab.focus();
  } else {
    contactSales('over-quota-contact', metadata);
  }
};

export default onContactSales;
