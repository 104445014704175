import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantQuotaReducer(state = initialState.reportsTenantQuota, action) {
  switch (action.type) {
    case types.LOAD_REPORTS_TENANT_QUOTA_BEGIN:
      return Object.assign({}, initialState.reportsTenantQuota, {loading: true});

    case types.LOAD_REPORTS_TENANT_QUOTA_SUCCESS:
      return Object.assign({}, state, {loading: false, error: null, record: action.record});

    case types.LOAD_REPORTS_TENANT_QUOTA_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading usage statistics: ${action.errorMessage}`,
        record: {}
      });

    case types.PAGINATE_REPORTS_TENANT_QUOTA:
      return Object.assign({}, state, {
        page: +action.page
      });

    case types.FILTER_REPORTS_TENANT_QUOTA:
      return Object.assign({}, state, {
        filtering: {
          ...state.filtering,
          [action.key]: action.set
        }
      });

    case types.RESET_FILTER_REPORTS_TENANT_QUOTA:
      return Object.assign({}, state, {
        filtering: {}
      });

    default:
      return state;
  }
}
