import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import { Link } from 'react-router';
import { HotjarMasked } from '@a0/support-center-components';
import { tickets as constants } from '../../constants';
import { getTicketStatusClasses } from "../../../tickets/utils/ticketStatus";
import './Dropdown.module.css';
import { ArrowRight } from 'react-feather';

/**
 * Dropdown for 'Tickets' object in the Navbar
 * @param {object} params
 */
const DropdownTickets = ({ tickets, isAuthenticated, show, active, emailVerified }) => {
  if (!isAuthenticated) {
    return (
      <Dropdown title={constants.title} show={show}>
        <div styleName="dropdown-fixed">
          <Link to="/tickets">Log in</Link>
          {' '}
          {constants.loggedOutAction}
        </div>
      </Dropdown>
    );
  }


  return (
    <Dropdown title={constants.title} show={show} active={active}>
      <div styleName="tickets">
        <div styleName="dropdown-header">
          <strong>{constants.title}</strong>
          <Link to="/tickets">
            {constants.headerAction}
            <ArrowRight />
          </Link>
        </div>
        <ul>
          {tickets.length < 1 && <li styleName="dropdown-empty">{emailVerified ? constants.empty : constants.verifyEmail}</li>}
          {tickets.map(ticket => {
            return (
              <Link key={ticket.id} to={`/tickets/${ticket.id}`}>
                <li styleName="dropdown-item">
                  <div styleName="ticket-status" className={getTicketStatusClasses(ticket.status)}>{ticket.status}</div>
                  <div styleName="ticket-title">#{ticket.previousId ? ticket.previousId : ticket.id}</div>
                  <div styleName="ticket-text"><HotjarMasked>{ticket.subject}</HotjarMasked></div>
                </li>
              </Link>
            );
          }
          )}
        </ul>
      </div>
    </Dropdown>
  );
};


DropdownTickets.propTypes = {
  tickets: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  show: PropTypes.bool,
  active: PropTypes.bool,
  emailVerified: PropTypes.bool
};

export default DropdownTickets;
