import isDBConnection from '../../../utils/isDBConnection';

/**
 * Gets the currently logged in user
 * @param {object} state - full redux state object
 * @returns {object} user
 */
export const getUser = state => state.auth && state.auth.user;

/**
 * Returns true if user belongs to a db connection (auth0 provided) and has a verified email
 * Non-db users are always assumed to have validated emails
 *
 * @param {object} state - full redux state object 
 * @returns {boolean}
 */
export const isUserEmailVerified = state => {
  const user = getUser(state);
  if(user && user.hasOwnProperty('email_verified') && isDBConnection(user.user_id)){
    return user.email_verified;
  }
  return true;
};
