import api from "api";

export const TokenType = {
  SEARCH: "search",
  RECOMMENDATIONS: "recommendations"
};

/**
 * Gets an access token response from the cache, or from an SC-API if a
 * cached token is not available.
 * @param {string} tokenType The type of token to obtain. Valid options are TokenType.SEARCH and TokenType.RECOMMENTATIONS
 * @param {boolean} skipCache if the cache should be skipped and force an API request
 * @returns {Object} A token response object, with a `token` and `orgId` properties.
 */
export async function getSearchTokenResponse(tokenType, skipCache = false) {
  // no need to worry about token expiration, Coveo will
  // get a renewed token if necessary
  if (!skipCache) {
    const storedTokenResponse = getStoredTokenResponse(tokenType);
    if (storedTokenResponse) {
      return storedTokenResponse;
    }
  }

  removeStoredTokenResponse(tokenType);
  let tokenResult = await requestTokenFromApi(tokenType);

  // cache result
  storeTokenResponse(tokenType, tokenResult);
  return tokenResult;
}

async function requestTokenFromApi(tokenType) {
  switch (tokenType) {
    case TokenType.SEARCH:
      return await api.search.getSearchToken();
    case TokenType.RECOMMENDATIONS:
      return await api.tickets.getSuggestionsToken();
    default:
      throw new Error(`Don't know how to obtain a token of type ${tokenType}`);
  }
}

export async function renewAccessToken(tokenType) {
  return (await getSearchTokenResponse(tokenType, true)).token;
}

import { safeSessionStorage as storage } from "../../services/storage";

const getStorageKey = (tokenType) => `${tokenType}Token`;

function storeTokenResponse(tokenType, tokenResponse) {
  storage.setItem(getStorageKey(tokenType), JSON.stringify(tokenResponse));
}

function getStoredTokenResponse(tokenType) {
  return JSON.parse(storage.getItem(getStorageKey(tokenType)));
}

function removeStoredTokenResponse(tokenType) {
  storage.removeItem(getStorageKey(tokenType));
}
