import Joi from "joi";
import schemas from '@a0/support-center-common/schemas/tickets';
import { isEmpty } from "lodash";

const tenantIdRegex = new RegExp(`^[a-zA-Z0-9][-a-zA-Z0-9]{1,62}[a-zA-Z0-9](@us|@ca|@eu|@au|@pus2|@jp|@uk|@sus|@tus\d?-?\d?)$`);
const tenantIdRule = Joi.string().regex(tenantIdRegex,' tenantId')
  .messages({
    string: {
      regex: 'TenantId is invalid'
    }
  });

const modifiedSchema = schemas.modelSchema.keys({
  tenant: tenantIdRule
});

export function ticketCommentValidation(values) {
  const errors = validate(values, schemas.commentSchema);
  const isValid = isEmpty(errors) ? true : false;

  return { isValid, errors };
}

const validate = (values, schema) => {
  const errors = {};
  const formErrors = schema.validate(values, { abortEarly: false }).error;
  if (formErrors && formErrors.details) {
    formErrors.details.forEach(error => {
      const errorPath = error.path && error.path.length ? error.path.join("."):"";
      // Arrays have invalid error paths
      if (errorPath.match("^collaborators")) {
        errors["collaborators"] = error.message;
      } else if (errorPath.match("^additional_collaborators")) {
        errors["additional_collaborators"] = error.message;
      } else {
        errors[errorPath] = error.message;
      }
    });
  }

  return errors;
};

export default values => validate(values, modifiedSchema);
