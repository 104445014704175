/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { pick, get } from 'lodash';
import NavbarItem from './NavbarItem';
import BSNavbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import NavbarItemGroup from './NavbarItemGroup';
import { DropdownSubscriptionsErrorBoundary } from './DropdownSubscriptions';
import DropdownNotifications from './DropdownNotifications';
import DropdownTickets from './DropdownTickets';
import DropdownReports from './DropdownReports';

import './Navbar.module.css';

/**
 * Gets current active state for a NavbarItem
 * @param {string|array} path
 * @param {string} itemKey
 * @returns {boolean}
 */
export function getActiveState(path, itemKey) {
  if (Array.isArray(path)) {
    path = path.join('/');
  }

  const map = {
    notifications: 'notifications',
    tickets: 'tickets',
    tenants: 'tenants/public',
    privateInstances: 'tenants/private',
    reports: 'reports',
    compliance: 'compliance'
  };

  return !!map[itemKey] && map[itemKey] === path;
}

/**
 * Extract all props for the DropdownSubscriptions component
 * @param {array} allProps
 * @returns {array} props
 */
export function subscriptionProps(allProps) {
  const props = pick(allProps, [
    'actions',
    'isAuthenticated',
    'customSubscriptions',
    'selfServiceSubscriptions',
    'freeSubscriptions',
    'trialSubscriptions',
    'selectedSubscription',
    'selectedTenant',
    'v8Subscriptions'
  ]);
  props.key     = 'navbar-subscription';
  props.loading = allProps.tenantsDropdownLoading;
  return props;
}

/**
 * Extract all props for the DropdownNotifications component
 * @param   {object} allProps
 * @returns {object} props
 */
export function notificationProps(allProps) {
  const props = pick(allProps, [
    'notifications',
    'isAuthenticated',
    'createReadNotificationHandler',
    'createDismissNotificationHandler',
    'createClickNotificationHandler'
  ]);
  props.key     = 'navbar-notifications';
  props.login   = allProps.actions.login;
  props.loading = allProps.notificationsLoading;
  props.active  = getActiveState(allProps.activePath[0], 'notifications');
  return props;
}

/**
 * Extract all props for the DropdownTickets component
 * @param   {object} allProps
 * @returns {object} props
 */
export function ticketProps(allProps) {
  const props = pick(allProps, [
    'tickets',
    'isAuthenticated',
    'emailVerified'
  ]);
  props.key    = 'navbar-tickets';
  props.login  = allProps.actions.login;
  props.active = getActiveState(allProps.activePath[0], 'tickets');
  return props;
}

/**
 * Extract all props for the DropdownReports component
 * @param   {object} allProps
 * @returns {object} props
 */
export function reportProps(allProps) {
  return {
    key:    'navbar-reports',
    login:  allProps.actions.login,
    active: getActiveState(allProps.activePath[0], 'reports')
  };
}

/**
 * 'Tenants' menu item
 * @returns {object} NavbarItem
 */
export const TenantsLink = allProps => {
  return (
    <NavbarItem active={getActiveState(allProps.activePath, 'tenants')}>
      <Link to={'/tenants'}>Tenants</Link>
    </NavbarItem>
  );
};

/**
 * 'Private Instances' menu item
 * @param   {object} props
 * @returns {object} NavbarItem
 */
export const PrivateInstancesLink = allProps => {
  if (!get(allProps, 'selectedSubscription.is_appliance')) {
    return null;
  }
  return (
    <NavbarItem active={getActiveState(allProps.activePath, 'privateInstances')}>
      <Link to="/tenants/private">Private Instances</Link>
    </NavbarItem>
  );
};

/**
 * Whether to compensate for 'Private Instances' in the menu
 * @param   {object} props
 * @returns {object} NavbarItem
 */
export function getOffset(props) {
  return get(props, 'selectedSubscription.is_appliance') ? 1 : 0;
}

/**
 * The main site navigation component
 * @param {object} props
 * @returns {object} NavbarItem
 */
const Navbar = props => (
  <BSNavbar styleName="navbar">
    <Container>
      <div>
        <nav styleName="navbar-inner">
          <DropdownSubscriptionsErrorBoundary {...subscriptionProps(props)}/>
          <div styleName="navbar-separator" key="navbar-separator">
            <DropdownNotifications {...notificationProps(props)} />
            <DropdownTickets {...ticketProps(props)} />
            <TenantsLink {...props} key="navbar-tenants" />
            <PrivateInstancesLink {...props} key="navbar-private-instances" />
            <DropdownReports {...reportProps(props)} />
            <NavbarItemGroup offset={getOffset(props)} firstActive={getActiveState(props.activePath[0], 'compliance')} key="navbar-more">
              <Link to="/compliance" key="navbar-compliance">Compliance</Link>
            </NavbarItemGroup>
          </div>
        </nav>
      </div>
    </Container>
  </BSNavbar>
);

Navbar.propTypes = {
  activePath:   PropTypes.array.isRequired
};

export default Navbar;
