import { filter, find }  from 'lodash';

export const getSelectedTenant = ({tenants}) => tenants.selected;

export const getAllAssociatedTenantsForSelectedTenant = ({tenants}) => {
  const selectedTenant = find(tenants.records, tenant => tenant.id === tenants.selected);

  if (!selectedTenant) {
    return [];
  } else if (selectedTenant.master_tenant) {
    return filter(tenants.records, tenant => tenant.master_tenant === selectedTenant.master_tenant);
  } else {
    return filter(tenants.records, tenant => tenant.id === selectedTenant.id);
  }
};
