import * as types from '../../redux/actionTypes';
import { filterUnpublishedVersions } from '@a0/support-center-common/lib/appliances';
import api from 'api';

export function loadAppliancesBegin() {
  return { type: types.LOAD_APPLIANCES_BEGIN };
}

export function loadAppliancesSuccess(records) {
  return { type: types.LOAD_APPLIANCES_SUCCESS, records };
}

export function loadAppliancesError(error) {
  return { type: types.LOAD_APPLIANCES_ERROR, error };
}

export function loadAppliances(masterTenantId) {
  return function(dispatch) {
    dispatch(loadAppliancesBegin());
    return api.appliances.getAll(masterTenantId)
    .then(appliances => {
      dispatch(loadAppliancesSuccess(appliances));
    })
    .catch(error => {
      dispatch(loadAppliancesError(error));
    });
  };
}

export function loadApplianceVersionsBegin() {
  return { type: types.LOAD_APPLIANCE_VERSIONS_BEGIN };
}

export function loadApplianceVersionsSuccess(records) {
  return { type: types.LOAD_APPLIANCE_VERSIONS_SUCCESS, records };
}

export function loadApplianceVersionsError(error) {
  return { type: types.LOAD_APPLIANCE_VERSIONS_ERROR, error };
}

export function loadApplianceVersions() {
  return function(dispatch) {
    dispatch(loadApplianceVersionsBegin());
    return api.appliances.getVersions()
    .then(versions => {
      const validVersions = filterUnpublishedVersions(versions);
      dispatch(loadApplianceVersionsSuccess(validVersions));
    })
    .catch(error => {
      dispatch(loadApplianceVersionsError(error));
    });
  };
}
