import { useState, useEffect, useContext } from "react";
import { CoveoEngineContext } from "./CoveoEngineContext";

/**
 * @typedef useCoveoControllerReturnType
 * @type {object}
 * @property {object} headlessEngine - a reference to the Coveo Headless Engine instance
 * @property {object} controller - a reference to the controller.
 * @property {number} state - a reference to the controller's state.
 */

// this helper function is to be used within a component that requires
// a reference to a Coveo controller.
//
// it takes care of the repetitive task of:
// - Getting the current Coveo headless engine reference
// - using useState() with a builder function to initialize the controller once for the life of the component
// - returning the state of the controller
// - subscribing to changes in the state of the controller
// - unsubscribing when the component ends its cycle
//

/**
 *
 * @param {function(headlessEngine)} buildController - A function that takes an engine reference as a parameter and builds the controller
 * @param {string} name - An [optional] name for the controller, used only for debugging purposes.
 * @returns {useCoveoControllerReturnType}
 */
export const useCoveoController = (buildController, name) => {
  const buildTheController = () => {
    return buildController(headlessEngine);
  };

  const headlessEngine = useContext(CoveoEngineContext);
  const [controller] = useState(buildTheController);
  const [state, setState] = useState(controller.state);
  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);
  return {
    headlessEngine,
    controller,
    state
  };
};
