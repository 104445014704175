import React from "react";
import severityConstants from '@a0/support-center-common/constants/tickets/customerSelectedSeverities';

// Options for ticket kind
const issues = [
  {
    value: 'auth0_issue',
    text: 'Auth0 Service Issue'
  },
  {
    value: 'extend_trial',
    text: 'Trial Extension'
  },
  {
    value: 'account_issues',
    text: 'Billing, Payments, or Account Issues'
  },
  {
    value: 'product_feedback',
    text: 'Auth0 Service Feedback'
  },
  {
    value: 'vulnerability_or_legal',
    text: 'Security, Privacy, or Legal Issues'
  },
  {
    value: 'migrations_deprecations',
    text: 'Deprecations'
  }
];

// Suboptions for ticket kind
const severities = [
  {
    value: severityConstants.CUSTOMER_SELECTED_SEVERITIES[severityConstants.LOW_SEVERITY],
    text: "Low: Product question",
    subtext: "Minor Problem",
    description:
      "Information requested on Auth0 Platform capabilities, navigation, installation, or configuration."
  },
  {
    value: severityConstants.CUSTOMER_SELECTED_SEVERITIES[severityConstants.NORMAL_SEVERITY],
    text: "Normal: General support issues",
    subtext: "Minor Feature / Function Issues / General Question",
    description:
      "Defect resulting in a component of the Auth0 Platform not performing as expected or documented or an inquiry by your representatives regarding general technical issues/questions."
  },
  {
    value: severityConstants.CUSTOMER_SELECTED_SEVERITIES[severityConstants.HIGH_SEVERITY],
    text: "High: Production application issue",
    subtext: "Significant Business Impact",
    description: (
      <React.Fragment>
        Defect resulting in a condition where major functionality is impacted or significant
        performance degradation is experienced;{" "}
        <mark>issue is persistent and affects many users and/or major functionality</mark>.
      </React.Fragment>
    )
  },
  {
    value: severityConstants.CUSTOMER_SELECTED_SEVERITIES[severityConstants.URGENT_SEVERITY],
    text: "Urgent: Production application offline",
    subtext: "Emergency Issue",
    description: (
      <React.Fragment>
        Defect resulting in full or partial system outage or a condition that makes the Auth0
        Platform unusable or unavailable in <mark>production for all of your users</mark>.
      </React.Fragment>
    )
  }
];

const enterpriseConnectionsFields = [
  {
    label:
      'Is this connection being set up for first time or did it work before and then stopped working?',
    name: 'first_time_connection_set_up'
  },
  {
    label: 'Does the problem affect all users or just some?',
    name: 'users_affected'
  },
  {
    label:
      'Does the problem always occur for impacted users or just sometimes?',
    name: 'issue_frequency'
  }
];

const sdkFields = [
  {
    label: 'What is the application name?',
    name: 'application_name'
  },
  {
    label: 'What version number of the SDK are you using?',
    name: 'sdk_version'
  },
  {
    label: 'Do you get an error message and if so, what is it?',
    name: 'sdk_error'
  }
];

// Additional fields by ticket category
const additionalFieldsPerCategory = {
  enterprise_connections_ad_connector_ldap: enterpriseConnectionsFields,
  enterprise_connections_saml: enterpriseConnectionsFields.concat([
    {
      label:
        'Is Auth0 serving as a SAML Service Provider, Identity Provider or both?',
      name: 'auth0_provider_type'
    },
    {
      label: 'Is this SP-initiated, IDP-initiated flow or both?',
      name: 'initiation_flow_type'
    },
    {
      label:
        'Please run the AD/LDAP connector troubleshooter and attach the exported file to the ticket. Instructions for running the troubleshooter <a target="_blank" href="https://auth0.com/docs/connector/troubleshooting#troubleshooting-in-the-admin-console">here</a>.'
    }
  ]),
  enterprise_connections_adfs: enterpriseConnectionsFields,
  enterprise_connections_azure_ad: enterpriseConnectionsFields,
  sdk_dotnet: sdkFields,
  sdk_angularjs: sdkFields,
  sdk_java: sdkFields,
  sdk_javascript: sdkFields,
  sdk_nodejs: sdkFields,
  sdk_php: sdkFields,
  sdk_react: sdkFields,
  sdk_swift: sdkFields,
  sdk_other: [
    {
      label: 'Which SDK are you using?',
      name: 'sdk'
    }
  ].concat(sdkFields),
  management_api: [
    {
      label:
        'What is the call you are making? (send screen snap or the resulting curl command)',
      name: 'management_api_endpoint'
    },
    {
      label: 'What are you expecting to receive back?',
      name: 'management_api_expected_result'
    },
    {
      label: 'What are you actually getting back?',
      name: 'management_api_result'
    }
  ],
  custom_db_connection: [
    {
      label: 'What type of database are you connecting to?',
      name: 'database_type'
    },
    {
      label: 'What type of encryption are your passwords using?',
      name: 'encryption_type'
    }
  ],
  sso_integrations: [
    {
      label:
        'What username are you logging in with at the authentication service?',
      name: 'auth_service_username'
    },
    {
      label:
        'What is the username that identifies the user in the 3rd party app?',
      name: 'thrid_party_app_username'
    }
  ]
};

const hintsPerCategory = {
  anomaly_detection: [],
  enterprise_connections_ad_connector_ldap: [
    {
      title: 'Troubleshooting Connector Issues',
      href: 'https://auth0.com/docs/connector/troubleshooting',
      description: 'Steps and Tools for troubleshooting AD/LDAP connector'
    },
    {
      title: 'Connector General Documentation',
      href: 'https://auth0.com/docs/connector',
      description:
        'Everything about installing, configuring, updating, monitoring the Connetor and more...'
    }
  ],
  enterprise_connections_saml: [],
  enterprise_connections_adfs: [],
  enterprise_connections_azure_ad: [],
  sdk_dotnet: [],
  sdk_angularjs: [],
  sdk_java: [],
  sdk_javascript: [],
  sdk_nodejs: [],
  sdk_php: [],
  sdk_react: [],
  sdk_swift: [],
  sdk_other: [],
  management_api: [],
  custom_db_connection: [],
  sso_integrations: []
};

export default {
  issues,
  severities,
  additionalFieldsPerCategory,
  hintsPerCategory
};
