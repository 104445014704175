/* eslint-disable import/no-named-as-default */
import { isEmpty, flow } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { IndexRoute, Redirect, Route } from 'react-router';

import {
  catchErrors,
  requireAuthenticationAndCatchErrors
} from './routeHelpers';

import { SUPPORT_TICKET_ERROR } from './utils/logger/logTypes';

import App from './App';

import UnavailableLoginPage from './features/login/containers/UnavailableLoginPage';

import HomePage from './features/home/containers/HomePage';

import TicketsPage from './features/tickets/containers/TicketsPage';
import NewTicketForm from './features/tickets/containers/NewTicketForm';
import OfflineTicketForm from './features/tickets/containers/OfflineTicketForm';
import TicketPage from './features/tickets/containers/TicketPage';

import UsagePage from './features/reports/containers/UsagePage';
import QuotaPage from './features/reports/containers/QuotaPage';
import DetailsReportPage from './features/reports/containers/DetailsPage';

import NotificationsPage from './features/notifications/containers/NotificationsPage';
import NotificationPage from './features/notifications/containers/NotificationPage';

import PageNotFound from './features/common/components/PageNotFound';
import NavbarWrap from './features/navigation/containers/NavbarWrap';

import ServicesPage from './features/services/containers/ServicesPage';

import { SearchPage } from './features/search/containers/SearchPage';

import { routes as compliance } from './features/compliance';

import Callback from './features/login/containers/Callback';

const Routes = () => {

  /**
   * onEnter hook for login route
   *
   * There is a link to SC login/ on auth0 dashboard - used to automatically trigger login with SSO.
   * It used to work with the old server-side auth flow where login/ was server-side route
   * responsible for triggering login flow. With new client side auth there is no such route.
   * We had to add it to make the feature backwards compatible with the dashboard.
   * This hook will either redirect user to index.
   *
   * @param {Object} nextState
   * @param {Function} replace
   */
  function handleVisitFromAuth0Dashboard(nextState, replace) {
    replace({ pathname: '/' });
  }

  return (
    <Route path="/" component={App}>
      <Route path="callback" component={Callback}/>
      <Redirect path="invitations" to="/" />
      <Route path="tickets/offline/new" component={catchErrors(OfflineTicketForm)} />
      <Route component={NavbarWrap}>
        <IndexRoute component={catchErrors(HomePage)} />
        <Route
          path="tickets"
          component={requireAuthenticationAndCatchErrors(TicketsPage, { log_type: SUPPORT_TICKET_ERROR })} />
        <Route
          path="tickets/new"
          component={requireAuthenticationAndCatchErrors(NewTicketForm, { log_type: SUPPORT_TICKET_ERROR })}
        />
        <Route
          path="reports/usage"
          component={requireAuthenticationAndCatchErrors(UsagePage)}
        />
        <Route
          path="reports/quota"
          component={requireAuthenticationAndCatchErrors(QuotaPage)}
        />
        <Route
          path="search"
          component={SearchPage}
        />
        {/* TODO: remove the redirects when legacy routes are not needed anymore */}
        <Redirect
          from="reports/quota/auth0-guardian"
          to="reports/quota/enterprise-mfa"
        />
        <Redirect
          from="reports/quota/third-party-multifactor"
          to="reports/quota/pro-mfa"
        />
        <Route
          path="reports/quota/:reportType"
          component={requireAuthenticationAndCatchErrors(DetailsReportPage)}
        />
        <Route
          path="tickets/:id"
          component={requireAuthenticationAndCatchErrors(TicketPage, { log_type: SUPPORT_TICKET_ERROR })}
        />
        <Route
          path="notifications"
          component={requireAuthenticationAndCatchErrors(NotificationsPage)}
        />
        <Route
          path="notifications/:id"
          component={requireAuthenticationAndCatchErrors(NotificationPage)}
        />
        <Route path="tenants" component={requireAuthenticationAndCatchErrors(ServicesPage)} />
        <Route
          path="tenants/:category"
          component={requireAuthenticationAndCatchErrors(ServicesPage)}
        />
        {compliance}
        <Redirect from="compliance/*" to="compliance" />
        <Route path="unavailable-login" component={UnavailableLoginPage} />
        {/*
          There is a link to SC login/ on auth0 dashboard - used to automatically trigger login with SSO.
          It used to work with the old server-side auth flow where login/ was server-side route
          responsible for triggering login flow. With new client side auth there is no such route on the server.
          We had to add it to make the feature backwards compatible with the dashboard.
        */}
        <Route path="login" onEnter={handleVisitFromAuth0Dashboard} component={HomePage} />
        <Route path="*" component={PageNotFound} />
      </Route>
    </Route>
  );
};

Routes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired
};

export default Routes;
