import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import moment from "moment";
import { isTrialTenant } from '@a0/support-center-common/lib/tenants';
import ExpirationDate from '../ExpirationDate';

import "./styles.css";

class SubscriptionTag extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { subscription } = this.props;
    const { isFree, name } = subscription;

    const isTrialSubscription = isTrialTenant({ subscription });
    const isFreeSubscription = isFree && !isTrialSubscription;
    const trialExpirationDate = get(subscription, "trialDates.trialEnds");

    if (isFreeSubscription) {
      return <div className="subscription-tag subscription-tag-free">Free</div>;
    } else if (isTrialSubscription) {
      return (
        <Fragment>
          <div className="subscription-tag subscription-tag-trial">Trial</div>
          <ExpirationDate date={trialExpirationDate} />
        </Fragment>
      );
    } else {
      return <div>{name}</div>;
    }
  }
}

SubscriptionTag.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default SubscriptionTag;
