import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { find } from 'lodash';

import PageNotFound from '../../common/components/PageNotFound';
import PublicSaas from '../components/PublicSaas';
import PrivateSaas from '../containers/PrivateSaas';
import PageHeader from '../../common/components/PageHeader';
import PageWrap from '../../common/components/PageWrap';
import Breadcrumb from '../../common/components/Breadcrumb';

import * as tenantActions from '../../tenants/actions/tenantActions';
import * as authActions from '../../login/actions/authActions';
import * as tenantAgreementsActions from '../../tenants/actions/tenantAgreementsActions';
import { actions as applianceActions } from '../../appliances';

import { getTenantSummaries } from '@a0/support-center-common/selectors/common';
import { getSelectedSubscription } from '@a0/support-center-common/selectors/subscriptions';
import { Loading, Error } from '@a0/support-center-components';

import Metrics from '../../../utils/metrics';

class ServicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleTenantEnvironmentUpdate = this.handleTenantEnvironmentUpdate.bind(
      this
    );
  }

  componentDidMount() {
    this.props.actions.loadTenantsSummaryForUser();
    this.props.actions.loadApplianceVersions();
  }

  componentWillUpdate(nextProps) {
    const subscription = nextProps.selectedSubscription;
    const category = nextProps.params.category;

    // redirect to either public or private if no category set
    if (!category) {
      browserHistory.push('/tenants/public');
    }

    // redirect to public if attempting to access private tenants of a public subscription
    if (subscription && !subscription.is_appliance && category === 'private') {
      browserHistory.push('/tenants/public');
    }
  }

  handleTenantEnvironmentUpdate(id, environment) {
    const current = find(this.props.tenants, { id });
    const from =
      current && current.environment ? current.environment : 'unassigned';
    Metrics.track('update:sc:tenant:environment', {
      trackData: from,
      value: environment
    });
    this.props.actions.updateTenant(id, { environment });
  }

  renderPrivateSaas() {
    const { appliances, versions, privateLoading, privateError } = this.props;
    return (
      <PrivateSaas
        appliances={appliances}
        versions={versions}
        loading={privateLoading}
        error={privateError}
      />
    );
  }

  renderPublicSaas() {
    const {
      location: { query: { ref: locationRef } },
      tenants,
      selectedSubscription,
      publicLoading,
      publicError,
      updating
    } = this.props;

    return (
      <PublicSaas
        handleTenantEnvironmentUpdate={this.handleTenantEnvironmentUpdate}
        selectedSubscription={selectedSubscription}
        tenants={tenants}
        loading={publicLoading}
        error={publicError}
        updating={updating}
        locationRef={locationRef}
      />
    );
  }

  render() {
    const category = this.props.params.category;
    if (category && category !== 'private' && category !== 'public')
      return <PageNotFound />;

    const {
      selectedSubscription,
      tenantAgreementsLoading,
      tenantAgreementsError
    } = this.props;

    const isAppliance =
      selectedSubscription && selectedSubscription.is_appliance;
    const isPrivateInstance = isAppliance && category === 'private';

    return (
      <Loading show={tenantAgreementsLoading}>
        <Error message={tenantAgreementsError}>
          {selectedSubscription && category === 'private' && !isAppliance ? (
            <PageNotFound />
          ) : (
            <div className="appliances tenants">
              <PageHeader>
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/tenants/${category}`}>
                    {isPrivateInstance ? 'Private Instances' : 'Tenants'}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <h1>{isPrivateInstance ? 'Private Instances' : 'Tenants'}</h1>
              </PageHeader>
              <PageWrap>
                {isPrivateInstance
                  ? this.renderPrivateSaas()
                  : this.renderPublicSaas()}
              </PageWrap>
            </div>
          )}
        </Error>
      </Loading>
    );
  }
}

ServicesPage.propTypes = {
  actions: PropTypes.object.isRequired,
  appliances: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  versions: PropTypes.array,
  selectedSubscription: PropTypes.object,
  selectedTenant: PropTypes.string,
  publicLoading: PropTypes.bool,
  publicError: PropTypes.string,
  privateLoading: PropTypes.bool,
  privateError: PropTypes.string,
  updating: PropTypes.array,
  location: PropTypes.object,
  params: PropTypes.shape({
    category: PropTypes.string
  }),
  tenantAgreementsLoading: PropTypes.bool,
  tenantAgreementsError: PropTypes.string
};

function mapStateToProps(state) {
  const tenants = getTenantSummaries(state);

  return {
    selectedSubscription: getSelectedSubscription(state),
    tenants,
    publicLoading:
      state.tenants.loading ||
      state.tenantSummaries.loading ||
      state.tenantAgreements.loading,
    publicError:
      state.tenants.error ||
      state.tenantSummaries.error ||
      state.tenantAgreements.error,
    updating: state.tenants.updating,
    selectedTenant: state.tenants.selected,
    versions: state.appliances.versions,
    appliances: state.appliances.records,
    privateLoading: state.appliances.loading,
    privateError: state.appliances.error,
    tenantAgreementsLoading: state.tenantAgreements.loading,
    tenantAgreementsError: state.tenantAgreements.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...tenantActions,
        ...tenantAgreementsActions,
        ...authActions,
        ...applianceActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);
