import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import Loading from './Loading';
import { Error } from '@a0/support-center-components';


export const AdminRestricted = ({tenants, loading, error, children}) => {
  const flags = window.scConfig.flags;
  const manageUrl = window.scConfig.MANAGE_URL;
  if (loading || error || tenants.length > 0){
    return (
      <Loading show={loading}>
        <Error message={error}>
          {children}
        </Error>
      </Loading>
    );
  }
  return flags && flags.elevated_support_access_role ? (
    <Alert variant="warning" >
      <h4>Access denied!</h4>
      <p><strong>You need to have the new, Elevated Support Access role added to your user of an Auth0 tenant to access this resource.</strong></p>
      <p>What can I do?</p>
      <ul>
        <li>You are an Elevated Support Access user of an Auth0 tenant but you use other credentials to log in? Try to log in again using the correct credentials.</li>
        <li>You are a member of the tenant but do not have Elevated Support Access role assigned? Contact the tenant's administrators to upgrade your permissions.</li>
      </ul>
    </Alert>
  ) : (
    <Alert variant="warning" >
      <h4>Access denied!</h4>
      <p><strong>You need to be a full Administrator or Support Access user of an Auth0 tenant to access this resource.</strong></p>
      <p>What can I do?</p>
      <ul>
        <li>You are a full admin/Support Access user of an Auth0 tenant but you use other credentials to log in? Try to log in again using the correct credentials.</li>
        <li>You are a member of the tenant but not an admin/Support Access user? Contact the tenant's administrators to upgrade your permissions or ask them to manage the support tickets.</li>
        <li>You may not have finished the signup process to create an Auth0 tenant. Check your email to see if there are any steps missing, or go to the <a href={`${manageUrl}/welcome`}>Auth0 Dashboard</a> to complete the provisioning.</li>
      </ul>
    </Alert>
  );
};



AdminRestricted.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  tenants: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string
};
