import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';
import { gravatarUrl } from '@a0/support-center-common/lib/tenants';
import moment from 'moment';

export default function ticketReducer(state = initialState.ticket, action) {
  switch (action.type) {
    case types.LOAD_TICKET_BEGIN:
      return Object.assign({}, state,
        {
          loading: true,
          error: null,
          unauthorizedObjectId: null,
          record: {
            comments: []
          }
        });

    case types.LOAD_TICKET_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        error: null,
        unauthorizedObjectId: null,
        record: Object.assign({}, action.ticket, {
          comments: action.ticket.comments.map(comment => {
            return Object.assign({}, comment, {
              created_at: moment(comment.created_at).fromNow(),
              body: comment.body,
              author_picture: comment.author_picture || gravatarUrl(comment.author_email, comment.author_name)
            });
          })
        })
      });
    }

    case types.LOAD_TICKET_ERROR:
      return Object.assign({}, state,{
        loading: false,
        error: `An error occurred while loading the ticket info: ${action.errorMessage}`,
        unauthorizedObjectId: action.unauthorizedObjectId,
        record: {comments: []}
      });

    default:
      return state;
  }
}
