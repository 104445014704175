import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import { Error } from '@a0/support-center-components';
import logger from '../../../utils/logger';
import { ErrorBoundary as constants } from '../constants';
import StorageError from '../../../services/storage/StorageError';

/**
 * HOC to add an ErrorBoundary to a provided component
 * @param {object} Component
 * @param {object} logTags tags for logging
 * @param {bool} displayStorageErrors whether this component should subscribe to storage errors
 * @return {Component} enhanced component
 */
export function catchErrors(Component, logTags = {}, displayStorageErrors = true) {

  /**
   * Passed component is wrapped in an ErrorBoundary class which
   * will contain any errors occuring lower in the tree
   */
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.logTags = logTags;
    }

    /**
     * Triggered when an exception occurs
     * Switches to fallback UI and logs the error
     * @param {Error} error
     * @param {object} errorInfo
     */
    componentDidCatch(error, errorInfo) {
      this.setState({ hasError: true });
      logger.error({ extra: errorInfo }, this.logTags, error);
    }

    /**
     * Renders the original component or fallback UI
     * Adds Storage Error display to the output if appropriate
     */
    render() {
      if (this.state.hasError) {
        return (
          <Container>
            <Error message={constants.defaultError} />
          </Container>
        );
      }
      return (
        <Fragment>
          {displayStorageErrors && <StorageError />}
          <Component {...this.props} />
        </Fragment>
      );
    }
  }

  return ErrorBoundary;
}
