import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantActiveUsersReducer(state = initialState.reportsTenantActiveUsers, action) {
  switch (action.type) {
    case types.LOAD_REPORTS_TENANT_ACTIVE_USERS_BEGIN:
      return Object.assign({}, initialState.reportsTenantActiveUsers, {loading: true});

    case types.LOAD_REPORTS_TENANT_ACTIVE_USERS_SUCCESS: {
      return Object.assign({}, state, {loading: false, error: null, records: action.records});
    }

    case types.LOAD_REPORTS_TENANT_ACTIVE_USERS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading usage statistics: ${action.errorMessage}`
      });

    case types.PAGINATE_REPORTS_TENANT_ACTIVE_USERS:
      return Object.assign({}, state, {
        page: +action.page
      });

    case types.FILTER_REPORTS_TENANT_ACTIVE_USERS:
      return Object.assign({}, state, {
        filtering: {
          ...state.filtering,
          [action.key]: action.set
        }
      });

    case types.RESET_FILTER_REPORTS_TENANT_ACTIVE_USERS:
      return Object.assign({}, state, {
        filtering: {}
      });

    default:
      return state;
  }
}
