import React from "react";
import PropTypes from "prop-types";
import { STATUS_PROCESSING } from "../../../uploads/reducers/deferredUploadReducer";
import { getQueuedFile } from "../../../uploads/actions/deferredUploadActions";
import { DownloadFileLink } from "./DownloadFileLink";

export const QueuedFile = ({ queuedItem, onRemove }) => {
  const fileId = queuedItem.localId || queuedItem.id;
  const filename = queuedItem.file_name || queuedItem.filename;
  const downloadableFile = queuedItem.cleanedUp && getQueuedFile(fileId);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    onRemove(fileId);
  };

  return (
    <span key={queuedItem.id} className="file">
      {filename}
      {downloadableFile && <DownloadFileLink file={downloadableFile} />}
      {queuedItem.status === STATUS_PROCESSING ? (
        <span> (processing)</span>
      ) : (
        <a onClick={handleDeleteClick} href="#">✕ remove</a>
      )}
    </span>
  );
};

QueuedFile.propTypes = {
  queuedItem: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};
