import React from 'react';
import PropTypes from 'prop-types';

const linkStyle ={
  paddingLeft:'2px'
};

const UnverifiedEmail = ({ email, sendEmailClickHandler, isNotDBConnection }) => {

  return (
    <div className="empty-records">
        {isNotDBConnection && (<p>This feature requires email verification. Please verify your email with your identity provider </p>)}
        {!isNotDBConnection && (<p>To ensure the security of your data, this feature requires email verification.<br /><a href="#" style={linkStyle} onClick={sendEmailClickHandler}>Send a verification email</a> again to <strong>{email}</strong> to verify your account.</p>)}
    </div>);

};

UnverifiedEmail.propTypes = {
  email: PropTypes.string,
  sendEmailClickHandler: PropTypes.func,
  isNotDBConnection: PropTypes.bool
};

export default UnverifiedEmail;