import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantFeaturesReducer(state = initialState.reportsTenantFeatures, action) {
  switch (action.type) {
    case types.LOAD_REPORTS_TENANT_FEATURES_BEGIN:
      return Object.assign({}, initialState.reportsTenantFeatures, { loading: true });

    case types.LOAD_REPORTS_TENANT_FEATURES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        record: action.record
      });

    case types.LOAD_REPORTS_TENANT_FEATURES_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading the report: ${action.errorMessage}`,
        record: {}
      });

    case types.PAGINATE_REPORTS_TENANT_FEATURES:
      return Object.assign({}, state, {
        page: + action.page
      });

    case types.FILTER_REPORTS_TENANT_FEATURES:
      return Object.assign({}, state, {
        filtering: {
          ...state.filtering,
          [action.key]: action.set
        }
      });

    case types.RESET_FILTER_REPORTS_TENANT_FEATURES:
      return Object.assign({}, state, {filtering: {}});

    default:
      return state;
  }
}
