export function canOpenTicket(currentTenant, tenants) {
  const pukTicketsAllowed = window.scConfig.PUK1_GA_TICKETS_ALLOWED;
  if (currentTenant) {
    if (currentTenant.role === 'guest') {
      return false;
    }
    if (!pukTicketsAllowed && currentTenant.host_environment === 'prod-uk-1') {
      return false;
    }
  }
  if (tenants) {
    if (pukTicketsAllowed) {
      return true;
    }
    if (tenants.some((t) => t.host_environment === 'prod-uk-1')) {
      return false;
    }
    return true;
  }
  return false;
}

export function filterTenantsToShow(tenant) {
  const pukTicketsAllowed = window.scConfig.PUK1_GA_TICKETS_ALLOWED;
  if (tenant && tenant.role !== 'guest' && (pukTicketsAllowed || tenant.host_environment.toLowerCase() !== 'prod-uk-1')) {
    return true;
  }
  return false;
}

export function shouldShowTrialAccountAlert(tenant) {
  const pukTicketsAllowed = window.scConfig.PUK1_GA_TICKETS_ALLOWED;
  if (tenant && (pukTicketsAllowed || tenant.host_environment !== 'prod-uk-1')) {
    return true;
  }
  return false;
}
