import { isEmpty, isArray, toUpper, upperFirst } from "lodash";
import semverSatisfies from "semver/functions/satisfies";

// returns if a subscription (not tenant) is a v8 subscription
export const isV8Subscription = (subscription) => {
  if (
    !!subscription &&
    !!subscription.version &&
    semverSatisfies(subscription.version, ">=8.0.0-alpha")
  ) {
    return true;
  }
  return false;
};

// returns formatted subscription name for either an array object of v8 subscriptions (not tenants)
export const getV8SubscriptionName = (subscriptions) => {
  if (!isEmpty(subscriptions)) {
    if (!isArray(subscriptions)) {
      return formatV8SubscriptionName(
        Object.values(subscriptions)[0].plan
      );
    }
    return formatV8SubscriptionName(subscriptions[0].plan);
  }
};

// this needs some work to catch all formatting cases or we can just use a switch case
export const formatV8SubscriptionName = (name) => {
  const fullNameArray = name.split("_");

  if (fullNameArray.length === 1) {
    return upperFirst(name);
  }

  if (fullNameArray[0].indexOf("2") > -1) {
    fullNameArray[0] = toUpper(fullNameArray[0]);
  } else {
    fullNameArray[0] = upperFirst(fullNameArray[0]);
  }

  fullNameArray[1] = upperFirst(fullNameArray[1]);
  return fullNameArray.join(" - ");
};
