import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Button from 'react-bootstrap/Button';
import { get, isEqual, isEmpty, noop, debounce } from 'lodash';

import Fields from '../../components/Fields';
import SubscriptionAlerts from '../../components/SubscriptionAlerts';
import PageWrap from '../../../common/components/PageWrap';
import InlineAlert from '../../../common/components/InlineAlert';
import { FreeAccountContact } from '../../../common/containers/FreeAccountContact';
import EmailVerificationPanel from '../../../emailVerification/containers/EmailVerificationPanel';
import { AdminRestricted } from '../../../common/components/AdminRestricted';
import MissingEmailWarning from '../../components/MissingEmailWarning';
import UnavailableSupport from '../../../login/components/UnavailableSupport';
import TicketResultPage from '../../components/TicketResultPage';
import NewTicketPage from '../../components/NewTicketPage';
import DeferredUploadStatus from '../../components/DeferredUploadStatus';

import { createTicket } from '../../actions/ticketActions';
import { upload as deferredUpload } from '../../../uploads/actions/deferredUploadActions';
import { propTypes as deferredUploadPropTypes } from '../../../uploads/reducers/deferredUploadReducer';
import { getV8SubscriptionName } from '../../../../utils/V8PlanUtils';

import {
  getSelectedSubscription,
  getSelectedSubscriptionName,
  getCustomSubscriptions,
  getTrialSubscriptions,
  getv8Subscriptions
} from '@a0/support-center-common/selectors/subscriptions';

import {
  filterTenantsBySubscription,
  getSelectedSupportPlan
} from '@a0/support-center-common/selectors/common';
import { getUser, isUserEmailVerified } from '../../../login/selectors/users';

import Metrics from '../../../../utils/metrics';

import { ticketPayloadFromState } from '../../transformers';
import fieldVisibility from './fieldVisibility';
import validate from '../../validations';

import {
  setTicketStateInStorage,
  getTicketStateFromStorage,
  clearTicketStateFromStorage
} from '../../utils/session';

import { addUnloadHook, removeUnloadHook } from '../../../../utils/unloadHooks';
import getTicketErrorMessage from '../../utils/getTicketErrorMessage';
import { Recommendations } from "./Recommendations";
import { COVEO_ANALYTICS_EVENT, logCustomEvent as logCoveoCustomEvent } from '../../../../utils/coveo/recommendationEngine';

const initialState = {
  ticket: {
    type: '',
    customerReason: '',
    environment: '',
    targetVersion: '',
    proposedDate: '',
    subject: '',
    comment: '',
    tenant: '',
    applianceTenant: '',
    rootTenantAuthority: '',
    customerSelectedSeverity: '',
    collaborators: []
  },
  additionalQuestions: {},
  errors: {},
  saved: false,
  saving: false,
  recommendationsContext: null,
  editing: false
};

// how much to wait after the user changes the Subject
// and remains in the field before updating the Recommendations context.
// An immediate change happens when the user leaves the field.
const DEBOUNCE_RECOMMENDATIONS_MS = 2000;

/**
 * Component responsible for maintaining form state,
 * dispatching related actions and displaying errors
 */
export class NewTicketForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialStateFromStorage(props);

    // prettier-ignore
    this.handleAdditionalFieldChange = this.handleAdditionalFieldChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.propsForField = this.propsForField.bind(this);
    this.visible = this.visible.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.preselectTenant = this.preselectTenant.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.updateRecommendationsContext = this.updateRecommendationsContext.bind(this);
    this.debouncedUpdateRecommendationsContext = debounce(this.updateRecommendationsContext, DEBOUNCE_RECOMMENDATIONS_MS);
  }

  componentDidMount() {
    const { router, route } = this.props;

    this.preselectTenant();

    // Avoid leave the ticket form using another route link
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  // Avoid leave the form closing the browser or browser tab
  componentWillUpdate(nextProps, nextState) {
    if (nextState.editing) {
      addUnloadHook(clearTicketStateFromStorage);
    } else {
      removeUnloadHook(clearTicketStateFromStorage);
    }
  }

  componentDidUpdate(prevProps) {
    this.preselectTenant(prevProps);
    this.debouncedUpdateRecommendationsContext();
  }

  componentWillUnmount() {
    removeUnloadHook(clearTicketStateFromStorage);
    logCoveoCustomEvent(COVEO_ANALYTICS_EVENT.CASE_CANCEL);
  }

  updateRecommendationsContext() {
    const { recommendationsContext, ticket } = this.state;
    if (
      !recommendationsContext ||
      recommendationsContext.subject !== ticket.subject
      //recommendationsContext.description !== ticket.comment
    ) {
      this.setState({
        recommendationsContext: {
          subject: ticket.subject
          //description: ticket.comment
        }
      });
    }
  }

  routerWillLeave() {
    if (this.state.editing) {
      return 'Leave this page? Changes that you made will not be saved.';
    }
  }

  getInitialStateFromStorage(props) {
    const stateFromStorage = getTicketStateFromStorage();
    return stateFromStorage
      ? { ...initialState, ...stateFromStorage }
      : initialState;
  }

  /**
   * Preselect tenant if there is only one
   * @param {Object} prevProps
   */
  preselectTenant(prevProps = {}) {
    const selectedSubscriptionChanged = !isEqual(
      this.props.selectedSubscription,
      prevProps.selectedSubscription
    );

    const tenantsChanged = !isEqual(this.props.tenants, prevProps.tenants);
    const onlyOneTenant = get(this.props, 'tenants.length') === 1;
    const ticketStateFromStorage = getTicketStateFromStorage();

    if (selectedSubscriptionChanged && tenantsChanged && onlyOneTenant && !ticketStateFromStorage) {
      const firstTenantId = this.props.tenants[0].id;
      const ticket = {
        ...initialState.ticket,
        tenant: firstTenantId
      };

      const state = {
        ...initialState,
        ticket
      };
      this.setState({ ...state });
    } else if (selectedSubscriptionChanged && !ticketStateFromStorage) {
      this.setState({ ...initialState });
    }
  }

  saveTicketStateInStorageFlow(error) {
    if (error && error.status === 401) {
      setTicketStateInStorage({
        ...this.state,
        saving: false,
        editing: true
      });
    }
  }

  /**
   * Event handler to be used by field
   * components to update the ticket state
   * @param {Object} event
   */
  handleFieldChange(event) {
    const ticket = {
      ...this.state.ticket,
      [event.target.name]: event.target.value
    };

    this.setState({ ...this.state, ticket, editing: true });
  }

  /**
   * Event handler for additional field changes
   * These are handled slightly differently to the others
   * @param {Object} event
   */
  handleAdditionalFieldChange(event) {
    const additionalQuestions = {
      ...this.state.additionalQuestions,
      [event.target.name]: event.target.value
    };

    this.setState({ ...this.state, additionalQuestions });
  }

  /**
   * Gets props for a given field key
   * @param   {String} key
   * @param   {Object} extraProps
   * @returns {Object} props
   */
  propsForField(key, extraProps) {
    return {
      name: key,
      value: this.state.ticket[key],
      error: this.state.errors[key],
      onChange: this.handleFieldChange,
      ...extraProps
    };
  }

  /**
   * Determines whether a field should be displayed in the form
   * @param   {String} key
   * @returns {Boolean}
   */
  visible(key) {
    const { ticket } = this.state;
    const { selectedSubscription, selectedTenant } = this.props;

    if (fieldVisibility[key]) {
      return fieldVisibility[key](ticket, selectedSubscription, selectedTenant);
    }

    return true;
  }

  /**
   * Submit ticket info
   * @param {Object} event Event Object
   */
  handleSubmit(event) {
    event && event.preventDefault();

    Metrics.track('click:sc:ticket:create');
    const { ticket, additionalQuestions } = this.state;
    const { selectedTenant } = this.props;
    const payload = ticketPayloadFromState(
      ticket,
      additionalQuestions,
      selectedTenant
    );
    // Validate ticket
    const errors = validate(payload);
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }

    this.setState({ saving: true, editing: false });

    this.props
      .createTicket(payload)
      .then(result => {
        Metrics.track('success:sc:ticket:create');
        logCoveoCustomEvent(COVEO_ANALYTICS_EVENT.CASE_SUBMIT);

        if (result.commentId) {
          return this.props.deferredUpload(result.commentId)
            .then((  ) => this.setState({ ...initialState, saved: result }))
            .catch(err => this.setState({ ...initialState, saved: result })); // temporary, ignore errors
        }
        this.setState({ ...initialState, saved: result });
      })
      .catch(error => {
        Metrics.track('error:sc:ticket:create');
        this.saveTicketStateInStorageFlow(error);
        this.setState({
          errors: {...this.state.errors, submit: getTicketErrorMessage({error, type: 'request'})},
          saving: false
        });
      });
  }

  /**
   * Cancel ticket. Just go to the home page.
   */
  handleCancel() {
    browserHistory.push("/");
  }

  /**
   * Try again to show the create ticket page in case that the support service is down
   */
  goToCreateATicketPage() {
    browserHistory.push('/tickets/new');
  }

  /**
   * Passes through only the necessary props for each field
   * Displays form progressively
   */
  render() {
    const {
      loggedUser,
      selectedSubscription,
      loadingTenants,
      errorTenants,
      tenants,
      appliances,
      isUserPaying,
      isUserEmailVerified,
      selectedTenant,
      selectedSubscriptionName,
      customSubscriptions,
      trialSubscriptions,
      supportPlan,
      status
    } = this.props;

    const { recommendationsContext, ticket, saving, errors, saved } = this.state;

    const visible = this.visible;
    const disabledSubmit = saving;
    const submitButtonMsg = saving ? 'Submitting...' : 'Submit Ticket';

    const isSelectedTenantPaying = get(selectedTenant, 'subscription.isPaying');
    const isSelectedTenantTrial = get(selectedTenant, 'subscription.isTrial');
    const isGuestRole = get(selectedTenant, 'role') === 'guest';
    const showTicketForm =
      isUserEmailVerified && (isSelectedTenantPaying || isSelectedTenantTrial) && !isGuestRole;
    const isSelectedSubscriptionFree = selectedSubscriptionName === 'Free';

    if (saved) {
      return (
        <TicketResultPage
          step="create"
          ticket={saved}
          supportPlan={supportPlan}
          deferredUploads={this.props.deferredUploads}
        />
      );
    }

    if (errorTenants) {
      return (
        <PageWrap>
          <UnavailableSupport
            title="We are currently investigating an issue"
            status={status}
            withAskCommunity
          />
        </PageWrap>
      );
    }

    if (!loadingTenants && !get(loggedUser, 'email')) {
      return <MissingEmailWarning />;
    }

    return (
      <NewTicketPage>
        <h1>New Ticket</h1>
        {!loadingTenants &&
          isSelectedSubscriptionFree && (
          <FreeAccountContact trackData="new-ticket-page" />
        )}
        <SubscriptionAlerts
          isUserPaying={isUserPaying}
          selectedTenant={selectedTenant}
          subscriptionName={selectedSubscriptionName}
          customSubscriptions={customSubscriptions}
          trialSubscriptions={trialSubscriptions}
          emailVerified={isUserEmailVerified}
        />
        <EmailVerificationPanel featureMode />

        {!loadingTenants && !showTicketForm ? (
          selectedTenant && (
            <div className="alert alert-global alert-warning">
              You do not have access to open support tickets for{" "}
              {selectedTenant && selectedTenant.name}. Please contact your tenant administrator to
              add you this tenant via the Dashboard.
            </div>
          )
        ) : (
          <AdminRestricted tenants={tenants} loading={loadingTenants} error={errorTenants}>
            <form className="new-ticket-form" onSubmit={this.handleSubmit}>
              {visible('tenant') && (
                <Fields.Tenant
                  {...this.propsForField('tenant', {
                    selectedSubscription,
                    tenants
                  })}
                />
              )}
              {visible('rootTenantAuthority') && (
                <Fields.RootTenantAuthority
                  {...this.propsForField('rootTenantAuthority', { appliances })}
                />
              )}
              {visible('applianceTenant') && (
                <Fields.ApplianceTenant
                  {...this.propsForField('applianceTenant', {
                    appliances,
                    rootTenantAuthority: ticket.rootTenantAuthority
                  })}
                />
              )}
              {visible('type') && (
                <Fields.Type {...this.propsForField('type', { selectedSubscription })} />
              )}
              {visible('customerSelectedSeverity') && (
                <Fields.Severity
                  {...this.propsForField('customerSelectedSeverity')}
                  selected={this.state.ticket['customerSelectedSeverity']}
                />
              )}
              {visible('customerReason') && (
                <Fields.Reason
                  {...this.propsForField('customerReason', {
                    type: ticket.type
                  })}
                />
              )}
              {visible('additionalQuestions') && (
                <Fields.AdditionalQuestions
                  {...this.propsForField('additionalQuestions', {
                    value: this.state.additionalQuestions,
                    customerReason: ticket.customerReason,
                    onChange: this.handleAdditionalFieldChange
                  })}
                />
              )}
              {visible("subject") && (
                <Fields.Subject
                  {...this.propsForField("subject", {
                    onBlur: this.updateRecommendationsContext
                  })}
                />
              )}
              <Recommendations context={recommendationsContext} />
              {visible('environment') && (
                <Fields.Environment {...this.propsForField('environment')} />
              )}
              {visible('targetVersion') && (
                <Fields.TargetVersion {...this.propsForField('targetVersion')} />
              )}
              {visible('proposedDate') && (
                <Fields.ProposedDate {...this.propsForField('proposedDate')} />
              )}
              {visible('comment') && (
                <Fields.Comment
                  {...this.propsForField('comment')}
                />
              )}
              {visible('collaborators') && (
                <Fields.Collaborators
                  {...this.propsForField('collaborators')}
                />
              )}
              {visible('submitButton') && (
                <Fragment>
                  <Button
                    type="submit"
                    variant="success"
                    className="animated fadeIn"
                    disabled={disabledSubmit}
                  >
                    {submitButtonMsg}
                  </Button>
                  <Button
                    type="button"
                    variant="default"
                    className="animated fadeIn"
                    onClick={this.handleCancel}
                  >
                    Cancel New Ticket
                  </Button>
                  <DeferredUploadStatus deferredUploads={this.props.deferredUploads} />
                </Fragment>
              )}
              {errors.submit && (
                <span className="help-block">
                  <InlineAlert message={errors.submit} level="danger" />
                </span>
              )}
            </form>
          </AdminRestricted>
        )}
      </NewTicketPage>
    );
  }
}

NewTicketForm.propTypes = {
  // The user logged
  loggedUser: PropTypes.object,
  // Define if the user is paying or not
  isUserPaying: PropTypes.bool,
  // Define if the user has the email verified
  isUserEmailVerified: PropTypes.bool,
  /** The current subscription context from redux, if set */
  selectedSubscription: PropTypes.object,
  // The name of the current subscription, if set */
  selectedSubscriptionName: PropTypes.string,
  // The trial subscriptions of the user, if exists
  trialSubscriptions: PropTypes.array,
  // The trial custom subscriptions of the user, if exists
  customSubscriptions: PropTypes.array,
  // The current tenant context from redux, if set
  selectedTenant: PropTypes.object,
  // Define if the tenants have been loaded or not
  loadingTenants: PropTypes.bool,
  // The error message loading the tenants
  errorTenants: PropTypes.string,
  // The tenants available in the current subscription from redux, if loaded
  tenants: PropTypes.array,
  // The appliances available in the current subscription from redux, if loaded
  appliances: PropTypes.array,
  // Create ticket action with dispatch bound
  createTicket: PropTypes.func.isRequired,
  // The name of the support plan
  supportPlan: PropTypes.string,
  // The status of the support service
  status: PropTypes.object,
  // Router object
  router: PropTypes.object,
  // Route Object
  route: PropTypes.object,
  // Deferred Upload state from Redux
  deferredUploads: deferredUploadPropTypes.isRequired,
  // Trigger deferred upload action
  deferredUpload: PropTypes.func.isRequired
};

NewTicketForm.defaultProps = {
  router: {
    setRouteLeaveHook: noop
  },
  route: {}
};

function mapStateToProps(state) {
  const selectedTenant = get(state, 'tenants.selected');

  return {
    loggedUser: getUser(state),
    isUserPaying: get(state, 'auth.data.paying'),
    isUserEmailVerified: isUserEmailVerified(state),
    selectedSubscription:  getSelectedSubscription(state, getV8SubscriptionName(getv8Subscriptions(state))),
    selectedSubscriptionName: getSelectedSubscriptionName(state),
    trialSubscriptions: getTrialSubscriptions(state),
    customSubscriptions: getCustomSubscriptions(state),
    selectedTenant: get(state, 'tenants.records', {})[selectedTenant],
    loadingTenants: get(state, 'tenants.loading'),
    errorTenants: get(state, 'tenants.error'),
    tenants: filterTenantsBySubscription(state),
    appliances: get(state, 'appliances.records'),
    supportPlan: getSelectedSupportPlan(state),
    deferredUploads: state.deferredUploads
  };
}

const mapDispatchToProps = {
  createTicket,
  deferredUpload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTicketForm);
