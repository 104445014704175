import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../login/actions/authActions';
import Loading from '../components/Loading';

export function requireAuthentication(Component) {

  const AuthenticatedComponent = (props) => {
    const {
      actions,
      isAuthenticated,
      isLoading,
      location,
      onEnterComponent
    } = props;

    useEffect(function triggerLoginIfNotAuthenticated() {
      if (isAuthenticated || isLoading) {
        return;
      }
      const returnTo = location.pathname + location.search;
      actions.login(returnTo);
    }, [
      isAuthenticated, location, isLoading
    ]);

    if (!isAuthenticated) {
      return <div className="empty-page container"><Loading show /></div>;
    }
    // Pass through all props, rename those that should apply to child component
    const childProps = { ...props, onEnter: onEnterComponent };
    return <Component {...childProps} />;
  };

  const mapStateToProps = ({ auth }) => ({
    isLoading: auth.isLoading,
    isAuthenticated: auth.isAuthenticated
  });

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(authActions, dispatch)
    };
  }

  AuthenticatedComponent.propTypes = {
    accessTokenIsValid: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object.isRequired,
    onEnterComponent: PropTypes.func
  };

  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}
