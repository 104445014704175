import initialState from '../initialState';

import {
  LOAD_COMPLIANCE_DOCUMENT_META_BEGIN,
  LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS,
  LOAD_COMPLIANCE_DOCUMENT_META_ERROR
} from '../actionTypes';

export default (state = initialState.documents, action) => {
  switch (action.type) {
    case LOAD_COMPLIANCE_DOCUMENT_META_BEGIN:
      return { ...state, loading: true, error: null };

    case LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS:
      return { ...state, loading: false, error: null, records: action.records };

    case LOAD_COMPLIANCE_DOCUMENT_META_ERROR:
      return { ...state, loading: false, error: action.errorMessage };

    default:
      return state;
  }
};