import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import api from "api";

import { Loading } from '@a0/support-center-components';

import "./styles.css";

class SalesforceAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  downloadAttachment(fileId, commentId, type, fileName) {
    return event => {
      event && event.preventDefault();
      this.props.resetErrors();
      this.setState({ isLoading: true });

      api.tickets
        .downloadAttachment(fileId, commentId, type, fileName)
        .then(data => {
          const elementA = document.createElement("a");
          elementA.style.visibility = "hidden";

          const url = window.URL.createObjectURL(new Blob([data]));
          elementA.href = url;
          elementA.download = fileName;

          // must be appended to the DOM for firefox
          document.body.appendChild(elementA);
          elementA.click();

          // clean up
          document.body.removeChild(elementA);
          window.URL.revokeObjectURL(url);

          this.setState({ isLoading: false });
        })
        .catch(error => {
          this.props.handleError(error);
          this.setState({ isLoading: false });
        });
    };
  }

  render() {
    const { id, type, name, emailMessageId, title, fileExtension } = this.props;
    const { isLoading } = this.state;

    const attachmentName =
      name || `${title}${fileExtension && `.${fileExtension}`}`;

    return (
      <Fragment>
        <a
          href="#"
          className={classNames("attachment", { "is-loading": isLoading })}
          onClick={this.downloadAttachment(
            id,
            emailMessageId,
            type,
            attachmentName
          )}
        >
          <Loading show={isLoading} small>
            <i className="icon icon-budicon-750 attachment-icon" />
          </Loading>
          <span>{attachmentName}</span>
        </a>
      </Fragment>
    );
  }
}

SalesforceAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  emailMessageId: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  fileExtension: PropTypes.string,

  handleError: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired
};

SalesforceAttachment.defaultProps = {
  title: "",
  name: "",
  fileExtension: ""
};

export default SalesforceAttachment;
