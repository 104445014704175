export const STORAGE_ERROR = 'STORAGE_ERROR';

/**
 * Creates a storage error action
 * @param {Error} error
 * @returns {object} action 
 */
export function storageError(error) {
  return {
    type: STORAGE_ERROR,
    error
  };
}