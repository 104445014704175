import { find } from 'lodash';
import * as cookies from './cookies';
import { safeSessionStorage, safeLocalStorage } from '../services/storage';

function getDashboardCurrentTenant(){
  let dashboardCookie = cookies.getCurrentTenantCookie();
  if (dashboardCookie) {
    return dashboardCookie.replace(/@default$/,'@us');
  }

  return null;
}

export function isUserLoggedInAuth0(){
  const auth0Cookie = cookies.getAuth0SessionCookie();
  return auth0Cookie !== null;
}

export function getTenantFromStorage(tenants){
  let currentTenant = safeSessionStorage.getItem('currentTenant');
  let foundTenant = find(tenants, tenant => tenant.id === currentTenant);

  if (!foundTenant) {
    currentTenant = getDashboardCurrentTenant();
    foundTenant = find(tenants, tenant => tenant.id === currentTenant);
  }
  if (!foundTenant) {
    currentTenant = safeLocalStorage.getItem('currentTenant');
    foundTenant = find(tenants, tenant => tenant.id === currentTenant);
  }

  return foundTenant || null;
}

export function setTenantInStorage(tenant) {
  if (tenant) {
    safeLocalStorage.setItem('currentTenant',tenant);
    safeSessionStorage.setItem('currentTenant',tenant);
  }
}
