export const getFeaturesReportData = ({ reportsTenantFeatures }) =>
  reportsTenantFeatures.record;

export const getFeaturesReportLoadingStatus = ({ reportsTenantFeatures }) =>
  reportsTenantFeatures.loading;

export const getFeaturesReportError = ({ reportsTenantFeatures }) =>
  reportsTenantFeatures.error;

export const getFeaturesReportActivePage = ({ reportsTenantFeatures }) =>
  reportsTenantFeatures.page;

export const getFeaturesReportFiltering = ({ reportsTenantFeatures }) =>
  reportsTenantFeatures.filtering;

export const isFeaturesOverquota = state => {
  const reportData = getFeaturesReportData(state);

  const overquota =
    reportData && reportData.overview && reportData.overview.overquota;

  return (
    overquota &&
    (overquota.apiCalls ||
      overquota.mfaDuo ||
      overquota.mfaGoogle ||
      overquota.mfaUsers)
  );
};
