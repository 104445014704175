import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import reportsTenantActiveUsers from '../features/reports/reducers/reportsTenantActiveUsersReducer';
import reportsTenantTotalUsers from '../features/reports/reducers/reportsTenantTotalUsersReducer';
import reportsTenantQuota from '../features/reports/reducers/reportsTenantQuotaReducer';
import reportsTenantFeatures from '../features/reports/reducers/reportsTenantFeaturesReducer';
import reportsTenantM2MDaily from '../features/reports/reducers/reportsTenantM2MDailyReducer';
import reportsTenantEnterpriseConnections from '../features/reports/reducers/reportsTenantEnterpriseConnectionsReducer';
import reportsTenantEnabled from '../features/reports/reducers/reportsTenantEnabledReducer';
import reportsApplianceLastUpdatedUsage from '../features/reports/reducers/reportsApplianceLastUpdatedUsageReducer';

import myTickets from '../features/tickets/reducers/myTicketsReducer';
import orgTickets from '../features/tickets/reducers/orgTicketsReducer';
import ticket from '../features/tickets/reducers/ticketReducer';
import ticketCategories from '../features/tickets/reducers/ticketCategoriesReducer';

import notifications from '../features/notifications/reducers/notificationsReducer';
import notification from '../features/notifications/reducers/notificationReducer';
import recentNotifications from '../features/notifications/reducers/recentNotificationsReducer';

import tenants from '../features/tenants/reducers/tenantsReducer';
import tenantSummaries from '../features/tenants/reducers/tenantSummariesReducer';
import tenantAgreements from '../features/tenants/reducers/tenantAgreementsReducer';

import { reducers as appliances } from '../features/appliances';

import deferredUploads from '../features/uploads/reducers/deferredUploadReducer';

import extendTrial from '../features/trials/reducers/extendTrialReducer';


import auth from '../features/login/reducers/authReducer';
import { reducers as compliance } from '../features/compliance';
import { default as storageService } from '../services/storage/reducer';

import emailVerification from '../features/emailVerification/reducers/emailVerificationReducer';


function lastAction(state = null, action) {
  return action;
}

const rootReducer = combineReducers({
  auth,
  form,
  appliances,
  tenants,
  tenantAgreements,
  tenantSummaries,
  reportsTenantActiveUsers,
  reportsTenantTotalUsers,
  reportsTenantQuota,
  reportsTenantFeatures,
  reportsTenantM2MDaily,
  reportsTenantEnterpriseConnections,
  reportsTenantEnabled,
  reportsApplianceLastUpdatedUsage,
  myTickets,
  orgTickets,
  ticket,
  ticketCategories,
  notifications,
  notification,
  lastAction,
  deferredUploads,
  extendTrial,
  recentNotifications,
  emailVerification,
  compliance,
  storageService,
  routing: routerReducer
});

export default rootReducer;
