import React from 'react';

const pageNotFoundImage = require('../../../img/404/404.svg');

const PageNotFound = () => {
  return (
    <div>
      <div className="bg-dots page-header-dots-404"></div>
      <div className="row">
        <div className="col-md-6 col-md-offset-3 page-body page-canvas text-center">
            <img className="page-404-image" src={pageNotFoundImage} alt="Page Not Found"/>
            <h3><strong>Sorry!</strong></h3>
            <p className="description">The page you were looking for doesn't exist.</p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
