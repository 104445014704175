import React, { Fragment } from 'react';

import {
  propTypes as deferredUploadPropTypes,
  STATUS_SUCCESS,
  STATUS_ERROR,
  STATUS_UPLOADING
} from '../../../uploads/reducers/deferredUploadReducer';

import './styles.module.css';

/**
 * Minimal progress indicator for deferred uploads
 * TODO: replace with Cosmos' spinner component
 * 
 * @param props {object}
 * @param props.deferredUploads {object} deferredUploads state from redux
 */
const DeferredUploadStatus = ({ deferredUploads }) => {
  if (deferredUploads.queue.length === 0 || deferredUploads.status !== STATUS_UPLOADING) {
    return null;
  }

  const completedItems = deferredUploads.queue.filter(queueItem => {
    return [ STATUS_SUCCESS, STATUS_ERROR ].includes(queueItem.status);
  });

  return (
    <Fragment>
      <div styleName="spinner overridden" className="spinner spinner-xs fadeIn">
        <div className="circle"></div>
      </div>
      <span styleName="status" className="fadeIn">Uploading attachment {completedItems.length + 1}/{deferredUploads.queue.length}</span>
    </Fragment>
  );
};

DeferredUploadStatus.propTypes = {
  deferredUploads: deferredUploadPropTypes.isRequired
};

export default DeferredUploadStatus;
