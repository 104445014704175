import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UnavailableSupport from '../components/UnavailableSupport';
import { login } from '../../login/actions/authActions';

import { browserHistory } from 'react-router';

export class UnavailableLoginPage extends Component {
  constructor(props) {
    super(props);

    this.tryLogin = this.tryLogin.bind(this);
  }

  componentDidMount() {
    const { auth: { status } } = this.props;

    if (status === 'up') {
      browserHistory.push('/');
    }
  }

  tryLogin() {
    this.props.login();
  }

  render() {
    return (
      <div className="bg-dots page-header-dots">
        <div className="container">
          <UnavailableSupport withBorder withImage />
        </div>
      </div>
    );
  }
}

UnavailableLoginPage.propTypes = {
  login: PropTypes.func,
  auth: PropTypes.object
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, { login })(UnavailableLoginPage);
