import React from 'react';
import { Route } from 'react-router';
import { requireAuthenticationAndCatchErrors } from '../../routeHelpers';

import ComplianceIndex from './containers/ComplianceIndex';

export default (
  <React.Fragment>
    <Route path="compliance" component={requireAuthenticationAndCatchErrors(ComplianceIndex)} />
  </React.Fragment>
);
