import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/Navbar';

import { find } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getRecentSubscriptionNotifications } from '../../notifications/selectors/notifications';
import itIsFreeOrTrialOrDeveloper from '../../../utils/itIsFreeOrTrialOrDeveloper';
import { getV8SubscriptionName } from '../../../utils/V8PlanUtils';

import { isUserEmailVerified } from '../../login/selectors/users';
import EmailVerificationPanel from '../../emailVerification/containers/EmailVerificationPanel';

import {
  getCustomSubscriptions,
  getSelfServiceSubscriptions,
  getSelectedSubscription,
  getFreeSubscriptions,
  getTrialSubscriptions,
  getv8Subscriptions
} from '@a0/support-center-common/selectors/subscriptions';

import {
  getNotifications,
  getLoadingStatusForTenantsDropdown,
  filterTenantIdsBySubscription
} from '@a0/support-center-common/selectors/common';

import * as authActions from '../../login/actions/authActions';
import * as tenantActions from '../../tenants/actions/tenantActions';
import * as notificationActions from '../../notifications/actions/notificationActions';

export class NavbarWrap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showServiceDropdown: false
    };

    this.toggleServiceDropdown = this.toggleServiceDropdown.bind(this);
    this.createReadNotificationHandler = this.createReadNotificationHandler.bind(this);
    this.createDismissNotificationHandler = this.createDismissNotificationHandler.bind(this);
    this.createClickNotificationHandler = this.createClickNotificationHandler.bind(this);
  }

  /**
   * Open and close the 'services' menu
   */
  toggleServiceDropdown() {
    this.setState({
      ...this.state,
      showServiceDropdown: !this.state.showServiceDropdown
    });
  }

  /**
   * Creates a handler for read notification events with a bound notificationId
   * Calls the action to update the related notificationStates
   *
   * @param {string} notificationId
   * @returns {function}
   */
  createReadNotificationHandler(notificationId) {
    const { currentTenantIds, actions } = this.props;
    return e => {
      if (e) { e.preventDefault(); }
      actions.updateNotificationState(notificationId, currentTenantIds, 'read');
    };
  }

  /**
   * Creates a handler for dismiss notification events with a bound notificationId
   * Calls the action to update the related notificationStates
   *
   * @param {string} notificationId
   * @returns {function}
   */
  createDismissNotificationHandler(notificationId) {
    const { currentTenantIds, actions } = this.props;

    return e => {
      if (e) { e.preventDefault(); }
      actions.updateNotificationState(
        notificationId,
        currentTenantIds,
        'dismissed'
      );
    };
  }

  /**
   * Creates a handler for click notification events with a bound notificationId
   * Calls the action to update the related notificationStates
   *
   * @param {string} notificationId
   * @returns {function}
   */
  createClickNotificationHandler(notificationId) {
    const { currentTenantIds, actions, notifications } = this.props;
    const notification = find(notifications, { _id: notificationId });

    return () => {
      if (notification.state && notification.state !== 'clicked') {
        actions.updateNotificationState(
          notificationId,
          currentTenantIds,
          'clicked'
        );
      }
    };
  }

  render() {
    const { children } = this.props;
    const [ _, first, second ] = this.props.location.pathname.split('/');
    const activePath = [ first, second ];

    const {
      createReadNotificationHandler,
      createDismissNotificationHandler,
      createClickNotificationHandler
    } = this;

    const navProps = {
      ...this.props,
      activePath,
      createReadNotificationHandler,
      createDismissNotificationHandler,
      createClickNotificationHandler
    };

    return (
      <React.Fragment>
        <Navbar {...navProps} key="navbar" />
        <EmailVerificationPanel key="email-verification-panel" />
        <React.Fragment key="page-content">
          {children}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

NavbarWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  tickets: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  emailVerified: PropTypes.bool,
  selectedTenant: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentTenantIds: PropTypes.array.isRequired,
  freeSubscriptions: PropTypes.array.isRequired,
  trialSubscriptions: PropTypes.array.isRequired,
  customSubscriptions: PropTypes.array.isRequired,
  v8Subscriptions: PropTypes.array.isRequired,
  notificationsLoading: PropTypes.bool,
  selectedSubscription: PropTypes.object,
  tenantsDropdownLoading: PropTypes.bool.isRequired,
  selfServiceSubscriptions: PropTypes.array.isRequired
};

export function mapStateToProps(state, ownProps) {
  return {
    tickets:         state.myTickets.records.slice(0, 3),
    isAuthenticated: state.auth.isAuthenticated,
    notifications:   getRecentSubscriptionNotifications(state, ownProps).slice(0, 3),
    notificationsLoading:     state.recentNotifications.loading || state.tenants.loading,

    customSubscriptions:      getCustomSubscriptions(state),
    selfServiceSubscriptions: getSelfServiceSubscriptions(state),
    freeSubscriptions:        getFreeSubscriptions(state),
    trialSubscriptions:       getTrialSubscriptions(state),
    v8Subscriptions:          getv8Subscriptions(state),

    selectedSubscription:      getSelectedSubscription(state, getV8SubscriptionName(getv8Subscriptions(state))),
    selectedTenant:            state.tenants.selected,

    tenantsDropdownLoading: getLoadingStatusForTenantsDropdown(state),
    currentTenantIds:       filterTenantIdsBySubscription(state),
    emailVerified:          isUserEmailVerified(state)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...authActions, ...tenantActions, ...notificationActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarWrap);
