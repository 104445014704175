import types from '@a0/support-center-common/constants/tickets/types';
import reasons from '@a0/support-center-common/constants/tickets/customerReasons';
import { isTrialTenant } from '@a0/support-center-common/lib/tenants';
/**
 * Infer if an appliance subscription is provided
 * @param  {Object} subscription
 * @return {Boolean}
 */
function isAppliance(subscription) {
  return subscription && !!subscription.is_appliance;
}

/**
 * If a tenant is in trial
 * @param  {Object} tenant
 * @return {Boolean}
 */
function isTrial(tenant) {
  return isTrialTenant(tenant);
}

/**
 * True if a tenant is currently selectected in the ticket form
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isTenantSelected(ticket) {
  return !!ticket.applianceTenant || !!ticket.tenant;
}


/**
 * True if a severity is expected according to current ticket data
 * @param  {Object} ticket
 * @param  {Object} tenant
 * @return {Boolean}
 */
function severityRequired(ticket, tenant) {
  if (isTrial(tenant)) {
    return false;
  }

  return (
    ticket.type === types.PUBLIC_CLOUD_SUPPORT_INCIDENT_TYPE ||
    ticket.type === types.APPLIANCE_SUPPORT_INCIDENT_TYPE
  );
}

/**
 * True if a customerReason is expected according to current ticket data
 * @param  {Object} ticket
 * @return {Boolean}
 */
function customerReasonRequired(ticket) {
  return (
    ticket.type === types.PUBLIC_CLOUD_SUPPORT_INCIDENT_TYPE ||
    ticket.type === types.APPLIANCE_SUPPORT_INCIDENT_TYPE ||
    ticket.type === types.OPERATIVE_REQUEST_TYPE
  );
}

/**
 * True if a the user selected a 'type' for the current ticket
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isTypeSelected(ticket) {
  return !!ticket.type;
}

/**
 * True if a the user selected a 'severity' for the current ticket
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isSeveritySelected(ticket) {
  return !!ticket.customerSelectedSeverity;
}

/**
 * True if a the user selected a 'customerReason' for the current ticket
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isCustomerReasonSelected(ticket) {
  return !!ticket.customerReason;
}

/**
 * True if severity and customer reason are neither required or selected
 * @param  {Object} ticket
 * @return {Boolean}
 */
function severityAndCustomerReasonNotRequiredOrSelected(ticket, tenant) {
  return (
    (!severityRequired(ticket, tenant) || !!ticket.customerSelectedSeverity) &&
    (!customerReasonRequired(ticket) || !!ticket.customerReason)
  );
}

/**
 * True if type and severity and customerReason have been selected
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isTypeAndSeverityAndCustomerReasonSelected(ticket, tenant) {
  return (
    isTypeSelected(ticket) &&
    severityAndCustomerReasonNotRequiredOrSelected(ticket, tenant)
  );
}

/**
 * True if type is private cloud and reason is that they want to update
 * @param  {Object} ticket
 * @return {Boolean}
 */
function isApplianceUpdate(ticket, subscription) {
  return (
    isAppliance(subscription) &&
    ticket.customerReason === 'UPDATE_APPLIANCE_CUSTOMER_REASON'
  );
}

/**
 * Rules for determining if a field should be displayed in the form
 * Each function corresponds to one form field
 * Each receives the ticket form values, selected subscription and selected tenant as arguments
 * Truthy return values display the field, false hides it
 */
export default {
  tenant() {
    return true;
  },
  rootTenantAuthority(ticket, selectedSubscription) {
    return isAppliance(selectedSubscription);
  },
  applianceTenant(ticket, selectedSubscription) {
    return isAppliance(selectedSubscription) && ticket.rootTenantAuthority;
  },
  type(ticket, selectedSubscription) {
    return isTenantSelected(ticket);
  },
  customerSelectedSeverity(ticket, selectedSubscription, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      !isTrial(selectedTenant) &&
      isTypeSelected(ticket) &&
      severityRequired(ticket, selectedTenant)
    );
  },
  customerReason(ticket, _, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      isTypeSelected(ticket) &&
      (!severityRequired(ticket, selectedTenant) || isSeveritySelected(ticket)) &&
      customerReasonRequired(ticket)
    );
  },
  additionalQuestions(ticket, selectedSubscription) {
    return (
      isTenantSelected(ticket) &&
      isTypeSelected(ticket) &&
      isCustomerReasonSelected(ticket) &&
      customerReasonRequired(ticket)
    );
  },
  subject(ticket, selectedSubscription, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      isTypeAndSeverityAndCustomerReasonSelected(ticket, selectedTenant)
    );
  },
  environment(ticket, selectedSubscription, selectedTenant) {
    return isApplianceUpdate(ticket, selectedSubscription);
  },
  targetVersion(ticket, selectedSubscription, selectedTenant) {
    return isApplianceUpdate(ticket, selectedSubscription);
  },
  proposedDate(ticket, selectedSubscription, selectedTenant) {
    return isApplianceUpdate(ticket, selectedSubscription);
  },
  comment(ticket, selectedSubscription, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      isTypeAndSeverityAndCustomerReasonSelected(ticket, selectedTenant)
    );
  },
  collaborators(ticket, selectedSubscription, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      isTypeAndSeverityAndCustomerReasonSelected(ticket, selectedTenant)
    );
  },
  submitButton(ticket, selectedSubscription, selectedTenant) {
    return (
      isTenantSelected(ticket) &&
      isTypeAndSeverityAndCustomerReasonSelected(ticket, selectedTenant)
    );
  }
};
