import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { TenantCard } from '@a0/support-center-components';
import { tenantRoles } from '@a0/support-center-common/constants/tenants';

import SubscriptionTag from '../components/SubscriptionTag';
import { buildManageProductionChecksUrl } from "../../../../utils/external-links";

import Metrics from '../../../utils/metrics';
const manageUrl = window.scConfig.MANAGE_URL;

class TenantSummaryCards extends Component {
  constructor(props) {
    super(props);
    this.openCheckListPage = this.openCheckListPage.bind(this);
  }

  openCheckListPage(tenantId) {
    return event => {
      if (event) {
        event.preventDefault();
      }

      const [tenant, locality] = tenantId.split("@");

      Metrics.track('click:sc:tenant:productioncheck');
      window.open(buildManageProductionChecksUrl({ tenant, locality }), '_blank');
    };
  }

  render() {
    const {
      tenants,
      handleTenantEnvironmentUpdate,
      updatingTenants,
      selectedSubscription
    } = this.props;

    const createUpdateHandler = (id) => (environment) => {
      handleTenantEnvironmentUpdate(id, environment);
    };

    const getSubscriptionTag = (subscription, id) => <SubscriptionTag id={id} subscription={subscription} />; // eslint-disable-line react/no-multi-comp

    const trackClickManage = (tenant, url) => (e) => {
      Metrics.track('click:sc:manage', {trackData:tenant}, () => window.location = url);
      e.preventDefault();
    };

    return (
      <div>
        <div className="tenant-container">
          {tenants.map((tenant, i) => (
            <div key={tenant.id} className="tenant">
              <TenantCard
                {...tenant}
                disabled={tenant.role === tenantRoles.MASTER_ADMIN_TENANT_ROLE}
                subscriptionName={selectedSubscription && selectedSubscription.name}
                baseManageUrl={manageUrl}
                getSubscriptionTag={getSubscriptionTag}
                updating={updatingTenants.indexOf(tenant.id) !== -1}
                reverseAction={createUpdateHandler(tenant.id)}
                trackClickManage={trackClickManage}
                toggleShowChecklistModal={this.openCheckListPage(tenant.id)}
                runChecksSettingTooltip="Redirects to the Management Dashboard"
                Metrics={Metrics}
                withSettings
                withTooltipSuggestion={i === 0}
                withTaggingSuggestion
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
TenantSummaryCards.propTypes = {
  handleTenantEnvironmentUpdate: PropTypes.func.isRequired,
  updatingTenants: PropTypes.array.isRequired,
  tenants: PropTypes.array.isRequired,
  locationRef: PropTypes.string,
  selectedSubscription: PropTypes.string
};

export default TenantSummaryCards;
