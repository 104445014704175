import { actionTypes } from '../actions/deferredUploadActions';
import PropTypes from 'prop-types';

/**
 * The main state in deferredUploads is an array of queueItems
 * These hold data representing a single file upload
 *
 * @typedef {object} queueItem
 * @property {string} id - unique local id
 * @property {string} filename
 * @property {string} status
 * @property {(string|null)} errorMessage
 */

 // Possible values for queueItem status
export const STATUS_PROCESSING = 'validating';
export const STATUS_PENDING = 'pending';
export const STATUS_UPLOADING = 'uploading';
export const STATUS_SUCCESS = 'success';
export const STATUS_ERROR = 'error';

// Empty default state
export const initialState = {
  queue: [],
  status: STATUS_PENDING,
  // a global error for the upload operations
  errorMessage: null,
  // keeps the last error that happened on the queue
  lastQueueOperation: {}
};

// Export PropTypes for usage by React
// everywhere we consume this module
const status = PropTypes.oneOf([
  STATUS_PROCESSING,
  STATUS_PENDING,
  STATUS_UPLOADING,
  STATUS_SUCCESS,
  STATUS_ERROR
]);

export const propTypes = PropTypes.shape({
  queue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
      status: status.isRequired,
      errorMessage: PropTypes.string
    })
  ).isRequired,

  status: status.isRequired,
  errorMessage: PropTypes.string
});

// Selectors
export function inErrorState(state) {
  return state.status === STATUS_ERROR;
}

/**
 * Helper function to build queue items consistently
 * @param {string} id
 * @param {string} filename
 * @param {string} status
 * @returns {object} queueItem
 */
function queueItem(id, filename, status) {
  return {
    id,
    filename,
    status
  };
}

/**
 * Deferred upload redux reducer
 * @param {object} state
 * @param {object} action
 * @returns {object} newState
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {

    // Append a queueItem with the passed file
    case actionTypes.DEFERRED_UPLOAD_QUEUE_ADDING: {
      return {
        ...state,
        lastQueueOperation: {},
        queue: [
          ...state.queue,

          queueItem(action.id, action.filename, STATUS_PROCESSING)
        ]
      };
    }

    // Append a queueItem with the passed file
    case actionTypes.DEFERRED_UPLOAD_QUEUE_ADDED: {
      const queue = state.queue.map(queueItem => ({ ...queueItem }));
      const item = queue.find(item => item.id === action.id);
      item.status = STATUS_PENDING;
      if (action.cleanedUp) {
        item.cleanedUp = action.cleanedUp;
      }
      // filename might change after a clean-up/sanitization
      item.filename = action.filename;
      return {
        ...state,
        queue
      };
    }

    // Append a queueItem with the passed file
    case actionTypes.DEFERRED_UPLOAD_QUEUE_FAILED: {
      const item = state.queue.find(item => item.id === action.id);
      const queue = state.queue.filter(queueItem => queueItem.id !== action.id);
      return {
        ...state,
        lastQueueOperation: {
          filename: action.filename,
          cleanedUp: action.cleanedUp,
          id: action.id,
          error: action.error
        },
        queue
      };
    }

    // Search for the file and remove it
    case actionTypes.DEFERRED_UPLOAD_QUEUE_REMOVE: {
      const queue = state.queue.filter(item => {
        return item.id !== action.id;
      });

      return {
        ...state,
        lastQueueOperation: {},
        queue
      };
    }

    // Set uploading status
    case actionTypes.DEFERRED_UPLOAD_BEGIN: {
      return {
        ...state,
        lastQueueOperation: {},
        status: STATUS_UPLOADING
      };
    }

    // Set success status
    case actionTypes.DEFERRED_UPLOAD_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        queue: []
      };
    }

    // Set error status and message
    case actionTypes.DEFERRED_UPLOAD_ERROR: {
      return {
        ...state,
        status: STATUS_ERROR,
        errorMessage: action.errorMessage
      };
    }

    // Set the status on the individual queueItem
    case actionTypes.DEFERRED_UPLOAD_REQUEST_BEGIN: {
      const queue = state.queue.map(queueItem => ({ ...queueItem }));
      const item = queue.find(item => item.id === action.id);
      item.status = STATUS_UPLOADING;

      return {
        ...state,
        queue
      };
    }

    // Set the status on the individual queueItem
    case actionTypes.DEFERRED_UPLOAD_REQUEST_SUCCESS: {
      const queue = state.queue.map(queueItem => ({ ...queueItem }));
      const item = queue.find(item => item.id === action.id);
      item.status = STATUS_SUCCESS;

      return {
        ...state,
        queue
      };
    }

    // Set the status and errorMessage on the individual queueItem
    case actionTypes.DEFERRED_UPLOAD_REQUEST_ERROR: {
      const queue = state.queue.map(queueItem => ({ ...queueItem }));
      const item = queue.find(item => item.id === action.id);
      item.status = STATUS_ERROR;
      item.errorMessage = action.errorMessage;

      return {
        ...state,
        queue
      };
    }

    // Reset the state
    case actionTypes.DEFERRED_UPLOAD_QUEUE_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}
