import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function emailVerificationReducer(state = initialState.emailVerification, action) {
  switch (action.type) {

    case types.SEND_EMAIL_VERIFICATION_BEGIN:
      return { ...state, loading: true, emailSent: false};

    case types.SEND_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, emailSent: true, loading: false };

    case types.SEND_EMAIL_VERIFICATION_ERROR:
      return { ...state, error: `An error occurred while verifying your email: ${action.errorMessage}`, loading: false, emailSent: false };

    default:
      return state;
  }
}
