import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './module.css';

const Introduction = ({ children }) => {
  return (
    <p styleName="introduction">
      {children}
    </p>
  );
};

Introduction.propTypes = {
  children: PropTypes.node
};

export default Introduction;