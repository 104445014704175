import initialState from '../initialState';

import {
  LOAD_NDA_STATUS_BEGIN,
  LOAD_NDA_STATUS_SUCCESS,
  LOAD_NDA_STATUS_ERROR,
  LOAD_NDA_STATUS_RESET
} from '../actionTypes';

export default (state = initialState.nda, action) => {
  switch (action.type) {
    case LOAD_NDA_STATUS_BEGIN:
      return { ...state, loading: true, error: null };

    case LOAD_NDA_STATUS_SUCCESS:
      return { ...state, loading: false, error: null, exists: action.record.ndaInPlace };

    case LOAD_NDA_STATUS_ERROR:
      return { ...state, loading: false, exists: false, error: action.errorMessage };

    case LOAD_NDA_STATUS_RESET:
      return { ...initialState.nda };

    default:
      return state;
  }
};