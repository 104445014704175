import { request } from 'api';

export default {
  getUsageActiveUsersReport(userId, allTenants = false) {
    return request(`reports/usage/active_users?user_id=${encodeURIComponent(userId)}&allTenants=${allTenants}`);
  },

  getUsageTotalUsersReport(userId, allTenants = false) {
    return request(`reports/usage/total_users?user_id=${encodeURIComponent(userId)}&allTenants=${allTenants}`);
  },

  getQuotaReport(tenantId) {
    return request(`reports/quota?tenant_id=${tenantId}`);
  },

  getFeaturesReport(tenantId, featuresArray) {
    return request(`reports/features?tenantId=${tenantId}`);
  },

  getM2MDailyReport(tenantId) {
    return request(`reports/usage/m2m_per_day?tenant_id=${tenantId}`);
  },

  getEnterpriseConnectionsReport(tenantId) {
    return request(`reports/enterprise_connections?tenantId=${tenantId}`);
  },

  getReportsEnabled(tenantId) {
    return request(`reports/enabled?tenantId=${tenantId}`);
  },

  getApplianceLastUpdatedUsage(tenantId) {
    return request(`reports/appliance_last_updated_usage?tenantId=${tenantId}`);
  }
};
