/**
 * Get the status code from an API error
 * @param {Error} error
 * @returns {number} status code 
 */
export default function getErrorStatus(error) {
  if (!error) {
    return null;
  }

  return error.status || error.response && error.response.status || null;
}
