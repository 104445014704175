import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../../common/components/PageHeader';
import PageWrap from '../../common/components/PageWrap';
import Breadcrumb from '../../common/components/Breadcrumb';

/**
 * Component responsible for wrapping the
 * new ticket form in page context
 */
const OfflineTicketPage = ({ children }) => (
  <Fragment>
    <PageHeader extended />
    <PageWrap canvas>
      {children}
    </PageWrap>
  </Fragment>
);

OfflineTicketPage.propTypes = {
  children: PropTypes.node
};

export default OfflineTicketPage;
