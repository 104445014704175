import { getSubscriptionPlanData } from '@a0/support-center-common/selectors/subscriptions';

// TODO this duplicates logic in auth0-contact-form 2.x.x, we can remove when it's upgraded
const looksLikeContactForm = payload => {
  const fields = [
    'email',
    'message',
    'name',
    'company',
    'role'
  ];

  return fields.every(field => payload.hasOwnProperty(field));
};

const useFormData = payload => {
  payload.trackData = payload.email;
  payload.track_data2 = payload.message;
  payload.track_data3 = payload.name;
  payload.track_data4 = payload.company;
  payload.track_data5 = payload.role;
  return payload;
};

const usePlanData = (payload, state) => {
  const plans = getSubscriptionPlanData(state);
  if (!plans) {
    return payload;
  }

  payload.track_data2 = payload.track_data2 || plans.highest;
  payload.track_data3 = payload.track_data3 || plans.active;
  payload.track_data4 = payload.track_data4 || plans.csv;
  return payload;
};


export default store => (payload = {}) => {
  return looksLikeContactForm(payload) ?
    useFormData(payload) :
    usePlanData(payload, store.getState());
};
