/*eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { confirmable } from 'react-confirm';
import { sanitizeHTML } from '../../../utils/sanitizeHTML';

class Confirmation extends React.Component {

  constructor(props) {
    super(props);
    this.createCancelButton = this.createCancelButton.bind(this);
    this.createOkButton = this.createOkButton.bind(this);
    this.getButtons = this.getButtons.bind(this);
  }

  getButtons() {
    const buttons = [this.createOkButton(),this.createCancelButton()];
    if (this.props.showOkButtonAtEnd) {
      return buttons.reverse();
    }
    return buttons;
  }

  createCancelButton() {
    return <Button variant="default" className={this.props.cancelStyle} onClick={this.props.cancel}>{this.props.cancelLabel || 'Cancel'}</Button>;
  }

  createOkButton() {
    return <Button variant="default" className={this.props.okStyle} onClick={this.props.proceed}>{this.props.okLabel || 'OK'}</Button>;
  }

  render() {
    const {
      okLabel = 'OK',
      cancelLabel = 'Cancel',
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      enableEscape = true,
      showOkButtonAtEnd = false,
      okStyle = "btn-success",
      cancelStyle = "btn-default"
    } = this.props;
    return (
      <div className="static-modal">
        <Modal show={show} onHide={dismiss} backdrop={enableEscape ? true : 'static'} keyboard={enableEscape}>
          {title && <Modal.Header className="has-border">
            <Modal.Title as="h4">{title}</Modal.Title>
          </Modal.Header>}
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML.process(confirmation) }}></div>
          </Modal.Body>
          <Modal.Footer>
            {this.getButtons()}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Confirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,     // called when ok button is clicked.
  cancel: PropTypes.func,      // called when cancel button is clicked.
  dismiss: PropTypes.func,     // called when backdrop is clicked or escaped.
  enableEscape: PropTypes.bool,
  showOkButtonAtEnd: PropTypes.bool,
  cancelStyle: PropTypes.string,
  okStyle: PropTypes.string
};

export default confirmable(Confirmation);
