import { get } from 'lodash';
import * as sentry from './sentry/sentry_provider';
import * as consoleProvider from './console/console_provider';

const levels = {
  'trace': 100,
  'debug': 200,
  'info': 300,
  'warn': 400,
  'error': 500
};

export class Logger {
  constructor(options = {}) {
    this.config = {
      tags: {
        error: get(options, 'tags.error', {})
      }
    };
    this.providers = [];
    if (window.scConfig && window.scConfig.SENTRY_REPORTING_URL){
      sentry.setup(window.scConfig.SENTRY_REPORTING_URL);
      this.providers.push(sentry);
    }
    if (process.env.NODE_ENV === 'development'){
      this.providers.push(consoleProvider);
    }
    this.nlevel = process.env.NODE_ENV === 'production' ? levels['info'] : levels['debug'];
    window.onunhandledrejection = (evt) => {
      this.error({
        log_type: 'unhandled'
      }, null, evt.reason);
    };
  }

  /**
   @param id
   @param username
   @param email
   @param ip_address
  */
  setUser(user) {
    this.providers.forEach((p) => p.setUser(user));
  }

  /**
  @param level
  @param [context]
  @param [tags]
  @param message
  */
  log(level, context, tags, message) {
    if (this.nlevel > levels[level]) {
      return;
    }

    if (!message) {
      message = tags;
      tags = {};
    }

    const allTags = Object.assign({ level: level }, this._addDefaultTags(level, tags));

    if (message instanceof Error) {
      this.providers.forEach((p) => p.captureException(message, context, allTags));
    } else {
      this.providers.forEach((p) => p.captureMessage(message, context,  allTags));
    }
  }

  trace(context, tags, message) {
    this.log('trace', context, tags, message);
  }

  debug(context, tags, message) {
    this.log('debug', context, tags, message);
  }

  info(context, tags, message) {
    this.log('info', context, tags, message);
  }

  warn(context, tags, message) {
    this.log('warning', context, tags, message);
  }

  error(context, tags, message) {
    this.log('error', context, tags, message);
  }

  /**
   * Adds default tags by error level passed by class constructor.
   * @param {string} level Error level
   * @param {object} tags Tags to extend with default tags by level.
   */
  _addDefaultTags(level, tags = {}) {
    if (!this.config.tags[level]) return tags;

    const _tags = { ...tags };
    Object.keys(this.config.tags[level]).filter(key => {
      return Boolean(this.config.tags[level][key]);
    }).forEach(key => {
      _tags[key] = this.config.tags[level][key];
    });
    return _tags;
  }
}

export default new Logger();
