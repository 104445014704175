import initialState from '../../../redux/initialState';

import {
  LOAD_RECENT_NOTIFICATIONS_BEGIN,
  LOAD_RECENT_NOTIFICATIONS_SUCCESS,
  LOAD_RECENT_NOTIFICATIONS_ERROR,
  UPDATE_NOTIFICATION_STATE_SUCCESS
} from '../../../redux/actionTypes';

export default (state = initialState.recentNotifications, action) => {
  switch (action.type) {
  
    case LOAD_RECENT_NOTIFICATIONS_BEGIN:
      return { ...state, loading: true, error: null, records: [] };

    case LOAD_RECENT_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, error: null, records: action.records, states: action.states };

    case LOAD_RECENT_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.errorMessage, records: [], states: [] };

    case UPDATE_NOTIFICATION_STATE_SUCCESS:
      return { ...state, states: [ ...state.states, ...action.states ]};

    default:
      return state;

  }
};