import { clone } from 'lodash';
import * as types from '../../../redux/actionTypes';
import api from 'api';
import Metrics from '../../../utils/metrics';
import {getUser} from '../../login/actions/authActions';

const TRACKING_QUOTA_REPORTS = 'click:sc:reports:quota:';

// ACTIVE USERS REPORTS
export function paginateUsageReportsActiveUsers(page) {
  return { type: types.PAGINATE_REPORTS_TENANT_ACTIVE_USERS, page };
}

export function filterUsageReportsActiveUsers(value, key) {
  if (!value && !key)
    return { type: types.RESET_FILTER_REPORTS_TENANT_ACTIVE_USERS };
  return filterSet(
    types.FILTER_REPORTS_TENANT_ACTIVE_USERS,
    'reportsTenantActiveUsers',
    key,
    value
  );
}

export function loadReportsTenantActiveUsersBegin() {
  return { type: types.LOAD_REPORTS_TENANT_ACTIVE_USERS_BEGIN };
}

export function loadReportsTenantActiveUsersSuccess(records) {
  return { type: types.LOAD_REPORTS_TENANT_ACTIVE_USERS_SUCCESS, records };
}

export function loadReportsTenantActiveUsersError(error) {
  return { type: types.LOAD_REPORTS_TENANT_ACTIVE_USERS_ERROR, error };
}

export function loadReportsTenantActiveUsers(userId) {

  if (!userId) {
    const user = getUser();
    userId = user.sub;
  }

  return function(dispatch) {
    dispatch(loadReportsTenantActiveUsersBegin());

    return api.reports
      .getUsageActiveUsersReport(userId, true)
      .then(data => {
        dispatch(loadReportsTenantActiveUsersSuccess(data));
      })
      .catch(error => {
        dispatch(loadReportsTenantActiveUsersError(error));
      });
  };
}

// TOTAL USERS REPORTS

export function paginateUsageReportsTotalUsers(page) {
  return { type: types.PAGINATE_REPORTS_TENANT_TOTAL_USERS, page };
}

export function filterUsageReportsTotalUsers(value, key) {
  if (!value && !key)
    return { type: types.RESET_FILTER_REPORTS_TENANT_TOTAL_USERS };
  return filterSet(
    types.FILTER_REPORTS_TENANT_TOTAL_USERS,
    'reportsTenantTotalUsers',
    key,
    value
  );
}

export function loadReportsTotalUsersBegin() {
  return { type: types.LOAD_REPORTS_TENANT_TOTAL_USERS_BEGIN };
}

export function loadReportsTotalUsersSuccess(records) {
  return { type: types.LOAD_REPORTS_TENANT_TOTAL_USERS_SUCCESS, records };
}

export function loadReportsTotalUsersError(error) {
  return { type: types.LOAD_REPORTS_TENANT_TOTAL_USERS_ERROR, error };
}

export function loadReportsTotalUsers(userId) {
  if (!userId) {
    const user = getUser();
    userId = user.sub;
  }

  return function(dispatch) {
    dispatch(loadReportsTotalUsersBegin());

    return api.reports
      .getUsageTotalUsersReport(userId, true)
      .then(data => {
        dispatch(loadReportsTotalUsersSuccess(data));
      })
      .catch(error => {
        dispatch(loadReportsTotalUsersError(error));
      });
  };
}

// Quota Reports
export const paginateQuotaReports = (page, reportType, tab) => {
  // ensure we do not track resets due to tab changes or filtering as pagination events
  if (page > 1) {
    Metrics.track(`${TRACKING_QUOTA_REPORTS}paginate`, {
      trackData: {
        page,
        reportType,
        tab
      }
    });
  }

  return {
    type: types.PAGINATE_REPORTS_TENANT_QUOTA,
    page
  };
};

export const filterQuotaReports = (value, key, reportType, tab) => {
  if (!value && !key) return { type: types.RESET_FILTER_REPORTS_TENANT_QUOTA };

  Metrics.track(`${TRACKING_QUOTA_REPORTS}filter`, {
    trackData: {
      key,
      reportType,
      tab,
      value: value || []
    }
  });

  return filterSet(
    types.FILTER_REPORTS_TENANT_QUOTA,
    'reportsTenantQuota',
    key,
    value
  );
};

export const loadReportsTenantQuotaBegin = () => ({
  type: types.LOAD_REPORTS_TENANT_QUOTA_BEGIN
});
export const loadReportsTenantQuotaSuccess = record => ({
  type: types.LOAD_REPORTS_TENANT_QUOTA_SUCCESS,
  record
});
export const loadReportsTenantQuotaError = error => ({
  type: types.LOAD_REPORTS_TENANT_QUOTA_ERROR,
  error
});
export const loadReportsTenantQuotaReset = error => ({
  type: types.LOAD_REPORTS_TENANT_QUOTA_RESET
});

export const loadReportsTenantQuota = tenantId => dispatch => {
  dispatch(loadReportsTenantQuotaBegin());

  return api.reports
    .getQuotaReport(tenantId)
    .then(data => {
      dispatch(loadReportsTenantQuotaSuccess(data));
    })
    .catch(error => {
      dispatch(loadReportsTenantQuotaError(error));
    });
};

// Feature Reports
export const paginateFeaturesReports = page => ({
  type: types.PAGINATE_REPORTS_TENANT_FEATURES,
  page
});
export const filterFeaturesReports = (value, key) => {
  if (!value && !key)
    return { type: types.RESET_FILTER_REPORTS_TENANT_FEATURES };
  return filterSet(
    types.FILTER_REPORTS_TENANT_FEATURES,
    'reportsTenantFeatures',
    key,
    value
  );
};

export const loadReportsTenantFeaturesBegin = () => ({
  type: types.LOAD_REPORTS_TENANT_FEATURES_BEGIN
});
export const loadReportsTenantFeaturesSuccess = record => ({
  type: types.LOAD_REPORTS_TENANT_FEATURES_SUCCESS,
  record
});
export const loadReportsTenantFeaturesError = error => ({
  type: types.LOAD_REPORTS_TENANT_FEATURES_ERROR,
  error
});
export const loadReportsTenantFeaturesReset = error => ({
  type: types.LOAD_REPORTS_TENANT_FEATURES_RESET
});

export const loadReportsTenantFeatures = (
  tenantId,
  featuresArray
) => dispatch => {
  dispatch(loadReportsTenantFeaturesBegin());

  return api.reports
    .getFeaturesReport(tenantId, featuresArray)
    .then(data => {
      dispatch(loadReportsTenantFeaturesSuccess(data));
    })
    .catch(error => {
      dispatch(loadReportsTenantFeaturesError(error));
    });
};

export const loadReportsTenantM2MDailyBegin = () => ({
  type: types.LOAD_REPORTS_TENANT_M2M_DAILY_BEGIN
});

export const loadReportsTenantM2MDailySuccess = ({
  data,
  maxLimitReached
}) => ({
  type: types.LOAD_REPORTS_TENANT_M2M_DAILY_SUCCESS,
  record: data,
  maxLimitReached
});

export const loadReportsTenantM2MDailyError = error => ({
  type: types.LOAD_REPORTS_TENANT_M2M_DAILY_ERROR,
  error
});
export const loadReportsTenantM2MDailyReset = () => ({
  type: types.LOAD_REPORTS_TENANT_M2M_DAILY_RESET
});

export const loadReportsTenantM2MDaily = tenantId => dispatch => {
  dispatch(loadReportsTenantM2MDailyBegin());

  return api.reports
    .getM2MDailyReport(tenantId)
    .then(response => {
      dispatch(loadReportsTenantM2MDailySuccess(response));
    })
    .catch(error => {
      dispatch(loadReportsTenantM2MDailyError(error));
    });
};

export const paginateReportsTenantM2MDaily = page => ({
  type: types.PAGINATE_REPORTS_TENANT_M2M_DAILY,
  page
});

export const filterReportsTenantM2MDaily = (value, key) => {
  if (!value && !key)
    return { type: types.RESET_FILTER_REPORTS_TENANT_M2M_DAILY };
  return filterSet(
    types.FILTER_REPORTS_TENANT_M2M_DAILY,
    'reportsTenantM2MDaily',
    key,
    value
  );
};

// Enterprise Connections
export const paginateEnterpriseConnectionsReports = page => ({
  type: types.PAGINATE_REPORTS_TENANT_ENTERPRISE_CONNECTIONS,
  page
});
export const filterEnterpriseConnectionsReports = (value, key) => {
  if (!value && !key)
    return { type: types.RESET_FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS };
  return filterSet(
    types.FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS,
    'reportsTenantEnterpriseConnections',
    key,
    value
  );
};

export const loadReportsTenantEnterpriseConnectionsBegin = () => ({
  type: types.LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_BEGIN
});
export const loadReportsTenantEnterpriseConnectionsSuccess = record => ({
  type: types.LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_SUCCESS,
  record
});
export const loadReportsTenantEnterpriseConnectionsError = error => ({
  type: types.LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_ERROR,
  error
});
export const loadReportsTenantEnterpriseConnectionsReset = error => ({
  type: types.LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_RESET
});

export const loadReportsTenantEnterpriseConnections = tenantId => dispatch => {
  dispatch(loadReportsTenantEnterpriseConnectionsBegin());

  return api.reports
    .getEnterpriseConnectionsReport(tenantId)
    .then(data => {
      dispatch(loadReportsTenantEnterpriseConnectionsSuccess(data));
    })
    .catch(error => {
      dispatch(loadReportsTenantEnterpriseConnectionsError(error));
    });
};

// SHARED
function filterSet(type, stateKey, key, value = []) {
  return function(dispatch, getState) {
    const item = getState()[stateKey].filtering[key];
    const set = Array.isArray(value) ? value : filterItemFromSet(value, item);
    dispatch({ type, key, set });
  };
}

function filterItemFromSet(value, originalArray) {
  const set = clone(originalArray || []);
  const index = set.indexOf(value);
  index < 0 ? set.push(value) : set.splice(index, 1);
  return set;
}

// Whitelist
export const loadReportsEnabledBegin = () => ({
  type: types.LOAD_REPORTS_ENABLED_BEGIN
});
export const loadReportsEnabledSuccess = enabled => ({
  type: types.LOAD_REPORTS_ENABLED_SUCCESS,
  enabled
});
export const loadReportsEnabledError = error => ({
  type: types.LOAD_REPORTS_ENABLED_ERROR,
  error
});
export const loadReportsEnabledReset = error => ({
  type: types.LOAD_REPORTS_ENABLED_RESET
});

export const loadReportsEnabled = tenantId => dispatch => {
  dispatch(loadReportsEnabledBegin());

  return api.reports
    .getReportsEnabled(tenantId)
    .then(data => {
      dispatch(loadReportsEnabledSuccess(data));
    })
    .catch(error => {
      dispatch(loadReportsEnabledError(error));
    });
};

// Appliance Last Updated Usage
export const loadApplianceLastUpdateUsageBegin = () => ({
  type: types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_BEGIN
});
export const loadApplianceLastUpdateUsageSuccess = records => ({
  type: types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_SUCCESS,
  records
});
export const loadApplianceLastUpdateUsageError = error => ({
  type: types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_ERROR,
  error
});
export const loadApplianceLastUpdateUsageReset = error => ({
  type: types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_RESET
});

export const loadApplianceLastUpdateUsage = tenantId => dispatch => {
  dispatch(loadApplianceLastUpdateUsageBegin());

  return api.reports
    .getApplianceLastUpdatedUsage(tenantId)
    .then(data => {
      dispatch(loadApplianceLastUpdateUsageSuccess(data));
    })
    .catch(error => {
      dispatch(loadApplianceLastUpdateUsageError(error));
    });
};
