export const LOAD_COMPLIANCE_DOCUMENT_META_BEGIN   = 'LOAD_COMPLIANCE_DOCUMENT_META_BEGIN';
export const LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS = 'LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS';
export const LOAD_COMPLIANCE_DOCUMENT_META_ERROR   = 'LOAD_COMPLIANCE_DOCUMENT_META_ERROR';

export const LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN   = 'LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN';
export const LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS = 'LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS';
export const LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR   = 'LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR';

export const LOAD_NDA_STATUS_BEGIN   = 'LOAD_NDA_STATUS_BEGIN';
export const LOAD_NDA_STATUS_SUCCESS = 'LOAD_NDA_STATUS_SUCCESS';
export const LOAD_NDA_STATUS_ERROR   = 'LOAD_NDA_STATUS_ERROR';
export const LOAD_NDA_STATUS_RESET   = 'LOAD_NDA_STATUS_RESET';