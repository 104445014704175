import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router';
import Metrics from '../../../utils/metrics';
import { Auth0Notification } from '@a0/support-center-components';
import SecureLink from './SecureLink';

const OktaAlert = () => {
  const trackEvent = typeOfEvent => () =>
    Metrics.track(`click:sc:${typeOfEvent}`, {trackData: 'free-account-alert'});

  return (
    <div id="okta-acquisition">
      <Auth0Notification type="primary">
          <span>Auth0 <SecureLink href="https://community.auth0.com/t/okta-completes-acquisition-of-auth0/62127">has been acquired by Okta</SecureLink>. Continue to use the Auth0 Support Center for your Auth0 Support needs.</span>
      </Auth0Notification>
    </div>
  );
};

export default OktaAlert;
