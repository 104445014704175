import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContactButtons } from './ContactButtons';

class HavingTrouble extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { still } = this.props;

    const pageName = still ? 'having-trouble-homepage' : 'having-trouble-search';
    const title = still ? 'Still can\'t find what you\'re looking for?' : 'Can\'t find what you\'re looking for?';

    return (
      <div className="container having-trouble">
        <div className="card-docs having-trouble-container">
          <div>
            <h3 className="having-trouble-title">{title}</h3>
          </div>
          <div>
            <p>No need to worry, we're here to make your experience as smooth as possible.</p>
            <ContactButtons pageName={pageName} />
          </div>
        </div>
      </div>
    );
  }
}

HavingTrouble.propTypes = {
  still: PropTypes.bool
};

export default HavingTrouble;
