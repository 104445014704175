import React, { Component } from 'react';
import PropTypes from 'prop-types';


const Loading = ({ show, children, linear, style }) => {
  if (!show) {
    return children || <div></div>;
  }
  return !linear ?
    <div className={`spinner spinner-md is-auth0 ${style}`}><div className="circle"></div></div>
    : <div className={`la-ball-pulse la-sm inline ${style}`}><div /><div /><div /></div>;
};

Loading.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  linear: PropTypes.bool,
  style: PropTypes.string
};

export default Loading;
