import React from 'react';
import PropTypes from 'prop-types';

// A component to show static information, usually along with label. E.g.
// <FormGroup className="form-group">
//   <FormLabel>Tenant</FormLabel>
//   <StaticFormField>{ticket.tenant}</StaticFormField>
// </FormGroup>
export const StaticFormField = ({ children }) => {
  return <p className="form-control-static form-control-static--small">{children}</p>;
};

StaticFormField.propTypes = {
  children: PropTypes.node
};
