import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';
import moment from 'moment';

export default function notificationReducer(state = initialState.notification, action) {
  switch (action.type) {
    case types.LOAD_NOTIFICATION_BEGIN:
      return Object.assign({}, state,
        {loading: true, error: null, record: {}});

    case types.LOAD_NOTIFICATION_SUCCESS: {
      return Object.assign({}, state, {
        loading: false,
        error: null,
        record: Object.assign({}, action.notification, {
          createdOn: moment(action.notification.createdOn).fromNow(),
          longBody: action.notification.longBody || action.notification.body
        })
      });
    }

    case types.LOAD_NOTIFICATION_ERROR:
      return Object.assign({}, state,{
        loading: false,
        error: `An error occurred while loading your notification: ${action.errorMessage}`,
        record: {}
      });

    default:
      return state;
  }
}
