/*eslint-disable react/no-danger*/
import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeHTML } from '../../../utils/sanitizeHTML';

const InlineAlert = ({message,level,onDismiss,index}) => {
  return (
    <div className={'alert alert-global alert-'+level}>
      {onDismiss && <button type="button" data-dismiss="alert" aria-label="Close" className="close" onClick={onDismiss}><span aria-hidden="true" data-index={index}>×</span></button>}
      <span dangerouslySetInnerHTML={{ __html: sanitizeHTML.process(message) }}></span>
    </div>
  );
};

InlineAlert.propTypes = {
  message: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
  index: PropTypes.number.isRequired
};

export default InlineAlert;
