import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Notification } from '@a0/support-center-components';
import NeutralAlert from '../../common/components/NeutralAlert';
import ActionButton from '../../common/components/ActionButton';
import EmailVerificationSentAlert from '../components/EmailVerificationSentAlert';

const linkStyles = {
  marginLeft: '50px'
};

const EmailVerificationAlert = ({ email, emailSent, loading, resendEmailHandler, error, isNotDBConnection }) => (
  emailSent ?
    <EmailVerificationSentAlert email={email} autoClose /> :
    (!error ?
      <NeutralAlert outside>
        <i className="icon-neutral icon-neutral--outside icon-budicon-354" />
        {isNotDBConnection &&
          (<span className="alert-neutral-text-body">
            Your email address <strong>{email}</strong> needs to be verified. Please verify your email with your identity provider.
            </span>)}
        {!isNotDBConnection &&
          (<span className="alert-neutral-text-body">
              To help keep your account secure, we require your email address <strong>{email}</strong> to be verified.
              <ActionButton showLoader={loading} loadingMessage="Sending ..." text="Resend email" customStyle={linkStyles} clickHandler={resendEmailHandler} />
            </span>)}
      </NeutralAlert> :
      <Auth0Notification type="danger" isGlobal>
          There was a problem sending the verification email.
          {!isNotDBConnection && <ActionButton showLoader={loading} loadingMessage="Sending ..." text="Try again" clickHandler={resendEmailHandler} />}
      </Auth0Notification>)
);

EmailVerificationAlert.propTypes = {
  email: PropTypes.string,
  resendEmailHandler: PropTypes.func.isRequired,
  emailSent: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  isNotDBConnection: PropTypes.bool
};

export default EmailVerificationAlert;
