import React from "react";
import { buildResultList } from "@coveo/headless";
import classNames from "classnames";
import { InteractiveResult } from "./InteractiveResult";
import { useCoveoController } from "./utils";
import { Pager } from "./Pager";

export const ResultsList = () => {
  const { state, headlessEngine } = useCoveoController(
    (headlessEngine) => buildResultList(headlessEngine),
    "ResultsList"
  );

  const { results, hasResults, firstSearchExecuted, isLoading, hasError } = state;

  if (!firstSearchExecuted && !hasResults && hasError) {
    return null;
  }

  return (
    <div className={classNames("results-list", { loading: isLoading })}>
      <ul>
        {results.map((result) => (
          <li key={result.uniqueId}>
            <article>
              <InteractiveResult result={result} headlessEngine={headlessEngine}>
                {result.title}
              </InteractiveResult>
              <p>{result.excerpt}</p>
            </article>
          </li>
        ))}
      </ul>
      <Pager />
    </div>
  );
};
