import React from "react";
import PropTypes from "prop-types";

import { CoveoRecommendationEngineWrapper } from "../../recommendations/CoveoRecommendationEngineWrapper";
import { RecommendationsSection } from "../../recommendations/RecommendationsSection";

export const Recommendations = ({ context }) => {
  return (
    <CoveoRecommendationEngineWrapper>
      <RecommendationsSection context={context} />
    </CoveoRecommendationEngineWrapper>
  );
};

Recommendations.propTypes = {
  context: PropTypes.shape( {
    subject: PropTypes.string.isRequired,
    description: PropTypes.string
  })
};
