import { keyBy, keys } from 'lodash';
import * as types from '../../../redux/actionTypes';
import api from 'api';
import semverSatisfies from 'semver/functions/satisfies';

import { getUser } from '../../login/actions/authActions';
import { setSelectedAgreement } from '../../tenants/actions/tenantAgreementsActions';

import { actions as applianceActions } from '../../appliances';

import { selectDefaultTenant, getTenantsForSubscription } from '@a0/support-center-common/selectors/common';
import { getTenantFromStorage, setTenantInStorage } from '../../../utils/session';
import { SUBSCRIPTION_ERROR } from '../../../utils/logger/logTypes';


export function setSelectedTenant(tenant) {
  setTenantInStorage(tenant);
  return { type: types.SET_SELECTED_TENANT, tenant };
}

export function loadTenantsBegin() {
  return { type: types.LOAD_TENANTS_BEGIN };
}

export function loadTenantsSuccess(records) {
  return { type: types.LOAD_TENANTS_SUCCESS, records };
}

export function loadTenantsError(error) {
  return { type: types.LOAD_TENANTS_ERROR, error, log_type: SUBSCRIPTION_ERROR };
}

function loadUserTenantIds(tenants) {
  return { type: types.LOAD_TENANTS_IDS_FOR_USER, tenants };
}

function loadTenantsSummaryBegin() {
  return { type: types.LOAD_TENANTS_SUMMARY_BEGIN };
}

function loadTenantsSummarySuccess(records) {
  return { type: types.LOAD_TENANTS_SUMMARY_SUCCESS, records };
}

function loadTenantsSummaryError(error) {
  return { type: types.LOAD_TENANTS_SUMMARY_ERROR, error };
}

function updateTenantBegin(id) {
  return { type: types.UPDATE_TENANT_BEGIN, id };
}

function updateTenantSuccess(id, data) {
  return { type: types.UPDATE_TENANT_SUCCESS, id, data };
}

function updateTenantError(error) {
  return { type: types.UPDATE_TENANT_ERROR, error };
}

function selectAgreement(tenant) {
  // this includes v8 sales assisted and v8 self-service
  if (semverSatisfies(tenant.subscription.version, '>=8.0.0-alpha')) {
    return 'v8';
  }
  else if (tenant && tenant.subscription && tenant.subscription.isCustomAgreement) {
    return tenant.master_tenant;
  }
  else {
    return getDeveloperType(tenant);
  }
}

function getDeveloperType(tenant) {
  if (tenant && tenant.subscription && !tenant.subscription.isPaying) {
    return tenant.subscription.isTrial ? 'Trial' : 'Free';
  } else {
    return 'Developer';
  }
}

export function setAccountContext(selected) {
  return function(dispatch, getState) {
    let tenant;

    if (selected) {
      tenant = getTenantsForSubscription(getState(), selected)[0];
    } else {
      tenant = getTenantFromStorage(getState().tenants.records)
        || selectDefaultTenant(getState());
    }

    if (tenant) {
      dispatch(setSelectedAgreement(selectAgreement(tenant)));
      dispatch(setSelectedTenant(tenant.id));

      if (tenant.master_tenant && tenant.master_tenant.includes('@pi')) {
        dispatch(applianceActions.loadAppliances(tenant.master_tenant));
      }
    }
  };
}

export function loadTenantsForUser(userId, options) {
  if (!userId) {
    const user = getUser();
    userId = user.sub;
  }

  return function(dispatch) {
    dispatch(loadTenantsBegin());

    return api.tenants.getAll(userId, true, options)
    .then(tenants => {
      const normalizedData = keyBy(tenants.map(t => Object.assign(t, {id: `${t.name}@${t.region}`})), 'id');
      const tenantIds = keys(normalizedData);

      dispatch(loadUserTenantIds(tenantIds));
      dispatch(loadTenantsSuccess(normalizedData));

    }).catch(error => {
      dispatch(loadTenantsError(error));
    });
  };
}

export function loadTenantsSummaryForUser(userId) {
  if (!userId) {
    const user = getUser();
    userId = user.sub;
  }

  return function(dispatch) {
    dispatch(loadTenantsSummaryBegin());
    return api.tenants.getTenantsSummary(userId)
    .then(tenants => {
      dispatch(loadTenantsSummarySuccess(keyBy(tenants,'tenant')));
    }).catch(error => {
      dispatch(loadTenantsSummaryError(error));
    });
  };
}

export function updateTenant(id, data) {
  return function(dispatch) {
    dispatch(updateTenantBegin(id));

    return api.tenants.updateEnvironment(id, data)
    .then(() => {
      dispatch(updateTenantSuccess(id, data));
    })
    .catch((error) => {
      dispatch(updateTenantError(error));
    });
  };
}
