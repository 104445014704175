import * as types from '../../../redux/actionTypes';
import Metrics from '../../../utils/metrics';
import api from 'api';

export function loadNotificationsBegin() {
  return {type: types.LOAD_NOTIFICATIONS_BEGIN};
}

export function loadNotificationsSuccess(notifications) {
  return {type: types.LOAD_NOTIFICATIONS_SUCCESS, notifications};
}

export function loadNotificationsError(error) {
  return {type: types.LOAD_NOTIFICATIONS_ERROR, error};
}

export function loadRecentNotificationsBegin() {
  return {type: types.LOAD_RECENT_NOTIFICATIONS_BEGIN};
}

export function loadRecentNotificationsSuccess({ notifications, notificationStates }) {
  return {type: types.LOAD_RECENT_NOTIFICATIONS_SUCCESS, records: notifications, states: notificationStates };
}

export function loadRecentNotificationsError(error) {
  return {type: types.LOAD_RECENT_NOTIFICATIONS_ERROR, error};
}

export function loadNotificationBegin() {
  return {type: types.LOAD_NOTIFICATION_BEGIN};
}

export function loadNotificationSuccess(notification) {
  return {type: types.LOAD_NOTIFICATION_SUCCESS, notification};
}

export function loadNotificationError(error) {
  return {type: types.LOAD_NOTIFICATION_ERROR, error};
}

export function setNotificationFilterOption(filterOption) {
  return {type: types.SET_NOTIFICATION_FILTER_OPTION, filterOption};
}

export function updateNotificationStateBegin() {
  return { type: types.UPDATE_NOTIFICATION_STATE_BEGIN };
}

export function updateNotificationStateSuccess(id, tenantIds, state) {
  const states = tenantIds.map(tenantId => ({
    _id: { notification_id: id, tenant: tenantId },
    state
  }));

  return { type: types.UPDATE_NOTIFICATION_STATE_SUCCESS, states };
}

export function updateNotificationStateError(error) {
  return { type: types.UPDATE_NOTIFICATION_STATE_ERROR, error };
}

export function updateNotificationState(id, tenantIds, state) {
  return function(dispatch) {
    dispatch(updateNotificationStateBegin());

    if (state === 'dismissed') { Metrics.track('dismiss:sc:notification', { trackData: id }); }
    if (state === 'clicked')   { Metrics.track('click:sc:notification',   { trackData: id }); }

    return api.notifications.setState(id, tenantIds, state)
    .then(() => dispatch(updateNotificationStateSuccess(id, tenantIds, state)))
    .catch(err => dispatch(updateNotificationStateError(err)));
  };
}

export function loadRecentNotifications() {
  return function(dispatch) {
    dispatch(loadRecentNotificationsBegin());

    return api.notifications.getRecent()
    .then(notifications => {
      dispatch(loadRecentNotificationsSuccess(notifications));
    })
    .catch(error => {
      dispatch(loadRecentNotificationsError(error));
    });
  };
}

export function loadNotifications() {
  return function(dispatch) {
    dispatch(loadNotificationsBegin());
    return api.notifications.getAll().then(notifications => {
      dispatch(loadNotificationsSuccess(notifications));
    }).catch(error => {
      dispatch(loadNotificationsError(error));
    });
  };
}

export function loadNotification(notificationId) {
  return function(dispatch) {
    dispatch(loadNotificationBegin());
    return api.notifications.getById(notificationId).then(notification => {
      dispatch(loadNotificationSuccess(notification));
    }).catch(error => {
      dispatch(loadNotificationError(error));
    });
  };
}
