import logger from '../../utils/logger';
import * as actions from './actions';

const LOCAL_STORAGE = 'localStorage';
const SESSION_STORAGE = 'sessionStorage';

// Store object that must be replaced with an initialized
// redux store using bindStore
// We need to inject the store to avoid circular dependencies
// in redux middleware (they use this module)
let _store = {
  dispatch: action => {
    const errStr = 'Action dispatched on unbound redux store';
    const err = new Error(errStr);
    err.action = action;
    logger.error(err, errStr);
  }
};

/**
 * Bind a redux store to dispatch critical error actions
 * @param {object} store 
 */
export function bindStore(store) {
  _store = store;
}

/**
 * Wrapped session storage getItem
 * Catches and logs exceptions
 * @param {string} storageApi
 * @param {function} errorHandler
 * @param {string} key
 * @returns {string} item 
 */
function _getItem(storageApi, errorHandler, key) {
  try {
    return window[storageApi].getItem(key);
  } catch(error) {
    errorHandler && errorHandler(error);
    return null;
  }
}

/**
 * Wrapped session storage setItem
 * Catches and logs exceptions
 * @param {string} storageApi
 * @param {function} errorHandler
 * @param {string} key
 * @param {string} value
 */
function _setItem(storageApi, errorHandler, key, value) {
  try {
    return window[storageApi].setItem(key, value);
  } catch(error) {
    errorHandler && errorHandler(error);
    return null;
  }
}

/**
 * Wrapped session storage removeItem
 * Catches and logs exceptions
 * @param {string} storageApi
 * @param {function} errorHandler
 * @param {string} key
 */
function _removeItem(storageApi, errorHandler, key) {
  try {
    return window[storageApi].removeItem(key);
  } catch(error) {
    errorHandler && errorHandler(error);
    return null;
  }
}

/**
 * Error handler for low priority errors
 * Logs to Sentry as a warning only
 * @param {Error} error 
 */
function _logWarning(error) {
  logger.warn(error, 'Low priority browser storage failure');
}

/**
 * Error handler for critical errors
 * Logs to Sentry as an warning
 * Dispatches an error action for user feedback
 * @param {Error} error 
 */
function _dispatchError(error) {
  logger.warn(error, 'Critical browser storage failure');

  const action = actions.storageError(error);  
  _store.dispatch(action);
}

/**
 * Low priority localStorage exports
 * Use these if failures should be silent, ie. you need to read or write 
 * something that won't render the app unusable if it fails
 */
export const safeLocalStorage = {
  getItem: _getItem.bind(window, LOCAL_STORAGE, _logWarning),
  setItem: _setItem.bind(window, LOCAL_STORAGE, _logWarning),
  removeItem: _removeItem.bind(window, LOCAL_STORAGE, _logWarning)
};

/**
 * Low priority sessionStorage exports
 * Use these if failures should be silent, ie. you need to read or write 
 * something that won't render the app unusable if it fails
 */
export const safeSessionStorage = {
  getItem: _getItem.bind(window, SESSION_STORAGE, _logWarning),
  setItem: _setItem.bind(window, SESSION_STORAGE, _logWarning),
  removeItem: _removeItem.bind(window, SESSION_STORAGE, _logWarning)
};

/**
 * High priority sessionStorage exports
 * If one of these fails we log an error to sentry and display
 * an error message to the user
 */
export const criticalSessionStorage = {
  getItem: _getItem.bind(window, SESSION_STORAGE, _dispatchError),
  setItem: _setItem.bind(window, SESSION_STORAGE, _dispatchError),
  removeItem: _removeItem.bind(window, SESSION_STORAGE, _dispatchError)
};

