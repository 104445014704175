import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/components/SelectInput';
import { ROOT_TENANT_AUTHORITY } from '../../constants';

export function mapAppliancesForSelect(appliances) {
  return appliances.map(appliance => ({
    value: appliance.root_domain_auth,
    text: `${appliance.root_domain_auth} (${appliance.dev_staging_prod})`
  }));
}

/**
 * Root tenant authority select component
 */
const RootTenantAuthority = ({ name, onChange, value, appliances = [] }) => (
  <SelectInput
    name={name}
    label={ROOT_TENANT_AUTHORITY.LABEL}
    defaultOption={ROOT_TENANT_AUTHORITY.DEFAULT_OPTION}
    onChange={onChange}
    value={value}
    options={mapAppliancesForSelect(appliances)}
  />
);

RootTenantAuthority.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** Appliances **/
  appliances: PropTypes.array
};

export default RootTenantAuthority;