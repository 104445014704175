import { request } from 'api';

class NotificationApi {
  static getAll() {
    return request('all-notifications');
  }

  static getById(notificationId) {
    return request('notifications/' + notificationId);
  }

  static getRecent() {
    return request('recent-notifications');
  }

  static setState(notificationIds, tenants, state) {
    if (!Array.isArray(notificationIds)) {
      notificationIds = [notificationIds];
    }

    return request('notifications/state', {
      method: 'post',
      data: {
        notificationIds,
        tenants,
        state
      }
    });
  }
}

export default NotificationApi;
