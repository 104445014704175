import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantEnabledReducer(
  state = initialState.reportsTenantEnabled,
  action
) {
  switch (action.type) {
    case types.LOAD_REPORTS_ENABLED_BEGIN:
      return { ...state, loading: true };

    case types.LOAD_REPORTS_ENABLED_SUCCESS:
      return { ...state, loading: false, error: null, enabled: action.enabled };

    case types.LOAD_REPORTS_ENABLED_ERROR:
      return {
        ...state,
        loading: false,
        error: `An error occurred while loading the reports enabled: ${action.errorMessage}`,
        enabled: null
      };

    case types.LOAD_REPORTS_ENABLED_RESET:
      return {
        ...initialState.reportsTenantEnabled
      };

    default:
      return state;
  }
}
