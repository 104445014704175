import React from "react";
import { ResultsList } from "./ResultsList";
import { QuerySummary } from "./QuerySummary";

export const ResultsSection = () => {
  return (
    <div className="results-section column">
      <QuerySummary />
      <ResultsList />
    </div>
  );
};
