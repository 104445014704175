import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function ticketCategoriesReducer(state = initialState.ticketCategories, action) {
  switch (action.type) {
    case types.LOAD_TICKET_CATEGORIES_BEGIN:
      return Object.assign({}, state, {loading:true, error: null, records: []});
    case types.LOAD_TICKET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        loading:false, 
        error: null, 
        records: action.categories});
    case types.LOAD_TICKET_CATEGORIES_ERROR:
      return Object.assign({}, state, {
        loading:false, 
        error: `An error occurred while loading the available ticket categories: ${action.errorMessage}`, 
        records: []
      });
    default:
      return state;
  }
}
