import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loading } from '@a0/support-center-components';
import { Link } from 'react-router';
import { login } from '../actions/authActions';
import history from '../../../history';

const Callback = ({ actions, auth }) => {
  useEffect(function redirectIfDoneLoading() {
    if (!auth.isLoading && (
      !auth.error || auth.isAuthenticated)) {
      history.replace('/');
    }
  });
  const handleLogin = (evt) => {
    evt.preventDefault();
    actions.login("/");
  };

  const { error } = auth;
  return (
    <div className="bg-dots page-header-dots">
    <div className="container">
      {error
        ? (
          <React.Fragment>
          <h4>Oops! It seems that there has been an error when authenticating</h4>
          <pre>{error}</pre>
          Try <a href="#" onClick={handleLogin}>logging in again</a> or <Link to="/">return to the home page</Link>.
          </React.Fragment>
        ) : <Loading show fullscreen opaque />
      }
    </div>
    </div>
  );
};

Callback.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object
};

const mapStateToProps = state => ({ auth: state.auth });

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ login }, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
