export const tickets = {
  title: 'Tickets',
  loggedOutAction: 'to see your tickets',
  empty: 'You have no new tickets.',
  headerAction: 'View All',
  verifyEmail: 'Verify your email to see your tickets'
};

export const notifications = {
  title: 'Notifications',
  loggedOutAction: 'to see your notifications.',
  empty: 'You have no new notifications.',
  headerAction: 'View All',
  tracking: {
    preview: 'preview:sc:notifications'
  }
};

export const reports = {
  title: 'Reports'
};

export const more = {
  title: 'More'
};