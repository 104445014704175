const CURRENT_TENANT_COOKIE = 'current_tenant';
const AUTH0_SESSION_COOKIE = 'auth0|';

export function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0)==' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length,c.length);
    }
  }
  return null;
}

export function getCurrentTenantCookie() {
  return getCookie(CURRENT_TENANT_COOKIE);
}

export function getAuth0SessionCookie() {
  return getCookie(AUTH0_SESSION_COOKIE);
}
