import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router';
import Metrics from '../../../utils/metrics';
import { Auth0Notification } from '@a0/support-center-components';
import SecureLink from './SecureLink';

const FreeAccountAlert = ({hideOpenTicket}) => {
  const trackEvent = typeOfEvent => () =>
    Metrics.track(`click:sc:${typeOfEvent}`, {trackData: 'free-account-alert'});

  return (
    <Auth0Notification type="primary">
        <span>Support for Free Plan customers is provided through <SecureLink href="https://community.auth0.com" onClick={trackEvent('ask-forum')}>Auth0 Community</SecureLink> which is staffed by the Auth0 team members and enthusiasts, you can post questions and search for answers there.</span>{' '}
        <span>For more information check out our <SecureLink href="https://auth0.com/docs/support" onClick={trackEvent('support-access')}>support policies</SecureLink>.</span>
        <p>To report security vulnerabilities please refer to our <SecureLink href="https://auth0.com/whitehat" onClick={trackEvent('white-hat-program')}>White Hat Program</SecureLink>.</p>
        {!hideOpenTicket && <p>&nbsp;If the issue involves private information you can still <Link to="/tickets/new" onClick={trackEvent('open-ticket')}>open a ticket</Link>.</p>}
    </Auth0Notification>
  );
};

FreeAccountAlert.propTypes = {
  hideOpenTicket: PropTypes.bool
};

export default FreeAccountAlert;
