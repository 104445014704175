import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../common/components/TextInput';
import { TARGET_VERSION } from '../../constants';

/**
 * New Ticket Target Version field component
 */
const TargetVersion = ({ name, onChange, value, error }) => (
  <TextInput
    name={name}
    label={TARGET_VERSION.LABEL}
    onChange={onChange}
    classes="animated fadeIn"
    value={value}
    error={error}
  />
);

TargetVersion.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** The possible error of the field */
  error: PropTypes.string
};

export default TargetVersion;
