import { HarSanitizer } from "./harSanitizer";

export async function cleanupHar(file) {
  const content = await readFileAsync(file);

  let parsedHar;
  try {
    parsedHar = JSON.parse(content);
  } catch (error) {
    error.jsonParseError = true;
    throw error;
  }

  const sanitizedHarObject = await cleanWithHarSanitizer(parsedHar);

  const newFilename = getSanitizedFilename(file.name);

  // the generated .HAR file is stringified with indentations
  // even if it's bigger in size, to aid in legibility
  // Chrome's HAR files are also indented.
  return new File([JSON.stringify(sanitizedHarObject, null, 2)], newFilename, {
    type: "application/json"
  });
}

async function cleanWithHarSanitizer(parsedHar) {
  const hs = new HarSanitizer(parsedHar);
  hs.getTypeNames("cookies");
  hs.getTypeNames("headers");
  hs.getTypeNames("queryString");
  hs.getTypeNames("params");
  hs.getTypeNames("hashParams");
  hs.getMimeTypes();
  hs.harJson = hs.scrubUrlPass();
  hs.harJson = hs.scrubMimeTypes();
  hs.harJson = hs.scrubStackTraces();
  hs.harJson = await hs.redactHar();
  return hs.harJson;
}


function getSanitizedFilename(previousFilename) {
  const filenameParts = previousFilename.split(".");
  const ext = filenameParts.pop();
  return `${filenameParts.join(".")}_sanitized.${ext}`;
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}
