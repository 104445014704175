import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function tenantReducer(state = initialState.tenantSummaries, action) {
  switch (action.type) {
    case types.LOAD_TENANTS_SUMMARY_BEGIN:
      return Object.assign({}, state, { loading: true, error: null });

    case types.LOAD_TENANTS_SUMMARY_SUCCESS: {
      return Object.assign({}, state, { loading: false, error: null, records: { ...action.records }});
    }

    case types.LOAD_TENANTS_SUMMARY_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading your tenants: ${action.errorMessage}`,
        record: {}
      });

    default:
      return state;
  }
}
