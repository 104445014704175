import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';
import { without } from 'lodash';

export default function tenantReducer(state = initialState.tenants, action) {
  switch (action.type) {
    case types.SET_SELECTED_TENANT:
      return Object.assign({}, state, { selected: action.tenant });

    case types.LOAD_TENANTS_BEGIN:
      return Object.assign({}, state, { loading: true, error: null });

    case types.LOAD_TENANTS_SUCCESS:
      return Object.assign({}, state, { loaded: true, loading: false, error: null, records: { ...action.records } });

    case types.LOAD_TENANTS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading your Auth0 tenants: ${action.errorMessage}`
      });

    case types.UPDATE_TENANT_BEGIN: {
      const updating = state.updating.slice();
      updating.push(action.id);
      return Object.assign({}, state, {
        updating,
        error: null
      });
    }

    case types.UPDATE_TENANT_SUCCESS: {
      const updating = without(state.updating, action.id);
      const record   = Object.assign({}, state.records[action.id], action.data);
      const records  = Object.assign({}, state.records, { [action.id]: record });
      return Object.assign({}, state, { records, updating, error: null });
    }

    default:
      return state;
  }
}
