import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as emailVerificationActions from '../actions/emailVerificationActions';
import EmailVerificationAlert from '../components/EmailVerificationAlert';
import UnverifiedEmail from '../components/UnverifiedEmail';
import emailVerifyDialog from '../emailVerifyDialog';
import Metrics from '../../../utils/metrics';
import { isUserEmailVerified } from '../../login/selectors/users';

export class EmailVerificationPanel extends React.Component {

  constructor(props) {
    super(props);
    this.resendEmailClickEvent = this.resendEmailClickEvent.bind(this);
    this.sendEmailAction = this.sendEmailAction.bind(this);
    this.showSendEmailDialog = this.showSendEmailDialog.bind(this);
    this.trackData = this.trackData.bind(this);
    if(this.props.emailVerified === false){
      this.trackData('show:sc:email-unverified');
    }
  }

  getCurrentLocation() {
    const location = window.location.pathname.replace('/', '');
    return !location ? 'homepage' : location;
  }

  resendEmailClickEvent(event) {
    event.preventDefault();
    this.showSendEmailDialog();
  }

  trackData(featureModeEventName) {
    Metrics.track(this.props.featureMode ? featureModeEventName : `${featureModeEventName}-global`, { trackData: this.getCurrentLocation() });
  }

  sendEmailAction() {
    this.trackData('click:sc:send-email-verification');
    window.scroll(0, 0);
    this.props.actions.sendEmailVerification();
  }

  showSendEmailDialog() {
    this.trackData('show:sc:send-email-verification-dialog');
    emailVerifyDialog(this.props.email, this.sendEmailAction);
  }

  render() {
    const { isAuthenticated, email, loading, featureMode, emailVerified, user_id } = this.props;
    if (!isAuthenticated || emailVerified || !user_id || !email) {
      return null;
    }
    return (
      <div>
        {featureMode ?
          <UnverifiedEmail {...this.props}  sendEmailClickHandler={this.resendEmailClickEvent} /> :
          <EmailVerificationAlert {...this.props}  resendEmailHandler={this.resendEmailClickEvent} />}
      </div>
    );
  }
}

EmailVerificationPanel.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user_id: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  emailSent: PropTypes.bool,
  error: PropTypes.string,
  featureMode: PropTypes.bool,
  emailVerified: PropTypes.bool
};

export function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user_id: state.auth.user.user_id,
    email: state.auth.user.email,
    loading: state.emailVerification.loading,
    emailSent: state.emailVerification.emailSent,
    error: state.emailVerification.error,
    emailVerified: isUserEmailVerified(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, emailVerificationActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPanel);
