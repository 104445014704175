import logger from './logger';
import { ABORTED, NETWORK_ERROR } from './logger/logTypes';

// Status codes that should always trigger warnings, not errors
export const WARNING_STATUSES = [401, 404];
export const ERROR_CONNECTION_ABORTED_CODE = 'ECONNABORTED';

/**
 * Helper for resolving log_type tags
 * @param {object} action
 * @returns {string|undefined} logType
 */
export function getLogType(action) {
  if (isAbortedError(action.error)) {
    return ABORTED;
  }
  
  if (isNetworkError(action.error)) {
    return NETWORK_ERROR;
  }

  return action.log_type;
}

/**
 * Check whether this error should be tagged as an aborted request
 * @param {error} error
 * @returns {boolean} 
 */
export function isAbortedError(error) {
  return (
    (error && error.code && error.code === ERROR_CONNECTION_ABORTED_CODE) ||
    false
  );
}


/**
 * Check whether this error was decorated as a network err
 * @param {error} error
 * @returns {boolean} 
 */
export function isNetworkError(error) {
  return !!error.networkError;
}

/**
 * Decide whether an error should be downgraded to a warning
 * @param {object} action
 * @returns {boolean} 
 */
export function downgradeToWarning(action) {
  return action.status && WARNING_STATUSES.includes(action.status) || false;
}

/**
 * Format and log an API response error
 * @param {object} action
 */
export default function logErrorAction(action) {
  const logType = getLogType(action);
  const tags = logType ? { log_type: logType } : {};

  if (downgradeToWarning(action)) {
    return logger.warn(action.error, tags, `${action.type}: ${action.errorMessage}`);
  }

  logger.error(action.error, tags, `${action.type}: ${action.errorMessage}`);
}
