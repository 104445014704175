import api from 'api';
import { loadTenantsForUser, setAccountContext } from '../../tenants/actions/tenantActions';
import { loadTenantAgreementsForUser } from '../../tenants/actions/tenantAgreementsActions';
import { loadMyTickets } from '../../tickets/actions/ticketActions';
import { loadRecentNotifications } from '../../notifications/actions/notificationActions';
import { loadNdaStatus } from '../../compliance/actions';
import * as types from '../../../redux/actionTypes';
import Metrics from '../../../utils/metrics';
import logger from '../../../utils/logger';
import * as drift from '../../../utils/drift';
import { isUserEmailVerified } from '../selectors/users';
import {
  AUTHORIZATION_ERROR,
  UNVERIFIED_EMAIL,
  USER_DATA_ERROR
} from '../../../utils/logger/logTypes';

/**
 * Action responsible for loading all auth and user data into store
 * @returns {Function}
 */
export function loadCredentials() {
  return async function(dispatch, getState) {
    const user = getState().auth.user;
    if (!user || !user.sub) {
      return null;
    }
    const userId = user.sub;

    dispatch(loadTenantsForUser(userId, { expire: true })).then(() => {
      dispatch(loadRecentNotifications());
      dispatch(setAccountContext());
      Metrics.track('success:sc:tenants:load');
    });
    dispatch(loadTenantAgreementsForUser(userId));
    dispatch(loadNdaStatus(userId));
    Metrics.identify(userId, user);
    logger.setUser({
      id: userId,
      username: user.name,
      email: user.email
    });

    if (!user.email) {
      const error = new Error('User Missing Email');
      error.user_id = userId;
      logger.error(error, { log_type: AUTHORIZATION_ERROR }, 'User Missing Email');
    }

    // Load my tickets is performing his own validation for the user.
    // We need to improve this logic in the future.
    dispatch(loadMyTickets());
    if (isUserEmailVerified(getState())) {
      dispatch(loadUserData(user));
    } else {
      logger.warn({ log_type: UNVERIFIED_EMAIL, user_id: userId }, `User has unverified email address`);
    }
  };
}

export function loadUserData(userInfo) {
  return function(dispatch) {
    return api.users
      .getUserData(userInfo.user_id)
      .then(data => {
        drift.setUserAttributes(userInfo, data);
        dispatch(loadUserDataSuccess(data));
      })
      .catch(error => {
        dispatch(loadUserDataError(error));
      });
  };
}

export function loadUserDataSuccess(data) {
  return { type: types.LOAD_USER_DATA_SUCCESS, data };
}

export function loadUserDataError(error) {
  return { type: types.LOAD_USER_DATA_ERROR, error, log_type: USER_DATA_ERROR };
}
