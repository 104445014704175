import React from "react";
import PropTypes from "prop-types";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import { HotjarMasked } from "@a0/support-center-components";

import FileUploads from "../containers/FileUploads";
import Markdown from "../../common/components/Markdown";

const CommentEditor = ({
  name,
  value,
  onChange,
  error,
  uploadedRecordsFromStorage,
  hideUpload
}) => {
  const textareaClass = error ? "form-control has-error" : "form-control";

  return (
    <div className="form-group ticket-editor">
      <div className="tab-content editor-tabs">
        <Tabs defaultActiveKey={1} transition id="uncontrolled-tab-markdown">
          <Tab eventKey={1} title="Description (*)">
            <div id="bodyEdit" role="tabpanel" className="tab-pane">
            <textarea
                className={textareaClass}
                name={name}
                value={value}
                onChange={onChange}
              />
              {!hideUpload && (
                <FileUploads
                  className="comment-editor-file-upload"
                  uploadedRecordsFromStorage={uploadedRecordsFromStorage}
                />
              )}
              {error && (
                <div className="error-field has-error">
                  <div className="help-block">{error}.</div>
                </div>
              )}
              <span className="help-block">
                <Alert variant="warning">
                  Please do not include any text,{!hideUpload && (" attachments, or images")} that may contain sensitive information.
                  {!hideUpload && (
                    <React.Fragment>
                      <br />
                      Uncompressed HAR file attachments are automatically sanitized.{" "}
                      <a href="https://community.auth0.com/t/how-to-sanitize-a-http-trace-file-automatically/120583" target="_blank">Learn what this means and how it works.</a>
                      <br />
                      <i>While Okta will attempt to identify sensitive data for you in HAR files, you acknowledge that
                      it is your responsibility, and not Okta's, to identify sensitive data that you want to be redacted.
                      </i>
                    </React.Fragment>
                  )}
                </Alert>
              </span>
            </div>
          </Tab>
          <Tab eventKey={2} title="Preview">
          <div
              id="bodyPreview"
              role="tabpanel"
              className="tab-pane form-control preview-result"
            >
              <HotjarMasked>
                <Markdown text={value} />
              </HotjarMasked>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

CommentEditor.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  uploadedRecordsFromStorage: PropTypes.array,
  hideUpload: PropTypes.bool
};

export default CommentEditor;
