import * as Sentry from '@sentry/browser'; //comes from @sentry/react
import defaultOptions from './sentry_options';

export function setup(reportingUrl) {
  const sentryOptions = Object.assign({}, defaultOptions, {
    release: window.scConfig.SERVICE_VERSION,
    environment: window.scConfig.SERVICE_ENVIRONMENT || process.env.NODE_ENV
  });

  Sentry
    .init({
      dsn: reportingUrl,
      ...sentryOptions });
}

export function setUser(user) {
  Sentry.setUser(user);
}

export function captureException(ex, context, tags) {
  Sentry.captureException(ex, {
    extra: context,
    tags: tags,
    level: tags.level
  });
}

export function captureMessage(msg, context, tags) {
  Sentry.captureMessage(msg, {
    extra: context,
    tags: tags,
    level: tags.level
  });
}
