import React, { Component } from 'react';
import { IndexLink, Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { capitalize, startCase, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Metrics from '../../../utils/metrics';

import {
  Loading,
  Error,
  ReportDetailsContainer
} from '@a0/support-center-components';

import {
  // Quota Reports
  loadReportsTenantQuota,
  loadReportsTenantQuotaReset,
  paginateQuotaReports,
  filterQuotaReports,

  // Features Reports
  loadReportsTenantFeatures,
  loadReportsTenantFeaturesReset,
  paginateFeaturesReports,
  filterFeaturesReports,

  // Enterprise Connections Reports
  loadReportsTenantEnterpriseConnections,
  loadReportsTenantEnterpriseConnectionsReset,
  paginateEnterpriseConnectionsReports,
  filterEnterpriseConnectionsReports,

  // Reports Enabled
  loadReportsEnabled,
  loadReportsEnabledReset,

  // Appliance Last Updated Usage
  loadApplianceLastUpdateUsage,
  loadApplianceLastUpdateUsageReset,

  // M2M daily report
  loadReportsTenantM2MDaily,
  loadReportsTenantM2MDailyReset,
  paginateReportsTenantM2MDaily,
  filterReportsTenantM2MDaily
} from '../actions/reportActions';

import { contactSales } from '../../sales/actions/contactActions';

import {
  getQuotaReportLoadingStatus,
  getQuotaReportError,
  getQuotaReportData,
  getQuotaReportType,
  getQuotaReportActivePage,
  getQuotaReportFiltering
} from '../selectors/quota';

import {
  getFeaturesReportData,
  getFeaturesReportLoadingStatus,
  getFeaturesReportError,
  getFeaturesReportActivePage,
  getFeaturesReportFiltering
} from '../selectors/features';

import {
  getSelectedTenant,
  hasAdminMasterAccess,
  getMasterRegion
} from '@a0/support-center-common/selectors/common';

import { getSelectedSubscription } from '@a0/support-center-common/selectors/subscriptions';

import { isApplianceTenant, humanizeTenantId } from '@a0/support-center-common/lib/tenants';
import itIsFreeOrTrialOrDeveloper from '../../../utils/itIsFreeOrTrialOrDeveloper';
import onContactSales from '../../../utils/onContactSales';
import itShouldRenderReports from '../../../utils/itShouldRenderReports';

const M2M_GRANULARITY_REPORT_DISABLED =
  (window.scConfig && window.scConfig.M2M_GRANULARITY_REPORT_DISABLED) || false;

const TRACKING_QUOTA_REPORTS = 'click:sc:reports:quota:';

const getQuotaPercentage = (current, quota) => {
  const percentage = Number(current / quota * 100).toFixed(2);
  return current && percentage <= 1 ? 1 : percentage;
};

export class DetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: ''
    };

    this.paginate = this.paginate.bind(this);
    this.filter = this.filter.bind(this);
    this.resetRecords = this.resetRecords.bind(this);
    this.handleClickBackToAllReports = this.handleClickBackToAllReports.bind(
      this
    );
    this.filterM2MDailyReport = this.filterM2MDailyReport.bind(this);
    this.trackM2MDailyClick = this.trackM2MDailyClick.bind(this);
  }

  componentDidMount() {
    const { currentTenant, hasAdminMasterAccess } = this.props;

    if (currentTenant && hasAdminMasterAccess) {
      const tenantId = currentTenant.master_tenant || currentTenant.id;
      this.props.loadReportsTenantQuota(tenantId);
      this.props.loadReportsTenantFeatures(tenantId);
      this.props.loadReportsEnabled(tenantId);

      if (isApplianceTenant(tenantId)) {
        this.props.loadApplianceLastUpdateUsage(tenantId);
      }
    }
  }

  componentWillReceiveProps({
    currentTenant,
    hasAdminMasterAccess,
    quotaReportData,
    featuresReportData,
    params: { reportType },
    location: {
      query: { tenantId: tenantIdFromUrl }
    }
  }) {
    if (currentTenant != this.props.currentTenant && hasAdminMasterAccess) {
      const tenantId = currentTenant.master_tenant || currentTenant.id;

      if (tenantId) {
        this.props.loadReportsTenantQuota(tenantId);
        this.props.loadReportsTenantFeatures(tenantId);
        this.props.loadReportsEnabled(tenantId);

        if (isApplianceTenant(tenantId)) {
          this.props.loadApplianceLastUpdateUsage(tenantId);
        }
      }

      if (tenantId && tenantId !== tenantIdFromUrl) {
        browserHistory.push(`${window.location.pathname}?tenantId=${tenantId}`);
      }
    }

    if (!hasAdminMasterAccess) {
      this.handleClickBackToAllReports();
    }

    if (
      !isEmpty(quotaReportData) &&
      quotaReportData != this.props.quotaReportData
    ) {
      if (
        (reportType === 'regular-active-users' &&
          !this.getReportData(quotaReportData, 'quota', 'regularUsers') &&
          !this.getReportData(quotaReportData, 'total', 'regularUsers')) ||
        (reportType === 'enterprise-active-users' &&
          !this.getReportData(quotaReportData, 'quota', 'enterpriseUsers') &&
          !this.getReportData(quotaReportData, 'total', 'enterpriseUsers')) ||
        (reportType === 'employee-users' &&
          !this.getReportData(quotaReportData, 'quota', 'employeeUsers') &&
          !this.getReportData(quotaReportData, 'total', 'employeeUsers'))
      ) {
        this.handleClickBackToAllReports();
      }
    }

    if (
      !isEmpty(featuresReportData) &&
      featuresReportData != this.props.featuresReportData
    ) {
      if (
        (reportType === 'machine-to-machine-auth' &&
          !this.getReportData(featuresReportData, 'quota', 'apiCalls') &&
          !this.getReportData(featuresReportData, 'total', 'apiCalls')) ||
        (reportType === 'enterprise-mfa' &&
          !this.getReportData(featuresReportData, 'quota', 'mfaUsers') &&
          !this.getReportData(featuresReportData, 'total', 'mfaUsers')) ||
        (reportType === 'pro-mfa' &&
          !this.getReportData(featuresReportData, 'quota', 'mfaDuo') &&
          !this.getReportData(featuresReportData, 'total', 'mfaDuo') &&
          !this.getReportData(featuresReportData, 'quota', 'mfaGoogle') &&
          !this.getReportData(featuresReportData, 'total', 'mfaGoogle'))
      ) {
        this.handleClickBackToAllReports();
      }
    }
  }

  componentWillUnmount() {
    this.resetRecords();
  }

  resetRecords() {
    this.props.loadReportsTenantQuotaReset();
    this.props.loadReportsTenantFeaturesReset();
    this.props.loadReportsEnabledReset();
    this.props.loadApplianceLastUpdateUsageReset();
    this.props.loadReportsTenantM2MDailyReset();
  }

  paginate(reportType, reportSubType, tab) {
    return page => {
      this.props[`paginate${capitalize(reportType)}Reports`](page || 1, reportSubType, tab);
    };
  }

  filter(reportType, reportSubType, tab) {
    return key => {
      return (value, event) => {
        const items = event && event.currentTarget.dataset.items;
        if (items) {
          this.props[`filter${capitalize(reportType)}Reports`](
            items.split(','),
            key,
            reportSubType,
            tab
          );
        } else {
          this.props[`filter${capitalize(reportType)}Reports`](value, key, reportSubType, tab);
        }

        this.paginate('quota', reportSubType, tab)();
      };
    };
  }

  filterM2MDailyReport(key) {
    const {
      filterReportsTenantM2MDaily,
      paginateReportsTenantM2MDaily
    } = this.props;
    return (value, event) => {
      const items = event && event.currentTarget.dataset.items;
      if (items) {
        filterReportsTenantM2MDaily(items.split(','), key);
      } else {
        filterReportsTenantM2MDaily(value, key);
      }

      paginateReportsTenantM2MDaily();
    };
  }

  trackM2MDailyClick() {
    Metrics.track('click:sc:reports:m2m-daily');
  }

  handleClickBackToAllReports(event) {
    if (event) {
      event.preventDefault();
    }

    browserHistory.push('/reports/quota');
  }

  getReportDataActiveUsersDetails(reportData, type = 'cloud') {
    return (
      (reportData &&
        reportData.details &&
        reportData.details[type] &&
        reportData.details[type].activeUsers) ||
      []
    );
  }

  getReportDataEmployeeDetails(reportData, type = 'cloud') {
    return (
      (reportData &&
        reportData.details &&
        reportData.details[type] &&
        reportData.details[type].employeeUsers) ||
      []
    );
  }

  getReportDataFeaturesDetails(reportData, type = 'cloud') {
    return (
      (reportData &&
        reportData.details &&
        reportData.details[type] &&
        reportData.details[type].features) ||
      []
    );
  }

  getReportDataEnterpriseConnectionsDetails(reportData, type = 'cloud') {
    return (
      (reportData &&
        reportData.details &&
        reportData.details[type] &&
        reportData.details[type].connections) ||
      []
    );
  }

  getReportData(reportData, type, keyName) {
    return (
      reportData &&
      reportData.overview &&
      reportData.overview[type] &&
      reportData.overview[type][keyName]
    );
  }

  getUsersPerEnterpriseConnections(enterpriseConnectionsReportData) {
    return (
      enterpriseConnectionsReportData &&
      enterpriseConnectionsReportData.overview &&
      enterpriseConnectionsReportData.overview.quota &&
      enterpriseConnectionsReportData.overview.quota.usersPerConnection
    );
  }

  getIsMixedPlan(reportData) {
    return reportData && reportData.reportType === 'mixedCustomAgreement';
  }

  getMonth(reportData) {
    return reportData && reportData.overview && reportData.overview.month;
  }

  _onDownloadCSVClick(metric_name){
    return () => {
      Metrics.track(metric_name);
    };
  }

  onTabChange(reportType, newTab){
    this.setState({
      currentTab: newTab
    }, () => {
      Metrics.track(`${TRACKING_QUOTA_REPORTS}tab`, {
        trackData: {
          reportType,
          tab: this.state.currentTab
        }
      });
    });
  }

  renderQuotaReportDetails() {
    const {
      params: { reportType },
      currentTenant,
      selectedSubscription,
      contactSales,

      // Quota
      quotaReportData,
      quotaReportType,
      activePageQuotaReport,
      filteringQuotaReport,

      // EnterpriseConnections
      enterpriseConnectionsReportData,
      activePageEnterpriseConnectionsReport,
      filteringEnterpriseConnectionsReport,

      reportsApplianceLastUpdatedUsageRecords
    } = this.props;

    const tenantId = currentTenant.master_tenant || currentTenant.id;

    const additionalProps = {
      onDownloadCSVClick: () => Metrics.track(`${TRACKING_QUOTA_REPORTS}csv-download`, {
        trackData: {
          reportType,
          tab: this.state.currentTab
        }
      }),
      onEntering: (newTab) => {
        this.onTabChange(reportType, newTab);
      }
    };

    if (['regular-active-users', 'enterprise-active-users',
         'external-active-users', 'employee-users'].includes(reportType)) {
      additionalProps.data = quotaReportData;
      additionalProps.filtering = filteringQuotaReport;
      additionalProps.activePage = activePageQuotaReport;
      additionalProps.recordsPath = reportType != 'employee-users' ? 'activeUsers' : 'employeeUsers';
    } else if (reportType === 'enterprise-connections') {
      additionalProps.data = enterpriseConnectionsReportData;
      additionalProps.quantityName=`Connections @ ${Number(
        this.getUsersPerEnterpriseConnections(additionalProps.data) || 0
      ).toLocaleString()} Users Per Connection`;
      additionalProps.filtering = filteringEnterpriseConnectionsReport;
      additionalProps.activePage = activePageEnterpriseConnectionsReport;
      additionalProps.recordsPath = 'connections';
    }

    return (
      <ReportDetailsContainer
        applianceUsageRecords={reportsApplianceLastUpdatedUsageRecords}
        contactSales={onContactSales(contactSales, selectedSubscription,
          {
            place: 'report chart',
            type: 'link',
            text: itIsFreeOrTrialOrDeveloper(selectedSubscription) ? 'upgrade' : 'talk to sales'
          })}
        contactSalesLink={
          itIsFreeOrTrialOrDeveloper(selectedSubscription) ? 'Upgrade' : null
        }
        filter={this.filter('quota', reportType, this.state.currentTab)}
        handleClickBackToAllReports={this.handleClickBackToAllReports}
        paginate={this.paginate('quota', reportType, this.state.currentTab)}
        reportType={reportType}
        tenantId={tenantId}
        isV8Plan={selectedSubscription.id === 'v8'}
        {...additionalProps}
      />);
  }

  renderFeaturesReportDetails() {
    const {
      params: { reportType },
      currentTenant,
      selectedSubscription,
      contactSales,

      // Features
      featuresReportData,
      activePageFeaturesReport,
      filteringFeaturesReport,

      reportsApplianceLastUpdatedUsageRecords,

      // M2M daily report
      loadReportsTenantM2MDaily,
      reportsTenantM2MDailyData,
      paginateReportsTenantM2MDaily,
      reportsTenantM2MDailyMaxLimitReached,
      reportsTenantM2MDailyFiltering,
      reportsTenantM2MDailyActivePage,
      reportsTenantM2MDailyLoading,
      reportsTenantM2MDailyError
    } = this.props;

    const tenantId = currentTenant.master_tenant || currentTenant.id;

    const additionalProps = {};

    if (reportType === 'enterprise-mfa') {
      additionalProps.charts = [
        {
          type: 'quotaMfaUsers',
          quota: this.getReportData(
            featuresReportData,
            'quota',
            'mfaUsers'
          ),
          currentQuota: this.getReportData(
            featuresReportData,
            'total',
            'mfaUsers'
          ),
          currentUsage: {
            public: this.getReportData(featuresReportData, "cloud", "mfaUsers"),
            private: this.getReportData(featuresReportData, "appliance", "mfaUsers")
          },
          percentage: this.getReportData(
            featuresReportData,
            'percentage',
            'mfaUsers'
          ),
          percentageDetails: {
            appliance: isApplianceTenant(tenantId) ?
              getQuotaPercentage(
              this.getReportData(featuresReportData, "appliance", "mfaUsers"),
              this.getReportData(featuresReportData, "quota", "mfaUsers")
            ) : null,
            cloud: getQuotaPercentage(
              this.getReportData(featuresReportData, "cloud", "mfaUsers"),
              this.getReportData(featuresReportData, "quota", "mfaUsers")
            )
          }
        }
      ];

    } else if (reportType === 'pro-mfa') {
      additionalProps.charts = [
        {
          type: 'quotaThirdPartyMfa',
          currentQuota:
            this.getReportData(featuresReportData, 'total', 'mfaDuo') +
            this.getReportData(featuresReportData, 'total', 'mfaGoogle'),
          currentUsage: {
            public:
              this.getReportData(featuresReportData, "cloud", "mfaDuo") +
              this.getReportData(featuresReportData, 'cloud', 'mfaGoogle'),
            private:
              this.getReportData(featuresReportData, "appliance", "mfaDuo") +
              this.getReportData(featuresReportData, 'appliance', 'mfaGoogle')
          },
          isActive:
            this.getReportData(featuresReportData, 'quota', 'mfaDuo') ||
            this.getReportData(featuresReportData, 'quota', 'mfaGoogle')
        }
      ];
    } else if (reportType === 'machine-to-machine-auth') {
      additionalProps.onDownloadCSVClick = this._onDownloadCSVClick('click:sc:reports:csv-download:m2m-daily');
      additionalProps.extendDefaultTabs =
        M2M_GRANULARITY_REPORT_DISABLED
          ? []
          : [
              {
                title: 'Authentication Calls by Application - Daily',
                onEntering: () => {
                  this.trackM2MDailyClick();
                  loadReportsTenantM2MDaily(tenantId);
                },
                paginate: page => paginateReportsTenantM2MDaily(page || 1),
                activePage: reportsTenantM2MDailyActivePage,
                filter: this.filterM2MDailyReport,
                loading: reportsTenantM2MDailyLoading,
                error: reportsTenantM2MDailyError,
                dataLimitExceeded: reportsTenantM2MDailyMaxLimitReached,
                filtering: reportsTenantM2MDailyFiltering,
                csvFilename: 'nonInteractiveApiByApplicationDaily',
                csvDescription:
                  'This table shows daily Authentication Calls for each application for the past 7 days.',
                csvColumns: {
                  day: 'Day',
                  tenant: 'Tenant',
                  client_name: 'Application',
                  api_calls: 'Authentication Calls'
                },
                records: reportsTenantM2MDailyData,
                headers: [
                  {
                    title: 'Day'
                  },
                  {
                    title: 'Tenant',
                    keyName: 'tenant',
                    isFilter: true
                  },
                  {
                    title: 'Application',
                    keyName: 'client_name',
                    isFilter: true
                  },
                  {
                    title: 'Authentication Calls'
                  }
                ],
                columns: [
                  {
                    keyName: 'day'
                  },
                  {
                    keyName: 'tenant',
                    isTooltip: true
                  },
                  {
                    keyName: 'client_name',
                    isTooltip: true
                  },
                  {
                    keyName: 'api_calls'
                  }
                ]
              }
            ];
      additionalProps.charts = [
        {
          type: 'quotaNonInteractiveApi',
          quota: this.getReportData(
            featuresReportData,
            'quota',
            'apiCalls'
          ),
          currentQuota: this.getReportData(
            featuresReportData,
            'total',
            'apiCalls'
          ),
          currentUsage: {
            public: this.getReportData(featuresReportData, "cloud", "apiCalls"),
            private: this.getReportData(featuresReportData, "appliance", "apiCalls")
          },
          percentage: this.getReportData(
            featuresReportData,
            'percentage',
            'apiCalls'
          ),
          percentageDetails: {
            appliance: isApplianceTenant(tenantId) ?
              getQuotaPercentage(
              this.getReportData(featuresReportData, "appliance", "apiCalls"),
              this.getReportData(featuresReportData, "quota", "apiCalls")
              ): null,
            cloud: getQuotaPercentage(
              this.getReportData(featuresReportData, "cloud", "apiCalls"),
              this.getReportData(featuresReportData, "quota", "apiCalls")
            )
          }
        }
      ];
    }

    return (
      <ReportDetailsContainer
        activePage={activePageFeaturesReport}
        applianceUsageRecords={reportsApplianceLastUpdatedUsageRecords}
        contactSales={onContactSales(contactSales, selectedSubscription,
          {
            place: 'report chart',
            type: 'link',
            text: itIsFreeOrTrialOrDeveloper(selectedSubscription) ? 'upgrade' : 'talk to sales'
          })}
        contactSalesLink={
          itIsFreeOrTrialOrDeveloper(selectedSubscription) ? 'Upgrade' : null
        }
        data={featuresReportData}
        filter={this.filter('features')}
        filtering={filteringFeaturesReport}
        handleClickBackToAllReports={this.handleClickBackToAllReports}
        paginate={this.paginate('features')}
        recordsPath="features"
        reportType={reportType}
        tenantId={tenantId}
        isV8Plan={selectedSubscription.id === 'v8'}
        {...additionalProps}
      />
    );
  }

  formatReportBreadcumTitle(reportType) {
    return startCase(reportType)
      .replace(/Non Interactive/, 'Non-Interactive')
      .replace(/Mfa/, 'MFA')
      .replace(/Api/, 'API');
  }

  checkIfReportTypeUserConsumption() {
    const {
      params: { reportType }
    } = this.props;

    return (
      reportType === 'regular-active-users' ||
      reportType === 'enterprise-active-users' ||
      reportType === 'employee-users' ||
      reportType === 'enterprise-connections' ||
      reportType === 'external-active-users'
    );
  }

  render() {
    const {
      params: { reportType },
      currentTenant,
      hasAdminMasterAccess,
      selectedSubscription,

      // Quota
      quotaReportLoading,
      quotaReportError,

      // Features
      featuresReportLoading,
      featuresReportError,

      reportsTenantEnabled,
      reportsTenantEnabledLoading,
      reportsTenantEnabledError
    } = this.props;

    const tenantId = currentTenant.master_tenant || currentTenant.id;

    return (
      <div className="reports" style={{ paddingBottom: '40px' }}>
        <div className="bg-dots page-header-dots">
          <div className="container">
            <ol className="breadcrumb">
              <li>
                <IndexLink to="/">Home</IndexLink>
              </li>
              <li>
                <Link to="/reports/quota">Quota Utilization</Link>
              </li>
              <li>{this.formatReportBreadcumTitle(reportType)}</li>
            </ol>
          </div>
        </div>
        <div className="container">
          <Loading show={!tenantId}>
            {hasAdminMasterAccess ? (
              <Loading
                show={
                  quotaReportLoading ||
                  featuresReportLoading ||
                  reportsTenantEnabledLoading
                }
              >
                <Error
                  message={
                    quotaReportError ||
                    featuresReportError ||
                    reportsTenantEnabledError
                  }
                >
                  {itShouldRenderReports(
                    selectedSubscription,
                    reportsTenantEnabled
                  ) ? (
                    this.checkIfReportTypeUserConsumption() ? (
                      this.renderQuotaReportDetails()
                    ) : (
                      this.renderFeaturesReportDetails()
                    )
                  ) : (
                    <div className="auth0-notification atention alert alert-warning">
                      <div
                        style={{ marginBottom: '0' }}
                        className="alert alert-global alert-info"
                      >
                        We are busy improving our quota reports for you. They'll
                        be available again soon.
                      </div>
                    </div>
                  )}
                </Error>
              </Loading>
            ) : (
              <div className="auth0-notification atention alert alert-warning">
                <div className="alert alert-global alert-warning">
                  <i className="icon-budicon-354" /> Sorry! Only dashboard
                  admins of the main production tenant{' '}
                  <b>{humanizeTenantId(tenantId)}</b> have access to this
                  report. However, you may view the{' '}
                  <Link to="/reports/usage">usage reports</Link>.
                </div>
              </div>
            )}
          </Loading>
        </div>
      </div>
    );
  }
}

DetailsPage.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  hasAdminMasterAccess: PropTypes.bool.isRequired,
  currentTenant: PropTypes.object,
  contactSales: PropTypes.func.isRequired,
  selectedSubscription: PropTypes.object,

  // Features
  loadReportsTenantFeatures: PropTypes.func.isRequired,
  loadReportsTenantFeaturesReset: PropTypes.func.isRequired,
  featuresReportData: PropTypes.object.isRequired,
  featuresReportLoading: PropTypes.bool.isRequired,
  featuresReportError: PropTypes.string.isRequired,
  activePageFeaturesReport: PropTypes.number.isRequired,
  filteringFeaturesReport: PropTypes.object.isRequired,

  // Quota
  loadReportsTenantQuota: PropTypes.func.isRequired,
  loadReportsTenantQuotaReset: PropTypes.func.isRequired,
  quotaReportData: PropTypes.object.isRequired,
  quotaReportType: PropTypes.string.isRequired,
  quotaReportLoading: PropTypes.bool.isRequired,
  quotaReportError: PropTypes.string.isRequired,
  activePageQuotaReport: PropTypes.number.isRequired,
  filteringQuotaReport: PropTypes.object.isRequired,

  // Enterprise Connections
  loadReportsTenantEnterpriseConnections: PropTypes.func.isRequired,
  loadReportsTenantEnterpriseConnectionsReset: PropTypes.func.isRequired,
  enterpriseConnectionsReportData: PropTypes.object.isRequired,
  enterpriseConnectionsReportType: PropTypes.string.isRequired,
  enterpriseConnectionsReportLoading: PropTypes.bool.isRequired,
  enterpriseConnectionsReportError: PropTypes.string.isRequired,
  activePageEnterpriseConnectionsReport: PropTypes.number.isRequired,
  filteringEnterpriseConnectionsReport: PropTypes.object.isRequired,

  loadReportsEnabled: PropTypes.func,
  loadReportsEnabledReset: PropTypes.func,

  reportsTenantEnabled: PropTypes.bool,
  reportsTenantEnabledLoading: PropTypes.bool,
  reportsTenantEnabledError: PropTypes.string,

  loadApplianceLastUpdateUsage: PropTypes.func.isRequired,
  loadApplianceLastUpdateUsageReset: PropTypes.func.isRequired,

  reportsApplianceLastUpdatedUsageRecords: PropTypes.array,

  // M2M daily report
  loadReportsTenantM2MDaily: PropTypes.func.isRequired,
  loadReportsTenantM2MDailyReset: PropTypes.func.isRequired,
  reportsTenantM2MDailyData: PropTypes.array,
  paginateReportsTenantM2MDaily: PropTypes.func.isRequired,
  filterReportsTenantM2MDaily: PropTypes.func.isRequired,
  reportsTenantM2MDailyMaxLimitReached: PropTypes.bool,
  reportsTenantM2MDailyFiltering: PropTypes.object,
  reportsTenantM2MDailyActivePage: PropTypes.number,
  reportsTenantM2MDailyLoading: PropTypes.bool,
  reportsTenantM2MDailyError: PropTypes.string
};

DetailsPage.defaultProps = {
  currentTenant: {}
};

const mapStateToProps = state => {
  const {
    reportsTenantEnabled: {
      loading: reportsTenantEnabledLoading,
      error: reportsTenantEnabledError,
      enabled: reportsTenantEnabled
    },
    reportsApplianceLastUpdatedUsage: {
      loading: reportsApplianceLastUpdatedUsageLoading,
      error: reportsApplianceLastUpdatedUsageError,
      records: reportsApplianceLastUpdatedUsageRecords
    },
    reportsTenantM2MDaily: {
      record: reportsTenantM2MDailyData,
      loading: reportsTenantM2MDailyLoading,
      error: reportsTenantM2MDailyError,
      page: reportsTenantM2MDailyActivePage,
      filtering: reportsTenantM2MDailyFiltering,
      maxLimitReached: reportsTenantM2MDailyMaxLimitReached
    }
  } = state;

  return {
    reportsTenantEnabledLoading,
    reportsTenantEnabledError,
    reportsTenantEnabled,

    // Appliance Last Updated Usage
    reportsApplianceLastUpdatedUsageLoading,
    reportsApplianceLastUpdatedUsageError,
    reportsApplianceLastUpdatedUsageRecords,

    currentTenant: getSelectedTenant(state.tenants),
    selectedSubscription: getSelectedSubscription(state),

    // Quota
    quotaReportData: getQuotaReportData(state),
    quotaReportType: getQuotaReportType(state),
    quotaReportLoading: getQuotaReportLoadingStatus(state),
    quotaReportError: getQuotaReportError(state),
    activePageQuotaReport: getQuotaReportActivePage(state),
    filteringQuotaReport: getQuotaReportFiltering(state),

    // Features
    featuresReportData: getFeaturesReportData(state),
    featuresReportLoading: getFeaturesReportLoadingStatus(state),
    featuresReportError: getFeaturesReportError(state),
    activePageFeaturesReport: getFeaturesReportActivePage(state),
    filteringFeaturesReport: getFeaturesReportFiltering(state),

    // Enterprise connections
    enterpriseConnectionsReportData:
      state.reportsTenantEnterpriseConnections.record,
    hasAdminMasterAccess: hasAdminMasterAccess(state),

    // M2M daily report
    reportsTenantM2MDailyData,
    reportsTenantM2MDailyActivePage,
    reportsTenantM2MDailyFiltering,
    reportsTenantM2MDailyMaxLimitReached,
    reportsTenantM2MDailyLoading,
    reportsTenantM2MDailyError
   };
};

const mapDispatchToProps = {
  contactSales,

  loadReportsEnabled,
  loadReportsEnabledReset,

  // Quota
  loadReportsTenantQuota,
  loadReportsTenantQuotaReset,
  paginateQuotaReports,
  filterQuotaReports,

  // Features
  loadReportsTenantFeatures,
  loadReportsTenantFeaturesReset,
  paginateFeaturesReports,
  filterFeaturesReports,

  // Enterprise Connections
  loadReportsTenantEnterpriseConnections,
  loadReportsTenantEnterpriseConnectionsReset,
  paginateEnterpriseConnectionsReports,
  filterEnterpriseConnectionsReports,

  // Appliance Last Updated Usage
  loadApplianceLastUpdateUsage,
  loadApplianceLastUpdateUsageReset,

  // M2M daily report
  loadReportsTenantM2MDaily,
  loadReportsTenantM2MDailyReset,
  paginateReportsTenantM2MDaily,
  filterReportsTenantM2MDaily
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsPage);
