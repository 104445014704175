export default function getTicketTooltipMessage(status) {
  switch (status) {
    case "new":
      return "The request was received and will be assigned to an agent soon.";
    case "open":
    case "in progress":
      return "An agent is working to resolve the request.";
    case "with sustainment":
      return "Sustainment engineering is working on your case.  Updates on case will be provided by Support.";
    case "pending":
      return "The support agent needs more information about the issue.";
    case "solved":
    case "resolved":
      return "The issue has been solved. Add a comment to reopen it.";
    case "hold":
      return "A response is needed from a third-party, not the requester or the assignee.";
    case "customer hold":
      return "No response is required.  Case is placed on hold until the requested customer date/time.";
    case "closed":
      return "The ticket is complete and can't be reopened.";
    case "closed - no response":
      return "Ticket closed due to inactivity, but can be reopened.";
    default:
      return status;
  }
}
