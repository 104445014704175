import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { HotjarMasked } from "@a0/support-center-components";
import { getTicketStatusClasses } from "../utils/ticketStatus";
import getTicketTooltipMessage from "../utils/getTicketTooltipMessage";

const TicketListRow = ({ ticket }) => {
  return (
    <tr>
      <td>#{ticket.previousId ? ticket.previousId : ticket.id}</td>
      <td className="text-truncate">
        <HotjarMasked isInline>
          <Link to={"/tickets/" + ticket.id}>{ticket.subject}</Link>
        </HotjarMasked>
      </td>
      <td className="text-wrap hide-for-mobiles">
        <HotjarMasked isInline>{ticket.tenant || ""}</HotjarMasked>
      </td>
      <td className="text-truncate hide-for-mobiles">
        <HotjarMasked isInline>{ticket.requester_name}</HotjarMasked>
      </td>
      <td>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip" className="right">
              {getTicketTooltipMessage(ticket.status)}
            </Tooltip>
          }
        >
          <span className={getTicketStatusClasses(ticket.status)}>
            {ticket.status === "pending" ? "pending-customer" : ticket.status}
          </span>
        </OverlayTrigger>
      </td>
      <td className="hide-for-mobiles">
        {moment(ticket.updated_at).fromNow()}
      </td>
    </tr>
  );
};

TicketListRow.propTypes = {
  ticket: PropTypes.object.isRequired
};

export default TicketListRow;
