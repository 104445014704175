import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IndexLink, browserHistory } from 'react-router';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import TicketsPanel from '../components/TicketsPanel';
import MissingEmailWarning from '../components/MissingEmailWarning';
import AccountPicker from '../../common/components/AccountPicker';
import { AdminRestricted } from '../../common/components/AdminRestricted';
import FreeAccountAlert from '../../common/components/FreeAccountAlert';
import TrialAccountAlert from '../../common/components/TrialAccountAlert';
import SwitchSubscriptionAlert from '../../common/components/SwitchSubscriptionAlert';
import {
  getAdminOrSupportTenants,
  getElevatedSupportTenants
} from '../utils/tenantFilters';
import { LeastPrivilegedAccessAlert } from '../../common/components/LeastPrivilegedAccessAlert';


import * as ticketActions from '../actions/ticketActions';
import * as tenantActions from '../../tenants/actions/tenantActions';

import * as contactActions from '../../sales/actions/contactActions';
import EmailVerificationPanel from '../../emailVerification/containers/EmailVerificationPanel';

import {
  filterRecordsByPage,
  getSelectedTenant,
  filterTenantsBySubscription
} from '@a0/support-center-common/selectors/common';

import {
  getSelectedSubscriptionName,
  getSelectedSubscriptionType,
  getCustomSubscriptions,
  getTrialSubscriptions,
  allowedToOpenTicket
} from '@a0/support-center-common/selectors/subscriptions';

import { getUser, isUserEmailVerified } from '../../login/selectors/users';

import Metrics from '../../../utils/metrics';
import { canOpenTicket } from '../../../utils/ticketFilters';
import { ContactButtons } from '../../common/components/ContactButtons';

export class TicketsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = ({ activeTab: 'myTickets' });
    this.selectTenant = this.selectTenant.bind(this);
    this.handlePageSelect = this.handlePageSelect.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onContactSales = this.onContactSales.bind(this);
  }

  componentDidMount() {
    this.loadOrgTickets(this.props.currentTenant);
  }

  componentDidUpdate({ currentTenant }) {
    const previousTenant = currentTenant;
    if (
      this.props.emailVerified && ((this.props.currentTenant &&
        previousTenant &&
        this.props.currentTenant.id != previousTenant.id) ||
      //the call to loadOrgTickets in componentDidMount doesn't work sometimes because this.props.currentTenant is not there yet
      //by reacting to a change of currentTenant from undefined to new value here, we load tickets on that case as well
      (this.props.currentTenant && this.props.currentTenant.id && !previousTenant))
    ) {
      this.loadOrgTickets(this.props.currentTenant);
    }
  }

  loadOrgTickets(tenant) {
    if(this.props.emailVerified && tenant) {
      this.props.actions.loadOrgTickets(tenant);
    }
  }

  selectTenant(event) {
    this.props.actions.setSelectedTenant(event.target.value);
  }

  handlePageSelect(pageNumber) {
      return () => {
        browserHistory.push({ ...location, search: `?${this.state.activeTab}=${pageNumber}` });
        document
          .getElementById('tickets-tab')
          .scrollIntoView({ behavior: 'smooth' });
        };
  }

  handleTabChange(key) {
    browserHistory.push({ ...location, search: `?${key}` });
    this.setState({ activeTab: key });
  }

  onOpenTicket(e) {
    Metrics.track(`click:sc:open-ticket`, { trackData: 'tickets-page' });
  }

  onAskForum(e) {
    Metrics.track(`click:sc:ask-forum`, { trackData: 'tickets-page' });
  }

  onContactSales(e) {
    e.preventDefault();
    this.props.actions.contactSales('tickets-page', { place: 'page introduction', type: 'button', text: 'talk to sales' });
  }

  renderAlerts() {
    const {
      loading,
      customSubscription,
      selectedSubscriptionType,
      selectedTenant,
      isPaying,
      trialSubscriptions,
      allowedToOpenTicket,
      emailVerified,
      tenants
    } = this.props;

    const subscription = customSubscription || 'Developer';
    if (!loading && selectedSubscriptionType === 'Trial' && emailVerified && canOpenTicket(selectedTenant, tenants)) {
      return (
        <div>
          <TrialAccountAlert
            subscription={selectedTenant && selectedTenant.subscription}
            multiple={trialSubscriptions && trialSubscriptions.length > 1}
          />
          {isPaying && <SwitchSubscriptionAlert subscription={subscription} />}
        </div>
      );
    }

    if (!loading && !allowedToOpenTicket && emailVerified) {
      return (
        <div>
          <FreeAccountAlert hideOpenTicket />
          {isPaying && <SwitchSubscriptionAlert subscription={subscription} />}
        </div>
      );
    }
  }


  renderTickets() {
    const {
      flags,
      currentTenant,
      selectedSubscriptionName,
      accessibleTenants,
      user,
      loading,
      error,
      myTickets,
      orgTickets,
      myTicketsLoading,
      myTicketsError,
      orgTicketsLoading,
      orgTicketsError,
      emailVerified
    } = this.props;

    if (!loading && (user && !user.email)) {
      return <MissingEmailWarning />;
    }

    return (
      <Tabs transition id="tickets-tab" onSelect={this.handleTabChange}>
        <Tab eventKey={'myTickets'} title="My Tickets">
          <div className="gray-box">
            {emailVerified || <EmailVerificationPanel featureMode />}
            {emailVerified &&
            <TicketsPanel
              tickets={myTickets.recordsByPage}
              activePage={myTickets.activePage}
              pageCount={myTickets.pageCount}
              pageSelect={this.handlePageSelect}
              loading={myTicketsLoading}
              error={myTicketsError}
            />}
          </div>
        </Tab>
        <Tab eventKey={'orgTickets'} title="Subscription Tickets">
          {flags && flags.elevated_support_access_role && !flags.support_access_role_deprecation && <LeastPrivilegedAccessAlert />}
          {emailVerified || <div className="gray-box"><EmailVerificationPanel featureMode /></div>}
          {emailVerified && <AdminRestricted tenants={accessibleTenants} loading={loading} error={error}>
            <form className="gray-box form-inline">
              {accessibleTenants.length != 1 && currentTenant && (
                <AccountPicker
                  tenants={accessibleTenants}
                  currentTenant={currentTenant}
                  onChange={this.selectTenant}
                  selectedSubscriptionName={selectedSubscriptionName}
                />
              )}
              <TicketsPanel
                tickets={orgTickets.recordsByPage}
                activePage={orgTickets.activePage}
                pageCount={orgTickets.pageCount}
                pageSelect={this.handlePageSelect}
                loading={orgTicketsLoading}
                error={orgTicketsError}
              />
            </form>
          </AdminRestricted>}
        </Tab>
      </Tabs>
    );
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="tickets">
        <div className="bg-dots page-header-dots">
          <div className="container">
            <ol className="breadcrumb">
              <li>
                <IndexLink to="/">Home</IndexLink>
              </li>
              <li>Tickets</li>
            </ol>
            <h1>Support Tickets</h1>
            <ContactButtons pageName="tickets-page" />
            {this.renderAlerts()}
          </div>
        </div>
        <div className="container">{this.renderTickets()}</div>
      </div>
    );
  }
}

TicketsPage.propTypes = {
  flags: PropTypes.object,
  myTickets: PropTypes.object.isRequired,
  orgTickets: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allowedToOpenTicket: PropTypes.bool.isRequired,
  accessibleTenants: PropTypes.array,
  currentTenant: PropTypes.object,
  tenants: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  myTicketsLoading: PropTypes.bool,
  myTicketsError: PropTypes.string,
  orgTicketsLoading: PropTypes.bool,
  orgTicketsError: PropTypes.string,
  selectedSubscriptionName: PropTypes.string,
  selectedSubscriptionType: PropTypes.string,
  selectedTenant: PropTypes.object,
  isPaying: PropTypes.bool,
  customSubscription: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  trialSubscriptions: PropTypes.array,
  user: PropTypes.object,
  emailVerified: PropTypes.bool
};

TicketsPage.defaultProps = {
  allowedToOpenTicket: true,
  myTicketsLoading: false,
  orgTicketsLoading: false
};

function mapStateToProps(state, ownProps) {
  const customSubscription = getCustomSubscriptions(state)[0];
  const tenants = filterTenantsBySubscription(state);
  const currentTenant = getSelectedTenant(state.tenants);
  const adminOrSupportTenants = getAdminOrSupportTenants(tenants);
  const elevatedSupportTenants = getElevatedSupportTenants(tenants);
  const flags = window.scConfig.flags;
  const elevatedSupportAccessEnforced = flags && flags.elevated_support_access_role && flags.support_access_role_deprecation;
  const accessibleTenants = elevatedSupportAccessEnforced ? elevatedSupportTenants : adminOrSupportTenants;

  return {
    flags: flags,
    isPaying: state.auth && state.auth.data && state.auth.data.paying,
    location: ownProps.location,
    allowedToOpenTicket: allowedToOpenTicket(state, getSelectedTenant(state.tenants)),
    myTickets: filterRecordsByPage(
      state.myTickets.records,
      ownProps.location.query.myTickets || 1
    ),
    orgTickets: filterRecordsByPage(
      state.orgTickets.records,
      ownProps.location.query.orgTickets || 1
    ),
    tenants: tenants,
    accessibleTenants: accessibleTenants,
    currentTenant: accessibleTenants.includes(currentTenant) ? currentTenant : accessibleTenants[0],
    loading: state.tenants.loading,
    error: state.tenants.error,
    myTicketsLoading: state.myTickets.loading,
    myTicketsError: state.myTickets.error,
    orgTicketsLoading: state.orgTickets.loading,
    orgTicketsError: state.orgTickets.error,
    selectedSubscriptionName: getSelectedSubscriptionName(state),
    selectedSubscriptionType: getSelectedSubscriptionType(state),
    selectedTenant: state.tenants.records[state.tenants.selected],
    customSubscription: customSubscription && customSubscription.name,
    trialSubscriptions: getTrialSubscriptions(state),
    user: getUser(state),
    emailVerified: isUserEmailVerified(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, ticketActions, tenantActions, contactActions),
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsPage);
