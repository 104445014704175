import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function reportsTenantQuotaReducer(
  state = initialState.reportsApplianceLastUpdatedUsage,
  action
) {
  switch (action.type) {
    case types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_BEGIN:
      return Object.assign({}, initialState.reportsTenantQuota, {
        loading: true
      });

    case types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        records: action.records
      });

    case types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading appliance last updated usage: ${action.errorMessage}`,
        records: []
      });

    case types.LOAD_APPLIANCE_LAST_UPDATED_USAGE_RESET:
      return { ...initialState.reportsApplianceLastUpdatedUsage };

    default:
      return state;
  }
}
