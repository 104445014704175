import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import SelectInput from './SelectInput';
import {tenantsFormattedForDropdown} from '@a0/support-center-common/selectors/common';

const returnPlural = name => {
  return (name === 'Developer' || name === 'Trial' || name === 'Free') ? '(s)' : '';
};

const AccountPicker = ({tenants, currentTenant, onChange, defaultOption, selectedSubscriptionName}) => {
  return (
    currentTenant && <div className="account-picker">
      <SelectInput
        name="tenants"
        label="Tenants:"
        value={currentTenant && currentTenant.id || ''}
        options={tenantsFormattedForDropdown(tenants)}
        onChange={onChange}
        defaultOption={defaultOption}
        disabled={tenants.length < 2}
        optionsGroupLabel={selectedSubscriptionName && `${startCase(selectedSubscriptionName)} Subscription${returnPlural(selectedSubscriptionName)}`}
        hotjarMasked/>
    </div>
  );
};

AccountPicker.propTypes = {
  tenants: PropTypes.array.isRequired,
  currentTenant: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  selectedSubscriptionName: PropTypes.string
};

export default AccountPicker;
