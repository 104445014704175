import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Navbar.module.css';

/**
 * Represents a single item in the Navbar, with or without a dropdown
 * chevron (reveal icon) and notch (the tip of the dropdown overlay) 
 * 
 * This needs to be a class component due to react-bootstrap internals
 */
export class NavbarItem extends Component {
  render() {
    const { children, dropdown, onClick, className, active } = this.props;
    return (
      <div styleName="navbar-item" className={className} onClick={onClick}>
        <span styleName={active ? 'navbar-item-active' : ''}>{children}</span>
        {dropdown && <i styleName="navbar-item-reveal" className="icon-budicon-460"/>}
        {dropdown && <span styleName="navbar-item-notch"/>}
      </div>
    );
  }
}

NavbarItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  onClick: PropTypes.func,
  dropdown: PropTypes.bool,
  className: PropTypes.string,
  active: PropTypes.bool
};

export default NavbarItem;