import getErrorMessage from '../../utils/getErrorMessage';
import getErrorStatus from '../../utils/getErrorStatus';
import logErrorAction from '../../utils/logErrorAction';

export default function normalizeErrorMiddleware() {
  return () => {
    return next => action => {
      if (action && action.type.endsWith('_ERROR') && action.error) {
        action.errorMessage = getErrorMessage(action.error);
        action.status = getErrorStatus(action.error);
        logErrorAction(action);
      }
      next(action);
    };
  };
}
