import React from 'react';
import PropTypes from 'prop-types';
import Metrics from '../../../utils/metrics';

const iconSecurity = require('../../../img/icon-security-blue.svg');
const iconApi = require('../../../img/icon-api-blue.svg');
const iconSDK = require('../../../img/icon-sdk-blue.svg');
const iconUsers = require('../../../img/icon-users-blue.svg');
const iconAuthC = require('../../../img/icon-authorization-C-blue.svg');
const iconInteg = require('../../../img/icon-integrations-marketplace-blue.svg');


const DocCard = ({ card }) => {

  const trackClick = (title, url) => (e) => {
    Metrics.track('click:sc:doc-card', { trackData: title, value: url }, () => window.location = url);
    e.preventDefault();
  };

  const categoryIcon = {
    gettingStarted: <img src={iconSecurity} className="card-docs-icon" />,
    apis: <img src={iconApi} className="card-docs-icon" />,
    sdks: <img src={iconSDK} className="card-docs-icon" />,
    userProfiles : <img src={iconUsers} className="card-docs-icon" />,
    idProv: <img src={iconAuthC} className="card-docs-icon" />,
    extensibility: <img src={iconInteg} className="card-docs-icon" />
  }[card.icon];

  return (
    <div className="col-xs-12 col-sm-6 col-md-4">
      <article className="card-docs">
        {categoryIcon}
        <h2 className="card-docs-title">{card.title}</h2>
        <p className="card-docs-description">{card.description}</p>
        <div className="card-docs-links">
          {card.links.map((link, index) =>
            <a key={'cardLink' + index} className="card-docs-link" href={link.href} onClick={trackClick(link.title, link.href)}>{link.title}</a>
          )}
        </div>
        <a className="card-docs-more" href={card.href}>See more at Auth0 docs</a>
      </article>
    </div>
  );
};

DocCard.propTypes = {
  card: PropTypes.object.isRequired
};

export default DocCard;
