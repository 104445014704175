import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { buildSearchBox, buildStandaloneSearchBox } from "@coveo/headless";
import { SearchInput } from "../SearchInput";

import { useCoveoController } from "./utils";
import { storeRetrieveStandaloneSearchBoxData } from "./standaloneSearchBoxRedirectStorage";

const sharedSearchboxOptions = {
  numberOfSuggestions: 0
};

export const SearchBox = ({ redirectionUrl }) => {
  const buildController = (headlessEngine) =>
    redirectionUrl
      ? buildStandaloneSearchBox(headlessEngine, {
          options: {
            ...sharedSearchboxOptions,
            redirectionUrl
          }
        })
      : buildSearchBox(headlessEngine, {
          options: sharedSearchboxOptions
        });

  const { state, controller } = useCoveoController(buildController, "SearchBox");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.value.trim()) {
      controller.submit();
    }
  };

  const handleChange = (value) => {
    controller.updateText(value);
  };

  const handleClear = () => {
    controller.clear();
  };

  if (state.redirectTo) {
    const { redirectTo, value, analytics } = state;
    const data = { value, analytics };
    storeRetrieveStandaloneSearchBoxData(data);
    controller.afterRedirection();
    browserHistory.push(redirectTo + "?q=" + encodeURIComponent(value));
    return null;
  }

  return (
    <div className="search-box-container">
      <SearchInput
        value={state.value}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onClear={handleClear}
      />
    </div>
  );
};

SearchBox.propTypes = {
  redirectionUrl: PropTypes.string
};
