import { filter, includes, groupBy, map, reduce, flatten } from 'lodash';
import { getSelectedSubscriptionType }  from '@a0/support-center-common/selectors/subscriptions';
import { filterTenantIdsBySubscription } from '@a0/support-center-common/selectors/common';
import { getAllAssociatedTenantsForSelectedTenant }  from '../../tenants/selectors/tenants';


function getTenantIdsForUsage(state) {
  let tenantIds = [];

  if (getSelectedSubscriptionType(state) === "CustomAgreement") {
    if (
      state.tenantAgreements &&
      state.tenantAgreements.selected &&
      state.tenantAgreements.selected.indexOf("@pi") > 0 &&
      state.appliances &&
      state.appliances.records &&
      state.appliances.records.length > 0
    ) {
      const masterTenantId = state.tenantAgreements.selected;
      const mappedTenants = state.appliances.records
        .filter((psaas) => {
          return psaas.pi_tenant === masterTenantId;
        })
        .map((psaas) =>
          psaas.tenants.map(
            (t) => `${t.appliance_tenant}@${psaas.dev_staging_prod}`
          )
        );
      tenantIds = flatten(mappedTenants);
    }

    tenantIds.push(...filterTenantIdsBySubscription(state));
  } else {
    tenantIds = getAllAssociatedTenantsForSelectedTenant(state).map(
      (t) => t.id
    );
  }
  return tenantIds;
}

export const usagePerSubscription = state => {
  const tenantIds = getTenantIdsForUsage(state);

  return filter(state.reportsTenantActiveUsers.records, record => includes(tenantIds, record.tenant));
};

export const usageByClientPerSubscription = state => usagePerSubscription(state);

export const usageByServicePerSubscription = state => usageByTenantMonth(usagePerSubscription(state));

export const usageByMonthPerSubscription = state => usageByMonth(usagePerSubscription(state));

export const usageTotalsPerSubscription = state => {
  const tenantIds = getTenantIdsForUsage(state);

  return filter(state.reportsTenantTotalUsers.records, record => includes(tenantIds, record.tenant));
};

export const usageByMonth = records => {
  const months = groupBy(records, 'month');

  return map(months, (month, monthName) => {
    return reduce(month, (prev, next) => {
      return Object.assign(prev, {
        users_social: +prev.users_social + +next.users_social,
        users_password: +prev.users_password + +next.users_password,
        users_passwordless: +prev.users_passwordless + +next.users_passwordless,
        users_enterprise: +prev.users_enterprise + +next.users_enterprise,
        active_users: +prev.active_users + +next.active_users
      });
    }, {
      month: monthName,
      users_social: 0,
      users_password: 0,
      users_passwordless: 0,
      users_enterprise: 0,
      active_users: 0
    });
  });
};

export const usageByTenantMonth = records => {
  // TODO: sort by month
  const tenants = groupBy(records, 'tenant');

  return flatten(map(tenants, (tenant, tenantName) => {
    const months = groupBy(tenant, 'month');

    return map(months, (month, monthName) => {
      return reduce(month, (prev, next) => {
        return Object.assign(prev, {
          users_social: +prev.users_social + +next.users_social,
          users_password: +prev.users_password + +next.users_password,
          users_passwordless: +prev.users_passwordless + +next.users_passwordless,
          users_enterprise: +prev.users_enterprise + +next.users_enterprise,
          active_users: +prev.active_users + +next.active_users
        });
      }, {
        month: monthName,
        tenant: tenantName,
        users_social: 0,
        users_password: 0,
        users_passwordless: 0,
        users_enterprise: 0,
        active_users: 0
      });
    });
  }));
};
