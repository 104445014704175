import React from "react";
import { Facet } from "./Facet";

export const FacetSection = () => {
  return (
    <div className="facet-section column">
      <Facet field="source" title="Source" />
    </div>
  );
};
