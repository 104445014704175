import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../../common/components/Loading';
import { Error } from  '@a0/support-center-components';
import Pagination from '../../common/components/Pagination';

import TicketList from './TicketList';

const TicketsPanel = ({tickets, activePage, pageCount, pageSelect, loading, error}) => {
  return (
    <div className="tickets-panel">
      <Loading show={loading}>
        <Error message={error}>
          {tickets && tickets.length > 0 ?
            <Pagination {...{activePage, pageCount, pageSelect}}>
              <TicketList tickets={tickets}/>
            </Pagination>
          :
            <div className="empty-tickets">
              <p>There are no tickets.</p>
            </div>
          }
        </Error>
      </Loading>
    </div>
  );
};

TicketsPanel.propTypes = {
  tickets: PropTypes.array.isRequired,
  activePage: PropTypes.string,
  pageCount: PropTypes.number,
  pageSelect: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

TicketsPanel.defaultProps = {
  tickets: [],
  loading: false
};

export default TicketsPanel;
