import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ticketActions from '../../tickets/actions/ticketActions';
import * as tenantActions from '../../tenants/actions/tenantActions';
import * as contactActions from '../../sales/actions/contactActions';
import { FreeAccountContact } from '../../common/containers/FreeAccountContact';
import TrialAccountContact from '../../common/components/TrialAccountContact';
import { canOpenTicket } from '../../../utils/ticketFilters';
import { getSelectedSubscriptionType } from '@a0/support-center-common/selectors/subscriptions';
import { isUserEmailVerified } from '../../login/selectors/users';
import { filterTenantsBySubscription, getSelectedTenant } from '@a0/support-center-common/selectors/common';
import Metrics from '../../../utils/metrics';

const ContactButtons = ({ actions, loading, selectedSubscriptionType, emailVerified, currentTenant, tenants, pageName }) => {

  function trackEvent(event, next) {
    let eventName = 'unknown';
    if (event && event.target && event.target.id) {
      eventName = event.target.id;
    }
    Metrics.track(`click:sc:${eventName}`, { trackData: pageName });
  }

  function onContactSales(e) {
    e.preventDefault();
    actions.contactSales(pageName, { place: 'page introduction', type: 'button', text: 'talk to sales' });
  }

  const allowedToOpenTicket = canOpenTicket(currentTenant, tenants);


  if (!loading && selectedSubscriptionType === 'Free') {
    return <FreeAccountContact trackData={pageName} />;
  } else if (!loading && selectedSubscriptionType === 'Trial') {
    return (
      <TrialAccountContact
        handleContactSales={onContactSales}
        handleAskForum={trackEvent}
        handleOpenTicket={trackEvent}
        emailVerified={emailVerified}
        floating={false}
        allowedToOpenTicket={allowedToOpenTicket}
      />
    );
  }

  return (
    <div className={pageName === 'tickets-page' ? 'free-account-contact' : ''}>
      {allowedToOpenTicket ?
        <Link
          id="open-ticket"
          onClick={trackEvent}
          to="/tickets/new"
          className="btn btn-transparent btn-white btn-lg"
        >
          Open Ticket
        </Link>
        : ""}
      <a
        id="ask-forum"
        href="https://community.auth0.com"
        target="_blank"
        onClick={trackEvent}
        className="btn btn-lg btn-success"
      >
        Ask our Community
      </a>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    selectedSubscriptionType: getSelectedSubscriptionType(state),
    emailVerified: isUserEmailVerified(state),
    currentTenant: getSelectedTenant(state.tenants),
    tenants: filterTenantsBySubscription(state),
    loading: state.tenants.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, ticketActions, tenantActions, contactActions),
      dispatch
    )
  };
}

ContactButtons.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  selectedSubscriptionType: PropTypes.string,
  emailVerified: PropTypes.bool,
  currentTenant: PropTypes.object,
  tenants: PropTypes.array,
  pageName: PropTypes.string,
  still: PropTypes.bool
};

ContactButtons.defaultProps = {
  still: false
};


const ConnectedContactButtons = connect(mapStateToProps, mapDispatchToProps)(ContactButtons);
export { ConnectedContactButtons as ContactButtons };
