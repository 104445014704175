import React from "react";
import PropTypes from "prop-types";
import { RecommendedArticle } from "./RecommendedArticle";

export const RecommendationList = ({ recommendations, headlessEngine }) => {
  return (
    <div className="recommendations-list overflow-auto">
      <ul>
        {recommendations.map((recommendation) => (
          <li key={recommendation.uniqueId}>
            <RecommendedArticle recommendation={recommendation} headlessEngine={headlessEngine} />
          </li>
        ))}
      </ul>
    </div>
  );
};

RecommendationList.propTypes = {
  recommendations: PropTypes.array.isRequired,
  headlessEngine: PropTypes.object.isRequired
};
