import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';

const PageWrap = ({ children, canvas }) => (
  <Container>
    <Row>
      <Col className="col-xs-12" xs={12}>
        <div className={classNames({ 'page-canvas': canvas })}>
          {children}
        </div>
      </Col>
    </Row>
  </Container>
);

PageWrap.propTypes = {
  canvas: PropTypes.bool,
  children: PropTypes.array.isRequired
};

export default PageWrap;
