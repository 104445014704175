import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/components/SelectInput';
import { APPLIANCE_TENANT } from '../../constants';
import { find } from 'lodash';

const APPLIANCE_TENANT_KEY = 'appliance_tenant';

export function formatApplianceTenantForSelect(applianceTenant) {
  return {
    value: applianceTenant[APPLIANCE_TENANT_KEY],
    text: applianceTenant[APPLIANCE_TENANT_KEY]
  };
}

/**
 * New Ticket Appliance tenant
 */
const ApplianceTenant = ({ name, onChange, value, appliances = [], rootTenantAuthority }) => {
  const appliance = find(appliances, { root_domain_auth: rootTenantAuthority });
  const applianceTenants = (appliance && appliance.tenants) || [];
  const applianceTenantOptions = applianceTenants.map(formatApplianceTenantForSelect);

  return (
    <SelectInput
      classes="animated fadeIn"
      label={APPLIANCE_TENANT.LABEL}
      defaultOption={APPLIANCE_TENANT.DEFAULT_OPTION}
      name={name}
      onChange={onChange}
      value={value}
      options={applianceTenantOptions}
      hotjarMasked
    />
  );
};

ApplianceTenant.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** Array of appliances in the current subscription, from redux if loaded */
  appliances: PropTypes.array,
  /** The root tenant authority selected in the form by the user */
  rootTenantAuthority: PropTypes.string
};

export default ApplianceTenant;