const ADMIN_TENANT_ROLE = 'admin';
const SUPPORT_ACCESS_ROLE = 'support-access';
const ELEVATED_SUPPORT_ACCESS_ROLE = 'elevated-support-access';
const GUEST_TENANT_ROLE = 'guest';

export function getAdminOrSupportTenants(tenants) {
  const elevatedSupportAccessEnabled = window.scConfig.flags && window.scConfig.flags.elevated_support_access_role;
  return tenants.filter(({ role, roles }) => {
    if (ADMIN_TENANT_ROLE === role) {
        return true;
    }
    if (roles) {
        return (elevatedSupportAccessEnabled && roles.includes(ELEVATED_SUPPORT_ACCESS_ROLE)) || roles.includes(SUPPORT_ACCESS_ROLE);
    }
  });
}

export function getElevatedSupportTenants(tenants) {
  return tenants.filter(({ roles }) => roles && roles.includes(ELEVATED_SUPPORT_ACCESS_ROLE));
}

// filters out all tenants where user is the legacy Support Center User
export function getNonGuestTenants(tenants) {
    return tenants.filter(({ role }) => role !== GUEST_TENANT_ROLE);
}
