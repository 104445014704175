import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdditionalFieldWrapper from '../AdditionalFieldWrapper';
import TextInput from '../../../common/components/TextInput';

import constants from '@a0/support-center-common/constants/tickets/followUpQuestions';

/**
 * New Ticket Additional field component for follow-up questions
 * 'Additional' fields AKA follow up questions are simple string
 *    values that end up appended to the ticket comment.
 * Each 'customerReason' value has zero or more follow up questions.
 */
export class AdditionalQuestions extends Component {
  constructor() {
    super();

    this.state = {
      displayFields: true
    };

    this.handleDisplayFieldsChange = this.handleDisplayFieldsChange.bind(this);
    this.getAdditionalFields = this.getAdditionalFields.bind(this);
  }

 /**
  * Event handler to toggle the display of additional fields
  * @param {Object} event
  */
  handleDisplayFieldsChange(event) {
    this.setState({
      ...this.state,
      displayFields: event.target.value
    });
  }

 /**
  * Get additional fields (follow up questions) by provided reason value
  * @param   {String} customerReason
  * @returns {Array}  fields
  */
  getAdditionalFields(customerReason) {
    const questions = constants.FOLLOW_UP_QUESTIONS_BY_CUSTOMER_REASON[customerReason] || [];
    return Object.keys(questions).map(key => (
      <TextInput
        key={key}
        name={key}
        label={questions[key]}
        value={this.props.value[key] || ''}
        onChange={this.props.onChange}
        classes="animated fadeIn"
      />
    ));
  }

  /**
   * Get filtered additional fields according to current customerReason
   * If none are applicable return null otherwise render them inside a wrapper
   */
  render() {
    const { customerReason } = this.props;
    const additionalFields = this.getAdditionalFields(customerReason);

    if (!additionalFields.length) {
      return null;
    }

    return (
      <AdditionalFieldWrapper
        onChange={this.handleDisplayFieldsChange}
        showAddFields={this.state.displayFields}>
          {additionalFields}
      </AdditionalFieldWrapper>
    );
  }
}

AdditionalQuestions.defaultProps = {
  value: {}
};

AdditionalQuestions.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.object.isRequired,
  /** The customer provided reason field */
  customerReason: PropTypes.string.isRequired
};

export default AdditionalQuestions;
