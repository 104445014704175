import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { buildInteractiveResult } from "@coveo/headless/recommendation";
import { sanitizeUrl } from "@braintree/sanitize-url";

export const RecommendedArticle = ({ recommendation, headlessEngine }) => {
  // The InteractiveResult is only documented for search, but it's also available for recommendations
  // https://docs.coveo.com/en/headless/latest/reference/search/controllers/interactive-result/
  // An interactive results handles the analytics of users selecting a result, and prevents sending duplicates
  const controller = buildInteractiveResult(headlessEngine, {
    options: { result: recommendation }
  });

  // this effect just returns a clean-up function
  // to cancel a pending select if one was initiated
  useEffect(() => () => controller.cancelPendingSelect());

  return (
    <article>
      <h5>
        <a
          href={sanitizeUrl(recommendation.clickUri)}
          onClick={controller.select}
          onContextMenu={controller.select}
          onMouseDown={controller.select}
          onMouseUp={controller.select}
          onTouchStart={controller.beginDelayedSelect}
          onTouchEnd={controller.cancelPendingSelect}
          rel="external noopener"
          target="_blank"
          tabIndex={100}
        >
          <cite>
            <span className={`source ${recommendation.raw.source.toLowerCase()}`}>
              {recommendation.raw.source}
            </span>
            {recommendation.title}
          </cite>
        </a>
      </h5>
      <p>{recommendation.excerpt}</p>
    </article>
  );
};

RecommendedArticle.propTypes = {
  recommendation: PropTypes.object,
  headlessEngine: PropTypes.object
};
