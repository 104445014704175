import { buildUrlManager } from "@coveo/headless";
import { browserHistory } from "react-router";

/**
 * Create a UrlManager controller, subscribing to changes
 * to keep the URL updated, and watches for navigation to
 * notify the UrlManager and synchronize with the query engine.
 * Returns a function to unsubscribe. Should be used as the return value in useEffect()
   useEffect(() => {
    const unbindUrlManager = bindUrlManager(headlessEngine);
    executeInitialSearch();
    return unbindUrlManager;
  }, [headlessEngine]);
 *
 * Search parameters, defined in the url's hash, should not be restored until all components are registered.
 *
 * Additionally, a search should not be executed until search parameters are restored.
 *
 * @param engine - A headless search engine instance.
 * @returns An unsubscribe function to remove attached event listeners.
 */
export function bindUrlManager(engine) {
  const fragment = () => window.location.search.slice(1);
  const boundPath = window.location.pathname;
  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: fragment() }
  });

  const onLocationChange = (location) => {
    // browseHistory.listen will trigger when navigating outside of the page
    // before unmounting the component and unbounding the urlManager
    // so we only trigger the location change if the pathname
    // is were the bound was initiated
    if (location.pathname === boundPath) {
      urlManager.synchronize(fragment());
    }
  };

  const unlistenHistory = browserHistory.listen(onLocationChange);

  const unsubscribeManager = urlManager.subscribe(() => {
    const search = `?${urlManager.state.fragment}`;
    const newLocation = window.location.pathname + search;
    browserHistory.push(newLocation);
  });

  return () => {
    unlistenHistory();
    unsubscribeManager();
  };
}
