import { request } from 'api';

export default {
  requestAllDocumentMeta() {
    return request('compliance-documents');
  },
  requestSignedDownloadUrl(key) {
    return request(`compliance-documents/sign-download?key=${key}`);
  },
  requestNdaStatus(userId) {
    return request(`users/${encodeURIComponent(userId)}/nda-status`);
  }
};
