import api from 'api';
import * as types from '../../../redux/actionTypes';
import { getUser, isUserEmailVerified } from '../../login/selectors/users';
import { clearTicketStateFromStorage } from '../utils/session';
import { SUPPORT_TICKET_ERROR, INVALID_TICKET_ID_ERROR } from '../../../utils/logger/logTypes';
import { browserHistory } from 'react-router';

/** Load My Tickets **/
export function loadMyTicketsBegin() {
  return { type: types.LOAD_MY_TICKETS_BEGIN};
}

export function loadMyTicketsSuccess(tickets) {
  return { type: types.LOAD_MY_TICKETS_SUCCESS, tickets};
}

export function loadMyTicketsError(error) {
  return { type: types.LOAD_MY_TICKETS_ERROR, error, log_type: SUPPORT_TICKET_ERROR };
}

export function loadMyTickets() {
  return function(dispatch, getState) {
    const user = getUser(getState());
    if (!user || !user.email || !isUserEmailVerified(getState())) {
      return;
    }

    dispatch(loadMyTicketsBegin());
    return api.tickets.getMine().then(tickets => {
      dispatch(loadMyTicketsSuccess(tickets));
    }).catch(error => {
      dispatch(loadMyTicketsError(error));
    });
  };
}


/** Load Org Tickets **/

export function loadOrgTicketsBegin() {
  return { type: types.LOAD_ORG_TICKETS_BEGIN};
}

export function loadOrgTicketsSuccess(tickets) {
  return { type: types.LOAD_ORG_TICKETS_SUCCESS, tickets};
}

export function loadOrgTicketsError(error) {
  return { type: types.LOAD_ORG_TICKETS_ERROR, error, log_type: SUPPORT_TICKET_ERROR };
}

export function loadOrgTickets(tenant) {
  return function(dispatch, getState) {
    if (!tenant){
      return;
    }

    const user = getUser(getState());
    if (!user || !user.email) {
      return;
    }

    dispatch(loadOrgTicketsBegin());
    return api.tickets.getByTenant(tenant.name,tenant.region).then(tickets => {
      dispatch(loadOrgTicketsSuccess(tickets));
    }).catch(error => {
      dispatch(loadOrgTicketsError(error));
    });
  };
}
/** Load Ticket **/

export function loadTicketBegin() {
  return {type: types.LOAD_TICKET_BEGIN};
}

export function loadTicketSuccess(ticket) {
  return {type: types.LOAD_TICKET_SUCCESS, ticket};
}

export function loadInvalidTicketError(error) {
  return {
    type: types.LOAD_TICKET_ERROR,
    error,
    log_type: INVALID_TICKET_ID_ERROR
  };
}

/**
 * Action creator for dispatching error loading a single ticket

 * @param {Error} error
 */
export function loadTicketError(error) {
  const action = {
    type: types.LOAD_TICKET_ERROR,
    error,
    unauthorizedObjectId: null,
    log_type: SUPPORT_TICKET_ERROR
  };

  // Handles a special case where the SF CaseId is passed back with the error
  // This is because we need it to create the SF URL for a ticket in the
  // event that the current user is an 'agent' (we use the SF CaseNumber for IDs in SC)
  if (error.status === 401 && error.data && error.data.message) {
    action.unauthorizedObjectId = error.data.message;
  }

  return action;
}

export function loadTicket(ticketId) {
  return function(dispatch) {
    dispatch(loadTicketBegin());
    return api.tickets.getById(ticketId).then(ticket => {
      // If there's a discrepancy in the ID's, update the url
      // with the one provided by the API
      if (ticket.id && ticket.id !== ticketId) {
        const location = browserHistory.getCurrentLocation();
        location.pathname = `/tickets/${ticket.id}`;
        browserHistory.replace(location);
      }

      dispatch(loadTicketSuccess(ticket));
    }).catch(error => {
      dispatch(loadTicketError(error));
    });
  };
}

/** Comment on Ticket **/

export function commentTicketSuccess(ticket) {
  return {type: types.COMMENT_TICKET_SUCCESS, ticket};
}

export function commentTicketError(error) {
  return {type: types.COMMENT_TICKET_ERROR, error, log_type: SUPPORT_TICKET_ERROR };
}

export function commentTicket(ticketId, comment) {
  return function(dispatch) {
    return api.tickets.submitComment(ticketId, comment).then(ticket => {
      dispatch(commentTicketSuccess(ticket));
      clearTicketStateFromStorage();
      return ticket;
    }).catch(error => {
      dispatch(commentTicketError(error));
      throw(error);
    });
  };
}

/** Load Ticket Categories **/
export function loadTicketCategoriesBegin() {
  return {type: types.LOAD_TICKET_CATEGORIES_BEGIN};
}

export function loadTicketCategoriesSuccess(categories) {
  return {type: types.LOAD_TICKET_CATEGORIES_SUCCESS, categories};
}

export function loadTicketCategoriesError(error) {
  return {type: types.LOAD_TICKET_CATEGORIES_ERROR, error};
}

export function loadTicketCategories() {
  return function(dispatch) {
    dispatch(loadTicketCategoriesBegin());
    return api.tickets.getCategoryField().then(categories => {
      dispatch(loadTicketCategoriesSuccess(categories));
    }).catch(error => {
      dispatch(loadTicketCategoriesError(error));
    });
  };
}

/** Create new Ticket **/

export function createTicketSuccess(ticket) {
  return {type: types.CREATE_TICKET_SUCCESS, ticket};
}

export function createTicketError(error) {
  return { type: types.CREATE_TICKET_ERROR, error, log_type: SUPPORT_TICKET_ERROR };
}

export function createTicket(ticket) {
  return function(dispatch) {
    return api.tickets.create(ticket).then(ticket => {
      dispatch(createTicketSuccess(ticket));
      clearTicketStateFromStorage();
      return ticket.request;
    }).catch(error => {
      dispatch(createTicketError(error));
      throw(error);
    });
  };
}

export function createOfflineTicket(ticket) {
  return function(dispatch) {
    return api.tickets.createOffline(ticket).then(ticket => {
      dispatch(createTicketSuccess(ticket));
      clearTicketStateFromStorage();
      return ticket.request;
    }).catch(error => {
      dispatch(createTicketError(error));
      throw(error);
    });
  };
}
