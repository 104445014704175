import { safeSessionStorage as storage } from "../../../../services/storage";

const standaloneSearchBoxStorageKey = "standaloneSearchBoxData";

export function storeRetrieveStandaloneSearchBoxData(data) {
  storage.setItem(standaloneSearchBoxStorageKey, JSON.stringify(data));
}

export function retrieveStandaloneSearchBoxData() {
  return JSON.parse(storage.getItem(standaloneSearchBoxStorageKey));
}

export function clearStandaloneSearchBoxData() {
  storage.removeItem(standaloneSearchBoxStorageKey);
}
