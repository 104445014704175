import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function tenantAgreementsReducer(state = initialState.tenantAgreements, action) {
  switch (action.type) {
    case types.SET_SELECTED_AGREEMENT:
    return Object.assign({}, state, { selected: action.tenant });
    case types.LOAD_TENANT_AGREEMENTS_BEGIN:
      return Object.assign({}, state, { loading:true, error: null });
    case types.LOAD_TENANT_AGREEMENTS_SUCCESS:
      return Object.assign({}, state, { records: {...action.records}, loading: false, error: null});
    case types.LOAD_TENANT_AGREEMENTS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading your agreements: ${action.errorMessage}`
      });
    default:
      return state;
  }
}
