import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Metrics from '../../../../utils/metrics';
import Dropdown from './Dropdown';
import { Link, browserHistory } from 'react-router';
import { reports as constants } from '../../constants';
import './Dropdown.module.css';
import { ArrowRight } from 'react-feather';

/**
 * Dropdown for 'Reports' object in the Navbar
 * @param {object} params 
 */
const DropdownReports = ({ show, active }) => (
  <Dropdown title={constants.title} show={show} active={active}>
    <div styleName="dropdown-header">
      <strong>{constants.title}</strong>
    </div>
    <li styleName="dropdown-item">
      <div styleName="reports-link">
        <Link to={`/reports/quota`}>Quota Utilization</Link>
      </div>
    </li>
    <li styleName="dropdown-item">
      <div styleName="reports-link">
        <Link to={`/reports/usage`}>Usage</Link>
      </div>
    </li>
  </Dropdown>
);

DropdownReports.propTypes = {
  show: PropTypes.bool,
  active: PropTypes.bool
};

export default DropdownReports;