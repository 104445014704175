import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";
import { useCoveoController } from "./utils";
import { buildPager } from "@coveo/headless";

export const Pager = () => {
  const { state, controller } = useCoveoController(
    (headlessEngine) => buildPager(headlessEngine),
    "Pager"
  );

  const handlePageClick = (page) => () => {
    controller.selectPage(page);
  };
  const { currentPage, maxPage, currentPages = [] } = state;
  if (currentPages.length === 0) {
    return null;
  }
  const showFirstPage = !currentPages.includes(1);
  const showLastPage = !currentPages.includes(maxPage);
  const showInitialEllipsis = showFirstPage && currentPages[0] !== 2;
  const showFinalEllipsis = showLastPage && currentPages[currentPages.length - 1] !== maxPage - 1;

  return (
    <React.Fragment>
      <Pagination bsPrefix="auth0-pagination">
        {showFirstPage && <Pagination.First onClick={handlePageClick(1)}>1</Pagination.First>}
        {showInitialEllipsis && <Pagination.Ellipsis disabled />}
        {currentPages.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            onClick={handlePageClick(pageNumber)}
            active={currentPage === pageNumber}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
        {showFinalEllipsis && <Pagination.Ellipsis disabled />}
        {showLastPage && <Pagination.First onClick={handlePageClick(maxPage)}>{maxPage}</Pagination.First>}
      </Pagination>
    </React.Fragment>
  );
};

Pager.propTypes = {
  controller: PropTypes.object
};
