import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import FreeAccountAlert from '../../../common/components/FreeAccountAlert';
import TrialAccountAlert from '../../../common/components/TrialAccountAlert';
import SwitchSubscriptionAlert from '../../../common/components/SwitchSubscriptionAlert';
import { shouldShowTrialAccountAlert } from '../../../../utils/ticketFilters';

const FREE_SUBSCRIPTION = 'Free';
const TRIAL_SUBSCRIPTION = 'Trial';
const DEVELOPER_SUBSCRIPTION = 'Developer';

const SubscriptionAlerts = ({
  isUserPaying,
  selectedTenant,
  subscriptionName,
  customSubscriptions,
  trialSubscriptions,
  emailVerified
}) => {
  if(!emailVerified){
    return null;
  }
  const multipleTrialSubscriptions = get(trialSubscriptions, 'length') > 1;
  const selectedTenantSubscription = get(selectedTenant, 'subscription');
  const customSubscription = customSubscriptions && customSubscriptions[0];
  const payingSubscriptionName =
    isUserPaying && get(customSubscription, 'name', DEVELOPER_SUBSCRIPTION);

  if (
    subscriptionName == FREE_SUBSCRIPTION ||
    subscriptionName == TRIAL_SUBSCRIPTION
  ) {
    return (
      <Fragment>
        {subscriptionName == FREE_SUBSCRIPTION ? (
          <FreeAccountAlert hideOpenTicket />
        ) : (shouldShowTrialAccountAlert(selectedTenant)) ? (
          <TrialAccountAlert
            subscription={selectedTenantSubscription}
              multiple={multipleTrialSubscriptions}
          />
        ): ""}
        {payingSubscriptionName && (
          <SwitchSubscriptionAlert subscription={payingSubscriptionName} />
        )}
      </Fragment>
    );
  }

  return null;
};

SubscriptionAlerts.propTypes = {
  // Define if a user is paying or not
  isUserPaying: PropTypes.bool,
  /** The current subscription context from redux, if set */
  selectedSubscription: PropTypes.object,
  // The name of the current subscription, if set */
  subscriptionName: PropTypes.string,
  // The trial subscriptions of the user, if exists
  trialSubscriptions: PropTypes.array,
  // The trial custom subscriptions of the user, if exists
  customSubscriptions: PropTypes.array,
  // The current tenant context from redux, if set
  selectedTenant: PropTypes.object,
  // For security reasons we are not showing data for unverified emails (so far, only for auth0 db connection)
  // Please don't confuse the email_verified property coming from User info with this property.
  // This is coming from this selector /login/selectors/user/isUserEmailVerified
  emailVerified: PropTypes.bool
};

export default SubscriptionAlerts;
