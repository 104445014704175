import { confirm } from '../../utils/confirm';
import { noop } from 'lodash';

export default function emailVerifyDialog(email, sendEmailClickHandler = noop, sendEmailCancelClickHandler = noop) {
  confirm(`We will send you a verification email to ${email}. When you receive the email, follow the instructions to verify your address.`, {
    title: 'Send verification email',
    okLabel: 'Send email',
    enableEscape: true,
    showOkButtonAtEnd: true,
    okStyle: "btn-success",
    cancelStyle: "btn-default"
  }).then(sendEmailClickHandler, sendEmailCancelClickHandler);
}
