/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { HotjarMasked } from '@a0/support-center-components';

import TicketCommentAttachments from './TicketCommentAttachments';
import Markdown from '../../common/components/Markdown';

const TicketComment = ({ comment }) => {
  return (
    <div className="comment">
      <div className="row">
        <div className="col-md-1">
          <img className="avatar" src={comment.author_picture} />
        </div>
        <div className="col-md-11">
          {comment.author_agent && <div className="comment-auth0-badge" />}
          <h5 className="comment-author"><HotjarMasked>{comment.author_name}</HotjarMasked></h5>
          <span className="comment-date">{comment.created_at}</span>
          <p>
            <HotjarMasked>
              <Markdown text={comment.body} />
            </HotjarMasked>
          </p>
          {comment.attachments &&
            comment.attachments.length > 0 && (
              <TicketCommentAttachments attachments={comment.attachments} />
            )}
        </div>
      </div>
    </div>
  );
};

TicketComment.propTypes = {
  comment: PropTypes.object.isRequired
};

export default TicketComment;
