/*eslint-disable react/no-danger, react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import logger from '../../../utils/logger';
import LoadHljsModule from '../chunks/Hljs';
import { sanitizeHTML } from '../../../utils/sanitizeHTML';

const MD_CONFIG = {
  html: true,
  breaks: true
};

class Markdown extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.defaultFormatter = new MarkdownIt(MD_CONFIG);
  }

  componentDidMount() {
    LoadHljsModule()
      .then(hljs => {
        const highlight = (str, lang) => {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return `<pre class="hljs"><code>${hljs.highlight(lang, str, true)
                .value}</code></pre>`;
            } catch (e) {
              logger.error(
                e,
                `Error highlighting code in MarkdownIt. Lang: ${lang}. Str: ${str}`
              );
            }
          }

          return `<pre class="hljs"><code>${this.state.formatter.utils.escapeHtml(
            str
          )}</code></pre>`;
        };

        const formatter = new MarkdownIt({ ...MD_CONFIG, highlight });
        this.setState({ formatter });
      })
      .catch(e => {
        logger.error(
          e,
          `Error loading HighlightJS falling back to MarkdownIt.`
        );
      });
  }

  renderHtml(text) {
    let html;
    if (!text) {
      html = '';
    } else if (this.state.formatter) {
      html = this.state.formatter.render(text);
    } else {
      html = this.defaultFormatter.render(text);
    }

    return sanitizeHTML.process(html);
  }
  render() {
    const { text } = this.props;

    return <div dangerouslySetInnerHTML={{ __html: this.renderHtml(text) }} />;
  }
}

Markdown.propTypes = {
  text: PropTypes.string
};

export default Markdown;
