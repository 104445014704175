import React from 'react';
import PropTypes from 'prop-types';

const MissingEmailWarning = () => {

  return (
    <div className="alert alert-warning">
      Your account does not appear to have an associated email address.  In order to open a support ticket we require the account have an email address.
      Please check with your <a href="https://auth0.com/docs/identityproviders" target="_blank">Identity Provider</a> that your account has an email associated with it.
      After configuring an email under your Identity Provider's account, logout of Auth0 and log back in. If you are unable to configure your account,
      please reach out to the <a target="_blank" href="https://community.auth0.com">Auth0 Community</a>, which is staffed by the Auth0 team members and enthusiasts.
    </div>
  );
};

export default MissingEmailWarning;
