import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.AUTHENTICATION_STATUS_CHANGED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: action.user ? {...action.user, user_id: action.user.sub } : {},
        isLoading: action.isLoading,
        error: action.error && action.error.message,
        initialCheckPerformed: action.isAuthenticated || state.initialCheckPerformed
      };

    case types.LOAD_USER_DATA_SUCCESS:
      return { ...state, data: action.data };

    case types.LOAD_TENANTS_IDS_FOR_USER:
      return { ...state, tenantIds: action.tenants };

    case types.LOAD_TENANT_AGREEMENTS_IDS_FOR_USER:
      return { ...state, tenantAgreementIds: action.custom_agreements };

    case types.SESSION_CHECK_BEGIN:
      return { ...state};

    case types.SESSION_CHECK_SUCCESS:
      return { ...state, initialCheckPerformed: true };

    case types.SESSION_CHECK_FAILED:
      return { ...state, initialCheckError: action.error, initialCheckPerformed: true };

    case types.LOAD_AUTHENTICATION_STATUS:
      return { ...state, status: action.status };

    case types.LOAD_AUTHENTICATION_STATUS_ERROR:
      return { ...state, error: `An error occurred while authenticating: ${action.errorMessage}` };

    default:
      return state;
  }
}
