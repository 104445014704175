/*eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import Dropdown from 'react-bootstrap/Dropdown';
import cn from 'classnames';
import moment from 'moment';
import { HotjarMasked } from '@a0/support-center-components';
import { notifications as constants } from '../../constants';

import NavbarItem from './NavbarItem';
import Metrics from '../../../../utils/metrics';
import Markdown from '../../../common/components/Markdown';

import './Dropdown.module.css';
import { ArrowRight, Bell } from 'react-feather';

const notificationsIcon = require('../../../../img/notifications-icon.svg');

/**
 * Dropdown for 'Notifications' object in the Navbar
 */
export class DropdownNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: props.show || false
    };

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.createHandler = this.createHandler;
    this.navigateTo = this.navigateTo.bind(this);
    this.markUnreadAsRead = this.markUnreadAsRead.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderDropdownContent = this.renderDropdownContent.bind(this);
  }

  toggleDropdown(setting) {
    const showDropdown = typeof (setting) === 'boolean' ? setting : !this.state.showDropdown;
    this.setState({ showDropdown });

    if (showDropdown) {
      this.markUnreadAsRead();
    }
  }

  markUnreadAsRead() {
    const { createReadNotificationHandler, notifications } = this.props;

    const trackData = notifications
      .filter(n => n.state === 'unread')
      .map(n => {
        createReadNotificationHandler(n._id)();
        return n._id;
      })
      .join(',');

    Metrics.track(constants.tracking.preview, { trackData });
  }

  openNotification() {
    this.closeDropdown();
  }

  openDropdown() {
    this.toggleDropdown(true);
  }

  closeDropdown() {
    this.toggleDropdown(false);
  }

  navigateTo(url) {
    return () => browserHistory.push(url);
  }

  createHandler(notificationId) {
    const { createClickNotificationHandler } = this.props;
    return (e) => {
      createClickNotificationHandler(notificationId)(e);
      this.toggleDropdown();
    };
  }

  renderIcon() {
    const {
      notifications,
      loading
    } = this.props;

    const displayBadge = !loading &&
      notifications.filter(n => n.state === 'unread').length > 0;

    return (
      <span styleName="notifications-alert">
        {displayBadge && <span styleName="notifications-alert-badge" />}
        <img
          styleName="notifications-alert-icon"
          src={notificationsIcon}
          alt="Notifications icon"
        />
      </span>
    );
  }

  renderDropdownContent() {
    const {
      isAuthenticated
    } = this.props;

    if (!isAuthenticated) {
      return (
        <div styleName="dropdown-fixed">
          <Link to="/notifications">Log in</Link>
          {' '}
          {constants.loggedOutAction}
        </div>
      );
    }
  }

  render() {
    const {
      notifications,
      isAuthenticated,
      createDismissNotificationHandler,
      loading,
      active
    } = this.props;

    const renderIcon = () => {
      const displayBadge = !loading &&
        notifications.filter(n => n.state === 'unread').length > 0;

      return (
        <span styleName="notifications-alert">
          {displayBadge && <span styleName="notifications-alert-badge" />}
          <Bell styleName="notifications-alert-icon" alt="Notifications icon" />
        </span>
      );
    };

    const renderDropdownContent = () => {
      if (!isAuthenticated) {
        return (
          <div styleName="dropdown-fixed">
            <Link to="/notifications">Log in</Link>
            {' '}
            {constants.loggedOutAction}
          </div>
        );
      }

      return (
        <div styleName="notifications">
          <div styleName="dropdown-header">
            <strong>{constants.title}</strong>
            <Link to="/notifications" onClick={this.toggleDropdown}>{constants.headerAction}<ArrowRight/></Link>
          </div>
          <ul>
            {notifications.length < 1 && <li styleName="dropdown-empty">{constants.empty}</li>}
            {notifications.map(notification => {
              let categoryStyle = 'dropdown-item notification-category ';
              if (notification.priority != 1) { categoryStyle += 'notification-critical'; }

              return (
                <Link key={notification._id} to={`/notifications/${notification._id}`} onClick={this.createHandler(notification._id)}>
                  <li data-notification-category={notification.category} styleName={categoryStyle}>
                    <div styleName="notification-inner">
                      <span styleName="notification-close" onClick={createDismissNotificationHandler(notification._id)} />
                      <span styleName="notification-icon"><i /></span>
                      <span styleName="notification-title"><HotjarMasked isInline>{notification.title}</HotjarMasked></span>
                      <div styleName="notification-text">
                        <HotjarMasked>
                          <Markdown text={notification.body} />
                        </HotjarMasked>
                      </div>
                    </div>
                  </li>
                </Link>
              );
            }
            )}
          </ul>
        </div>
      );
    };

    return (
      <React.Fragment>
        <NavbarItem className="visible-xs-inline-block">
          <Link to="/notifications">
            {renderIcon()}
          </Link>
        </NavbarItem>
        <Dropdown
          id="notifications"
          className="btn-group hidden-xs"
          onMouseEnter={this.openDropdown}
          onMouseLeave={this.closeDropdown}
          onToggle={this.toggleDropdown}
          show={this.state.showDropdown}>

          <NavbarItem bsRole="toggle" dropdown onClick={this.navigateTo('/notifications')} >
            {renderIcon()}
            <span styleName={active ? 'dropdown-active' : ''}>
              {constants.title}
            </span>
          </NavbarItem>

          <Dropdown.Menu>
            {renderDropdownContent()}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

DropdownNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  active: PropTypes.bool,
  createReadNotificationHandler: PropTypes.func.isRequired,
  createClickNotificationHandler: PropTypes.func.isRequired,
  createDismissNotificationHandler: PropTypes.func.isRequired
};

export default DropdownNotifications;
