import React from 'react';
import PropTypes from 'prop-types';

import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from '../../common/components/Loading';

import { ButtonLink, Error } from '@a0/support-center-components';
import DocCards from '../components/DocCards';
import { StandaloneSearchBox } from '../../search/components/StandaloneSearchBox';
import TrialGeneralAlert from '../../common/components/TrialGeneralAlert';
import HavingTrouble from '../../common/components/HavingTrouble';
import OktaAlert from '../../common/components/OktaAlert';

import { getWithTrialDatesTenants, filterTenantsBySubscription } from '@a0/support-center-common/selectors/common';
import { allowedToOpenTicket } from '@a0/support-center-common/selectors/subscriptions';

import * as authActions from '../../login/actions/authActions';
import { setAccountContext } from '../../tenants/actions/tenantActions';
import * as contactActions from '../../sales/actions/contactActions';

import Metrics from '../../../utils/metrics';
import cards from '../../../utils/docCards';
import { Button } from 'react-bootstrap';
import { canOpenTicket } from '../../../utils/ticketFilters';

class HomePage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.onContactSales = this.onContactSales.bind(this);
  }

  onContactSales(e, metadata) {
    e.preventDefault();
    this.props.actions.contactSales('homepage', metadata);
  }

  render() {
    const {
      sendToForum,
      cards,
      trialTenants,
      setAccountContext,
      subscriptionTenants
    } = this.props;

    return (
      <div className="home-page">
        <TrialGeneralAlert
          trialTenants={trialTenants}
          trackData="homepage"
          setAccountContext={setAccountContext}
        />
        <div className="bg-dots page-header-dots home-page-header">
          <div className="container home-header-container">
            <div>
              <OktaAlert />
              <div className="jumbotron">
                <h1>How can we help you?</h1>
              </div>
              <div className="col-md-10 col-md-offset-1">
                <StandaloneSearchBox />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <DocCards cards={cards} />
          <HavingTrouble contactSales={this.onContactSales}/>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  sendToForum: PropTypes.bool.isRequired,
  cards: PropTypes.array,
  trialTenants: PropTypes.array,
  setAccountContext: PropTypes.func,
  subscriptionTenants: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    sendToForum: !allowedToOpenTicket(state),
    cards: cards,
    trialTenants: getWithTrialDatesTenants(state),
    subscriptionTenants: filterTenantsBySubscription(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, authActions, contactActions), dispatch),
    setAccountContext: bindActionCreators(setAccountContext, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
