import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { confirm } from '../../../../utils/confirm';
import constants from '../../utils/fields';
import { SEVERITY } from '../../constants';
import severityConstants from '@a0/support-center-common/constants/tickets/customerSelectedSeverities';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const options = constants.severities;
const confirmMsg = '<p>Please note that urgent severity is intended for addressing critical issues during <strong>production application outages that affect all users.</strong> We kindly request that you be available to join a Zoom meeting within 30 minutes of your case submission. This step is designed to facilitate real-time communication and enable our support team to triage your urgent issues promptly. If you’re unable to join a Zoom meeting, we may recategorize your case.</p>';

/**
 * Severity picker
 * Todo, this could be refactored and needs some tests
 */
class Severity extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected || ''
    };
    this.changeSelectedOption = this.changeSelectedOption.bind(this);
  }

  changeSelectedOption(name, option) {
    return () => {
      if (option === severityConstants.CUSTOMER_SELECTED_SEVERITIES[severityConstants.URGENT_SEVERITY]){
        confirm(confirmMsg, {title:'Confirm'}).then(() => {
          this.setState({selected: option});
          this.props.onChange({target: {name: name, value: option}});
        }
      ).catch(()=>{});
      } else {
        this.setState({selected: option});
        this.props.onChange({target: {name: name, value: option}});
      }
    };
  }

  descriptionPopover(option) {
    return (
      <Popover
        id={`severity-popover-${options.subtext}`}
        className="light top"
        title={option.subtext}
      >
        <Popover.Header as="h3" bsPrefix="popover-title">{option.subtext}</Popover.Header>
        <Popover.Body bsPrefix="popover-content">{option.description}</Popover.Body>
      </Popover>
    );
  }

  render() {
    const {name, label, defaultOption, value, error, classes} = this.props;

    return (
      <div className="form-group">
        <label className="control-label animated fadeIn" htmlFor={name}>{SEVERITY.LABEL}</label>
        <input type="hidden" name={name} value={this.state.selected}/>
        <ul className="confirm-severity-select">
          {options.map(option =>
            <OverlayTrigger key={option.value} placement="top" overlay={this.descriptionPopover(option)}>
              <li
                key={option.value}
                className={`${option.value} ${option.value === this.state.selected ? 'active' : ''}`}
                onClick={this.changeSelectedOption(name, option.value)}>
                <span>
                  <div>{option.text.split(':')[0]}</div>
                  <div>{option.text.split(':')[1].trim()}</div>
                </span>
              </li>
            </OverlayTrigger>
          )}
        </ul>
      </div>
    );
  }
}

Severity.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  classes: PropTypes.string,
  selected: PropTypes.string
};

export default Severity;
