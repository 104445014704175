import React from 'react';
import PropTypes from 'prop-types';
import { IndexLink } from 'react-router';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NavbarBrand from 'react-bootstrap/NavbarBrand';

import { HotjarMasked } from '@a0/support-center-components';
import { truncate } from 'lodash';

import resources from '../../../utils/resources';
import { LogOut } from 'react-feather';

const Header = ({ auth, onLogin, onLogout, contactSales }) => {
  const manageUrl = window.scConfig.MANAGE_URL;
  const statusPageUrl = window.scConfig.STATUS_PAGE_URL;
  return (
    <header className="site-header">
      <Navbar role="navigation" variant="default" expand={false}>
        <Container>
          <div className="navbar-header">
            <NavbarBrand as="h1">
              <IndexLink to="/" activeClassName="active">
                <svg width="213" viewBox="0 0 380 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M67.0274 28.168L75.2985 6.45352H78.4863L86.7254 28.168H83.8473L81.5566 22.3062H72.1107L69.82 28.168H67.0274ZM80.745 19.8566L76.8096 9.4005L72.9064 19.8566H80.745Z" fill="white" />
                  <path d="M98.6868 12.3475H101.228V28.168H99.0286L98.6868 26.0608C97.7577 27.2374 96.2413 28.3552 93.6408 28.3552C90.1701 28.3552 87.4148 26.4031 87.4148 21.1563V12.3475H89.9565V21.0012C89.9565 24.2904 91.4409 26.0287 94.0733 26.0287C96.9514 26.0287 98.6868 23.8251 98.6868 20.2577V12.3475Z" fill="white" />
                  <path d="M102.441 14.6719V12.3453H105.292V7.91148H107.834V12.3453H111.924V14.6719H107.834V24.0102C107.834 25.3419 108.298 25.8393 109.66 25.8393H112.169V28.1659H109.414C106.381 28.1659 105.297 26.8341 105.297 24.0423V14.6719H102.441Z" fill="white" />
                  <path d="M127.708 19.3539V28.1627H125.166V19.509C125.166 16.2197 123.586 14.4815 120.862 14.4815C117.92 14.4815 116.094 16.685 116.094 20.2524V28.1627H113.552V6.45352H116.094V14.9521C117.023 13.4653 118.636 12.1603 121.295 12.1603C124.824 12.1603 127.702 14.1124 127.702 19.3592L127.708 19.3539Z" fill="white" />
                  <path d="M129.475 16.8121C129.475 10.64 132.817 6.26501 137.714 6.26501C142.61 6.26501 145.921 10.64 145.921 16.8121V17.8069C145.921 24.2891 142.578 28.3539 137.714 28.3539C132.85 28.3539 129.475 24.2891 129.475 17.8069V16.8121ZM143.289 16.903C143.289 11.7525 141.057 8.6504 137.714 8.6504C134.371 8.6504 132.107 11.7525 132.107 16.903V17.7106C132.107 22.8611 134.339 25.9632 137.714 25.9632C141.089 25.9632 143.289 22.8611 143.289 17.7106V16.903Z" fill="white" />
                  <path d="M83.9647 49.8342C83.9647 54.6745 80.7449 57.9317 76.377 57.9317C73.7125 57.9317 71.9184 56.8459 70.9573 55.327L70.6155 57.7445H68.4156V36.0299H70.9573V44.4697C72.0092 43.0096 73.7125 41.742 76.377 41.742C80.7449 41.742 83.9647 44.689 83.9647 49.8395V49.8342ZM81.3643 49.8342C81.3643 46.3898 79.2604 44.0044 76.1314 44.0044C73.0024 44.0044 70.9306 46.3952 70.9306 49.7753C70.9306 53.1555 73.0344 55.6693 76.1314 55.6693C79.2284 55.6693 81.3643 53.2786 81.3643 49.8395V49.8342Z" fill="white" />
                  <path d="M83.8685 41.9228H86.565L91.4295 54.5504L96.139 41.9228H98.8356L91.5576 60.4122C90.5644 62.9581 89.9771 63.9475 87.7504 63.9475H84.7175V61.621H86.9762C88.4339 61.621 88.6796 61.2466 89.2669 59.7597L90.0412 57.8343L83.8792 41.9228H83.8685Z" fill="white" />
                  <path d="M117.812 57.9238C111.709 57.9238 107.469 53.2707 107.469 46.8794C107.469 40.488 111.714 35.8349 117.812 35.8349C123.91 35.8349 128.155 40.488 128.155 46.8794C128.155 53.2707 123.91 57.9238 117.812 57.9238ZM117.812 55.4422C122.335 55.4422 125.4 51.8748 125.4 46.8794C125.4 41.8839 122.335 38.3166 117.812 38.3166C113.29 38.3166 110.225 41.8839 110.225 46.8794C110.225 51.8748 113.29 55.4422 117.812 55.4422Z" fill="white" />
                  <path d="M130.11 36.0299H132.652V50.3316L140.581 41.9239H143.71L137.548 48.4703L144.143 57.7445H141.078L135.845 50.2995L132.657 53.5888V57.7445H130.115V36.0299H130.11Z" fill="white" />
                  <path d="M144.35 44.2494V41.9228H147.202V37.489H149.743V41.9228H153.833V44.2494H149.743V53.5877C149.743 54.9194 150.208 55.4168 151.569 55.4168H154.079V57.7434H151.324C148.291 57.7434 147.207 56.4116 147.207 53.6198V44.2494H144.35Z" fill="white" />
                  <path d="M170.382 55.4167V57.7432H168.989C166.885 57.7432 166.169 56.8447 166.137 55.2937C165.144 56.7217 163.569 57.9304 160.84 57.9304C157.37 57.9304 155.02 56.1922 155.02 53.3094C155.02 50.1432 157.22 48.3782 161.369 48.3782H166.014V47.2925C166.014 45.244 164.557 44.0032 162.079 44.0032C159.847 44.0032 158.363 45.0568 158.053 46.672H155.511C155.885 43.57 158.389 41.7408 162.202 41.7408C166.228 41.7408 168.551 43.7572 168.551 47.4476V54.363C168.551 55.2027 168.861 55.4167 169.571 55.4167H170.382ZM161.123 50.5175C158.865 50.5175 157.594 51.3572 157.594 53.1543C157.594 54.7053 158.923 55.759 161.033 55.759C164.194 55.759 166.02 53.9298 166.02 51.2931V50.5175H161.123Z" fill="white" />
                  <path d="M3.37138 27.7827C13.8455 26.0542 22.0568 17.383 23.7768 6.89172L24.3539 1.84927C24.4967 1.04797 23.9539 -0.0681241 22.9425 0.0120057C15.0283 0.63015 7.55988 3.24582 3.40566 4.94571C1.34284 5.7928 0 7.79605 0 10.0282V26.4777C0 27.4507 0.874271 28.1948 1.83426 28.0403L3.37138 27.7884V27.7827Z" fill="white" />
                  <path d="M28.8289 6.89209C30.5546 17.3834 38.7659 26.0546 49.2343 27.7831L50.7714 28.0349C51.7314 28.1952 52.6057 27.4511 52.6057 26.4724V10.0229C52.6057 7.79068 51.2629 5.78744 49.2 4.94035C45.0401 3.23473 37.5774 0.624791 29.6632 0.00664632C28.6461 -0.0734835 28.1261 1.05406 28.2461 1.84391L28.8232 6.88636L28.8289 6.89209Z" fill="white" />
                  <path d="M49.2264 32.3908C34.9123 35.2182 28.2667 44.7479 28.2667 62.7886C28.2667 63.6929 29.1638 64.3225 29.9181 63.8188C36.5008 59.3716 50.9863 47.7643 52.4777 33.2436C52.5349 31.4178 50.2549 32.2763 49.2264 32.3908Z" fill="white" />
                  <path d="M3.37665 32.3908C17.6907 35.2182 24.3363 44.7479 24.3363 62.7886C24.3363 63.6929 23.4392 64.3225 22.6849 63.8188C16.1022 59.3716 1.61668 47.7643 0.125275 33.2436C0.068133 31.4178 2.3481 32.2763 3.37665 32.3908Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M195.049 64V4.90798e-08L196.171 0V64H195.049Z" fill="white" />
                  <path d="M223.004 38.1332H225.641C225.971 43.0307 229.738 46.233 235.295 46.233C240.616 46.233 244.195 43.69 244.195 39.4518C244.195 34.6484 239.58 33.6124 234.4 32.7176C229.126 31.8229 223.757 30.4101 223.757 24.1469C223.757 18.543 228.137 15.0111 234.824 15.0111C241.558 15.0111 245.843 18.7314 246.314 24.3824H243.724C243.159 20.097 239.769 17.3186 234.824 17.3186C229.644 17.3186 226.3 19.7203 226.3 24.0056C226.3 28.7148 230.68 29.5625 235.719 30.4101C241.181 31.352 246.738 32.9531 246.738 39.2163C246.738 44.7731 242.17 48.5405 235.248 48.5405C227.854 48.5405 223.334 44.3493 223.004 38.1332Z" fill="white" />
                  <path d="M268.15 36.1082V24.3824H270.552V48.2579H268.432L268.103 43.2191C266.737 46.2801 263.959 48.5405 259.72 48.5405C254.446 48.5405 250.538 45.2441 250.538 37.7565V24.3824H252.939V37.6152C252.939 43.3133 255.671 46.3272 260.097 46.3272C265.042 46.3272 268.15 42.3714 268.15 36.1082Z" fill="white" />
                  <path d="M286.801 24.0998C293.347 24.0998 298.056 28.809 298.056 36.2966C298.056 43.5487 293.347 48.5405 286.801 48.5405C282.28 48.5405 279.031 46.233 277.665 42.7482V57.6763H275.264V24.3824H277.336L277.713 29.845C279.219 26.5015 282.328 24.0998 286.801 24.0998ZM286.613 46.3743C291.934 46.3743 295.607 42.2302 295.607 36.2966C295.607 30.4101 291.934 26.219 286.613 26.219C281.292 26.219 277.618 30.4101 277.618 36.2495C277.618 42.2302 281.292 46.3743 286.613 46.3743Z" fill="white" />
                  <path d="M313.406 24.0998C319.952 24.0998 324.661 28.809 324.661 36.2966C324.661 43.5487 319.952 48.5405 313.406 48.5405C308.885 48.5405 305.636 46.233 304.27 42.7482V57.6763H301.869V24.3824H303.941L304.318 29.845C305.824 26.5015 308.933 24.0998 313.406 24.0998ZM313.218 46.3743C318.539 46.3743 322.212 42.2302 322.212 36.2966C322.212 30.4101 318.539 26.219 313.218 26.219C307.897 26.219 304.223 30.4101 304.223 36.2495C304.223 42.2302 307.897 46.3743 313.218 46.3743Z" fill="white" />
                  <path d="M339.022 48.5405C332.288 48.5405 327.579 43.5487 327.579 36.2966C327.579 29.0916 332.288 24.0998 339.022 24.0998C345.757 24.0998 350.513 29.0916 350.513 36.2966C350.513 43.5487 345.757 48.5405 339.022 48.5405ZM339.022 46.3743C344.344 46.3743 348.017 42.2302 348.017 36.2966C348.017 30.4101 344.344 26.219 339.022 26.219C333.701 26.219 330.028 30.4101 330.028 36.2966C330.028 42.2302 333.701 46.3743 339.022 46.3743Z" fill="white" />
                  <path d="M364.091 24.3824H365.363V26.6899H363.62C358.016 26.6899 356.745 31.3049 356.745 36.1553V48.2579H354.343V24.3824H356.415L356.792 29.1387C357.781 26.5015 359.664 24.3824 364.091 24.3824Z" fill="white" />
                  <path d="M370.413 42.7011V26.5957H365.892V24.3824H370.413V17.6953H372.815V24.3824H379.266V26.5957H372.815V42.654C372.815 45.1028 373.709 46.0446 376.205 46.0446H379.502V48.2579H376.017C371.967 48.2579 370.413 46.5155 370.413 42.7011Z" fill="white" />
                </svg>
              </IndexLink>
            </NavbarBrand>
            <NavbarToggle
              bsPrefix="navbar-toggle"
              aria-controls="responsive-navbar-nav"
            >
              {[1,2,3].map((e) => <span key={e} className="icon-bar"></span>)}
            </NavbarToggle>
          </div>
          <NavbarCollapse className="container">
            <Nav as="ul" className="nav navbar-right">
              <NavItem as="li"><NavLink target="_blank" href={manageUrl}>Dashboard</NavLink></NavItem>
              <NavItem as="li"><NavLink target="_blank" href="https://auth0.com/docs">Documentation</NavLink></NavItem>
              <NavItem as="li"><NavLink target="_blank" href={statusPageUrl}>Status</NavLink></NavItem>
              <NavItem as="li">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip" className="bottom">
                      {resources.CONTACT_SALES_TOOLTIP}
                    </Tooltip>
                  }
                >
                  <NavLink
                    href="#"
                    onClick={function (e) {
                      contactSales(e, {place: "header", type: "link", text: "talk to sales"});
                    }}
                  >
                    Talk to Sales
                  </NavLink>
                </OverlayTrigger>
              </NavItem>
              {auth.isAuthenticated ? (
                <Dropdown as="li" style={{ marginTop: "-3px" }}>
                  <DropdownToggle as={NavLink} id="nav-dropdown" role="button">
                    <span>
                      {auth.user.picture && (
                        <img
                          src={auth.user.picture}
                          className="picture avatar ac-avatar"
                        />
                      )}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu as="ul">
                    <DropdownItem as="li" className="dropdown-email username-text text-truncate">
                      <div className="navbar-text">
                        {auth.user.picture && (<img src={auth.user.picture} className="picture avatar ac-avatar ac-avatar--inside"/>)}
                        <HotjarMasked isInline>
                          <strong style={{ marginRight: "20px" }} title={auth.user.email}>{truncate(auth.user.email)}</strong>
                        </HotjarMasked>
                      </div>
                    </DropdownItem>
                    <DropdownItem as="li" onClick={onLogout}><NavLink><LogOut className="logout-icon" /> Logout</NavLink></DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <NavItem as="li">
                  <NavLink onClick={onLogin}>
                    <span className="btn btn-primary btn-sm btn-login no-padding-right">Log in</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </NavbarCollapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  contactSales: PropTypes.func.isRequired
};

export default Header;
