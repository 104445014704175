import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { buildInteractiveResult } from "@coveo/headless";
import { sanitizeUrl } from "@braintree/sanitize-url";

// https://docs.coveo.com/en/headless/latest/reference/search/controllers/interactive-result/
// An interactive results handles the analytics of users selecting a result
export const InteractiveResult = (props) => {
  const { headlessEngine } = props;
  const controller = buildInteractiveResult(headlessEngine, {
    options: { result: props.result }
  });

  useEffect(() => () => controller.cancelPendingSelect());
  const result = props.result;
  return (
    <a
      href={sanitizeUrl(result.clickUri)}
      onClick={controller.select}
      onContextMenu={controller.select}
      onMouseDown={controller.select}
      onMouseUp={controller.select}
      onTouchStart={controller.beginDelayedSelect}
      onTouchEnd={controller.cancelPendingSelect}
    >
      <cite>
        <span className={`source ${result.raw.source.toLowerCase()}`}>{result.raw.source}</span>
        <span>{result.printableUri}</span>
      </cite>
      <h3>{props.children}</h3>
    </a>
  );
};

InteractiveResult.propTypes = {
  headlessEngine: PropTypes.object,
  result: PropTypes.object,
  children: PropTypes.node
};
