export const title = 'Compliance';

export const certifications = {
  'SOC2': {
    slug: 'soc2',
    name: 'SOC2',
    summary: 'The Service Organization Control (SOC) 2 Report is a standard auditing report governed by the American Institute of Certified Public Accountants (AICPA).',
    description: 'The Service Organization Control (SOC) 2 Report is a standard auditing report governed by the American Institute of Certified Public Accountants (AICPA). This report, although coming from an accounting institution, focuses on non-financial controls within an organization.<br/><br />The SOC 2 report has two types. An SOC 2 Type I report evaluates and reports on the design and controls that are put in place within an organization, while an SOC 2 Type II report additionally evaluates the operational effectiveness of controls over a period of time, typically 12 months. SOC2 compliance is based on five Trust Service Principles: Security, Availability, Processing Integrity, Confidentiality, and Privacy. Organizations can choose which principles they want to be audited on and this is generally dependent on what type of industry the organization is in.',
    icon: require('./img/soc2.svg')
  },
  'HIPAA': {
    slug: 'hipaa',
    name: 'HIPAA',
    summary: 'HIPAA (Health Insurance Portability and Accountability Act of 1996) provides data privacy and security provisions for protecting medical records.',
    description: 'HIPAA (Health Insurance Portability and Accountability Act of 1996) provides data privacy and security provisions for protecting medical records.<br /><br />The law is split into five sections, with Title II providing the framework for establishing standards regarding privacy, transactions, security, identity, and enforcement. To remain in good standing and compliant with HIPAA all of the above must be satisfied.<br /><br />Since HIPAA is a law, health plans, health care providers, and health care clearinghouse organizations in the United States must comply with the rules set forth or face steep fines and penalties ranging from civil to criminal charges.',
    icon: require('./img/hipaa.svg')
  },
  'GDPR': {
    slug: 'gdpr',
    name: 'GDPR',
    summary: 'The General Data Protection Regulation is a regulation enacted by the EU that aims to strengthen and unify data protection for individuals within the EU.',
    description: 'General Data Protection Regulation (GDPR) is an upcoming regulation enacted by the European Union that aims to strengthen and unify data protection for individuals within the European Union.<br /><br />The data protection rules set forth in this regulation extend to businesses outside of the EU that collect and process data on EU citizens.<br /><br />The GDPR will be a single set of rules that applies to all EU member nations. The regulation covers a wide spectrum of topics including data portability, right to erasure, reporting on data breaches, sanctions for violations, and more. With the new regulation in effect, data protection is placed in the spotlight and privacy protections must be enabled by default. GDPR goes in effect on May 25, 2018.',
    icon: require('./img/gdpr.svg')
  },
  'Cloud Security Alliance': {
    slug: 'cloud-security-alliance',
    name: 'Cloud Security Alliance',
    summary: 'Provides security assurance best practices and education on Cloud Computing security.',
    description: 'Promoting the use of best practices for providing security assurance within Cloud Computing, and provide education on the uses of Cloud Computing to help secure all other forms of computing.',
    icon: require('./img/csa.svg')
  }
};

export const disclosure = {
  icon: require('./img/rdprogram.svg'),
  title: 'Auth0 Responsible Disclosure Program',
  summary: 'If you believe you have discovered potential security vulnerabilities in an Auth0 service, we encourage you to disclose your discovery to us.',
  primaryAction: {
    text: 'Check out our program',
    url: 'https://auth0.com/whitehat',
    trackingKey: 'click:sc:compliance-disclosure'
  }
};

export const tracking = {
  downloadDocumentClicked: 'click:sc:compliance-download-document',
  externalUrlVisited: 'click:sc:compliance-external-document',
  requestNdaClicked: 'compliance-request-nda',
  documentContactSalesClicked: 'compliance-contact-sales'
};

export const contact = {
  modalTitle: 'Request an NDA',
  ndaMessage: docTitle => `NDA request for ${docTitle}`,
  requestMessage: docTitle => `Request for ${docTitle} document`
};

export const document = {
  downloadAction: 'Download document',
  requestNdaAction: 'Request an NDA to download this document',
  contactSalesAction: 'Request this document'
};
