import React from "react";
import PropTypes from "prop-types";
import { buildFacet } from "@coveo/headless";
import FormCheck from "react-bootstrap/FormCheck";
import { useCoveoController } from "./utils";

export const Facet = ({ field, title }) => {
  const { state, controller } = useCoveoController(
    (headlessEngine) => buildFacet(headlessEngine, { options: { field } }),
    "Facet"
  );

  const { values, isLoading } = state;
  if (values.length === 0) {
    return null;
  }

  const handleOnChange = (value) => () => {
    controller.toggleSelect(value);
  };

  return (
    <div className="search-facet">
      <h3>{title}</h3>
      <ul>
        {values.map((value) => {
          return (
            <li key={value.value}>
              <FormCheck
                className="checkbox"
                checked={controller.isValueSelected(value)}
                onChange={handleOnChange(value)}
                disabled={isLoading}
                label={`${value.value} (${value.numberOfResults})`}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Facet.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string
};
