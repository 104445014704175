import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Button from 'react-bootstrap/Button';
import { isEmpty, noop } from 'lodash';

import Fields from '../../components/Fields';
import InlineAlert from '../../../common/components/InlineAlert';
import OfflineTicketResultPage from '../../components/OfflineTicketResultPage';
import OfflineTicketPage from '../../components/OfflineTicketPage';

import { createOfflineTicket } from '../../actions/ticketActions';

import { top } from '../../../../utils/scroll';
import Metrics from '../../../../utils/metrics';
import validate from '../../offlineValidations';

import {
  setTicketStateInStorage,
  getTicketStateFromStorage,
  clearTicketStateFromStorage
} from '../../utils/session';

import { addUnloadHook, removeUnloadHook } from '../../../../utils/unloadHooks';

const initialState = {
  ticket: {
    tenant: '',
    region: '',
    email: '',
    subject: '',
    comment: ''
  },
  errors: {},
  saved: false,
  saving: false
};

/**
 * Component responsible for maintaining form state,
 * dispatching related actions and displaying errors
 */
export class OfflineTicketForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialStateFromStorage(props);

    // prettier-ignore
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.propsForField = this.propsForField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { router, route } = this.props;
  }

  // Avoid leave the form closing the browser or browser tab
  componentWillUpdate(nextProps, nextState) {
    if (nextState.editing) {
      addUnloadHook(clearTicketStateFromStorage);
    } else {
      removeUnloadHook(clearTicketStateFromStorage);
    }
  }

  componentWillUnmount() {
    removeUnloadHook(clearTicketStateFromStorage);
  }

  getInitialStateFromStorage(props) {
    const stateFromStorage = getTicketStateFromStorage();
    return stateFromStorage
      ? { ...initialState, ...stateFromStorage }
      : initialState;
  }

  saveTicketStateInStorageFlow(error) {
    if (error && error.status === 401) {
      setTicketStateInStorage({
        ...this.state,
        saving: false
      });
    }
  }

  /**
   * Event handler to be used by field
   * components to update the ticket state
   * @param {Object} event
   */
  handleFieldChange(event) {
    const ticket = {
      ...this.state.ticket,
      [event.target.name]: event.target.value
    };

    this.setState({ ...this.state, ticket });
  }

  /**
   * Gets props for a given field key
   * @param   {String} key
   * @param   {Object} extraProps
   * @returns {Object} props
   */
  propsForField(key, extraProps) {
    return {
      name: key,
      value: this.state.ticket[key],
      error: this.state.errors[key],
      onChange: this.handleFieldChange,
      ...extraProps
    };
  }

  /**
   * Submit ticket info
   * @param {Object} event Event Object
   */
  handleSubmit(event) {
    event && event.preventDefault();

    Metrics.track('click:sc:offlineticket:create');

    const { ticket } = this.state;

    const payload = ticket;

    // Validate ticket
    const errors = validate(payload);

    if (!isEmpty(errors)) {
      this.setState({ errors });
    }

    this.setState({ saving: true });
    this.props
      .createOfflineTicket(payload)
      .then(result => {
        Metrics.track('success:sc:offlineticket:create');

        this.setState({ ...initialState, saved: result });
      })
      .catch(error => {
        Metrics.track('error:sc:offlineticket:create');
        this.saveTicketStateInStorageFlow(error);
        this.setState({
          errors: {...this.state.errors, submit: error.data.message},
          saving: false
        });
        top();
      });
  }

  /**
   * Try again to show the create ticket page in case that the support service is down
   */
  goToCreateATicketPage() {
    browserHistory.push('/tickets/offline/new');
  }

  /**
   * Passes through only the necessary props for each field
   * Displays form progressively
   */
  render() {

    const { ticket, saving, saved, errors } = this.state;
    const submitButtonMsg = saving ? 'Submitting...' : 'Submit Offline Ticket';

    if (saved) {
      return (
        <OfflineTicketResultPage
          step="create"
          ticket={saved}
        />
      );
    }

    return (
      <OfflineTicketPage>
        <h1>New Offline Ticket</h1>

        {errors.submit && (
          <span className="help-block">
            <InlineAlert message={errors.submit} level="danger" />
          </span>
        )}

        <form className="new-offline-ticket-form" onSubmit={this.handleSubmit}>
          <Fields.TenantName {...this.propsForField('tenant')} />
          <Fields.Region {...this.propsForField('region')} />
          <Fields.Email {...this.propsForField('email')} />
          <Fields.Subject {...this.propsForField('subject')} />
          <Fields.CommentPlain hideUpload {...this.propsForField('comment')} />

          <Fragment>
            <Button
              type="submit"
              variant="success"
              classes="animated fadeIn"
            >
              {submitButtonMsg}
            </Button>
          </Fragment>

        </form>

      </OfflineTicketPage>
    );
  }
}

OfflineTicketForm.propTypes = {
  // Create ticket action with dispatch bound
  createOfflineTicket: PropTypes.func.isRequired,
  // The current tenant context from redux, if set
  selectedTenant: PropTypes.object,
  // Router object
  router: PropTypes.object,
  // Route Object
  route: PropTypes.object
};

OfflineTicketForm.defaultProps = {
  router: {
    setRouteLeaveHook: noop
  },
  route: {}
};

function mapStateToProps(state) {
  return {
    selectedTenant: {}
  };
}

const mapDispatchToProps = {
  createOfflineTicket
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineTicketForm);
