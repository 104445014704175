import React from 'react';
import PropTypes from 'prop-types';
import Introduction from '../../common/components/Introduction';

import * as subscriptions from '@a0/support-center-common/constants/subscriptions';
const { SUBSCRIPTION_PLAN_APPLIANCE, SUBSCRIPTION_PLAN_ENTERPRISE } = subscriptions.plans;

export const MESSAGE_ENTERPRISE = 'An overview of feature usage and quota consumption for all the tenants on your subscription';
export const MESSAGE_APPLIANCE = 'An overview of feature usage and quota consumption for all of the Private Instances and Cloud Tenants on your subscription';
export const MESSAGE_SELF_SERVICE = 'An overview of feature usage and quota consumption for your subscription';

/**
 * Component that renders introductory text for the
 * Overquota report by Subscription Plan type
 * @param {Object} props
 * @returns {Object} QuotaReportIntroduction
 */
const QuotaReportIntroduction = ({ subscriptionPlan }) => {
  if (subscriptionPlan === SUBSCRIPTION_PLAN_APPLIANCE) {
    return <Introduction>{MESSAGE_APPLIANCE}</Introduction>;
  }

  if (subscriptionPlan === SUBSCRIPTION_PLAN_ENTERPRISE) {
    return <Introduction>{MESSAGE_ENTERPRISE}</Introduction>;
  }

  return <Introduction>{MESSAGE_SELF_SERVICE}</Introduction>;
};

QuotaReportIntroduction.propTypes = {
  subscriptionPlan: PropTypes.string.isRequired
};

export default QuotaReportIntroduction;
