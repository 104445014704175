import { request, requestUnauthenticated } from 'api';
const UPLOAD_TIMEOUT = 4 * 60 * 1000; //4' in ms

class TicketApi {
  static getByTenant(tenant, region) {
    return request('tickets?tenant=' + tenant + '&region=' + region);
  }

  static getMine() {
    return request('my-tickets');
  }

  static getById(ticketId) {
    return request('tickets/' + ticketId );
  }

  static create(ticket) {
    return request('tickets', {method:'post', data:ticket});
  }

  static createOffline(ticket) {
    return requestUnauthenticated('tickets/offline', {method:'post', data:ticket});
  }

  static submitComment(ticketId, comment) {
    return request('tickets/' + ticketId + '/comments', { method:'post', data: comment });
  }

  static downloadAttachment(fileId, commentId, type) {
    const url = `tickets/attachments?fileId=${fileId}&commentId=${commentId}&type=${type}`;
    const options = { cache: true, responseType: 'arraybuffer' };
    return request(url, options);
  }

  static getSuggestionsToken() {
    return request('tickets/suggested-articles/token', { method: 'post' });
  }
}

export default TicketApi;
