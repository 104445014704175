export default {
  documents: {
    loading: false,
    error: null,
    records: []
  },
  document: {
    loading: false,
    error: null
  },
  nda: {
    exists: null,
    loading: false,
    error: null
  }
};