import React, { useState } from "react";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import CardTitle from "react-bootstrap/CardTitle";
import CardBody from "react-bootstrap/CardBody";

export const CollapsibleRecommendationsList = ({ children }) => {
  const [expanded, setExpanded] = useState(true);
  const handleTitleClick = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };
  return (
    <Card id="recommendations-panel">
      <CardHeader>
        <CardTitle as="div">
          <a className="center-block" href="#" onClick={handleTitleClick}>
            Recommended articles <span className="caret" />
          </a>
          {expanded &&
            <small>These might help resolve your issue faster. Clicking on an article will open in a new tab.</small>
          }
        </CardTitle>
      </CardHeader>
      <Collapse in={expanded}>
        <CardBody>{children}</CardBody>
      </Collapse>
    </Card>
  );
};

CollapsibleRecommendationsList.propTypes = {
  children: PropTypes.node
};
