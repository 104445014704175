import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { HotjarMasked, Loading, SubscriptionPreview } from '@a0/support-center-components';
import { buildSubscriptionPreview } from '@a0/support-center-common/lib/subscriptions';
import NavbarItem from './NavbarItem';
import Metrics from '../../../../utils/metrics';
import { catchErrors } from '../../../common/containers/ErrorBoundary';
import { SUBSCRIPTION_ERROR } from '../../../../utils/logger/logTypes';
import { isV8Subscription, formatV8SubscriptionName } from '../../../../utils/V8PlanUtils';

import './Dropdown.module.css';
const manageUrl = window.scConfig.MANAGE_URL;

// TODO refactor me
class DropdownSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: props.show || false,
      subscriptionPreview: null,
      loading: false
    };

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.changeSubscriptionPreview = this.changeSubscriptionPreview.bind(this);
    this.switchAccountContext = this.switchAccountContext.bind(this);
    this.login = this.login.bind(this);
    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    this.loadingNode = document.createElement('div');
    document.body.appendChild(this.loadingNode);
  }

  componentDidUpdate() {
    if (this.state.loading) {
      ReactDOM.render(<Loading show fullscreen />, this.loadingNode);
    } else {
      ReactDOM.unmountComponentAtNode(this.loadingNode);
    }
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.loadingNode);
    document.body.removeChild(this.loadingNode);
  }

  login() {
    this.props.actions.login();
  }

  switchAccountContext(agreement) {
    return () => {
      Metrics.track('change:sc:account-context', { trackData: agreement });
      this.loading();
      this.props.actions.setAccountContext(agreement);
    };
  }

  toggleDropdown(setting) {
    const showDropdown =
      setting != undefined ? setting : !this.state.showDropdown;
    if (showDropdown) Metrics.track('preview:sc:subscriptions');
    this.setState({ showDropdown });
  }

  openDropdown() {
    this.toggleDropdown(true);
  }

  closeDropdown() {
    this.toggleDropdown(false);
  }

  changeSubscriptionPreview(subscription) {
    return () => {
      const subscriptionPreview = buildSubscriptionPreview(subscription, this.props);
      this.setState({ subscriptionPreview });
    };
  }

  getSubscriptionPreview() {
    const { subscriptionPreview } = this.state;
    const { selectedSubscription } = this.props;

    return subscriptionPreview || buildSubscriptionPreview(selectedSubscription, this.props);
  }

  loading() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  render() {
    const {
      loading,
      isAuthenticated,
      v8Subscriptions,
      customSubscriptions,
      selfServiceSubscriptions,
      freeSubscriptions,
      trialSubscriptions,
      selectedSubscription
    } = this.props;

    const subscriptionPreview = this.getSubscriptionPreview();

    const hasTenants =
      customSubscriptions.length > 0 ||
      selfServiceSubscriptions.length > 0 ||
      trialSubscriptions.length > 0 ||
      v8Subscriptions.length > 0 ||
      freeSubscriptions.length > 0;

    return (
      <Dropdown
        id="subscriptions"
        className="btn-group"
        onMouseEnter={this.openDropdown}
        onMouseLeave={this.closeDropdown}
        onToggle={this.toggleDropdown}
        show={this.state.showDropdown}
      >
        <NavbarItem dropdown bsRole="toggle">
          Subscription:
          <span styleName="subscription-selection">
            {loading && (
              <div className="la-ball-pulse la-gray la-sm">
                <div />
                <div />
                <div />
              </div>
            )}
            {!loading && (!isAuthenticated || !hasTenants) && 'Select'}
            {!loading &&
              isAuthenticated &&
              hasTenants &&
              selectedSubscription && (
                <div className="subscripton-title-container">
                  <HotjarMasked isInline>
                    <span className="subscription-title">
                      {selectedSubscription.name &&
                        selectedSubscription.name.slice(0, 19)}
                    </span>
                  </HotjarMasked>{' '}
                  {selectedSubscription.formatted_start_date &&
                    `(${selectedSubscription.formatted_start_date})`}
                </div>
              )}
          </span>
        </NavbarItem>
        <DropdownMenu>
          {loading && (
            <div styleName="dropdown-fixed">
              <div className="la-ball-pulse la-gray la-sm">
                <div />
                <div />
                <div />
              </div>
            </div>
          )}
          {!loading &&
            !isAuthenticated && (
              <div styleName="dropdown-fixed">
                <a href="#" onClick={this.login}>Log in</a>
                {' '}
                to see your subscription details.
              </div>
            )}
          {!loading &&
            isAuthenticated &&
            !hasTenants && (
              <div styleName="dropdown-fixed">
                Sign up for an{' '}
                <a href={manageUrl}>Auth0 Subscription</a>
              </div>
            )}
          {!loading &&
            isAuthenticated &&
            hasTenants &&
            selectedSubscription && (
              <div id="subscription-select">
                <div
                  id="subscription-list"
                  onMouseLeave={this.changeSubscriptionPreview()}
                >
                  {/* this includes v8 self-service and v8 sales assisted  */}
                  {isAuthenticated && v8Subscriptions.length > 0 && isV8Subscription(v8Subscriptions[0]) && (
                      <li
                      className={
                        selectedSubscription.id === 'v8' ? "active" : ""
                      }
                      onClick={this.switchAccountContext('v8')}
                      onMouseEnter={this.changeSubscriptionPreview({
                        id: "v8",
                        name: v8Subscriptions[0].plan
                      })}
                    >
                      <div className="list-title">{formatV8SubscriptionName(v8Subscriptions[0].plan)}</div>
                      <i
                        className={`subscription-item-select icon-budicon-${
                          selectedSubscription.id === 'v8' ? 470 : 469
                        }`}
                      />
                    </li>
                  )}

                  {isAuthenticated &&
                    customSubscriptions.map(subscription => (
                      <li
                        key={subscription.tenant}
                        className={
                          selectedSubscription.tenant === subscription.tenant
                            ? 'active'
                            : ''
                        }
                        onClick={this.switchAccountContext(subscription.tenant)}
                        onMouseEnter={this.changeSubscriptionPreview(
                          subscription
                        )}
                      >
                        <div
                          className={
                            subscription.is_appliance
                              ? 'list-title appliance-title'
                              : 'list-title'
                          }
                        >
                          <HotjarMasked isInline>
                            <span className="subcription-list__name">
                              {subscription.name}
                            </span>
                          </HotjarMasked>
                          <span className="list-subtitle">
                            {subscription.formatted_start_date &&
                              `(${subscription.formatted_start_date})`}
                          </span>
                        </div>
                        <i
                          className={`subscription-item-select icon-budicon-${
                            selectedSubscription.tenant === subscription.tenant
                              ? 470
                              : 469
                          }`}
                        />
                        {subscription.is_appliance && (
                          <div className="appliance-subtitle">Private Cloud</div>
                        )}
                      </li>
                    ))}

                  {isAuthenticated &&
                    selfServiceSubscriptions.length > 0 && (
                      <li
                        className={
                          selectedSubscription.id === 'Developer'
                            ? 'active'
                            : ''
                        }
                        onClick={this.switchAccountContext('Developer')}
                        onMouseEnter={this.changeSubscriptionPreview({
                          id: 'Developer',
                          name: 'Developer'
                        })}
                      >
                        <div className="list-title">Developer</div>
                        <i
                          className={`subscription-item-select icon-budicon-${
                            selectedSubscription.id === 'Developer' ? 470 : 469
                          }`}
                        />
                      </li>
                    )}

                  {isAuthenticated &&
                    freeSubscriptions.length > 0 && (
                      <li
                        className={
                          selectedSubscription.id === 'Free' ? 'active' : ''
                        }
                        onClick={this.switchAccountContext('Free')}
                        onMouseEnter={this.changeSubscriptionPreview({
                          id: 'Free',
                          name: 'Free'
                        })}
                      >
                        <div className="list-title">Free</div>
                        <i
                          className={`subscription-item-select icon-budicon-${
                            selectedSubscription.id === 'Free' ? 470 : 469
                          }`}
                        />
                      </li>
                    )}

                  {isAuthenticated &&
                    trialSubscriptions.length > 0 && (
                      <li
                        className={
                          selectedSubscription.id === 'Trial' ? 'active' : ''
                        }
                        onClick={this.switchAccountContext('Trial')}
                        onMouseEnter={this.changeSubscriptionPreview({
                          id: 'Trial',
                          name: 'Trial'
                        })}
                      >
                        <div className="list-title">Trial</div>
                        <i
                          className={`subscription-item-select icon-budicon-${
                            selectedSubscription.id === 'Trial' ? 470 : 469
                          }`}
                        />
                      </li>
                    )}
                </div>

                <div id="subscription-preview">
                  <SubscriptionPreview {...subscriptionPreview} tenantId={this.props.selectedTenant}/>
                </div>
              </div>
            )}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

DropdownSubscriptions.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  customSubscriptions: PropTypes.array.isRequired,
  selfServiceSubscriptions: PropTypes.array.isRequired,
  freeSubscriptions: PropTypes.array.isRequired,
  trialSubscriptions: PropTypes.array.isRequired,
  selectedSubscription: PropTypes.object,
  selectedTenant: PropTypes.string.isRequired,
  v8Subscriptions: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  show: PropTypes.bool
};

export const DropdownSubscriptionsErrorBoundary = catchErrors(
  DropdownSubscriptions,
  { log_type: SUBSCRIPTION_ERROR },
  false
);

export default DropdownSubscriptions;
