/**
 * Show a confirm dialog when the user leaves of refresh the tab
 * @param  {Event} event  Event object
 * @return {Boolean}      Show message
 */
function showConfirmation(event) {
  const showMessage = true;

  event.returnValue = showMessage; // Gecko, Trident, Chrome 34+
  return showMessage;              // Gecko, WebKit, Chrome <34
}

/**
 * Show a confirm dialog when the user leaves of refresh the tab
 * @param  {Function} unloadCallback Function to call when the page is unload
 */
export function addUnloadHook(unloadCallback) {
  window.addEventListener('beforeunload', showConfirmation);

  if (unloadCallback) {
    window.addEventListener('unload', unloadCallback);
  }
}

/**
 * Remove beforeUnload and unload events that show the confirm dialog when the
 * user leaves of refresh the tab
 * @param  {Function} unloadCallback Function to remove when the page is unload
 */
export function removeUnloadHook(unloadCallback) {
  window.removeEventListener('beforeunload', showConfirmation);

  if (unloadCallback) {
    window.removeEventListener('unload', unloadCallback);
  }
}
