import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';

import './module.css';

const PageHeader = ({ extended, children }) => {
  const styleName = extended ? 'background extended' : 'background';
  return (
    <div styleName={styleName}>
      <Container>
        {children}
      </Container>
    </div>
  );
};

PageHeader.propTypes = {
  extended: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default PageHeader;
