/*eslint-disable no-console */
import dom from '../dom';

class Metrics {
  constructor() {
    this.ready = false;
    this.disabled = false;
    this.buffer = [];
    this.store = null;
    this.augmentPayload = payload => payload; // can be overridden with setAugmentPayload
    this.setAugmentPayload = this.setAugmentPayload.bind(this);
    this.setup();
  }

  setup() {
    const self = this;
    // Per this ticket https://auth0team.atlassian.net/browse/DXSUP-1223
    // temporarily no-op metrics
    this.disabled = true;
  }

  execBuffer() {
    this.buffer.forEach((item) => {
      this[item.name].apply(this, item.args);
    });
  }

  setUserId(id) {
    if (this.disabled) {
      return;
    }

    if (!this.ready) {
      this.buffer.push({
        name: 'setUserId',
        args: arguments
      });

      return;
    }

    global.metricsLib.setUserId(id);
  }

  identify(id, traits) {
    if (this.disabled) {
      return;
    }

    if (!this.ready) {
      this.buffer.push({
        name: 'identify',
        args: arguments
      });

      return;
    }

    global.metricsLib.identify(id, traits);
  }

  track(name, payload, callback) {
    if (this.disabled) {
      return 'function' === typeof callback && callback();
    }

    if (!this.ready) {
      this.buffer.push({
        name: 'track',
        args: arguments
      });

      return;
    }

    payload = this.augmentPayload(payload);

    if (process.env.NODE_ENV !== 'production') {
      console.info('track', name, payload);
    }

    global.metricsLib.track(name, payload, callback);
  }

  page() {
    if (this.disabled) {
      return;
    }

    if (!this.ready) {
      this.buffer.push({
        name: 'page',
        args: arguments
      });

      return;
    }

    global.metricsLib.page(this.augmentPayload());
  }

  setAugmentPayload(fn) {
    this.augmentPayload = fn;
  }
}

export default new Metrics();
