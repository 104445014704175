import { isEmpty, pick } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { IndexLink, Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Error, HotjarMasked } from '@a0/support-center-components';
import { canTenantEscalateTicket } from '@a0/support-center-common/lib/tenants';
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";

import TicketResultPage from '../components/TicketResultPage';
import TicketComment from '../components/TicketComment';
import Loading from '../../common/components/Loading';
import InlineAlert from '../../common/components/InlineAlert';
import CommentEditor from '../components/CommentEditor';
import AgentNotice from '../components/AgentNotice';
import DeferredUploadStatus from '../components/DeferredUploadStatus';
import Fields from '../components/Fields';
import TicketEscalation from '../components/TicketEscalation';
import { StaticFormField } from '../../common/components/StaticFormField';

import { ticketCommentValidation } from '../validations';

import { getSelectedSupportPlan } from '@a0/support-center-common/selectors/common';

import * as ticketActions from '../actions/ticketActions';
import * as deferredUploadActions from '../../uploads/actions/deferredUploadActions';
import { propTypes as deferredUploadPropTypes } from '../../uploads/reducers/deferredUploadReducer';

import { getUser, isUserEmailVerified } from '../../login/selectors/users';
import EmailVerificationPanel from '../../emailVerification/containers/EmailVerificationPanel';
import Metrics from '../../../utils/metrics';

import {
  setTicketStateInStorage,
  getTicketStateFromStorage,
  clearTicketStateFromStorage
} from '../utils/session';

import { addUnloadHook, removeUnloadHook } from '../../../utils/unloadHooks';

import getTicketErrorMessage from '../utils/getTicketErrorMessage';

import { ticketId } from '@a0/support-center-common/schemas/tickets/common';
import { APPLIANCE_TENANT, ROOT_TENANT_AUTHORITY, TENANT } from "../constants";

const initialState = {
  comment: {
    body: '',
    additional_collaborators: [],
    timezone: moment.tz.guess()
  },
  errors: {},
  saving: false,
  saved: null,
  editing: false
};

export class TicketPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.getInitialStateFromStorage();

    this.reloadTicket = this.reloadTicket.bind(this);
    this.updateCommentState = this.updateCommentState.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.renderAgentNotice = this.renderAgentNotice.bind(this);
    this.unauthorizedAgent = this.unauthorizedAgent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSudmitAndResolve = this.handleSudmitAndResolve.bind(this);
    this.handleSubmitAndEscalate = this.handleSubmitAndEscalate.bind(this);
  }

  componentDidMount() {
    const { router, route, actions, emailVerified, user } = this.props;

    router.setRouteLeaveHook(route, this.routerWillLeave);

    if(this.ticketIdIsValid(this.props.params.id)) {
      if (this.shouldLoadTicket(user, emailVerified)) {
        actions.loadTicket(this.props.params.id);
      }
    } else {
      actions.loadInvalidTicketError({
        error: `Invalid ticket number ${this.props.params.id}`
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { params, emailVerified, user } = nextProps;
    if (this.ticketIdIsValid(params.id)) {
      if (params.id != this.props.params.id && this.shouldLoadTicket(user, emailVerified)) {
        this.props.actions.loadTicket(params.id);
        this.setState(this.getInitialStateFromStorage());
      } else if (isEmpty(this.props.user) && this.shouldLoadTicket(user, emailVerified)) {
        // previous value of user object is empty - indicates that user had just been loaded
        this.props.actions.loadTicket(params.id);
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.editing) {
      addUnloadHook(clearTicketStateFromStorage);
    } else {
      removeUnloadHook(clearTicketStateFromStorage);
    }
  }

  componentWillUnmount() {
    removeUnloadHook(clearTicketStateFromStorage);
  }

  /**
   * Checks if ticket should be loaded based on user data
   * @param {Object} user
   * @param {Boolean} emailVerified
   * @returns {Boolean}
   */
  shouldLoadTicket(user, emailVerified) {
    // empty object is the initial state of user in Redux store.
    // in such case emailVerified will be true by default
    // to be sure that email is correctly verified we need to wait for the user to load
    return !isEmpty(user) && emailVerified;
  }

  routerWillLeave() {
    if (this.state.editing) {
      return 'Changes that you made may not be saved.';
    }
  }

  getInitialStateFromStorage() {
    const ticketStateFromStorage = getTicketStateFromStorage();

    return ticketStateFromStorage
      ? { ...initialState, ...ticketStateFromStorage }
      : initialState;
  }

  reloadTicket(event) {
    event && event.preventDefault();
    this.setState({ saved: null });
    this.props.actions.loadTicket(this.props.params.id);
  }

  updateCommentState(event) {
    const { name, value } = event.target;
    const comment = { ...this.state.comment };
    comment[name] = value;

    return this.setState({ comment: comment, editing: true });
  }

  commentFormIsValid() {
    const { isValid, errors } = ticketCommentValidation(this.state.comment);

    this.setState({ errors: errors });
    return isValid;
  }

  saveTicketStateInStorageFlow(error) {
    if (error && error.status === 401) {
      setTicketStateInStorage({
        ...this.state,
        saving: false,
        editing: true
      });
    }
  }

  buildNewComment(opts) {
    const { solved, isEscalated }  = opts || {};
    const { objectId, uuid, previousId } = this.props.ticket;
    const data = pick(
      this.state.comment,
      ['body', 'additional_collaborators', 'timezone']
    );

    data.solved = solved && !isEscalated;
    data.isEscalated = isEscalated;

    if (objectId)     { data.caseId = objectId; }
    if (uuid)         { data.ticketUUID = uuid; }
    if (previousId)   { data.ticketPreviousId = previousId; }

    return data;
  }

  submitComment(opts) {
    if (!this.commentFormIsValid()) {
      return;
    }

    const { actions, params: { id: ticketId } } = this.props;

    this.setState({ saving: true, editing: false });

    actions
      .commentTicket(
        ticketId,
        this.buildNewComment(opts)
      )
      .then(result => {
        Metrics.track('success:sc:ticket:update');

        if (result.commentId) {
          return this.props.deferredUploadActions.upload(result.commentId)
            .then((  ) => this.setState({ ...initialState, saved: result }))
            .catch(err => this.setState({ ...initialState, saved: result })); // temporary, ignore errors
        }

        this.setState({ ...initialState, saved: result });
      })
      .catch(error => {
        Metrics.track('error:sc:ticket:update');
        this.saveTicketStateInStorageFlow(error);
        this.setState({
          errors: {...this.state.errors, submit: getTicketErrorMessage({error})},
          saving: false
        });
      });
  }

  handleSudmitAndResolve(event) {
    event && event.preventDefault();
    this.submitComment({ solved: true });
  }

  handleSubmitAndEscalate() {
    this.submitComment({ isEscalated: true });
  }

  handleSubmit(event) {
    event && event.preventDefault();
    this.submitComment();
  }

  isUserSupportAgent() {
    return /@auth0.com$/.test(this.props.user.email);
  }

  /**
   * Returns an AgentNotice with the correct ID bound if it should be shown
   * @returns {AgentNotice|null}
   */
  renderAgentNotice() {
    if (!this.isUserSupportAgent()) {
      return null;
    }

    // Use either the id of the loaded ticket or the one passed
    // in an unauthorized error payload
    const id = this.props.ticket.objectId || this.props.unauthorizedObjectId;

    return !id ? null : <AgentNotice id={id} />;
  }

  printCollaborators(ticket) {
    if (Array.isArray(ticket.collaborators)) {
      return ticket.collaborators.join(', ');
    }

    return ticket.collaborators;
  }

  /**
   * Determines if current user is an Agent receiving an Unauthorized error
   * We don't want to display these errors because it is valid
   * SC usage for Agents to visit the details pages of tickets that
   * they don't have access to
   *
   * @returns {Boolean} isUnauthorizedAgent
   */
  unauthorizedAgent() {
    return !!this.props.unauthorizedObjectId && this.isUserSupportAgent();
  }

  ticketIdIsValid(id) {
    const { error } = ticketId.validate(id);
    return error ? false : true;
  }

  render() {
    const {
      ticket,
      loading,
      error,
      params,
      emailVerified
    } = this.props;
    if (this.state.saved) {
      return (
        emailVerified && (
          <TicketResultPage
            ticket={this.state.saved}
            ticketSubject={ticket.subject}
            supportPlan={this.props.supportPlan}
            handleUpdateLink={this.reloadTicket}
            step="update"
            deferredUploads={this.props.deferredUploads}
          />
        )
      );
    }

    return (
      <div>
        <div className="bg-dots page-header-dots page-header-dots--short">
          <div className="container">
            <ol className="breadcrumb">
              <li>
                <IndexLink to="/">Home</IndexLink>
              </li>
              <li>
                <Link to="/tickets">Tickets</Link>
              </li>
              <li>
                {ticket.id}
              </li>
            </ol>
            <h1>
              <HotjarMasked>{ticket.subject}</HotjarMasked>
            </h1>
          </div>
        </div>
        <div className="support-main-container container ticket">
          {emailVerified || <EmailVerificationPanel featureMode />}
          {emailVerified && (
            <Loading show={loading}>
              {/* This AgentNotice should be shown after ticket data is loaded and objectId is accessible */}
              {this.renderAgentNotice()}
              {ticket.isEscalated &&
               <p className="alert alert-warning">
                 <strong>Ticket Escalated</strong>. You indicated that this ticket requires
                 special attention. It has been escalated to management level for review.
               </p>}
              {!this.unauthorizedAgent() && <Error message={error}>
                <div>
                  {ticket.rootTenantAuthority ? (
                    <React.Fragment>
                      <FormGroup className="form-group">
                        <FormLabel>{TENANT.APPLIANCE_LABEL}</FormLabel>
                        <StaticFormField>{ticket.tenant}</StaticFormField>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <FormLabel>{ROOT_TENANT_AUTHORITY.LABEL}</FormLabel>
                        <StaticFormField>{ticket.rootTenantAuthority}</StaticFormField>
                      </FormGroup>
                      <FormGroup className="form-group">
                        <FormLabel>{APPLIANCE_TENANT.LABEL}</FormLabel>
                        <StaticFormField>{ticket.applianceTenant}</StaticFormField>
                      </FormGroup>
                    </React.Fragment>
                  ) : (
                    <FormGroup className="form-group">
                      <FormLabel>{TENANT.LABEL}</FormLabel>
                      <StaticFormField>{ticket.tenant}</StaticFormField>
                    </FormGroup>
                    )}
                  {/* 'closed' can be removed when ZD decommissioned */}
                  {ticket.status !== 'closed' && ticket.status !== 'Closed' && (
                    <div className="form reply-form">
                      <CommentEditor
                        name="body"
                        value={this.state.comment.body}
                        onChange={this.updateCommentState}
                        error={this.state.errors.body}
                      />
                      <Fields.Collaborators
                        name="additional_collaborators"
                        label={
                          <HotjarMasked isInline>
                            CC: {this.printCollaborators(ticket)}
                          </HotjarMasked>
                        }
                        value={this.state.comment.additional_collaborators}
                        onChange={this.updateCommentState}
                        error={this.state.errors.additional_collaborators}
                      />

                      {canTenantEscalateTicket(ticket.tenant, window.scConfig.TICKET_ESCALATION_TENANT_PATTERNS) &&
                       !ticket.isEscalated &&
                       <TicketEscalation
                        onEscalate={this.handleSubmitAndEscalate}
                        disabled={this.state.saving}
                        tenant={ticket.tenant} />}

                      <div className="editor-group">
                        <p className="btn-toolbar">
                          <input
                            type="submit"
                            disabled={this.state.saving}
                            value={this.state.saving ? 'Replying...' : 'Reply'}
                            className="btn btn-primary reply-submit"
                            onClick={this.handleSubmit}
                          />
                          {
                            this.state.saving ? null :
                              <input
                                type="button"
                                disabled={this.state.saving}
                                value={this.state.saving ? 'Replying...' : 'Reply & Resolve'}
                                className="btn btn-default reply-solved"
                                onClick={this.handleSudmitAndResolve}
                              />
                          }
                        </p>

                        <DeferredUploadStatus deferredUploads={this.props.deferredUploads} />
                      </div>
                      {this.state.errors.submit && (
                        <InlineAlert
                          message={this.state.errors.submit}
                          level="danger"
                        />
                      )}
                      <hr />
                    </div>
                  )}
                  <div className="ticket-comments">
                    {ticket.comments.map(comment => (
                      <TicketComment key={comment.id} comment={comment} />
                    ))}
                  </div>
                </div>
              </Error>}
            </Loading>
          )}
        </div>
      </div>
    );
  }
}

TicketPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  error: PropTypes.string,
  unauthorizedObjectId: PropTypes.string,
  supportPlan: PropTypes.string,
  userAgent: PropTypes.object,
  user: PropTypes.object,
  emailVerified: PropTypes.bool,
  deferredUploads: deferredUploadPropTypes.isRequired,
  deferredUploadActions: PropTypes.object.isRequired
};

TicketPage.defaultProps = {
  user: {},
  userAgent: {}
};

function mapStateToProps(state, ownProps) {
  return {
    loading: state.ticket.loading,
    error: state.ticket.error,
    unauthorizedObjectId: state.ticket.unauthorizedObjectId,
    ticket: state.ticket.record,
    supportPlan: getSelectedSupportPlan(state),
    user: getUser(state),
    emailVerified: isUserEmailVerified(state),
    deferredUploads: state.deferredUploads
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ticketActions, dispatch),
    deferredUploadActions: bindActionCreators(deferredUploadActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketPage);
