import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../../../common/components/SelectInput';

import constants from '@a0/support-center-common/constants/tickets/customerReasons';

/**
 * New Ticket Reason field component
 */
const Reason = ({ name, value, onChange, type }) => {
  const reasons = constants.CUSTOMER_REASONS_BY_TYPE[type] || {};
  const reasonsAsOptions = Object.keys(reasons).map(value => ({ value, text: reasons[value] }));

  return (
    <SelectInput
      name={name}
      label="What can we help you with? (*)"
      defaultOption="Please choose..."
      options={reasonsAsOptions}
      onChange={onChange}
      classes="animated fadeIn"
      value={value}
    />
  );
};

Reason.propTypes = {
  /** The form name and schema key */
  name: PropTypes.string.isRequired,
  /** Callback for change event */
  onChange: PropTypes.func.isRequired,
  /** The current field value */
  value: PropTypes.string.isRequired,
  /** The current value of the ticket's 'type' field */
  type: PropTypes.string
};


export default Reason;
