import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { buildSearchEngine } from "../../../../utils/coveo/searchEngine";
import { CoveoEngineContext } from "./CoveoEngineContext";
import logger from "../../../../utils/logger";

// Ensures the Coveo engine is initialized before showing its children
export const CoveoEngineWrapper = ({ children, fallback = null}) => {

  const [state, setState] = useState({});
  useEffect(() => {
    async function load() {
      if (window.scConfig.FORCE_AUTH0_DOCS_SEARCH) {
        setState({ disabled: true });
        return;
      }
      setState({ loading: true });
      try {
        const headlessEngine = await buildSearchEngine();
        setState({ headlessEngine });
      }
      catch (e) {
        logger.error(e, {}, "Error setting up the search engine. Falling back to default experience.");
        setState({ error: true });
      }
    }
    load();
  }, []);

  if (state.error || state.disabled) {
    return fallback;
  }

  if (!state.headlessEngine) {
    return null;
  }

  return (
    <CoveoEngineContext.Provider value={state.headlessEngine}>
      {children}
    </CoveoEngineContext.Provider>
  );
};

CoveoEngineWrapper.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node
};
