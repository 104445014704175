import React from 'react';
import { DEFERRED_UPLOAD_FAILURE_ALERT } from '../constants';

/**
 * Component for displaying deferred upload errors
 */
const DeferredUploadFailureAlert = () => {
  return (
    <div className="alert alert-with-icon alert-warning">
      <i className="alert-icon icon-budicon-354" />
      <div className="alert-content">
        <p>
          {DEFERRED_UPLOAD_FAILURE_ALERT[0]}
          <br />
          {DEFERRED_UPLOAD_FAILURE_ALERT[1]}
          <br />
          {DEFERRED_UPLOAD_FAILURE_ALERT[2]}
        </p>
      </div>
    </div>
  );
};

export default DeferredUploadFailureAlert;
