import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { HotjarMasked } from '@a0/support-center-components';

const TextInput = ({name, label, classes, onChange, onBlur, placeholder, value, error, disabled, textArea, tooltip, hotjarMasked }) => {
  let wrapperClass = 'form-group';
  let inputClass = 'form-control';
  if (error && error.length > 0) {
    wrapperClass += " " + 'has-error';
    inputClass += " " + 'has-error';
  }

  const placement = "right";

  let field = textArea ? (
    <textarea
      name={name}
      className={inputClass}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}/>
  ) : (
    <input
      type="text"
      name={name}
      className={inputClass}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled} />
  );

  if (hotjarMasked) {
    field = <HotjarMasked>{field}</HotjarMasked>;
  }

  const fieldLabel = label && (tooltip ? (
    <OverlayTrigger placement={placement} overlay={
      <Tooltip className={placement} id={name}>{tooltip}</Tooltip>
    }>
      <label htmlFor={name} className={classes}>{label}</label>
    </OverlayTrigger>
  ) : (
     <label htmlFor={name} className={classes}>{label}</label>
  ));

  return (
    <div className={wrapperClass}>
      {fieldLabel}
      <div className="field">
        {field}
        {error && (
          <div className="error-field">
            <div className="help-block">{error}.</div>
          </div>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  classes: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  textArea: PropTypes.bool,
  tooltip: PropTypes.string,
  hotjarMasked: PropTypes.bool
};

export default TextInput;
