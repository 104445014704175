const resources = {
  // Tooltips
  CONTACT_SALES_TOOLTIP:
    'Talk to sales about upgrading your account and other features that Auth0 offers',
  CURRENT_TOTAL_USERS_TOOLTIP:
    'Any user that authenticated with any connection or identity provider.',
  EMPLOYEE_USERS_TOOLTIP:
    'An internal employee of your company that authenticated with any connection or identity provider.',
  REGULAR_ACTIVE_USERS_TOOLTIP:
    'A user that has authenticated with username/password, passwordless connections or any social provider per calendar month, counted per application.',
  ENTERPRISE_ACTIVE_USERS_TOOLTIP:
    'A user that has authenticated using an enterprise connection like SAML, Active Directory or LDAP among others per calendar month, counted per application.',
  REGULAR_ACTIVE_USERS_TOOLTIP30D:
    'A user that has authenticated with username/password, passwordless connections or any social provider in the last 30 days, counted per application.',
  ENTERPRISE_ACTIVE_USERS_TOOLTIP30D:
    'A user that has authenticated using an enterprise connection like SAML, Active Directory or LDAP among others in the last 30 days, counted per application.'
};

export default resources;
