import React from 'react';
import PropTypes from 'prop-types';

import { filter, reduce} from 'lodash';

import Loading from '../../common/components/Loading';
import { ApplianceCard, Error, SummaryBar } from '@a0/support-center-components';

class PrivateSaas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      search: ''
    };

    this.searchChanged = this.searchChanged.bind(this);
    this.searchAppliances = this.searchAppliances.bind(this);
  }

  countTenants(appliances) {
    return reduce(appliances, (sum, appliance) => sum + appliance.tenants.length, 0);
  }

  searchChanged(event) {
    this.setState({...this.state, search: event.target.value});
  }

  searchAppliances(appliances) {
    if (!Array.isArray(appliances)) return new Array();

    const term = this.state.search.toUpperCase();
    return this.state.search
      ? filter(appliances, a => a.root_domain_auth.toUpperCase(term).indexOf(term) > -1)
      : appliances;
  }

  render() {
    const { appliances, error, loading } = this.props;
    const filteredAppliances = this.searchAppliances(appliances);

    if (!loading && !error && !appliances.length) return(
      <div className="alert alert-info">
        The appliance gateway is not configured, so no information is available for your Private Instances
      </div>
    );

    return (
      <Loading show={loading}>
        <Error message={error}>
          <div>
            <div className="row pad-top">
              <div className="alert alert-warning">
                <span className="alert-warning__icon" />
                Please allow up to 24 hours for environment changes to appear below.
              </div>
            </div>
            <div className="row search-filter-container">
              <div className="col-xs-12">
                <i className="icon-budicon-489" />
                <input
                  type="search"
                  name="search"
                  id="search-input"
                  value={this.state.search}
                  onChange={this.searchChanged}
                  placeholder="Search for Private Instances"
                />
              </div>
            </div>
            <div className="row">
              {filteredAppliances.map((appliance, i) => (
                <div className="col-xs-12 col-md-4" key={`appliance-${i}`}>
                  <ApplianceCard {...appliance} />
                </div>
              ))}
            </div>
          </div>
        </Error>
      </Loading>
    );
  }
}

PrivateSaas.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  versions: PropTypes.array,
  selectedTenant: PropTypes.object,
  appliances: PropTypes.array.isRequired
};

export default PrivateSaas;
