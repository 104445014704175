const cards = [{
  title:'Getting Started',
  description: 'Learn the basics of Auth0',
  icon: 'gettingStarted',
  href: 'https://auth0.com/docs/getting-started',
  links:[
    { title: 'Auth0 Overview', href: 'https://auth0.com/docs/getting-started/overview' },
    { title: 'The Basics', href: 'https://auth0.com/docs/getting-started/the-basics' },
    { title:'Dashboard Overview', href: 'https://auth0.com/docs/getting-started/dashboard-overview' }
  ]
},{
  title:'APIs',
  description: 'Working with Auth0 APIs and securing yours',
  icon: 'apis',
  href: 'https://auth0.com/docs/api/info',
  links:[
    { title: 'Auth0 Authentication API', href: 'https://auth0.com/docs/api/authentication' },
    { title: 'Auth0 Management API', href: 'https://auth0.com/docs/api/management/v2' },
    { title: 'Securing Your API', href: 'https://auth0.com/docs/api-auth' }
  ]
},{
  title:'SDKs/Quickstarts',
  description: 'Code snippets and packages to get you up and running',
  icon: 'sdks',
  href: 'https://auth0.com/docs#toc',
  links:[
    { title: 'Angular SDK', href: 'https://auth0.com/docs/quickstart/spa/angular2' },
    { title: 'React SDK', href: 'https://auth0.com/docs/quickstart/spa/react' },
    { title: 'Single-Page Application Quickstarts', href: 'https://auth0.com/docs/quickstart/spa' }
  ]
},{
  title:'User Profiles',
  description: 'Working with users and their profiles',
  icon: 'userProfiles',
  href: 'https://auth0.com/docs/user-profile',
  links:[
    { title: 'User Profile Details', href: 'https://auth0.com/docs/user-profile/user-profile-details' },
    { title: 'User Profile Structure', href: 'https://auth0.com/docs/user-profile/user-profile-structure' },
    { title: 'Normalized User Profiles', href: 'https://auth0.com/docs/user-profile/normalized' }
  ]
},{
  title:'Identity Providers',
  description: 'Integrating Auth0 with your identity providers',
  icon: 'idProv',
  color: 'yellow',
  href: 'https://auth0.com/docs/identityproviders',
  links:[
    { title: 'Database Connections', href: 'https://auth0.com/docs/connections/database' },
    { title: 'SAML', href: 'https://auth0.com/docs/saml-configuration' },
    { title: 'Single Sign-On', href: 'https://auth0.com/docs/sso/single-sign-on' }
  ]
},{
  title:'Extensibility',
  description: 'Adding to and customizing Auth0\'s standard feature set',
  icon: 'extensibility',
  href: 'https://auth0.com/docs/topics/extensibility',
  links:[
    { title: 'Auth0 Extensions', href: 'https://auth0.com/docs/extensions#using-an-auth0-provided-extension' },
    { title: 'Actions', href: 'https://auth0.com/docs/customize/actions' },
    { title: 'Migrate Rules/Hooks to Actions', href: 'https://auth0.com/docs/customize/actions/migrate' }
  ]
}];

export default cards;
