import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router';
import DocCard from './DocCard';

const DocCards = ({cards}) => {
  return (
    <div className="row card-docs-container">
      {cards.map( (card, index) =>
        <DocCard key={'card' + index} card={card}/>
      )}
    </div>
  );
};

DocCards.propTypes = {
  cards: PropTypes.array.isRequired
};

export default DocCards;
