import React from 'react';
import PropTypes from 'prop-types';


const ActionButton = ({ showLoader, text, loadingMessage, clickHandler, customStyle, type }) => {
  return (<button style={customStyle} disabled={showLoader}
    onClick={clickHandler}
    className={`btn btn-${!type ? 'primary' : type}`}>
    {showLoader ? loadingMessage : text}
  </button>);
};

ActionButton.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  loadingMessage: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  customStyle: PropTypes.object,
  type: PropTypes.string
};

export default ActionButton;