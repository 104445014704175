import React from 'react';
import PropTypes from 'prop-types';

import { IndexLink, browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  loadReportsTenantActiveUsers,
  paginateUsageReportsActiveUsers,
  loadReportsTotalUsers,
  paginateUsageReportsTotalUsers,
  filterUsageReportsActiveUsers,
  filterUsageReportsTotalUsers
} from '../actions/reportActions';

import { setSelectedTenant } from '../../tenants/actions/tenantActions';

import {
  filterTenantsBySubscription,
  getSelectedTenant
} from '@a0/support-center-common/selectors/common';

import {
  getSelectedSubscription,
  getSelectedSubscriptionType,
  getv8Subscriptions
}  from '@a0/support-center-common/selectors/subscriptions';

import { getV8SubscriptionName } from '../../../utils/V8PlanUtils';

import {
  getAllAssociatedTenantsForSelectedTenant
} from '../../tenants/selectors/tenants';

import {
  usageByMonthPerSubscription,
  usageByServicePerSubscription,
  usageByClientPerSubscription,
  usageTotalsPerSubscription
} from '../selectors/usage';

import AccountPicker from '../../common/components/AccountPicker';

import { UsageReport } from '@a0/support-center-components';

import SelfServiceMasterAlert from '../components/SelfServiceMasterAlert';

class UsagePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.selectTenant = this.selectTenant.bind(this);
    this.paginateActiveUsers = this.paginateActiveUsers.bind(this);
    this.paginateTotalUsers = this.paginateTotalUsers.bind(this);
    this.filterActiveUsers = this.filterActiveUsers.bind(this);
    this.filterTotalUsers = this.filterTotalUsers.bind(this);
  }

  componentDidMount() {
    this.props.loadReportsTenantActiveUsers();
    this.props.loadReportsTotalUsers();
  }

  // This is temporary for hide reports to Enterprise Customers
  componentWillReceiveProps({ selectedSubscription }) {
    if (selectedSubscription && selectedSubscription != this.props.selectedSubscription) {
      if (selectedSubscription.name !== 'Free' &&
          selectedSubscription.name !== 'Developer' &&
          selectedSubscription.name !== 'Trial' &&
          selectedSubscription.id !== 'v8'
      ) {
        browserHistory.push('/');
      }
    }
  }

  paginateActiveUsers(page) {
    if (page) {
      this.props.paginateActiveUsers(page);
      document.getElementById('usage-report').scrollIntoView({behavior: 'smooth'});
    } else {
      this.props.paginateActiveUsers(1);
    }
  }

  paginateTotalUsers(page) {
    if (page) {
      this.props.paginateTotalUsers(page);
      document.getElementById('usage-report').scrollIntoView({behavior: 'smooth'});
    } else {
      this.props.paginateTotalUsers(1);
    }
  }

  selectTenant(event) {
    this.props.setSelectedTenant(event.target.value);
  }

  filterActiveUsers(key) {
    return (value, event) => {
      const items = event && event.currentTarget.dataset.items;
      if (items) {
        this.props.filterActiveUsers(items.split(','), key);
      } else {
        this.props.filterActiveUsers(value, key);
      }

      this.paginateActiveUsers();
    };
  }

  filterTotalUsers(key) {
    return (value, event) => {
      const items = event && event.currentTarget.dataset.items;
      if (items) {
        this.props.filterTotalUsers(items.split(','), key);
      } else {
        this.props.filterTotalUsers(value, key);
      }

      this.paginateTotalUsers();
    };
  }

  render() {
    const {
      tenants,
      selectedSubscription,
      selectedSubscriptionType,
      currentTenant,
      associatedTenants,
      recordsByMonth,
      recordsByClient,
      recordsByService,
      recordsTotalUsers,
      activePageActiveUsers,
      activePageTotalUsers,
      loadingActiveUsers,
      loadingTotalUsers,
      errorActiveUsers,
      errorTotalUsers,
      filteringActiveUsers,
      filteringTotalUsers
    } = this.props;

    return (
      <div className="reports">
        <div className="bg-dots page-header-dots">
          <div className="container">
            <ol className="breadcrumb">
              <li><IndexLink to="/">Home</IndexLink></li>
              <li>Subscription Usage</li>
            </ol>
            <h1>Subscription Usage</h1>
          </div>
        </div>
        <div id="usage-report" className="container">
          {selectedSubscription && selectedSubscription.child_tenants && selectedSubscription.child_tenants > tenants.length &&
            <div className="alert alert-info">{`The ${selectedSubscription.name} subscription has a total of ${selectedSubscription.child_tenants} tenants. You are seeing the ${tenants.length} tenants you have administration access to.`}</div>}
          {tenants.length > 1 && currentTenant && !currentTenant.subscription.isCustomAgreement && !(selectedSubscription.id === 'v8') &&
            <AccountPicker tenants={tenants} currentTenant={currentTenant} onChange={this.selectTenant} selectedSubscriptionName={selectedSubscription && selectedSubscription.name} />}
          {currentTenant && currentTenant.master_tenant && !currentTenant.subscription.isCustomAgreement &&
            <SelfServiceMasterAlert tenants={associatedTenants} />}
          <UsageReport
            recordsByMonth={recordsByMonth}
            recordsByClient={recordsByClient}
            recordsByService={recordsByService}
            recordsTotalUsers={recordsTotalUsers}
            activePageActiveUsers={activePageActiveUsers}
            activePageTotalUsers={activePageTotalUsers}
            loadingActiveUsers={loadingActiveUsers}
            loadingTotalUsers={loadingTotalUsers}
            errorActiveUsers={errorActiveUsers}
            errorTotalUsers={errorTotalUsers}
            paginateActiveUsers={this.paginateActiveUsers}
            paginateTotalUsers={this.paginateTotalUsers}
            filterActiveUsers={this.filterActiveUsers}
            filterTotalUsers={this.filterTotalUsers}
            filteringActiveUsers={filteringActiveUsers}
            filteringTotalUsers={filteringTotalUsers}
          />
        </div>
      </div>
    );
  }
}

UsagePage.propTypes = {
  selectedSubscription: PropTypes.object,
  selectedSubscriptionType: PropTypes.string,
  currentTenant: PropTypes.object,
  tenants: PropTypes.array.isRequired,
  associatedTenants: PropTypes.array.isRequired,
  recordsByMonth: PropTypes.array.isRequired,
  recordsByClient: PropTypes.array.isRequired,
  recordsByService: PropTypes.array.isRequired,
  recordsTotalUsers: PropTypes.array.isRequired,
  loadReportsTenantActiveUsers: PropTypes.func.isRequired,
  loadReportsTotalUsers: PropTypes.func.isRequired,
  loadingActiveUsers: PropTypes.bool,
  loadingTotalUsers: PropTypes.bool,
  errorActiveUsers: PropTypes.string,
  errorTotalUsers: PropTypes.string,
  paginateActiveUsers: PropTypes.func.isRequired,
  paginateTotalUsers: PropTypes.func.isRequired,
  activePageActiveUsers: PropTypes.number.isRequired,
  activePageTotalUsers: PropTypes.number.isRequired,
  filterActiveUsers: PropTypes.func.isRequired,
  filterTotalUsers: PropTypes.func.isRequired,
  filteringActiveUsers: PropTypes.object.isRequired,
  filteringTotalUsers: PropTypes.object.isRequired,
  setSelectedTenant: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    selectedSubscription: getSelectedSubscription(state, getV8SubscriptionName(getv8Subscriptions(state))),
    selectedSubscriptionType: getSelectedSubscriptionType(state),
    currentTenant: getSelectedTenant(state.tenants),
    tenants: filterTenantsBySubscription(state),
    associatedTenants: getAllAssociatedTenantsForSelectedTenant(state),
    loadingActiveUsers: state.reportsTenantActiveUsers.loading,
    loadingTotalUsers: state.reportsTenantTotalUsers.loading,
    errorActiveUsers: state.reportsTenantActiveUsers.error,
    errorTotalUsers: state.reportsTenantTotalUsers.error,
    activePageActiveUsers: state.reportsTenantActiveUsers.page,
    activePageTotalUsers: state.reportsTenantTotalUsers.page,
    filteringActiveUsers: state.reportsTenantActiveUsers.filtering,
    filteringTotalUsers: state.reportsTenantTotalUsers.filtering,
    recordsByMonth: usageByMonthPerSubscription(state),
    recordsByService: usageByServicePerSubscription(state),
    recordsByClient: usageByClientPerSubscription(state),
    recordsTotalUsers: usageTotalsPerSubscription(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filterActiveUsers: bindActionCreators(filterUsageReportsActiveUsers, dispatch),
    filterTotalUsers: bindActionCreators(filterUsageReportsTotalUsers, dispatch),
    paginateActiveUsers: bindActionCreators(paginateUsageReportsActiveUsers, dispatch),
    paginateTotalUsers: bindActionCreators(paginateUsageReportsTotalUsers, dispatch),
    loadReportsTotalUsers: bindActionCreators(loadReportsTotalUsers, dispatch),
    loadReportsTenantActiveUsers: bindActionCreators(loadReportsTenantActiveUsers, dispatch),
    setSelectedTenant: bindActionCreators(setSelectedTenant, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsagePage);
