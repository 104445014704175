import api from 'api';
import { browserHistory } from 'react-router';
import logger from '../../../utils/logger';
import * as drift from '../../../utils/drift';
import * as types from '../../../redux/actionTypes';
import history from '../../../history';
import {
  AUTHORIZATION_ERROR
} from '../../../utils/logger/logTypes';
import { loadCredentials } from './userActions';

let auth0React;
function getAuth0React() {
  return auth0React;
}
function setAuth0React(auth0ReactState) {
  auth0React = auth0ReactState;
}

// the useAuth0() hook in auth0-react provides authentication functions (loginWithRedirect, getAccessTokenSilently, logout)
// and some state properties: isAuthenticated, isLoading, error, user.

// App.js calls this function when the auth0-react state changes
// This function remembers the full state and functions, and dispatches an action
// to update the state on redux.
export function updateAuth0ReactState(auth0Object) {
  return async function(dispatch, getState) {
    setAuth0React(auth0Object);
    const previousAuthState = getState().auth;
    // auth0-react will check when first starting if the user has a valid session.
    // the check is based on a cookie set by auth0-spa-js that has a limited lifetime.
    // When starting, isLoading will be true, until the session is checked or the callback redirection is processed.

    dispatch(authenticationStatusChanged(auth0Object.isAuthenticated, auth0Object.user, auth0Object.isLoading, auth0Object.error));

    // if the state switchs from non authenticated to authenticated, load all the user related data
    if (!previousAuthState.isAuthenticated && auth0Object.isAuthenticated &&!auth0Object.isLoading) {
      dispatch(loadCredentials());
    }

    // if the session wasn't automatically checked by auth0-react (no cookie was found),
    // we'll trigger a manual check so that we magically log the user in if they have a session in the RTA.
    if (!previousAuthState.initialCheckPerformed && !auth0Object.isAuthenticated && !auth0Object.isLoading && !auth0Object.error) {
      // try getTokenSilently() once
      dispatch(sessionCheckBegin());
      try {
        await auth0Object.getAccessTokenSilently();
        dispatch(sessionCheckSuccess());
      }
      catch (err) {
        // expected responses if the user needs to interact to authorize
        // login_required", "interaction_required", "mfa_required"
        if (["login_required", "interaction_required", "consent_required"].includes(err.error)) {
          dispatch(sessionCheckSuccess());
        } else {
          dispatch(sessionCheckError(err));
        }
      }
    }
  };
}

/**
 * Handles redirect after authentication callback.
 * We need a custom handler to use react-router
 * @param {Function} appState provided by auth0-react
 */
export async function handleRedirectCallback(appState, user) {
  const returnTo = appState && appState.returnTo || '/';
  history.replace(returnTo);
}

function handleLogin(returnTo = "/") {
  getAuth0React().loginWithRedirect({
    appState: {
      returnTo
    }
  });
}

/**
 * Logout action
 * @returns {Function}
 */
export function logout() {
  return function(dispatch) {
    drift.logout();
    getAuth0React().logout({
      logoutParams: {
        returnTo: window.scConfig.SUPPORT_CENTER_LOGOUT_URL || window.location.origin
      }
    });
  };
}

export const login = returnTo => dispatch => {
  handleLogin(returnTo);
  // getAuthentication disabled due to non working auth call
  // Refer to DXSUP-2027 for additional info

  // const logUnavailableLogin = experimenting => {
  //   const pipelineMsg = experimenting ? 'experimenting pipeline' : 'main pipeline';
  //   const message = `Authentication API is down using ${pipelineMsg}`;

  //   logger.error(new Error(message), { log_type: AUTHORIZATION_ERROR }, `AUTH_API_CHECK_RESULT_DOWN: ${message}`);
  // };

  // api.checks
  //   .getAuthentication()
  //   .then(({ status, experimenting }) => {
  //     dispatch(loadAuthenticationStatus(status));
  //     if (status && status === 'down') {
  //       browserHistory.push('/unavailable-login');
  //       logUnavailableLogin(experimenting);
  //     } else {
  //       handleLogin(returnTo);
  //     }
  //   })
  //   .catch(error => {
  //     dispatch(loadAuthenticationStatusError(error));
  //     handleLogin(returnTo);
  //   });
};

export function getAccessTokenSilently() {
  return getAuth0React().getAccessTokenSilently();
}

export function getUser() {
  return getAuth0React().user;
}

export function authenticationStatusChanged(isAuthenticated, user, isLoading, error) {
  return { type: types.AUTHENTICATION_STATUS_CHANGED, isAuthenticated, user, isLoading, error };
}

export function sessionCheckBegin() {
  return { type: types.SESSION_CHECK_BEGIN };
}

export function sessionCheckSuccess() {
  return { type: types.SESSION_CHECK_SUCCESS };
}

export function sessionCheckError(error) {
  return { type: types.SESSION_CHECK_FAILED, error };
}

export function loadAuthenticationStatus(status) {
  return { type: types.LOAD_AUTHENTICATION_STATUS, status };
}

export function loadAuthenticationStatusError(error) {
  return { type: types.LOAD_AUTHENTICATION_STATUS_ERROR, error };
}

