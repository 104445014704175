import {
  LOAD_COMPLIANCE_DOCUMENT_META_BEGIN,
  LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS,
  LOAD_COMPLIANCE_DOCUMENT_META_ERROR,

  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN,
  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR,
  LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS,

  LOAD_NDA_STATUS_BEGIN,
  LOAD_NDA_STATUS_ERROR,
  LOAD_NDA_STATUS_SUCCESS,
  LOAD_NDA_STATUS_RESET
} from './actionTypes';

import api from 'api';

const {
  requestAllDocumentMeta,
  requestSignedDownloadUrl,
  requestNdaStatus
} = api.compliance;

/*  
 * Load all compliance document metadata
 */
export const loadComplianceDocumentMetaBegin   = ()      => ({ type: LOAD_COMPLIANCE_DOCUMENT_META_BEGIN });
export const loadComplianceDocumentMetaSuccess = records => ({ type: LOAD_COMPLIANCE_DOCUMENT_META_SUCCESS, records });
export const loadComplianceDocumentMetaError   = error   => ({ type: LOAD_COMPLIANCE_DOCUMENT_META_ERROR, error });

export const loadComplianceDocumentMeta = () => dispatch => {
  dispatch(loadComplianceDocumentMetaBegin());
  
  return requestAllDocumentMeta()
  .then(records => dispatch(loadComplianceDocumentMetaSuccess(records)))
  .catch(error  => dispatch(loadComplianceDocumentMetaError(error)));
};

/*
 * Get a signed download URL for a compliance document
 */
export const loadComplianceDocumentSignedUrlBegin   = ()     => ({ type: LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_BEGIN });
export const loadComplianceDocumentSignedUrlError   = error  => ({ type: LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_ERROR, error });
export const loadComplianceDocumentSignedUrlSuccess = ()     => ({ type: LOAD_COMPLIANCE_DOCUMENT_SIGNED_URL_SUCCESS });

export const loadComplianceDocumentSignedUrl = key => dispatch => {
  dispatch(loadComplianceDocumentSignedUrlBegin());

  return requestSignedDownloadUrl(key)
  .then(({ signedUrl }) => {
    window.location = signedUrl;
    dispatch(loadComplianceDocumentSignedUrlSuccess());
  })
  .catch(error => dispatch(loadComplianceDocumentSignedUrlError(error)));
};

/*
 * Find out whether an NDA was signed for the provided subscription 
 */
export const loadNdaStatusBegin   = ()     => ({ type: LOAD_NDA_STATUS_BEGIN });
export const loadNdaStatusSuccess = record => ({ type: LOAD_NDA_STATUS_SUCCESS, record });
export const loadNdaStatusError   = error  => ({ type: LOAD_NDA_STATUS_ERROR, error });
export const loadNdaStatusReset   = ()     => ({ type: LOAD_NDA_STATUS_RESET });

export const loadNdaStatus = userId => dispatch => {
  dispatch(loadNdaStatusBegin());

  return requestNdaStatus(userId)
  .then(record => {
    dispatch(loadNdaStatusSuccess(record));
  })
  .catch(error => {
    dispatch(loadNdaStatusError(error));
  });
};

