import AdditionalQuestions from './AdditionalQuestions';
import ApplianceTenant from './ApplianceTenant';
import Comment from './Comment';
import CommentPlain from './CommentPlain';
import Collaborators from './Collaborators';
import Email from './Email';
import Environment from './Environment';
import ProposedDate from './ProposedDate';
import TargetVersion from './TargetVersion';
import Reason from './Reason';
import Region from './Region';
import RootTenantAuthority from './RootTenantAuthority';
import Severity from './Severity';
import Subject from './Subject';
import Tenant from './Tenant';
import TenantName from './TenantName';
import Type from './Type';

/**
 * Aggregation of fields used in NewTicketForm
 */
export default {
  AdditionalQuestions,
  ApplianceTenant,
  Comment,
  CommentPlain,
  Collaborators,
  Email,
  Environment,
  ProposedDate,
  Region,
  Reason,
  RootTenantAuthority,
  Severity,
  Subject,
  TargetVersion,
  Type,
  Tenant,
  TenantName
};