import { buildRecommendationEngine as buildCoveoRecommendationEngine } from "@coveo/headless/recommendation";
import { getOrganizationEndpoints, loadGenericAnalyticsActions } from "@coveo/headless";
import { createRenewInvalidAccessTokenMiddleware } from "./renewInvalidTokenMiddleware";
import { getSearchTokenResponse, renewAccessToken, TokenType } from "./coveoToken";

const RECOMMENDATIONS_SEARCH_HUB = "SupportCenterRecommendations";

let headlessEngine;
export async function buildRecommendationEngine() {
  const renewRecommendationsToken = () => renewAccessToken(TokenType.RECOMMENDATIONS);
  const tokenResponse = await getSearchTokenResponse(TokenType.RECOMMENDATIONS);
  const getEngine = () => headlessEngine;
  headlessEngine = buildCoveoRecommendationEngine({
    configuration: {
      accessToken: tokenResponse.token,
      renewAccessToken: renewRecommendationsToken,

      // The CoveoSearchEngine can obtain the search hub from a claim in the access token
      // but the CoveoRecommendationsEngine can't do that for now
      // so we need to specify the search hub for analytics to work properly.
      searchHub: RECOMMENDATIONS_SEARCH_HUB,
      organizationId: tokenResponse.coveo_org_id,
      organizationEndpoints: getOrganizationEndpoints(tokenResponse.coveo_org_id)
    },
    middlewares: [createRenewInvalidAccessTokenMiddleware(getEngine, renewRecommendationsToken)]
  });
  return headlessEngine;
}

export const COVEO_ANALYTICS_EVENT = {
  CASE_CANCEL: {
    type: "Cancel",
    value: "CaseCancel"
  },
  CASE_SUBMIT: {
    type: "Submit",
    value: "CaseSubmit"
  }
};

export function logCustomEvent(event) {
  if (headlessEngine) {
    const { logCustomEvent } = loadGenericAnalyticsActions(headlessEngine);
    const payload = {
        evt : event.value,
        type: event.type
    };
    headlessEngine.dispatch(logCustomEvent(payload));
  }
}
