import xss from 'xss';

export const sanitizeHTML = new xss.FilterXSS({
    onIgnoreTagAttr(tag, name, value, isWhiteAttr) {
      if (name === 'class') {
        const sanitizedValue = value.replace(/\'/g,'');
        return `${name}='${sanitizedValue}'`;
      }
    }
});
