import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HotjarMasked } from '@a0/support-center-components';

class SelectInput extends Component {

  renderSelect() {
    const {
      name,
      label,
      onChange,
      defaultOption,
      optionsGroupLabel,
      disabled,
      value,
      options,
      classes = null
    } = this.props;

    return (
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={"form-control " + classes}>
        {optionsGroupLabel && <optgroup label={optionsGroupLabel || null}>
          {defaultOption && <option value="">{defaultOption}</option>}
          {options.map((option, index) => <option key={name + '|' + index} value={option.value}>{option.text}</option>)}
        </optgroup>}

        {!optionsGroupLabel && defaultOption && <option value="">{defaultOption}</option>}
        {!optionsGroupLabel && options.map((option, index) => <option key={name + '|' + index} value={option.value}>{option.text}</option>)}
      </select>
    );
  }

  render() {

    const {
      name,
      label,
      error,
      classes = null,
      hotjarMasked
    } = this.props;

    return (
      <div className="form-group">
        {label && <label className={"control-label " + classes} htmlFor={name}>{label}</label>}
        <div className="field">
          {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
          {hotjarMasked && <HotjarMasked>{this.renderSelect()}</HotjarMasked>}
          {!hotjarMasked && this.renderSelect()}
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    );
  }
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  optionsGroupLabel: PropTypes.string,
  hotjarMasked: PropTypes.bool
};

export default SelectInput;
