import { map, reduce, flatten, groupBy, orderBy } from 'lodash';

export const getQuotaReportLoadingStatus = ({reportsTenantQuota, tenants, tenantAgreements}) =>
  reportsTenantQuota.loading
  || tenants.loading
  || tenantAgreements.loading;

export const getQuotaReportError = ({
  reportsTenantQuota
}) => reportsTenantQuota.error;

export const getQuotaReportData = ({reportsTenantQuota}) =>
  reportsTenantQuota.record;

export const getQuotaReportActivePage = ({ reportsTenantQuota }) =>
  reportsTenantQuota.page;

export const getQuotaReportFiltering = ({ reportsTenantQuota }) =>
  reportsTenantQuota.filtering;

export const getQuotaReportType = ({reportsTenantQuota}) =>
  reportsTenantQuota.record.reportType || 'unavailable';

export const activeUserByService = records => {
  const collection = {};
  const fields = {
    "users_regular": 0,
    "users_enterprise": 0
  };

  map(records, record => {
    const tenant = collection[record.tenant] = collection[record.tenant] || Object.assign({}, fields);
    map(fields, (field,fieldName) => tenant[fieldName] = tenant[fieldName] + +record[fieldName]);
  });

  return map(collection, (tenant, tenantName) => Object.assign({tenant: tenantName}, tenant));
};

export const activeUserByTenantMonth = records => {
  const tenants = groupBy(records, 'tenant');

  return orderBy(flatten(map(tenants, (tenant, tenantName) => {
    const months = groupBy(tenant, 'month');

    return map(months, (month, monthName) => {
      return reduce(month, (prev, next) => {
        return Object.assign(prev, {
          users_regular: +prev.users_regular + +next.users_regular,
          users_enterprise: +prev.users_enterprise + +next.users_enterprise
        });
      }, {
        month: monthName,
        tenant: tenantName,
        users_regular: 0,
        users_enterprise: 0
      });
    });
  })), ['month'], ['desc']);
};

export const activeUserByMonth = records => {
  const months = groupBy(records, 'month');

  return map(months, (month, monthName) => {
    return reduce(month, (prev, next) => {
      return Object.assign(prev, {
        users_regular: +prev.users_regular + +next.users_regular,
        users_enterprise: +prev.users_enterprise + +next.users_enterprise
      });
    }, {
      month: monthName,
      users_regular: 0,
      users_enterprise: 0
    });
  });
};

export const isQuotaOverquota = state => {
  const reportData = getQuotaReportData(state);

  const overquota =
    reportData && reportData.overview && reportData.overview.overquota;

  return (
    overquota &&
    (overquota.employeeUsers ||
      overquota.enterpriseUsers ||
      overquota.regularUsers)
  );
};
