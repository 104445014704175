import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const SecureLink = ({ href, children, target, onClick }) => {
  if (!href || !children) {
    return null;
  }

  return (
    <a rel="noopener noreferrer" href={href} target={target} onClick={onClick}>
      {children}
    </a>
  );
};

SecureLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func
};

SecureLink.defaultProps = {
  onClick: noop,
  target: '_blank'
};

export default SecureLink;
