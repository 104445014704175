export default function getErrorMessage(error){
  let errorMessage = error.statusText || error.status || error.message || 'Unknown Server Error';
  // Maybe some data is available.
  const err = (error.data && error.data.message) || (error.data && error.data.error) || error.error;
  if (err) {
    errorMessage = err.message || err;
  }

  if (error.status === 500) {
    errorMessage = 'We are aware of an issue with your request and working to resolve it.  We are sorry for the inconvenience.  Retry or contact support if the issue persists.';
  }

  return errorMessage;
}
