export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_ERROR = 'LOAD_USER_DATA_ERROR';

export const LOAD_USER_AGENT_SUCCESS = 'LOAD_USER_AGENT_SUCCESS';
export const LOAD_USER_AGENT_ERROR = 'LOAD_USER_AGENT_ERROR';

export const SET_SELECTED_TENANT = 'SET_SELECTED_TENANT';
export const LOAD_TENANTS_IDS_FOR_USER = 'LOAD_TENANTS_IDS_FOR_USER';
export const LOAD_TENANTS_BEGIN = 'LOAD_TENANTS_BEGIN';
export const LOAD_TENANTS_SUCCESS = 'LOAD_TENANTS_SUCCESS';
export const LOAD_TENANTS_ERROR = 'LOAD_TENANTS_ERROR';

export const SET_SELECTED_AGREEMENT = 'SELECTED_AGREEMENT';
export const LOAD_TENANT_AGREEMENTS_IDS_FOR_USER = 'LOAD_TENANT_AGREEMENTS_IDS_FOR_USER';
export const LOAD_TENANT_AGREEMENTS_BEGIN = 'LOAD_TENANT_AGREEMENTS_BEGIN';
export const LOAD_TENANT_AGREEMENTS_SUCCESS = 'LOAD_TENANT_AGREEMENTS_SUCCESS';
export const LOAD_TENANT_AGREEMENTS_ERROR = 'LOAD_TENANT_AGREEMENTS_ERROR';

export const LOAD_TENANTS_SUMMARY_BEGIN = 'LOAD_TENANTS_SUMMARY_BEGIN';
export const LOAD_TENANTS_SUMMARY_SUCCESS = 'LOAD_TENANTS_SUMMARY_SUCCESS';
export const LOAD_TENANTS_SUMMARY_ERROR = 'LOAD_TENANTS_SUMMARY_ERROR';

export const LOAD_REPORTS_TENANT_ACTIVE_USERS_BEGIN = 'LOAD_REPORTS_TENANT_ACTIVE_USERS_BEGIN';
export const LOAD_REPORTS_TENANT_ACTIVE_USERS_SUCCESS = 'LOAD_REPORTS_TENANT_ACTIVE_USERS_SUCCESS';
export const LOAD_REPORTS_TENANT_ACTIVE_USERS_ERROR = 'LOAD_REPORTS_TENANT_ACTIVE_USERS_ERROR';
export const PAGINATE_REPORTS_TENANT_ACTIVE_USERS = 'PAGINATE_REPORTS_TENANT_ACTIVE_USERS';
export const FILTER_REPORTS_TENANT_ACTIVE_USERS = 'FILTER_REPORTS_TENANT_ACTIVE_USERS';
export const RESET_FILTER_REPORTS_TENANT_ACTIVE_USERS = 'RESET_FILTER_REPORTS_TENANT_ACTIVE_USERS';

export const LOAD_REPORTS_TENANT_TOTAL_USERS_BEGIN = 'LOAD_REPORTS_TENANT_TOTAL_USERS_BEGIN';
export const LOAD_REPORTS_TENANT_TOTAL_USERS_SUCCESS = 'LOAD_REPORTS_TENANT_TOTAL_USERS_SUCCESS';
export const LOAD_REPORTS_TENANT_TOTAL_USERS_ERROR = 'LOAD_REPORTS_TENANT_TOTAL_USERS_ERROR';
export const PAGINATE_REPORTS_TENANT_TOTAL_USERS = 'PAGINATE_REPORTS_TENANT_TOTAL_USERS';
export const FILTER_REPORTS_TENANT_TOTAL_USERS = 'FILTER_REPORTS_TENANT_TOTAL_USERS';
export const RESET_FILTER_REPORTS_TENANT_TOTAL_USERS = 'RESET_FILTER_REPORTS_TENANT_TOTAL_USERS';

// Quota Reports
export const LOAD_REPORTS_TENANT_QUOTA_BEGIN = 'LOAD_REPORTS_TENANT_QUOTA_BEGIN';
export const LOAD_REPORTS_TENANT_QUOTA_SUCCESS = 'LOAD_REPORTS_TENANT_QUOTA_SUCCESS';
export const LOAD_REPORTS_TENANT_QUOTA_ERROR = 'LOAD_REPORTS_TENANT_QUOTA_ERROR';
export const LOAD_REPORTS_TENANT_QUOTA_RESET = 'LOAD_REPORTS_TENANT_QUOTA_RESET';

export const PAGINATE_REPORTS_TENANT_QUOTA = 'PAGINATE_REPORTS_TENANT_QUOTA';
export const FILTER_REPORTS_TENANT_QUOTA = 'FILTER_REPORTS_TENANT_QUOTA';
export const RESET_FILTER_REPORTS_TENANT_QUOTA = 'RESET_FILTER_REPORTS_TENANT_QUOTA';

// Features Reports
export const LOAD_REPORTS_TENANT_FEATURES_BEGIN = 'LOAD_REPORTS_TENANT_FEATURES_BEGIN';
export const LOAD_REPORTS_TENANT_FEATURES_SUCCESS = 'LOAD_REPORTS_TENANT_FEATURES_SUCCESS';
export const LOAD_REPORTS_TENANT_FEATURES_ERROR = 'LOAD_REPORTS_TENANT_FEATURES_ERROR';
export const LOAD_REPORTS_TENANT_FEATURES_RESET = 'LOAD_REPORTS_TENANT_FEATURES_RESET';

export const LOAD_REPORTS_TENANT_M2M_DAILY_BEGIN = 'LOAD_REPORTS_TENANT_M2M_DAILY_BEGIN';
export const LOAD_REPORTS_TENANT_M2M_DAILY_SUCCESS = 'LOAD_REPORTS_TENANT_M2M_DAILY_SUCCESS';
export const LOAD_REPORTS_TENANT_M2M_DAILY_ERROR = 'LOAD_REPORTS_TENANT_M2M_DAILY_ERROR';
export const LOAD_REPORTS_TENANT_M2M_DAILY_RESET = 'LOAD_REPORTS_TENANT_M2M_DAILY_RESET';
export const PAGINATE_REPORTS_TENANT_M2M_DAILY = 'PAGINATE_REPORTS_TENANT_M2M_DAILY';
export const FILTER_REPORTS_TENANT_M2M_DAILY = 'FILTER_REPORTS_TENANT_M2M_DAILY';
export const RESET_FILTER_REPORTS_TENANT_M2M_DAILY = 'RESET_FILTER_REPORTS_TENANT_M2M_DAILY';

export const PAGINATE_REPORTS_TENANT_FEATURES = 'PAGINATE_REPORTS_TENANT_FEATURES';
export const FILTER_REPORTS_TENANT_FEATURES = 'FILTER_REPORTS_TENANT_FEATURES';
export const RESET_FILTER_REPORTS_TENANT_FEATURES = 'RESET_FILTER_REPORTS_TENANT_FEATURES';

// Enterprise Connections
export const LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_BEGIN = 'LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_BEGIN';
export const LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_SUCCESS = 'LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_SUCCESS';
export const LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_ERROR = 'LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_ERROR';
export const LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_RESET = 'LOAD_REPORTS_TENANT_ENTERPRISE_CONNECTIONS_RESET';

export const PAGINATE_REPORTS_TENANT_ENTERPRISE_CONNECTIONS = 'PAGINATE_REPORTS_TENANT_ENTERPRISE_CONNECTIONS';
export const FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS = 'FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS';
export const RESET_FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS = 'RESET_FILTER_REPORTS_TENANT_ENTERPRISE_CONNECTIONS';


// Whitelist
export const LOAD_REPORTS_ENABLED_BEGIN = 'LOAD_REPORTS_ENABLED_BEGIN';
export const LOAD_REPORTS_ENABLED_SUCCESS = 'LOAD_REPORTS_ENABLED_SUCCESS';
export const LOAD_REPORTS_ENABLED_ERROR = 'LOAD_REPORTS_ENABLED_ERROR';
export const LOAD_REPORTS_ENABLED_RESET = 'LOAD_REPORTS_ENABLED_RESET';

// Appliance Last Updated Usage
export const LOAD_APPLIANCE_LAST_UPDATED_USAGE_BEGIN = 'LOAD_APPLIANCE_LAST_UPDATED_USAGE_BEGIN';
export const LOAD_APPLIANCE_LAST_UPDATED_USAGE_SUCCESS = 'LOAD_APPLIANCE_LAST_UPDATED_USAGE_SUCCESS';
export const LOAD_APPLIANCE_LAST_UPDATED_USAGE_ERROR = 'LOAD_APPLIANCE_LAST_UPDATED_USAGE_ERROR';
export const LOAD_APPLIANCE_LAST_UPDATED_USAGE_RESET = 'LOAD_APPLIANCE_LAST_UPDATED_USAGE_RESET';

export const LOAD_ORG_TICKETS_BEGIN = 'LOAD_ORG_TICKETS_BEGIN';
export const LOAD_ORG_TICKETS_SUCCESS = 'LOAD_ORG_TICKETS_SUCCESS';
export const LOAD_ORG_TICKETS_ERROR = 'LOAD_ORG_TICKETS_ERROR';

export const LOAD_MY_TICKETS_BEGIN = 'LOAD_MY_TICKETS_BEGIN';
export const LOAD_MY_TICKETS_SUCCESS = 'LOAD_MY_TICKETS_SUCCESS';
export const LOAD_MY_TICKETS_ERROR = 'LOAD_MY_TICKETS_ERROR';

export const LOAD_TICKET_BEGIN = 'LOAD_TICKET_BEGIN';
export const LOAD_TICKET_SUCCESS = 'LOAD_TICKET_SUCCESS';
export const LOAD_TICKET_ERROR = 'LOAD_TICKET_ERROR';

export const COMMENT_TICKET_SUCCESS = 'COMMENT_TICKET_SUCCESS';
export const COMMENT_TICKET_ERROR = 'COMMENT_TICKET_ERROR';

export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_ERROR = 'CREATE_TICKET_ERROR';

export const LOAD_TICKET_CATEGORIES_BEGIN = 'LOAD_TICKET_CATEGORIES_BEGIN';
export const LOAD_TICKET_CATEGORIES_SUCCESS = 'LOAD_TICKET_CATEGORIES_SUCCESS';
export const LOAD_TICKET_CATEGORIES_ERROR = 'LOAD_TICKET_CATEGORIES_ERROR';

export const AUTHENTICATION_STATUS_CHANGED = 'AUTHENTICATION_STATUS_CHANGED';
export const SESSION_CHECK_BEGIN = 'SESSION_CHECK_BEGIN';
export const SESSION_CHECK_SUCCESS = 'SESSION_CHECK_SUCCESS';
export const SESSION_CHECK_FAILED = 'SESSION_CHECK_FAILED';

export const SEARCH_BEGIN = 'SEARCH_BEGIN';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_FILTER = 'SEARCH_FILTER';

export const LOAD_NOTIFICATIONS_BEGIN = 'LOAD_NOTIFICATIONS_BEGIN';
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS';
export const LOAD_NOTIFICATIONS_ERROR = 'LOAD_NOTIFICATIONS_ERROR';

export const LOAD_NOTIFICATION_BEGIN = 'LOAD_NOTIFICATION_BEGIN';
export const LOAD_NOTIFICATION_SUCCESS = 'LOAD_NOTIFICATION_SUCCESS';
export const LOAD_NOTIFICATION_ERROR = 'LOAD_NOTIFICATION_ERROR';

export const SET_NOTIFICATION_FILTER_OPTION = 'SET_NOTIFICATION_FILTER_OPTION';

export const RESET_UPLOADS = 'RESET_UPLOADS';
export const UPLOAD_FILE_BEGIN = 'UPLOAD_FILE_BEGIN';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const DELETE_UPLOAD_BEGIN = 'DELETE_UPLOAD_BEGIN';
export const DELETE_UPLOAD_ERROR = 'DELETE_UPLOAD_ERROR';

export const LOAD_STATUS_SUCCESS = 'LOAD_STATUS_SUCCESS';
export const LOAD_STATUS_ERROR = 'LOAD_STATUS_ERROR';

export const LOAD_AUTHENTICATION_STATUS = 'LOAD_AUTHENTICATION_STATUS';
export const LOAD_AUTHENTICATION_STATUS_ERROR = 'LOAD_AUTHENTICATION_STATUS_ERROR';

export const SET_PAYING_ACCOUNT_STATUS = 'SET_PAYING_ACCOUNT_STATUS';

export const LOAD_APPLIANCES_BEGIN = 'LOAD_APPLIANCES_BEGIN';
export const LOAD_APPLIANCES_SUCCESS = 'LOAD_APPLIANCES_SUCCESS';
export const LOAD_APPLIANCES_ERROR = 'LOAD_APPLIANCES_ERROR';

export const LOAD_APPLIANCE_VERSIONS_BEGIN = 'LOAD_APPLIANCE_VERSIONS_BEGIN';
export const LOAD_APPLIANCE_VERSIONS_SUCCESS = 'LOAD_APPLIANCE_VERSIONS_SUCCESS';
export const LOAD_APPLIANCE_VERSIONS_ERROR = 'LOAD_APPLIANCE_VERSIONS_ERROR';

export const SET_EXTEND_TRIAL_TENANT_ID = 'SET_EXTEND_TRIAL_TENANT_ID';
export const EXTEND_TRIAL_BEGIN = 'EXTEND_TRIAL_BEGIN';
export const EXTEND_TRIAL_SUCCESS = 'EXTEND_TRIAL_SUCCESS';
export const EXTEND_TRIAL_ERROR = 'EXTEND_TRIAL_ERROR';
export const EXTEND_TRIAL_RESET = 'EXTEND_TRIAL_RESET';

export const UPDATE_TENANT_BEGIN = 'UPDATE_TENANT_BEGIN';
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_SUCCESS';
export const UPDATE_TENANT_ERROR = 'UPDATE_TENANT_ERROR';

// Tenant Checks
export const LOAD_TENANT_CHECKS_BEGIN = 'LOAD_TENANT_CHECKS_BEGIN';
export const LOAD_TENANT_CHECKS_SUCCESS = 'LOAD_TENANT_CHECKS_SUCCESS';
export const LOAD_TENANT_CHECKS_ERROR = 'LOAD_TENANT_CHECKS_ERROR';
export const LOAD_TENANT_CHECKS_RESET = 'LOAD_TENANT_CHECKS_RESET';

export const RUN_TENANT_CHECKS_BEGIN = 'RUN_TENANT_CHECKS_BEGIN';
export const RUN_TENANT_CHECKS_SUCCESS = 'RUN_TENANT_CHECKS_SUCCESS';
export const RUN_TENANT_CHECKS_ERROR = 'RUN_TENANT_CHECKS_ERROR';

export const RESULT_TENANT_CHECKS_BEGIN = 'RESULT_TENANT_CHECKS_BEGIN';
export const RESULT_TENANT_CHECKS_SUCCESS = 'RESULT_TENANT_CHECKS_SUCCESS';
export const RESULT_TENANT_CHECKS_ERROR = 'RESULT_TENANT_CHECKS_ERROR';
export const RESULT_TENANT_CHECKS_RESET = 'RESULT_TENANT_CHECKS_RESET';

// Tenant Clients
export const LOAD_TENANT_CLIENTS_BEGIN = 'LOAD_TENANT_CLIENTS_BEGIN';
export const LOAD_TENANT_CLIENTS_SUCCESS = 'LOAD_TENANT_CLIENTS_SUCCESS';
export const LOAD_TENANT_CLIENTS_ERROR = 'LOAD_TENANT_CLIENTS_ERROR';
export const LOAD_TENANT_CLIENTS_RESET = 'LOAD_TENANT_CLIENTS_RESET';

export const LOAD_RECENT_NOTIFICATIONS_BEGIN = 'LOAD_RECENT_NOTIFICATIONS_BEGIN';
export const LOAD_RECENT_NOTIFICATIONS_SUCCESS = 'LOAD_RECENT_NOTIFICATIONS_SUCCESS';
export const LOAD_RECENT_NOTIFICATIONS_ERROR = 'LOAD_RECENT_NOTIFICATIONS_ERROR';

export const UPDATE_NOTIFICATION_STATE_BEGIN = 'UPDATE_NOTIFICATION_STATE_BEGIN';
export const UPDATE_NOTIFICATION_STATE_SUCCESS = 'UPDATE_NOTIFICATION_STATE_SUCCESS';
export const UPDATE_NOTIFICATION_STATE_ERROR = 'UPDATE_NOTIFICATION_STATE_ERROR';

//Email Validation Enforcement
export const SEND_EMAIL_VERIFICATION_BEGIN = 'SEND_EMAIL_VERIFICATION_BEGIN';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_ERROR = 'SEND_EMAIL_VERIFICATION_ERROR';
