import { safeSessionStorage } from '../../../services/storage';

/**
 * Set the ticket state in session storage
 * @param {Object} ticketState Ticket state
 */
export function setTicketStateInStorage(ticketState) {
  safeSessionStorage.setItem('ticketState', JSON.stringify(ticketState));
}

/**
 * Get the ticket state from session storage
 * @return {Object} Ticket state
 */
export function getTicketStateFromStorage() {
  return JSON.parse(safeSessionStorage.getItem('ticketState'));
}

/**
 * Clean the ticket state from session storage
 */
export function clearTicketStateFromStorage() {
  safeSessionStorage.removeItem('ticketState');
}
