export const getEnterpriseConnectionsReportData = ({ reportsTenantEnterpriseConnections }) =>
  reportsTenantEnterpriseConnections.record;

export const getEnterpriseConnectionsReportLoadingStatus = ({ reportsTenantEnterpriseConnections }) =>
  reportsTenantEnterpriseConnections.loading;

export const getEnterpriseConnectionsReportError = ({ reportsTenantEnterpriseConnections }) =>
  reportsTenantEnterpriseConnections.error;

export const getEnterpriseConnectionsReportActivePage = ({ reportsTenantEnterpriseConnections }) =>
  reportsTenantEnterpriseConnections.page;

export const getEnterpriseConnectionsReportFiltering = ({ reportsTenantEnterpriseConnections }) =>
  reportsTenantEnterpriseConnections.filtering;

export const isEnterpriseConnectionsOverquota = state => {
  const reportData = getEnterpriseConnectionsReportData(state);

  const overquota =
    reportData && reportData.overview && reportData.overview.overquota;

  return (
    overquota &&
    (overquota.connections ||
      overquota.usersPerConnection)
  );
};
