import React from 'react';
import PropTypes from 'prop-types';
import { IndexLink, Link } from 'react-router';
import { HotjarMasked } from '@a0/support-center-components';
import DeferredUploadFailureAlert from './DeferredUploadFailureAlert';

import {
  inErrorState,
  propTypes as deferredUploadPropTypes
} from '../../uploads/reducers/deferredUploadReducer';

const STEP_CREATE = 'create';
const STEP_ADD_COMMENT = 'update';

/**
 * Determine if a DeferredUploadFailureAlert should be shown
 */
function showDeferredUploadFailureAlert(deferredUploads, step, ticket) {
  // Abort early if no files were queued
  if (!deferredUploads.queue.length) {
    return false;
  }

  // If the ticket creation fallback will be used files won't be uploaded
  if (step === STEP_CREATE && !ticket.id || step === STEP_ADD_COMMENT && !ticket.subject) {
    return true;
  }

  // If an error occurred during upload
  return inErrorState(deferredUploads);
}


const TicketResultPage = ({
  ticket, ticketSubject, step, handleUpdateLink, deferredUploads
}) => {
  if (step !== STEP_CREATE && step !== STEP_ADD_COMMENT) {
    throw new Error(`step attribute should be "${STEP_CREATE}" or "${STEP_ADD_COMMENT}" but received: ${step}`);
  }

  const printSuccessMsg = (step, ticket) => {
    if (step === STEP_CREATE && ticket.id) {
      return `Your request #${ticket.id} has been received and is being reviewed by our support staff.`;
    } else if (step === STEP_CREATE) {
      return `Your request has been received and is being reviewed by our support staff. It can take a few minutes until it appears in the ticket list.`;
    } else if (step === STEP_ADD_COMMENT && ticket.id && ticket.subject) {
      return `You successfully added a comment to ticket #${ticket.id}.`;
    } else if (step === STEP_ADD_COMMENT && ticket.id) {
      return `You successfully added a comment to the ticket #${ticket.id}. The changes can take a few minutes to be reflected in the Support Center.`;
    }
  };

  return (
    <div className="ticket-result">
      <div className="bg-dots page-header-dots hide-for-mobiles"></div>
      <div className="row">
        <div className="bg-dots page-header-dots">
          <div className="container page-canvas col-md-6 col-md-offset-3">
            <ol className="breadcrumb">
              <li><IndexLink to="/">Home</IndexLink></li>
              <li><Link to="/tickets">Tickets</Link></li>
              <li><HotjarMasked isInline>{ticket.subject || ticketSubject}</HotjarMasked></li>
            </ol>

            <h2>{step === 'create' ? 'Ticket created!' : 'Comment Sent!'}</h2>
            <p>{printSuccessMsg(step, ticket)}</p>

            {showDeferredUploadFailureAlert(deferredUploads, step, ticket) && <DeferredUploadFailureAlert />}
            <div>
              {ticket.id && (
                <Link
                  onClick={handleUpdateLink}
                  to={`/tickets/${ticket.id}`}
                  className="btn btn-transparent"
                >
                  Update Ticket
                </Link>
              )}
              <Link to="/tickets" className="btn btn-success">View All Tickets</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TicketResultPage.propTypes = {
  ticket: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  handleUpdateLink: PropTypes.func,
  ticketSubject: PropTypes.string,
  deferredUploads: deferredUploadPropTypes.isRequired
};

export default TicketResultPage;
