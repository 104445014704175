import * as types from '../../../redux/actionTypes';
import initialState from '../../../redux/initialState';
import moment from 'moment';

export default function orgTicketsReducer(state = initialState.orgTickets, action) {
  switch (action.type) {
    case types.LOAD_ORG_TICKETS_BEGIN:
      return Object.assign({}, state, {loading:true, error: null, records: []});
    case types.LOAD_ORG_TICKETS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        records: action.tickets
      });
    case types.LOAD_ORG_TICKETS_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: `An error occurred while loading the tenant's tickets: ${action.errorMessage}`,
        records: []});
    default:
      return state;
  }
}
