// This component handles the App template used on every page.
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './features/common/components/Header';
import Auth0Footer from '@a0/auth0-footer';
import * as authActions from './features/login/actions/authActions';
import * as contactActions from './features/sales/actions/contactActions';
import { loadLocalities as commonLoadLocalities } from '@a0/support-center-common/lib/localities';
import api from './api';


const App = ({ actions, location, auth, children }) => {
  const auth0State = useAuth0();

  useEffect(function loadLocalitiesOnAppStart() {
    commonLoadLocalities(async () => await api.localities.get());
  }, []);

  useEffect(function propagateAuth0Reactstate() {
    actions.updateAuth0ReactState(auth0State);
  }, [auth0State]);

  function onLogin(event) {
    event.preventDefault();
    actions.login(location.pathname);
  }

  function onLogout(event) {
    event.preventDefault();
    actions.logout();
  }

  function onContactSales(event, metadata) {
    event.preventDefault();
    actions.contactSales('header', metadata);
  }

  const changeLang = () => {
    return null;
  };
  return (
    <div className="support-page">
      <Header
        auth={auth}
        onLogin={onLogin}
        onLogout={onLogout}
        contactSales={onContactSales}
      />
      <div>{children}</div>
      <div className="footer-container">
        <Auth0Footer
          baseUrl="https://auth0.com"
          theme="light"
          lang="en"
          onChangeLang={changeLang} />
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions, ...contactActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
