import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const ExpirationDate = ({ date }) => {
  if (date) {
    return (
      <span
        style={{ verticalAlign: "middle" }}
        className="subscription-tag-expired"
      >
        Expires: {moment(date).format("YYYY-MM-DD")}
      </span>
    );
  }

  return null;
};

ExpirationDate.propTypes = {
  date: PropTypes.string
};

export default ExpirationDate;
