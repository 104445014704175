import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import NavbarItem from './NavbarItem';
import DropdownMore from './DropdownMore';

export const NUM_FLAT_MENU_ITEMS = 0;

/** 
 * Fit as many items as possible in the menu, put the rest under a 'more' dropdown
 * Returns bootstrap classes defining which menu format should show (flat, more dropdown) based on screen size
 * @param {number} index
 * @param {invert} boolean
 * @returns {string} className
 */
export const resolveClasses = (index, invert) => {
  if (index >= NUM_FLAT_MENU_ITEMS) {
    return invert ? '' : 'hide';
  }

  return invert ? 'hidden-md hidden-lg' : 'visible-md-inline-block visible-lg-inline-block';
};

/**
 * Component that wraps a group of NavbarItems
 * Items will either be shown inline or in a 'more' dropdown, depending on context
 * @param {object} props
 */
const NavbarItemGroup = ({ offset = 0, children, firstActive }) => {
  if (!Array.isArray(children)) { children = [children]; }
  children = children.filter(c => c);

  if (children.length == 1) {
    return <NavbarItem active={firstActive}>{children}</NavbarItem>;
  }

  const flat = children.map((item, i) => (
    <NavbarItem key={i} className={resolveClasses(i+offset)}>{item}</NavbarItem>
  ));

  const menu = (() => {
    // Don't show the menu at full width if it has no items
    let menuClass = '';
    if (children.length + offset <= 1) {
      menuClass = 'hidden-md hidden-lg';
    }

    return (
      <DropdownMore className={menuClass}>
        {children.map((item, i) => (
          <div key={i} className={resolveClasses(i+offset, true)}>{item}</div>
        ))}
      </DropdownMore>
    );
  })();

  // Return both versions and show the correct items via media query
  return (
    <React.Fragment>
      {flat}
      {menu}
    </React.Fragment>
  );
};

NavbarItemGroup.propTypes = {
  offset: PropTypes.number,
  firstActive: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired
};

export default NavbarItemGroup;