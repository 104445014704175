import * as types from '../../redux/actionTypes';
import initialState from '../../redux/initialState';

export default (state = initialState.appliances, action) => {
  
  switch (action.type) {  
    case types.LOAD_APPLIANCES_BEGIN:
      return {
        ...state,
        error:   null,
        loading: true,
        records: []
      };

    case types.LOAD_APPLIANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error:   null,
        records: action.records
      };

    case types.LOAD_APPLIANCES_ERROR:
      return {
        ...state,
        loading: false,
        error: `An error occurred while loading your Auth0 appliances: ${action.errorMessage}`
      };

    case types.LOAD_APPLIANCE_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: action.records
      };

    default:
      return state;
  }
  
};