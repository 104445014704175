import PageHeader from '../../../features/common/components/PageHeader';
import PageWrap from '../../../features/common/components/PageWrap';
import Breadcrumb from '../../../features/common/components/Breadcrumb';

import IntroductionParagraph from '../../common/components/Introduction';

import React, { Component } from 'react';

import { title } from '../constants';

export class ComplianceIndex extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHeader>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/compliance">Compliance</Breadcrumb.Item>
          </Breadcrumb>
          <h1>{title}</h1>
        </PageHeader>
        <PageWrap>
          <IntroductionParagraph>
            As part of our ongoing commitment to security and compliance we have
            launched a consolidated Security Trust Center.
          </IntroductionParagraph>
          <IntroductionParagraph>
            <strong>Enterprise Customer Identity Cloud (CIC) customers</strong>{" "}
            can now access the most up-to-date Security and Compliance
            documentation at{" "}
            <a href="https://security.okta.com" target="_blank">
              https://security.okta.com
            </a>
            .
          </IntroductionParagraph>
          <IntroductionParagraph>
            <strong>Self-service customers</strong>, please{" "}
            <a
              href="https://console.whistic.com/v2/request-profile/7e852245-565f-491c-93be-7264bd3e4c52/9a788f68-1f8e-4244-8633-1a86a3e19282"
              target="_blank"
            >
              click here
            </a>
            .
          </IntroductionParagraph>
        </PageWrap>
      </React.Fragment>
    );
  }
}

ComplianceIndex.propTypes = {};

export default ComplianceIndex;
