import { request } from 'api';

class UserApi {

  static getUserInfo(user_id) {
    return request(`users/${encodeURIComponent(user_id)}/info`);
  }

  static getUserData(user_id) {
    return request(`users/${encodeURIComponent(user_id)}/data`);
  }
}

export default UserApi;
