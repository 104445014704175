import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Link } from 'react-router';

const supportLogo = require('../../../img/error-msg-icon.svg');
const statusPageUrl = window.scConfig.STATUS_PAGE_URL;

const UnavailableSupport = ({title, subtitle, withImage, withBorder, withBackToHome, withAskCommunity }) => (
  <div className="unavailable-support">
    <div className="row">
      <div className={classNames({ 'card-docs': withBorder, 'unavailable-support-container': withBorder })}>
        {withImage ? (
          <div className="col-md-3">
            <img className="unavailable-support-icon" src={supportLogo} alt=""/>
          </div>
        ) : ""}
        <div className={classNames('text-left', { 'col-md-9': withImage, 'col-md-12': !withImage })}>
          <h3 className="unavailable-support-title">{title}</h3>
          <div className="unavailable-support-text">
            {subtitle && (<p><strong className="unavailable-support-subtitle">{subtitle}</strong></p>)}
            <p>There is a possible service disruption and our team is investigating it. Please refer to the <a href={statusPageUrl} target="_blank">status page</a> for the latest updates on the availability of services.</p>
            <p>Meanwhile, our team is here to support you. If needed, eligible accounts are still able to open a ticket using the button below. You can also <a href="https://auth0.com/docs/support" target="_blank">refer to this link</a> for further support options.</p>

          </div>
          <div>
            {withBackToHome ? (<Link to="/" className="btn btn-transparent btn-lg btn-forum unavailable-support-back-home">Back to home</Link>) : ""}
            {withAskCommunity ? (<a href="https://community.auth0.com/" className="btn btn-success btn-lg">Ask our community</a>): ""}
            <Link to="/tickets/offline/new" className="btn btn-transparent btn-lg btn-forum unavailable-support-offline-ticket">Open Ticket</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
);

UnavailableSupport.defaultProps = {
  title: "We are unable to log you into Support Center at this time.",
  withImage: false,
  withBorder: false,
  withAskCommunity: false
};

UnavailableSupport.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  withImage: PropTypes.bool,
  withBorder: PropTypes.bool,
  withBackToHome: PropTypes.bool,
  withTryAgain: PropTypes.bool,
  withAskCommunity: PropTypes.bool
};

export default UnavailableSupport;
