'use strict';
// Polyfill webpack require.ensure for testing
if (typeof require.ensure !== `function`) require.ensure = (dependencies, callback) => callback(require);

export default function() {
  return new Promise(function(resolve, reject) {
    require.ensure([], function(require) {
      try {
        resolve(require('highlight.js'));
      } catch (e) {
        reject (e);
      }
    }, function(err) {
      reject(err);
    }, 'HighlightJS');
  });
}
