import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

export const DownloadFileLink = ({ file, children }) => {
  const [blobUrl, setBlobUrl] = useState(null);

  useEffect(function createBlobURL() {
    const blobUrl = URL.createObjectURL(file);
    setBlobUrl(blobUrl);
    return function revokeObjectURL() {
      URL.revokeObjectURL(blobUrl);
    };
  }, [file]);

  return (
    blobUrl && (
      <a href={blobUrl} download={file.name}>
        <i className="icon-budicon-355 icon" />
          {" "}{children || "download"}
      </a>
    )
  );
};

DownloadFileLink.propTypes = {
  file: PropTypes.object.isRequired,
  children: PropTypes.node
};
