import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import normalizeErrorMiddleware from './middlewares/normalizeErrorMiddleware';
import redirectToLoginIfUnauthorized from './middlewares/redirectToLoginIfUnauthorized';
import rootReducer from './rootReducer';

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, normalizeErrorMiddleware(), redirectToLoginIfUnauthorized)
  );
}
