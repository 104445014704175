import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {Link} from 'react-router';
import Metrics from '../../../utils/metrics';
import moment from 'moment';
import { Auth0Notification } from '@a0/support-center-components';
import SecureLink from './SecureLink';

const TrialAccountAlert = ({ multiple, subscription }) => {
  const trackEvent = typeOfEvent => () =>
    Metrics.track(`click:sc:${typeOfEvent}`, {trackData: 'trial-account-alert'});

  // subscription.trialDates.trialEnds should contain string with date format
  const expirationDays = _.get(subscription, 'trialDates.trialEnds', '');
  const daysTillExpire = expirationDays && moment.duration(moment(expirationDays).diff(moment())).as('days');
  const prefix = 'Your trial concludes in ';

  return (
    <Auth0Notification type="primary">
      Auth0 Trial customers can open support tickets during the trial period. We also encourage you to visit the <SecureLink href="https://community.auth0.com" onClick={trackEvent('ask-forum')}>Auth0 Community</SecureLink>.&nbsp;
      {!multiple && expirationDays && (
        daysTillExpire > 1 ?
        <span className="expiration-days">{prefix}<strong>{Math.ceil(daysTillExpire)}</strong> days.&nbsp;</span> :
        <span className="expiration-days">{prefix}<strong>less than a day</strong>.&nbsp;</span>
      )}
      For more information check out our <SecureLink href="https://auth0.com/docs/support" onClick={trackEvent('support-access')}>support policies</SecureLink>.
    </Auth0Notification>
  );
};

TrialAccountAlert.propTypes = {
  subscription: PropTypes.object.isRequired,
  multiple: PropTypes.bool
};

TrialAccountAlert.defaultProps = {
  subscription: { trialDates: {} }
};

export default TrialAccountAlert;
