import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Metrics from '../../../../utils/metrics';

import './module.css';

class TicketEscalation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
  }

  hideModal() {
    this.setState( { showModal: false });
  }

  showModal() {
    Metrics.track('click:sc:ticket:escalate', {
      tracData: 'tenant',
      value: this.props.tenant
    });
    this.setState( { showModal: true });
  }

  handleConfirmation() {
    this.hideModal();
    Metrics.track('click:sc:ticket:escalate-confirm', {
      tracData: 'tenant',
      value: this.props.tenant
    });
    this.props.onEscalate();
  }

  render() {
    return (
      <div>
        <div styleName="panel">
          <div styleName="panel_text">
            <strong styleName="red">Escalate this ticket</strong>
            <div>
              This feature is for urgent production offline incidents only.
            </div>
          </div>
          <div styleName="panel_button">
            <button className="btn btn-danger btn-open-modal" styleName="btn_danger"
                    onClick={this.showModal}
                    disabled={this.props.disabled}>
              Reply & Escalate
            </button>
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.hideModal} keyboard>
          <Modal.Header className="has-border">
            <Modal.Title as="h4">Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>
              By escalating this ticket, you are acknowledging that this affects all production users.&nbsp;
              <strong>This option should only be used for production application outages.</strong>
            </span>
          </Modal.Body>
          <Modal.Footer>
            <div styleName="modal_btns" className="btn-toolbar">
              <button type="default"
                      className="btn btn-danger btn-confirm"
                      styleName="btn_danger"
                      onClick={this.handleConfirmation}>
                Escalate
              </button>
              <button type="default" className="btn btn-default btn-cancel" onClick={this.hideModal}>
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TicketEscalation.propTypes = {
  onEscalate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tenant: PropTypes.string.isRequired
};

export default TicketEscalation;
